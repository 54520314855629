.dashboard_top_customer_img {
  max-width: 130px;
  border-radius: 50%;
  max-height: 130px;
  height: 100%;
  width: 100%;
}

.dashboard_top_customer {
  display: flex !important;
  flex-direction: row;
  /* margin: 20px; */
  padding: 30px 50px 50px 50px;
}

.dashboard_top_customer_det {
  padding-left: 20px;
}

.w_130px {
  float: left;
  width: 130px;
}

.dashboard_top_customer_det h4 {
  margin: 0;
}

.dashboard_top_customer_det {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard_top_customer .image {
  height: 150px;
}

.mb_3px {
  margin-bottom: 3px;
}

.sizeandweight {
  font-size: 18px;
  font-weight: 700;
}

.green_font {
  color: #33a348;
}

.word_break {
  word-break: break-word;
}

.line-clamp {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* .company-name-set span {
  word-break: break-word;
  display: flex;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

} */
.slider-widget-right .top-cus-right {
  position: relative;
  padding-left: 10px;
}
.slider-widget-right .top-cus-right::before {
  content: ':';
  position: absolute;
  left: 0;
}
@media (max-width: 1600px) {
  .license-main span:nth-child(2) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    width: 102px;
  }
}

@media (max-width: 1280px) {
  /* .dashboard_customer-main .float-left.w-30.image {
    display: inline-block;
    width: 13%;
  } */
}

@media (max-width: 1024px) {
  .balance-main-set span.red_font {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .dashboard_top_customer.dashboard_customer-main .image {
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 7%;
  }
}

/* .dashboard_top_customer_mob .w_130px {
  width: 85px;
} */
