.section-header {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.4rem;
  min-height: 4rem; */
  float: left;
  /* margin-bottom: .5rem; */
  margin-left: 0.3rem;
}
.section-header--dropdown-title {
  font-weight: 500;
  color: var(--grey-text);
  font-size: 1.4rem;
}

.section-header--dropdown .custom-dropdown.button-text .button {
  font-size: 1.4rem;
}

.section-header--dropdown .custom-dropdown {
  margin-left: .5rem;
  margin-right: .8rem;
/* margin-bottom: 1rem; */
  min-width: 90px;
  width: 90%;
  background-color: #FFF;
}

.section-header--dropdown .custom-dropdown.button-text .button:hover {
  background: transparent;
}
.section-header--dropdown .custom-dropdown.button-text .button {
  margin: 0;
  /* border: 1px solid var(--border-dark); */
  padding-left: 0.8rem;
  padding-right: 1.5rem;
  border-radius: 0;
  /* background-color: #FFF; */

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 89%;
  max-width: 90%;
  display: block;

  font-size: 14px;
  color: #000;
  font-weight: 500;
}
/* change color of svg */
.section-header--dropdown .custom-dropdown.button-text .button > svg {
  fill: #000;
  vertical-align: -30%;
  position: absolute;
  right: 5px;
}
/* Svg Path color */
.section-header--dropdown .custom-dropdown.button-text .button > svg > path {
fill: #000;
}

.section-header--dropdown .custom-dropdown.button-text .custom-dropdown-container {
  left: 0;
  top: 100%;
  /* margin-top: 1rem; */
  border: 1px solid var(--border-dark);
  box-shadow: var(--grey-box-shadow);
  top: 34px !important;
  bottom: auto !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.section-header--dropdown .custom-dropdown-container > .custom-dropdown--options:hover, .section-header--dropdown .custom-dropdown.button-text .button-active + .custom-dropdown-container .custom-dropdown--options:hover {
  background-color: #DFE1E5;
}

.section-header--button {
  background: var(--blue-500);
  color: #fff;
  padding: 1rem 1.2rem 1rem 0rem;
  border-radius: .4rem;
  font-size: 1.4rem;
  transition: all .15s ease-in-out;
}
.section-header--button:hover {
  background: var(--blue-500-active);
  color: #fff;
}
.section-header--button > svg {
  fill: #fff;
  margin-bottom: .3rem;
}

/* NOTE: css is used to implement chevron as react select does not support custom icon prop */
.pagination-route .Select-arrow {
  display: inline-block;
  width: 8px; height: 8px;
  transform: rotate(45deg) translate(-3px, 0px);
  border: none;
  border-right: 2px solid #ADB6BC;
  border-bottom: 2px solid #ADB6BC;
}

.pagination-route .Select.is-open .Select-arrow {
  top: 0;
  border: none;
  transform: rotate(45deg) translate(-3px, 0px);
  border-right: 2px solid #ADB6BC;
  border-bottom: 2px solid #ADB6BC;
}
.float-left{float: left;}