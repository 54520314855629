.inline-terms-and-conditions {
  display: flex !important;
  flex-direction: column !important;
}

.radio_buttons {
  display: flex;
}

.radio_buttons label {
  margin-bottom: 0;
}

.radio_buttons.radio-SO div {
  margin-top: 0 !important;
  margin-left: 10px !important;
}

.inline-terms-and-conditions .form-field.inline-field {
  padding-top: 0;
}

/* .radio_buttons .form-field.inline-field.textarea-so-terms{
  padding-top: 0 !important;
} */

.ml-2 {
  margin-left: 2% !important;
}

.mt-10 {
  margin-top: 10%;
}

.middle-row {
  display: flex;
  /* align-items: center; */
}

.pr-0 {
  padding-right: 0 !important;
}

.radio-SO {
  margin-top: 0.3rem;
}

.radio-SO div div {
  margin-left: 0rem !important;
}

.radio-SO div {
  margin-top: 0.5rem !important;
}

.previous_sold .form-field {
  /* margin-top: 1.5rem; */
  margin-bottom: 1.2rem;
}

.bill_error {
  margin-bottom: 0.8rem;
}
.form-field.inline-field.textarea-so-terms textarea {
  width: 22.5rem !important;
  width: 490px !important;
}

.so-mg-1 {
  margin-bottom: 1rem;
}

.saleOrder_title_svg .ims-modal--title svg {
  margin-bottom: 2px;
}

.ims-modal.white.contact-modal-width {
  width: 72.3% !important;
}

.ims-modal.white.contact-modal-width .add_contact_tabs {
  height: calc(100vh - 200px);
}

.ims-modal.white.contact-modal-width .add_contact_tabs .layout--body {
  height: calc(100vh - 300px) !important;
}

.ims-modal.white.contact-modal-width
  .add_contact_tabs
  .layout--body
  .layout--box {
  height: 100%;
}

.so_view_invoices .contact svg,
.sales-return-main.sales_order_set .more_details ul li:nth-child(4) svg {
  transform: rotate(-89deg);
}

.modal_BulkItems_title_align {
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  width: 15%;
}

.modal_BulkItems_title_align span svg {
  height: 20px;
  width: 16px;
}

.sales-order-dropdown button.custom-dropdown--options.dropdown-cus-set {
  width: 100% !important;
}

html body .sales-order-dropdown svg.custom-dropdown-icon {
  left: 6px !important;
}

html body .sales-order-dropdown span.label-set {
  position: absolute;
  left: 23%;
}

.create_sales_order .searchable-dropdown__placeholder {
  padding-left: 7px;
}

.create_sales_order .searchable-dropdown__input {
  padding-left: 7px;
}

.create_sales_order .searchable-dropdown__single-value {
  padding-left: 7px;
}

.middle_row_set .description_box {
  margin-right: 28px;
}

.middle-row.middle_row_set .description_box textarea {
  width: 480px !important;
}

.sales-order-main-set > .form-field.inline-field:nth-child(2),
.sales-order-main-set > .form-field.inline-field:nth-child(3) {
  margin-right: 0;
}

@media (max-width: 1280px) {
 

  .blue {
    padding: 7px !important;
  }

  .salesorder_add_actions {
    float: left;
    width: 100%;
  }

  .so-addcontact-popup .pl-contacts {
    padding-left: 0;
  }

  .invoice-popup-set.UIUX-addtax-popup .form-input.large,
  .invoice-popup-set.UIUX-addtax-popup .tax-form .inline-field:nth-child(2),
  .invoice-popup-set .tax-form .inline-field:nth-child(3) input,
  .bulk_item_popup .ims-modal--title .modal_BulkItems_title_align,
  .bulk_item_popup .ims-modal--title {
    width: 100%;
  }

  .bulk_item_popup .ims-modal--title {
    padding-bottom: 5px;
  }

  .invoice-popup-set .tax-form {
    display: flex;
    flex-wrap: wrap;
  }

  .action-buttons {
    float: right;
    flex: 0 0 100%;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    margin-right: 0 !important;
  }
.invoice-main-set button.view-details-btn {
    left: auto;
    right: 23px;
  }

  .action-buttons.So-actions {
    padding-right: 0;
  }
}

@media (max-width: 1199px) {
  html body .sidepanel {
    width: 28%;
  }

  .add-new-tax-popup input {
    width: 100% !important;
  }
}

@media (max-width: 1099px) {
  .salesorder-form-set .form-field.inline-field {
    width: 48%;
  }

  .form-field.form-table--container.salesorder_main_form {
    padding-right: 20px;
  }

  .middle-row .inline-terms-and-conditions {
    padding: 0 0 0 13px;
  }

  /* .form.salesorder-form .form-field.inline-field.so_feilds, .form-field.inline-field.description_box {
    margin-right: 0;
  } */

  .salesorder-form .form-field.inline-field.description_box,
  .sales-order-inner .form-field.inline-field.so_feilds,
  .form-field.inline-field.description_box + .inline-terms-and-conditions {
    flex: 0 0 50%;
    width: 50% !important;
  }

  .salesorder_add_actions .action-buttons {
    margin-right: 1.7rem !important;
  }

  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    white-space: break-spaces;
  }

  .large.bulk.invoice-popup-set .bulk-add-items--item-row .check_icon {
    right: 4px;
    width: 16px;
  }

  .sales-order-main-set > div:not(.sales-order-inner) {
    width: 50%;
  }

  /* .salesorder-form-set .form-field.inline-field.textarea-so-terms,
  html body .salesorder-form-set .so_feilds input,
  .salesorder-form-set .so_feilds .searchable-dropdown.large,
  html
    body
    .salesorder-form-set
    .so-customer-address
    .so-customer-address-section,
  .salesorder-form-set
    .middle-row.middle_row_set
    .form-field.inline-field.textarea-so-terms
    textarea,
  .salesorder-form-set .middle-row .inline-terms-and-conditions,
  .middle-row.middle_row_set .description_box textarea,
  .form-field.form-table--container.salesorder_main_form,
  .sales-order-main-set > div .form-field,
  .salesorder-form .middle-row textarea,
  .sales-order-main-set > div input,
  .sales-order-main-set > div .so_feilds .searchable-dropdown.large,
  .sales-order-main-set .so-customer-address .so-customer-address-section {
    width: 100% !important;
  } */

  .sales-order-main-set > .form-field.inline-field:nth-child(2),
  .sales-order-main-set .form-field.inline-field.so_feilds:nth-child(even),
  .sales-order-inner .form-field.inline-field.so_feilds:nth-child(even) {
    padding-left: 15px;
  }

  .sales-order-main-set button.view-details-btn {
    right: 2px;
    left: auto;
  }

  .sales-order-main-set + .form-field.form-table--container {
    width: 100% !important;
  }

  .purchase-order-set.purchase-order-vendor button.view-details-btn {
    left: auto;
    right: 3px;
  }

  html body .warehouse_add_new .form-field.inline-field:nth-child(5) {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .salesorder-form-set
    .form-field.inline-field
    .so-customer-address-section:first-child,
  .salesorder-form-set .form-field.inline-field {
    margin-right: 0.7rem;
  }

  .salesorder-form-set .form-field.inline-field:nth-child(odd) {
    margin-right: 15px;
  }

  .middle-row .inline-terms-and-conditions {
    padding: 0;
  }

  .salesorder-delivery-popup.ims-modal.large,
  .new-payment-inner-popup.large,
  .new-recieve-payment-popup.ims-modal.large,
  .view-sales-return-So.ims-modal.large,
  .so_view_invoices.ims-modal.large,
  .view-package-popup.ims-modal.large,
  .large.bulk.invoice-popup-set,
  div.invoice-popup-set.contact-modal-width,
  .salesorder-delivery-popup,
  .ims-modal.white.Shipment_modals_fields,
  .ims-modal.white.address-popup-set {
    min-width: 90%;
  }

  .salesorder-delivery-popup .ims-modal--body {
    padding-top: 0;
  }

  .so-addcontact-popup .add_contact_tabs_info h1 {
    height: 6rem;
    padding-left: 5rem;
  }

  .so-addcontact-popup .add_contact_tabs_info {
    width: 25%;
  }

  html body div.so-addcontact-popup .layout--body {
    width: 75% !important;
  }

  .salesorder-delivery-popup.ims-modal.large .delivery_modal_title,
  .so-addcontact-popup .form-field-set .searchable-dropdown.large,
  .so-addcontact-popup .form-field-set,
  .so-addcontact-popup .form-field-set input,
  div.invoice-popup-set.contact-modal-width .form-field.inline-field {
    display: block !important;
    width: 100%;
  }

  .view-package-popup.ims-modal.large .main-table-w,
  div.invoice-popup-set.contact-modal-width
    .form-field.inline-field
    .form-input.large {
    width: 100%;
  }

  .view-package-popup.ims-modal.large .pckage-module-inner {
    padding-right: 20px;
  }

  .salesorder-delivery-popup.ims-modal.large .delivery_modal_title,
  .new-payment-inner-popup.large .payment_modal_title {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
  }

  .salesorder-delivery-popup.ims-modal.large .delivery_modal_title span,
  .new-payment-inner-popup.large .payment_modal_title span {
    margin-right: 10px;
  }

  .so_view_invoices .left_sender_Sec .icon + span {
    padding-left: 5px;
  }

  .view_items_popup .detail_component_inner.detais-component-main {
    overflow: unset;
  }

  .cus-popup-style .package-item-info.sales_return_table {
    overflow-x: auto;
  }

  .cus-popup-style
    .package-item-info.sales_return_table
    td.right-align:nth-child(2)
    input {
    width: 160px;
  }

  /* html body .options-dropdown .custom-dropdown.button-text .button-active+.custom-dropdown-container .custom-dropdown--options {
    padding-left: 16px !important;
  } */
  .form-field.inline-field.description_box + .inline-terms-and-conditions {
    padding-left: 16px;
  }
}

@media (max-width: 890px) {
  .new-sales-account form.form.account-form .float-left.w-100.mb_3,
  .warehouse_add_new .form-section--body,
  .create_new_account_inventory .account-form > div,
  .add_new_tax .tax-form,
  .new-recieve-payment-popup .payment-form-set > div,
  /* .ims-modal.white.Shipment_modals_fields form, */
  .add_address_width form.address-form > div {
    display: flex;
    flex-wrap: wrap;
  }
  html body .warehouse_add_new .action-buttons {
    padding-right: 0 !important;
  }
  .salesorder-form-set .horizontal-items-table tr td:nth-child(1) {
    width: 29% !important;
  }

  .salesorder-form-set .horizontal-items-table tr td:nth-child(5) {
    width: 16% !important;
  }

  .blue {
    padding: 7px;
  }

  .ims-modal.white.address-popup-set .action-buttons {
    margin-right: 0.1rem !important;
  }
  .new-recieve-payment-popup .action-buttons,
  .shipment-popup-set-main .action-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0;
    margin-right: 0 !important;
  }

  .new-recieve-payment-popup .payment-record textarea#notes,
  .shipment-popup-set-main .text_area_postion textarea#notes {
    margin-bottom: 10px;
  }
.invoice-popup-set .tax-form .inline-field:nth-child(3) {
    padding-right: 0;
  }
 .form.shipment-create-form .form-field.inline-field,
  .shipment-popup-set-main .form-field.inline-field {
    display: unset;
  }

  .create_sales_order.horizontal-items-table td:nth-child(1) {
    width: 29% !important;
  }

  .create_sales_order.horizontal-items-table td:nth-child(5) {
    width: 16% !important;
  }
  .form-field.inline-field.description_box + .inline-terms-and-conditions {
    padding-left: 0;
  }

  html body .sidepanel {
    width: 39% !important;
  }

  .add_new_tax .tax-form .form-field:nth-child(1) {
    margin-bottom: 0 !important;
  }

  .form-field.inline-field.textarea-so-terms {
    margin-right: 0;
  }

  .salesorder-form .form-field.inline-field {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .salesorder-form .form-field.form-table--container {
    width: 100% !important;
  }

  .salesorder-form .customer-name-fileds button.view-details-btn {
    right: 0;
    left: auto;
  }

  .salesorder-form .customer-name-fileds + .form-field.inline-field {
    margin-top: 16px;
  }

  .invoice-popup-set.UIUX-addtax-popup .form-input.large {
    width: 100%;
  }

  .action-buttons.Po-actions,
  .action-buttons.So-actions {
    padding-right: 0;
  }
}

button.view-details-btn {
  position: absolute;
  right: auto;
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 1.2rem;
  top: auto;
  left: 62%;
  bottom: -13px;
}

.customer-name-fileds {
  display: inline-block;
  position: relative;
}

p.text-limit-set {
  font-size: 13px !important;
  word-break: break-all;
  white-space: break-spaces;
}

.salesorder-form button.view-details-btn {
  bottom: -20px;
}

.payFullbtn {
  color: #2485e8;
  text-decoration: none;
  background-color: transparent;
  font-size: 80%;
  font-weight: 400;
  cursor: pointer;
}
