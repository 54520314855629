.settings-card {
  position: relative;
}

.settings-card path, .settings-card rect {
  transition: fill .35s ease-out;
}

.settings-card:hover > a {
  /* background: var(--hover); */
  border: 1px solid #3971B7;
}

.settings-card:hover path, .settings-card:hover rect {
  fill: var(--blue-500) !important;
}

.settings-card a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border-dark);
  box-shadow: var(--shadow-medium);
  background: white;
  min-height: 26rem;
  border-radius: 4px;
  text-align: center;
  transition: background .35s ease-out;
  padding: 2rem;
}
.settings-card a h3{
  color: #3971B7;
}
.settings-card a svg path{
  fill: rgb(51, 163, 72);
}
.settings-card a svg rect{
  fill: rgb(51, 163, 72);
}
.settings-card a, .settings-card a:hover {
  color: var(--text-primary);
  width: 100%;
  height: 100%;
}

.settings-card--title {
  font-size: 1.6rem;
  font-weight: 500;
}

.settings-card--sub-title {
  color: var(--text-secondary);
}
.settings_action_buttons{
  position: absolute;
  bottom: 15px;
  float: left;
  width: 100%;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;

}
.settings_action_buttons a{
  min-height: auto;
  padding: 0px 10px;
  border: 0;
  box-shadow: none;
  width: auto;
  /* margin-right: 10px; */
  border-right: 1px solid;
  border-radius: 0;
  color: var(--text-primary);


}

.settings_action_buttons a:last-child{
  border-right: 0;
}
.settings_action_buttons a:hover{
  width: auto;
  text-decoration: underline;
  color:#3971B7;

}