.paymentdetail-table tr th:nth-child(3),
.paymentdetail-table tr td:nth-child(3) {
  text-align: right;
  width: 20%;
}

html body .payment_details-main .details-header--info {
  display: block;
  word-break: break-word;
}

html body .payment_details-main .details-header--info>span {
  margin-left: 0;
  display: inline-flex;
  width: 50%;
}

.menu-component--menu.menu_top.uiux_customer_payment {
  position: initial;
}

.uiux_customer_payment .menu_top_content.box_shadow_custom {
  position: relative;
  margin-top: -50px;
  margin-bottom: 40px;
  height: 100%;
}

.payment_details .sales_order_info {
  padding-top: 0;
}

.payment_details .close_icon.customet-close-icon {
  display: none;
}

.paymentdetail-table tr th:nth-child(4),
.paymentdetail-table tr td:nth-child(4) {
  width: 30%;
}

.heading_title {
  width: 40%;
  font-weight: bold;
  color: #50a349;
}

.customer-payment-listing tr th:nth-child(4),
.customer-payment-listing tr td:nth-child(4) {
  width: 32%;
}

.received-payment-detail .section-details--info {
  width: 40%;
}

.close_icon.customet-close-icon {
  top: 2px !important;
}

/* .details_fields{
  display: flex;
  justify-content: space-between;
  width: 70%;
} */

.bottom_notes {
  float: left;
  width: 100%;
}

.notes_field {
  float: left;
  margin-top: 2%;
  width: 100%;
}

.payment_modal .heading_title {
  padding: 10px 10px 10px 0;
  border-bottom: 1px solid #dfe1e5;
}

.payment_modal .section-details--info {
  float: left;
  width: 56%;
  padding-left: 0 !important;
}

.payment_modal .details_fields {
  /* padding: 5px 10px 5px 0px; */
  float: left;
  width: 100%;
}

.details_fields div:first-child {
  float: left;
  width: 18%;
  text-align: left;
}

.details_fields div:last-child {
  float: left;
  width: 82%;
  text-align: left;
  padding-left: 20px;
  position: relative;
}

.details_fields div:last-child:after {
  content: ' : ';
  position: absolute;
  left: 0;
  color: #000;
}

.inv_billto .inv_name:hover {
  color: #000 !important;
}

.paymentinv_table_head_title {
  /* background-color: #DFE1E5; */
  font-weight: bold;
  padding: 0rem 10%;
}

.paymentinv_table_head_title h4 {
  font-weight: 900;
  font-size: 15px;
  background-color: #dfe1e5;
  padding: 1rem;
}

.add_creditnote_modal_Width {
  width: 112rem !important;
}

.AppplyCredits {
  padding: 0;
}

.ims-modal.AppplyCredits table tr th {
  padding: 1.6rem 0rem 1.6rem 1.2rem;
  font-size: 14px;
}

.AppplyCredits .ims-modal--title {
  float: left;
  width: 100%;
  display: block;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.AppplyCredits .ims-modal--title span:first-child {
  float: left;
  position: relative;
  top: 3px;
}

.AppplyCredits .ims-modal--title span:first-child span {
  top: 0;
}

.AppplyCredits .ims-modal--title span:nth-child(2) {
  float: right;
  padding-right: 40px;
  position: relative;
  top: 3px;
}

.AppplyCredits .ims-modal--title span:nth-child(3) {
  position: absolute;
  right: 5px;
  top: 17px;
}

.ims-modal--body.apply_credit_notes {
  float: left;
  width: 100%;
  padding: 0;
}

.ims-modal--body.apply_credit_notes table thead tr {
  float: left;
  width: 100%;
  height: 40px;
  background: #fff;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
  padding: 0 10px;
}

.ims-modal--body.apply_credit_notes table thead tr th {
  float: left;
  width: 16.66% !important;
  color: #000;
  font-weight: 500;
}

.ims-modal--body.apply_credit_notes table tbody tr {
  float: left;
  width: 100%;
  height: 50px;
  background: #fff;
  padding: 0 10px;
}

.ims-modal--body.apply_credit_notes table tbody tr td {
  float: left;
  width: 16.66% !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  vertical-align: middle;
  height: 100% !important;
  display: flex;
  align-items: center;
  margin: 0;
}

.ims-modal--body.apply_credit_notes .right-align {
  justify-content: flex-end;
}

.ims-modal--body.apply_credit_notes table tbody tr:nth-child(even) {
  background: #fbfbf7;
}

.ims-modal--body.apply_credit_notes table thead tr th:nth-child(3),
.ims-modal--body.apply_credit_notes table thead tr th:nth-child(4) {
  text-align: center !important;
}

.ims-modal--body.apply_credit_notes table tbody tr td:nth-child(3),
.ims-modal--body.apply_credit_notes table tbody tr td:nth-child(4) {
  justify-content: center !important;
  text-align: center;
}

.ims-modal--body.apply_credit_notes table tbody tr td:last-child {
  padding: 0 !important;
}

.ims-modal--body.apply_credit_notes table tbody tr td:last-child .form-field {
  display: flex;
  justify-content: flex-end;
}

.ims-modal--body.apply_credit_notes table tbody tr td:last-child .form-field input {
  width: 65% !important;
  min-width: 65% !important;
}

.ims-modal .apply_credit_notes form {
  float: left;
  padding: 0 20px;
  margin-top: 15px;
}

.apply_credit_notes .list-table.border thead {
  border-left: 0 !important;
  border-right: 0 !important;
}

.apply_credit_notes .list-table.border tbody {
  border: 0 !important;
}

.credit_note_utilization hr {
  display: none;
}

.credit_note_utilization .summary_table {
  margin-top: 0 !important;
  border-bottom: 1px solid #dfe1e5;
}

.credit_note_utilization .summary_table .float-left.w-70.p_10 .float-left.w-100.so-mg-1 span {
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: #000;
}

.credit_note_utilization .summary_table .float-left.w-70.p_10 .float-left.w-100 span {
  font-family: Roboto;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
  color: #8e8e8e;
}

.credit_note_utilization .summary_table .float-left.w-30.p_10 span {
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #000;
}

.credit_note_utilization .summary_table .float-left.w-30.p_10 .float-left.w-100.so-mg-1 span {
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #000;
}

.credit_note_utilization .p_10 {
  padding: 0 10px !important;
}

.credit_note_utilization .summary_table .float-left.w-30.p_10 .float-left.w-100.so-mg-1 {
  padding: 10px 7px;
  margin-bottom: 0;
}

.credit_note_utilization .summary_table .float-left.w-70.p_10 .float-left.w-100:first-child {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
}

.credit_note_utilization .summary_table .float-left.w-30.p_10 .float-left.w-100.so-mg-1:last-child span:first-child {
  font-weight: bold;
}

.credit_note_utilization .summary_table .float-left.w-30.p_10 .float-left.w-100.so-mg-1:first-child {
  border-bottom: 1px solid #e9e9e9;
}

.credit_note_utilization .create_invoice .form-input.small {
  text-align: right;
}

.credit_note_utilization .action-buttons {
  margin-right: 2rem !important;
}

.AppplyCredits table tbody {
  overflow: auto;
  max-height: 450px;
}

.credit_note_details .nav_menu a {
  /* min-width: 150px; */
  width: auto;
  padding: 0 10px;
}

/* payment Tab inner style  */
.paymentParent table thead tr {
  box-shadow: none !important;
  border-bottom: 1px solid #e9e9e9;
}

.paymentParent table tbody tr:nth-child(odd) {
  background-color: #fbfbf7;
}

.paymentParent table tbody tr:nth-child(even) {
  background-color: #fff;
}

.paymentParent .buttons_parent {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
}

.paymentParent .buttons_parent button {
  color: #306ab4;
}

.paymentParent .buttons_parent button:hover {
  text-decoration: underline;
}

.paymentParent .buttons_parent button:first-child {
  margin-right: 30px;
}

.paymentParent .credits_applied table thead tr th:nth-child(2),
.paymentParent .credits_applied table tbody tr td:nth-child(2) {
  float: left;
  width: 40% !important;
}

.paymentParent .credits_applied table {
  margin-top: 16px !important;
  margin-bottom: 0px !important;
}

/* payment Tab inner style  */
/* Modal PAyemnt parent Style */
.ims-modal .paymentParent .paymentinv_table_head_title {
  padding: 0 4rem;
}

/* Modal PAyemnt parent Style */

.sales-return-main.invoice-scroll-set .menu-component--menu.menu_top {
  width: 99.8% !important;
  margin-left: 0 !important;
}

.invoice-popup-set .sales-return-main .menu-component--menu.menu_top {
  width: 100% !important;
  margin-left: 0 !important;
}

.payment_modal.invoice-payment-modal .section-details--info {
  width: 100%;
}

@media (max-width: 1280px) {
  .summary_table .w-70 {
    width: 60% !important;
  }

  .summary_table .w-60 {
    width: 50% !important;
  }

  .summary_table .w-30 {
    width: 40%;
  }

  .package-form .action-buttons {
    margin-right: 12px ​ !important;
  }

  .paymentinv_table_head_title {
    padding: 0 13px;
  }
}

@media (max-width: 1366px) {
  .received-payment-detail .section-details--info {
    width: 50% !important;
  }
}
