.ims-sidenav {
  display: grid;
  background: #fff;
  border: 1px solid var(--hover);
  border-radius: 4px;
  height: min-content;
  box-shadow: var(--shadow-medium);
}

.ims-sidenav--link {
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-blue-light);
  color: var(--black);
}

.ims-sidenav--link:hover {
  color: var(--link-hover);
}

.ims-sidenav--link:focus {
  text-decoration: none;
}

.ims-sidenav--link.active {
  color: var(--blue-500);
  font-weight: 500;
}

.sidenav-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.sidenav-heading h4, .sidenav-heading h2 {
  margin: 0;
  color: #000;
  font-weight: 400;

}

.sidenav-heading h2 {
  font-size: 20px;
}

.sidenav-heading h4 {
  font-size: 16px;
}

.sidenav-heading a {
  font-size: 12px;
  text-decoration: underline;

}

.sidenav-heading a:hover {
  color: #000;
  text-decoration: underline;
}

/* mob sticky menu */
.ecom_mob_nav {
  position: fixed;
  top: 10rem !important;
  right: -0.1rem;
  width: 40px;
  height: 22px;
  font-size: 18px;
  background-color: #33a348;
  color: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 11;

}

.ecom_mob_nav,
.ecom-integration i.fas.fa-times {
  display: none;
}

@media(max-width:1280px) {
  .ecom_mob_nav .fas.fa-cog {
    position: absolute;
    top: 5px;
    right: 17px;
    height: 100%;
    font-size: 12px;

  }

  .ecom_mob_nav.mob_nav_hide,
  .ecom-integration .sidenav-heading .fas.fa-cog {
    display: none;
  }

  /* .ecom-integration i.fas.fa-times,
  .ecom_mob_nav {
    display: block;
  } */

  .ecom-integration .ecom-sidenav {
    position: fixed;
    right: -100%;
    left: auto;
    z-index: 29;
    width: 30rem;
    /* height: calc(100vh - 45px); */
    height: 100%;
    top: 48px;
    transition: right .7s ease-out;
  }

  .ecom-integration .ecom-nav-show {
    transition: right .5s ease;
    right: 0;
  }

}