.home {

}

.home-desc {
  font-size: 1.6rem;
  margin-bottom: 3rem;
}
.home--btn {
  border: 0;
  outline: 0;
  width: 18rem;
  text-align: center;
  padding: 1.2rem 0;
  background-color: #ef6c00;
  justify-self: center;
  border-radius: 4px;
  font-size: 1.6rem;
  color: white;
  margin-bottom: 5rem;
  transition:  0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.home--btn:hover {
  color: white;
  background-color: #e65100;
}

@media only screen and (min-width: 1800px) {
  .home-desc {
    font-size: 140%;
  }
  .home--btn {
    font-size: 120%;
  }
}