.menu_top_inner_content .sub_tabs_transaction_main.credit-refund-table thead tr th, .menu_top_inner_content .sub_tabs_transaction_main.credit-refund-table tbody tr td {
  float: left;
  width: 20%;
}

.menu_top_inner_content .sub_tabs_transaction_main.credit-refund-table thead tr th:nth-child(2), .menu_top_inner_content .sub_tabs_transaction_main.credit-refund-table tr td:nth-child(2) {
  float: left;
  width: 40% !important;
}

.AppplyCredits.Refund {
  min-width: 110rem;
}

.AppplyCredits.Refund .record-payment-form {
  width: 100% !important;
}

.AppplyCredits.Refund .action-buttons {
  margin-right: 0 !important;
}

.AppplyCredits.Refund hr {
  float: left;
  width: 100%;
}

.remaining_Credits>div {
  float: right;
  text-align: right;
  width: 300px;
  padding: 20px 5rem 20px 0;
  border-top: 1px solid var(--border-light);
  font-weight: bold;
}

.remaining_Credits>div span {
  margin-left: 30px;
  font-weight: normal;
}

.AppplyCredits.Refund .payment-record {
  padding-bottom: 0 !important;
}

.AppplyCredits.Refund .payment-record textarea {
  margin-bottom: 0 !important;
}

@media(max-width:991px) {
  .add_new_payment .payment_modal_title {
    width: 34%;
  }
}