div.item-sec-wrp div.item-sec {
  display: inline-block;
  border-right: 2px solid #ddd;
  color: #888;
  font-size: 12px;
  padding: 0px 5px;
}

div.item-sec-wrp div.item-sec:first-child {
  padding-left: 0px;
}

div.item-sec-wrp div.item-sec:last-child {
  border-right: 0px;
}

div.item-view {
  margin-top: 10px;
}

div.item-images-grid {
  max-width: 150px;
  text-align: center;
  margin-top: 5px;
}

div.item-images-grid div.image-item {
  width: 30px;
  height: 40px;
  margin: 0px 3px;
  border-radius: 3px;
  cursor: pointer;
}

div.item-view-wrapper {
  background: #f8f8f8;
  padding: 5px 10px 10px 10px;
  border: 1px solid #ccc;
  border-top: 0;
}

.item-view-wrapper .container {
  width: 100%;
}

h4.item-name {
  margin-top: 0;
  font-size: 24px;
}

.item-content {
  padding-left: 15px;
}

.item-content b {
  color: #777;
}

.select-all-wrapper {
  display: inline-block;
  margin-right: 5px;
  padding-right: 10px;
  /*border-right: 2px solid #ddd;*/
}

.selectall-h4 {
  display: inline-block;
}

.confirm-delete-header {
  background-color: #f4d4d4;
  border-bottom: 1px solid #eab298;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.list-item-act {
  display: inline-block !important;
}

.list-table--link-error {
  color: red !important;
}

.checkall_fix_split_btn {
  display: none;
}

.check_all_fix_rc {
  display: inline-block;
  padding: 6px 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
  background-color: #fff;
}

.item-list-exp-body {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-gap: 1.5rem;
}

.item-list--table .list-table--row-name div:first-child {
  color: var(--blue-500);
}

.item-list-exp--img-container {
  padding-right: 1.5rem;
  border-right: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-list-exp-first-section {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-gap: 2rem;
}

/* TODO: will be moved */
.list-head {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.list-head .sort-dropdown .custom-dropdown.button-text .button {
  font-size: 1.4rem;
}

.general_module_icon {
  width: 20px !important;
  height: 20px !important;
}

.btn-cancel,
.btn-connect {
  color: #fff;
  font-size: 16px;
  min-width: 100px;
  border-radius: 5px;
  padding: 7px 20px;
}

.btn-cancel {
  background-color: #dfe1e5;
  color: #8e8e8e;
}

.btn-connect {
  background-color: #33a348;
}
.item-listing-woocom td:nth-child(2) .list_items_desc div,
.item-listing-items td:nth-child(2) .list_items_desc div {
  display: flex;
  width: 100%;
  align-items: center;
}
.item-listing-woocom td:nth-child(2) .list_items_desc div > span:nth-child(1),
.item-listing-items td:nth-child(2) .list_items_desc div > span:nth-child(1) {
  flex: 0 0 90%;
}

.item-listing-items td:nth-child(2) .list_items_desc div > span:nth-child(2),
.item-listing-items td:nth-child(2) .list_items_desc div > span:nth-child(2) {
  flex: 1 0 10%;
}
.item-listing-woocom
  td:nth-child(2)
  .list_items_desc
  div
  > span:nth-child(2)
  svg,
.item-listing-items
  td:nth-child(2)
  .list_items_desc
  div
  > span:nth-child(2)
  svg {
  margin-top: 6px;
}
.item-listing-woocom.fullscreen .list_items_desc span:nth-child(2) {
  padding-right: 2px !important;
}

.item-listing-woocom.fullscreen .list_items_desc span:nth-child(2) span svg {
  padding: 2px;
  width: 18px;
}

@media (max-width: 1600px) {
  .w-80.UIUX-item-single {
    width: 95% !important;
  }
}
