/* .on-boarding {
  min-height: 48rem;
  grid-column-gap: 0;
}

.on-boarding .layout--grid {
  padding: 6.4rem;
}

.on-boarding .layout--grid:nth-child(1) {
  border-right: 1px solid var(--border-dark);
}

.on-boarding .layout--grid:nth-child(2) {
  background: var(--disabled);
  text-align: center;
  padding: 2.4rem 0;
}

.on-boarding--title {
  font-size: 2.4rem;
}

.on-boarding--semi-title {
  margin-bottom: 1.6rem;
  border-bottom: 1px solid var(--selected);
  padding-bottom: 2.4rem;
}

#Layer_1 {
  width: 100%;
}

.on-boarding--description {
  color: var(--icon-primary);
  margin: 1.6rem 0;
}

.on-boarding .button {
  display: inline-block;
} */

/* NEW DESIGN CSS */
.intialscreen_innerpages .left {
  float: left;
  width: 50%;
  height: calc(100vh - 99px);
  padding: 50px 20px;
  -webkit-box-shadow: 3px 0px 10px 0px rgb(0 0 0 / 16%);
  -moz-box-shadow: 3px 0px 10px 0px rgb(0 0 0 / 16%);
  box-shadow: 3px 0px 10px 0px rgb(0 0 0 / 16%);
  overflow: auto;
}

.intialscreen_innerpages .left iframe {
  width: 100%;
  height: 398px;
}

.intialscreen_innerpages .left .our_goal h2 {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  font-size: 22px;
  line-height: 32px;
  text-align: left;
  color: #949494;
  margin-top: 30px !important;
}

.intialscreen_innerpages .left .center_image {
  margin: auto;
  width: 90%;
}

.intialscreen_innerpages .right {
  float: left;
  width: 50%;
  height: calc(100vh - 99px);
  padding: 30px 20px;
  overflow: auto;
}

#onboarding-screen .layout--grid {
  box-shadow: unset !important;
  height: calc(100vh - 50px);
  overflow: hidden;
}

.intialscreen_innerpages .right .center_image {
  margin: auto;
  width: 100%;
}

.intialscreen_innerpages .right .center_image svg {
  width: 100%;
}

.actions_buttons {
  float: left;
  width: 100%;
}

.actions_buttons a {
  min-width: 130px;
  height: 45px;
  border-radius: 22.5px;
  background: #fff;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
  float: left;
  color: #000;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  text-decoration: none;
  transition: 0.3s;
}

.actions_buttons a:hover {
  background: #306ab4;
  color: #fff;
}

.actions_buttons a:hover svg path {
  fill: #fff;
}

.actions_buttons svg {
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.actions_buttons svg path {
  fill: #000;
}

@media screen and (min-width: 21px) and (max-width: 1550px) {
  .actions_buttons a {
    margin-bottom: 15px;
  }

  .intialscreen_innerpages .left iframe {
    height: 240px;
    width: 100%;
  }
}
