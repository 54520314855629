/* .sale-return-preference label{
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  width: 27rem;
} */


.prefix-main .sale-return-preference--label-text {
  font-size: 14px;
  font-weight: 400;
}
.prefix-main label.list-table--checkbox-lable{
  margin-bottom: 6px !important;
}
.prefix-main .single_prefix_field input.form-input.xs {
  font-weight: 400;
}
.sale-return-preference--label-text {
  margin: unset !important;
  padding-left: 0rem;
  padding-right: 2rem;
}

.preference .card-header {
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);
  font-weight: 700;
  padding: 1rem 1.5rem !important;
}

.preference .card-header .card-header--title {
  color: #000 !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  font-size: 18px;
}

.preference .card-body {
  float: left;
  width: 100%;
}

.preference .card-body form {
  float: left;
  width: 100%;
}

.preference .card-body form label {
  display: unset;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.height_custom__ {
  height: calc(100vh - 268px);
}

.preference .sale-return-preference--label-text {
  float: left;
  width: 50%;
  position: relative;
  top: 10px;
}

.preference .form-field {
  float: left;
  width: 50%;
  padding: 0;
  margin: 0;
}

.add_prefix {
  font-weight: 700;
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add_prefix p {
  font-weight: 400;
  display: inline-block;
}

.add_prefix p i {
  font-weight: 700;
}

.single_prefix_field .input-feedback {
  bottom: -1.8rem !important;
}

.prefixes-main-set {
  padding-top: 18px !important;
}

.preference .card-body form label:last-child {
  margin-bottom: 2px;
}

.height_custom__.preference-height {
  height: 610px !important;
}

.prefixes.prefixes-main-set.prefixes-main-child {
  padding-top: 28px !important;
}

@media(max-width:1200px) {
  .height_custom__.preference-height {
    height: auto !important;
  }

  .height_custom__.preference-height .w-70, .height_custom__.preference-height .right_sec .w-50, .preference .w-70 {
    width: 100% !important;
  }
}

@media(max-width:1199px) {
  .prefixes.prefixes-main-set.prefixes-main-child {
    display: flex;
    flex-direction: unset;
    flex-wrap: wrap;
    align-items: center;
  }
}

@media(max-width:991px) {
  .height_custom__.preference-height .form-input.xs {
    width: 100%;
  }
}