a:focus {
  outline: 0;
}
.nav {
  margin: 2rem 0;
  padding: var(--body-padding);
}
.nav-tabs {
  border: 0;
}
.nav-tabs > li {
  margin-right: 3rem;
}

.nav-tabs li.active a, .nav-tabs li.active a:focus {
  background-color: transparent;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  color: var(--blue-500);
  cursor: pointer;
  border-color: transparent;
}

.panel-default > .panel-heading {
  background-color: transparent;
}
.panel-success > .panel-heading {
  color: var(--black);
  background-color: var(--button-hover);
  border-color: var(--button-hover);
}
.panel {
  border: 0;
  box-shadow: none;
}

.form-horizontal .control-label,
label.control-label {
  padding: 2rem 0 1rem;
  font-weight: 400;
  text-align: left;
}
.form-control {
  box-shadow: none;
}

a {
  color: var(--blue-500);
}
a:hover {
  text-decoration: none;
  cursor: pointer;
}

.row,
.form-horizontal .form-group {
  margin: 0;
}
.fix-align {
  margin: 0 -1.5rem;
}
.panel-body {
  padding: 1.5rem 0;
}
.center {
  display: flex;
  align-items: center;
}
.container-fix {
  width: 100%;
}

textarea.form-control {
  height: 8rem;
}

.form-control[disabled] {
  background-color: #eeeeee;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  border-top: 1px solid var(--border-light);
  vertical-align: middle;
  position: relative;
}

.table>thead>tr>th {
  border: 0;
}

input:focus, select:focus, textarea:focus {
  /*box-shadow: 0px 2px 5px #ccc;*/
}

input, select, textarea,
input.form-control,
select.form-control,
textarea.form-control {
  border: 1px solid var(--input);
  border-radius: 4px;
}

span.form-error {
  display: block;
  font-size: 12px;
  padding: 0px 0px;
  border-radius: 3px;
  margin-top: 5px;
  color: #ef5656;
  font-weight: 500;
}

.dropdown-menu {
  right: 0;
  left: auto;
}

label {
  font-weight: 400;
  margin: 0;
}

.small {
  font-size: 100%;
}