.details-header {
  padding: 2.4rem;
  border-bottom: 1px solid var(--hover);
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: space-between;
  align-items: center;
}

.details-header--title {
  /* margin: 0 0 1rem; */
  word-break: break-word;
  margin:0;
}
.details-header--title svg{
  height: 22px;
  width: 22px;
  margin-left: 10px;
}

.details-header--info span {
  margin-left: 1.6rem;
}

/* other common details related css */
/* see Address component */

.section-details--info-title {
  /* font-size: 1.2rem; */
  /* color: var(--grey-text); */
  color: black !important;
}

.section-details--info-subtitle {
  font-size: 1.2rem;
  color: var(--grey-text);
}

.section-details--info {
  line-height: 2.6rem;
}

.section-details--info-details {
  line-height: 2.7rem;
}

.section-details--footer-amount {
  display: grid;
  grid-row-gap: 1.6rem;
  /*text-align-last: left*/
}

.section-details--footer-amount > div {
  text-align: right;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-row-gap: 1.6rem;
}

.section-details--footer-amount div.border-bottom {
  border-bottom: 1px solid var(--border-light);
  padding-bottom: 10px;
}
.section-details--footer-amount div.border-top {
  border-top: 1px solid var(--border-light);
  padding-top: 10px;
}

.section-details--not-created {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.section-details--not-created h5 {
  color: var(--light-grey-text);
}

.section-details--add-button {
  outline: none;
  border: 0;
  color: var(--blue-500);
  background: transparent;
}

.section-details--separator {
  border-top: 1px solid var(--border-light);
  margin: 2rem 0;
}

.bold-amount {
  font-size: 2rem;
  color: var(--text-primary);
  text-shadow: 0.5px 0; 
}

.customer_name{
  color: #306AB4;
  font-weight: 600;
  display: inline-block;
}
.details_header_package{
  padding: 2.4rem 0 !important;
  margin-bottom: 10px;
  border-bottom: 0 !important;
}
.details_header_package_no_bottom{
  padding: 2.4rem 0 0 !important;
  margin-bottom: 10px;
  border-bottom: 0 !important;
}
.contacts_modal_view{
  height: 810px;
  overflow: auto;
}
.dropshipicon-set {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 8px;
  position: relative;
  top: 2px;
}
.details-header--info {
  /* display: inline-block; */
  overflow: hidden;
}
.td-right-set>a {
  text-decoration: none;
}