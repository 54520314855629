/* .sale-return-preference label{
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  width: 27rem;
} */

.sale-return-preference--label-text {
  margin: unset !important;
  padding-left: 0rem;
  padding-right: 2rem;
}

html body .preference .preference-sn .form-field .searchable-dropdown.medium {
  width: 100%;
  max-width: 191px;
}
html body .sale-return-preference .genral_tab_content label.deduction-field > div{
  max-width: 193px;
}
html body .sale-return-preference .genral_tab_content label.deduction-field > div input{
  width: 100%;
}
.w-auto {
  width: auto !important;
}

.preferences-deduction .searchable-dropdown__indicators,
.preferences-deduction .searchable-dropdown .searchable-dropdown__value-container,
.preferences-deduction .searchable-dropdown .searchable-dropdown__indicator {
  height: 100%;
  margin: 0;
}

.preferences-deduction .searchable-dropdown__indicators {
  display: grid;
  place-content: center;
}

@media(max-width:768px) {

  html body .sale-return-preference .genral_tab_content label {
    display: flex;
    flex-direction: column;
  }

  html body .sale-return-preference .genral_tab_content label>div {
    top: 0;
    width: 100% !important;
  }
  html body .sale-return-preference .genral_tab_content label.deduction-field > div{
    max-width: 191px;
  }
  html body .sale-return-preference .genral_tab_content label.deduction-field{
    align-items: initial;
  }
  html body .sale-return-preference .genral_tab_content,
  html body .sale-return-preference .genral_tab_content label>div input,
  html body .sale-return-preference .genral_tab_content label>div .searchable-dropdown.medium {
    width: 100% !important;
  }


}