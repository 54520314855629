.checked-menu {
  /* padding-left: 1.4rem; */
  float: left;
  /* margin-bottom: 1rem;  */
}

.checked-menu--toolbar {
  border: 1px solid #D3DEE6;
  border-radius: 4px;
  padding-left: 1rem;
  display: flex;
  width: max-content;
  align-items: center;
  height: 3.4rem;
  /* box-shadow: var(--shadow-light); */
}

.action-menu {
  background: var(--background);
  padding-left: 0;
  border-left: 0;
  font-size: 1.2rem;
  float: right;
}

.checked-menu--toolbar .checked-menu--count {
  padding: .5rem 0;
  margin-right: 1rem;
  color: var(--blue-500);
}

.checked-menu--buttons button:last-child {
  border-right: 0;
}
.section-header--pagination .checked-menu--buttons{
  height: 100%;
}
.checked-menu--button--icon-only {
  outline: none;
  background: transparent;
  border: 0;
  border-left: 1px solid #D3DEE6;
  /* padding: 0.9rem 0; */
  border-radius: 4px;
}

.checked-menu--button {
  outline: none;
  background: transparent;
  border: 0;
  border-left: 1px solid #D3DEE6;
  /* padding: 0.9rem 0; */
  border-radius: 4px;
}

.checked-menu--button svg {
  fill: #ADB6BC;
  width: 1.8rem;
  height: 1.8rem;
}

.checked-menu--button .checked-menu--label {
  color: var(--icon-grey);
  vertical-align: middle;
  /* margin-left: -.5rem; */
  /* margin-right: 1rem; */
}

.checked-menu--button--icon-only svg {
  fill: #ADB6BC;
  width: 1.6rem;
  /* height: 1.6rem; */
  margin: 0 7px !important;
  height: 100%;
}

.checked-menu--button--icon-only:hover svg {
  fill: var(--blue-500);
}

.secondry_buttons_group .checked-menu--dropdown {
  border-radius: 4px;
}

.checked-menu--dropdown .custom-dropdown.button-text .button {
  /* border-left: 1px solid var(--border-dark); */
  font-size: 1.4rem;
  margin: 0;
  /* color: var(--grey-text); */
  /* padding: 0.8rem 0.9rem; */
  /* border-radius: 4px; */
  /* font-size: 1.4rem;
  margin: 0; */
  color: #000 !important;
  padding: 0.6rem 0.9rem;
  background: #fff;
}

.checked-menu--dropdown .custom-dropdown.button-text .button svg {
  fill: #000 !important;
}

.checked-menu--dropdown .custom-dropdown.button-text .button-active {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.checked-menu--dropdown .custom-dropdown.button-text .custom-dropdown-container>.custom-dropdown--options {
  color: var(--blue-500);
}

.checked-menu--dropdown .custom-dropdown.button-text .custom-dropdown-container {
  left: -57px;
  top: 100%;
  margin-top: 1rem;
  border: 1px solid var(--border-dark);
  box-shadow: var(--grey-box-shadow);
  border-radius: 4px;
  min-width: 15rem !important;
}

.secondry_buttons_group {
  border: 0 !important;
  background: none !important;
}

.secondry_buttons_group button, .secondry_buttons_group a {
  border-left: 0;
  /* background-color: #306ab4; */
  /* border: 1px solid #306ab4; */
  color: #fff;
  margin: 0 5px;
  font-size: 14px;
}

.mr-3 {
  margin-right: 16px;
}

.secondry_buttons_group button:hover, .secondry_buttons_group a:hover {
  /* background-color: #FFF; */
  /* remove white abckground upon hover on buttons */
  /* color: #306ab4; */
  box-shadow: var(--hover-box-shadow);
}

.secondry_buttons_group button svg, .secondry_buttons_group a svg {
  fill: #000;
}

.secondry_buttons_group a:hover svg {
  fill: #306ab4;
}

.secondry_buttons_group .checked-menu--dropdown {
  border-left: 0;
  background-color: #306ab4;
  /* border: 1px solid #306ab4; */
  color: #FFF;
  margin: 0 5px;
}

.secondry_buttons_group .checked-menu--dropdown button {
  color: #FFF !important;
}

.secondry_buttons_group .checked-menu--dropdown:hover button {
  background-color: #FFF !important;
}

.secondry_buttons_group .checked-menu--dropdown:hover button {
  color: #306ab4 !important;
}

.secondry_buttons_group .checked-menu--dropdown button svg path {
  fill: #FFF !important;
}

.secondry_buttons_group .checked-menu--dropdown:hover button svg path {
  fill: #306ab4 !important;
}

.checked-menu--button--icon-only {
  color: #fff;
  margin: 0 5px;
  font-size: 14px;
  /* padding: 0.9rem 0; */
  border-radius: 4px;
  background: transparent;
  transition: 0.3s;
  float: left;
  height: 30px;
  /* border: 1px solid #000;*/
}

.checked-menu--count {
  color: #000 !important;
}

.checked-menu--button .checked-menu--label {
  line-height: 1px;
}

.checked-menu--button--icon-only:hover {
  background-color: #E9E9E9;
  color: #306ab4;
  box-shadow: var(--hover-box-shadow);
}

.checked-menu--button--icon-only svg {
  fill: #000
}

.checked-menu--button svg {
  fill: #000;
}
@media screen and (min-width:1101px) and (max-width:1460px) {
  .secondry_buttons_group button, .secondry_buttons_group a{
    margin: 0 3px;
  }
}
@media screen and (min-width:991px) and (max-width:1400px) {
  .checked-menu--toolbar .checked-menu--count {
    font-size: 12px;
  }

  /* .checked-menu--button--icon-only svg, .checked-menu--button svg {
    width: 1.4rem;
    height: 1.4rem;
  } */
  .checked-menu--button .checked-menu--label {
    font-size: 12px;
  }
}