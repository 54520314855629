.leftx {
  display: inline-block;
}

.rightx {
  display: inline-block;
}

.contacts-subheading {
  margin-bottom: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  flex-direction: row;
}

.fullscreen_contacts.list-table.linked-row
  td:nth-child(2)
  .list-table--link
  span.list_items_desc
  div {
  justify-content: space-between;
}

.fullscreen_contacts.list-table.linked-row
  td:nth-child(2)
  .list-table--link
  span.list_items_desc
  div
  span:nth-child(2) {
  align-items: center;
  height: 100%;
}

.fullscreen_contacts.list-table.linked-row
  td:nth-child(2)
  .list-table--link
  span.list_items_desc
  div,
.fullscreen_contacts.list-table.linked-row
  td:nth-child(2)
  .list-table--link
  span.list_items_desc
  div
  span:nth-child(2)
  span {
  align-items: center;
  display: flex;
}

.add_contact_tabs {
  height: calc(100vh - 100px);
  overflow: hidden;
}

.add_contact_tabs .layout--body {
  width: 70% !important;
  border-left: 1px solid #ececec;
  height: calc(100vh - 100px);
  overflow: auto;
}
.add_contact_tabs .form-image .profile_image .ImagePreview_imagePreview__2GppM {
  border-radius: 50%;
}

.add_contact_tabs .form-image .button--image-delete {
  top: 10px;
  right: 15px;
}
.add_contact_tabs .layout--body .layout--box {
  margin-bottom: 0 !important;
}

.add_contact_tabs .layout--body .layout--box {
  height: calc(100vh - 176px);
  overflow: auto;
  scrollbar-width: inherit;
}

.add_contact_tabs_info {
  float: left;
  width: 30%;
  background: #fff;
  /* height: calc(100vh - 10.4vh);
  border-right-style: inset;
  border-right-color: #ececec; */
}

.add_contact_tabs_info h1 {
  margin: 0;
  height: 7rem;
  display: flex;
  align-items: center;
  background-color: #f0f2f5;
  font-size: 18px;
  padding-left: 5.5rem;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  border-bottom: 1px solid #d3dee5;
}

.add_contact_tabs_info svg {
  position: absolute;
  left: 2.5rem;
}

.add_contact_tabs_info h1 {
  margin: 0;
  height: 7rem;
  display: flex;
  font-weight: bold;
  align-items: center;
  background-color: #f0f2f5;
  font-size: 15px;
  padding-left: 6.5rem;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  border-bottom: 1px solid #d3dee5;
}

.add_contact_tabs_info svg {
  position: absolute;
  left: 2.5rem;
}

.active_tab:after {
  content: '';
  width: 0;
  height: 0;
  /* border-right: 12px solid #8E8E8E; */
  border-right: 12px solid #306ab4;
  border-left: 12px solid transparent;
  border-bottom: 12px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  top: 23px;
  right: -7px;
}

.contact-icon-active {
  stroke: #fff;
  fill: #fff;
  width: 4%;
}

.contact-icon {
  stroke: #8e8e8e;
  fill: #8e8e8e;
  width: 4%;
}

.active_tab:after {
  content: '';
  width: 0;
  height: 0;
  /* border-right: 12px solid #8E8E8E; */
  border-right: 12px solid #306ab4;
  border-left: 12px solid transparent;
  border-bottom: 12px solid transparent;
  position: absolute;
  transform: rotate(45deg);
  top: 23px;
  right: -7px;
}

.pl-contacts {
  /* padding-left: 8.8rem; */
  padding-left: 4rem;
}

.pl-contacts button#click_add_Address_Button {
  display: flex;
  align-items: center;
}

.radio-field,
.contact-add-profile {
  font-size: 16px;
  font-weight: 400 !important;
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.form-label {
  font-weight: 400 !important;
}

.contact-width-full {
  max-width: 150rem !important;
}

.mt-11 {
  margin-top: 11rem !important;
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
  color: black;
  position: relative;
  /* z-index: 99999;  commenting it because it overlaps with side organization panel. */
  z-index: 2;
}

.contact-listing-main .list-table {
  z-index: 1;
}

.contatcs_all_details p.semi-bold.font_custom.alignment {
  word-break: break-word;
}

.mt-11:hover {
  transition: 0.3s ease-in-out;
  transform: rotate(90deg);
  box-shadow: 0px 0px 5px 0px #c6c8cb;
}

.back_to_listing svg {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: 0.3s;
}

.back_to_listing svg:hover {
  transform: rotate(90deg);
}

.go_back {
  background: #33a348;
  color: #fff;
  display: flex;
  justify-content: center;
  margin: 10px auto 10px auto;
  width: 200px;
  padding: 10px 20px;
  transition: 0.3s;
}

.go_back:hover {
  background: #fff;
  box-shadow: var(--hover-box-shadow);
  color: #000;
}

.list-table--wrapper.unset_heights {
  /* max-height: 100vh !important; */
}

.sales_order_tabs {
  float: left;
  width: 100%;
}

.sales_order_tabs .porfile_2 {
  display: flex;
  align-items: center;
}

.contact-list-compressed .list_items_desc span:nth-child(2) {
  width: 100%;
  max-width: 300px;
  word-break: break-word;
  white-space: break-spaces;
}

.transaction-left .custom-dropdown-container button {
  left: 0 !important;
}

.fullscreen_contacts thead th:nth-child(2) {
  width: 22%;
}

.fullscreen_contacts thead th:nth-child(3) {
  width: 10%;
}

.fullscreen_contacts thead th:nth-child(4) {
  width: 15%;
}

.fullscreen_contacts thead th:nth-child(5) {
  width: 10%;
}

.fullscreen_contacts thead th:nth-child(6) {
  width: 8%;
}

.fullscreen_contacts thead th:nth-child(7),
.fullscreen_contacts thead th:nth-child(8) {
  width: 8%;
}

.fullscreen_contacts tbody td:nth-child(8) .text-right,
.fullscreen_contacts tbody td:nth-child(7) .text-right {
  word-break: break-word;
}

.fullscreen_contacts tbody td:nth-child(7) .text-right,
.fullscreen_contacts tbody td:nth-child(8) .text-right {
  /*fix for https://seebizz.atlassian.net/browse/SIQ-6298*/
  /*padding: 0 9px 0 0;*/
  padding: 0 0 0 0;
  word-break: break-all;
}

.contact-edit-form .radio-container,
.contact-edit-form .radio-container .radio-btn,
.contact-edit-form .radio-container .radio-btn label {
  cursor: not-allowed;
}

.contact-infinite-scroll {
  clear: both;
}

.list-table--wrapper.contact-listing-main.contact-listing-fullscreen {
  height: 100%;
  overflow: unset;
}

/* .list-table--wrapper.contact-listing-main.contact-listing-compressed {
  height: calc(100vh - 10%) !important;
  padding-bottom: 20px;
}
.contact-list-compressed .alphabatical_listing {
  top: 0;
} */
.list-table--wrapper.contact-listing-main.contact-listing-compressed {
  overflow: unset !important;
  height: 100% !important;
}

.contact-listing-main .fullscreen_contacts tbody tr td:nth-of-type(5) {
  padding: 0 10px 0 0;
}

@media (min-width: 1920px) {
  /* .contact-listing-fullscreen */
  .contact-listing-main .infinite-scroll-component.contact-infinite-scroll {
    height: calc(100vh - 136px) !important;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #c0c0c0 #efecec;
  }

  .contact-alert-active
    + .layout--grid.no-height
    .contact-listing-main
    .infinite-scroll-component.contact-infinite-scroll {
    height: calc(100vh - 220px) !important;
  }

  .contact-alert-active
    + .layout--grid.no-height
    .contact-listing-main.contact-listing-compressed
    .infinite-scroll-component.contact-infinite-scroll {
    height: calc(100vh - 243px) !important;
  }
}

@media (max-width: 1919px) {
  .contact-listing-main .infinite-scroll-component.contact-infinite-scroll {
    height: calc(100vh - 136px) !important;
    overflow: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #c0c0c0 #efecec;
  }

  .contact-alert-active
    + .layout--grid.no-height
    .contact-listing-main
    .infinite-scroll-component.contact-infinite-scroll {
    height: calc(100vh - 220px) !important;
  }

  .contact-alert-active
    + .layout--grid.no-height
    .contact-listing-main.contact-listing-compressed
    .infinite-scroll-component.contact-infinite-scroll {
    height: calc(100vh - 243px) !important;
  }
}

@media (min-width: 1600px) {
  .contact-listing-main
    .list-table--wrapper.contact-listing-main.contact-listing-compressed {
    height: 100% !important;
  }
}

@media screen and (min-width: 21px) and (max-width: 1600px) {
  .fullscreen_contacts .list-table--options {
    padding-right: 3.5rem !important;
  }

  .fullscreen_contacts .list-table--options button {
    position: relative;
    /* left: 20px; */
  }

  .pl-contacts.contact-form-main {
    padding-left: 0.8rem;
  }

  .pl-contacts.contact-form-main .form-field:first-child input::placeholder {
    font-size: 13px;
  }
}

@media screen and (max-width: 1460px) {
  .fullscreen_contacts.list-table.linked-row td:nth-child(6) {
    width: 11%;
  }
}

@media (max-width: 1366px) {
  .layout--box.contact-width-full {
    padding: 1.6rem 1.7rem;
  }
}

@media screen and (min-width: 21px) and (max-width: 1200px) {
  .fullscreen_contacts .list-table--options button {
    position: relative;
    left: 0px;
  }
}

.lopi.list-table.linked-row.fullscreen_contacts .list-table--options {
  width: 4%;
}

.fullscreen_contacts.list-table.linked-row td:nth-child(2) {
  width: 26%;
}

.fullscreen_contacts.list-table.linked-row td:nth-child(3) {
  width: 10%;
}

.fullscreen_contacts.list-table.linked-row td:nth-child(5) {
  width: 20%;
}

.total_count2.contacts_count {
  margin-left: -40px;
}

.contact-detail-trans .date-range-picker.date-picker-main {
  left: 0;
}

@media (max-width: 1280px) {
  .contact-detail-trans .tab-component-sub-tab {
    width: 100%;
  }

  .contact-detail-trans .porfile_2 .image {
    width: 70px;
    height: 70px;
    margin-right: 10px;
  }

  .contact-detail-trans .porfile_2 .image img {
    min-height: auto;
    min-width: auto;
  }

  .contact-detail-trans .nav_tabs {
    padding-left: 20px;
  }

  .contact-detail-trans .nav_tabs a {
    width: 140px;
    font-size: 12px;
  }

  .add_contact_tabs .layout--body .layout--box {
    height: 100vh;
    overflow: unset;
  }

  .add_contact_tabs .action-buttons {
    position: static;
  }

  .add_contact_tabs .contact_email_field {
    left: 42px;
  }

  .add_contact_tabs .form-field.inline-field {
    margin-right: 16px;
  }

  .add_contact_tabs_info .contact-icon-active,
  .add_contact_tabs_info .contact-icon {
    width: auto;
  }

  .fullscreen_contacts.fullscreen_contacts {
    z-index: 1;
  }
}

@media (max-width: 1199px) {
  .fullscreen_contacts thead th:nth-child(5),
  .fullscreen_contacts tbody td:nth-child(5) {
    display: none;
  }

  .add_contact_tabs .contact_email_field {
    left: 48px;
    top: 10px;
  }

  .section-header--pagination.contact-header-pagination {
    z-index: 2 !important;
  }
}

@media (min-width: 992px) and (max-width: 1260px) {
  .contacts_address {
    width: 100%;
  }

  .contacts_address .card + .card,
  .contact_person_card {
    width: 50% !important;
  }

  .contact-tbl-tabs tr th:nth-child(6),
  .contact-tbl-tabs tr th:nth-child(7),
  .contact-tbl-tabs tr th:nth-child(8),
  .contact-tbl-tabs tr td:nth-child(6),
  .contact-tbl-tabs tr td:nth-child(7),
  .contact-tbl-tabs tr td:nth-child(8) {
    display: none;
  }

  .fullscreen_contacts thead th:nth-child(1) {
    width: 3%;
  }
}

@media (max-width: 1024px) {
  .fullscreen_contacts.fullscreen_contacts th:nth-child(3) {
    padding-right: 20px !important;
    width: 12%;
  }

  .fullscreen_contacts.fullscreen_contacts th:nth-child(7),
  .fullscreen_contacts.fullscreen_contacts th:nth-child(6),
  .fullscreen_contacts.fullscreen_contacts th:nth-child(5),
  .fullscreen_contacts.fullscreen_contacts th:nth-child(4) {
    padding-right: 10px !important;
  }

  .fullscreen_contacts.fullscreen_contacts th:nth-child(8),
  .fullscreen_contacts.fullscreen_contacts td:nth-child(8),
  .fullscreen_contacts.fullscreen_contacts th:nth-child(7),
  .fullscreen_contacts.fullscreen_contacts td:nth-child(7) {
    display: none;
  }

  .fullscreen_contacts.list-table.linked-row td:nth-child(2) {
    width: 24%;
  }

  .fullscreen_contacts.list-table.linked-row td:nth-child(5) {
    width: 30%;
  }

  .fullscreen_contacts.list-table.linked-row td:nth-child(7) {
    width: 12%;
  }

  .fullscreen_contacts.list-table.linked-row td:nth-child(6) {
    width: 20%;
  }

  .fullscreen_contacts.list-table.linked-row .table-row td:nth-child(1) {
    width: 3%;
  }
}

@media (max-width: 991px) {
  .add_contact_tabs .pl-contacts {
    padding-left: 0;
  }

  .add_contact_tabs .action-buttons {
    margin-right: 0 !important;
  }

  .add_contact_tabs .radio-feilds {
    margin-left: 0;
  }

  .add_contact_tabs .form-field.inline-field {
    display: block;
    margin-right: 0;
  }

  .add_contact_tabs .searchable-dropdown.large,
  .add_contact_tabs .form-input.large {
    width: 100%;
  }

  .contact-tbl-tabs tr th:nth-child(6),
  .contact-tbl-tabs tr th:nth-child(7),
  .contact-tbl-tabs tr th:nth-child(8),
  .contact-tbl-tabs tr td:nth-child(6),
  .contact-tbl-tabs tr td:nth-child(7),
  .contact-tbl-tabs tr td:nth-child(8) {
    display: none;
  }

  .top-header .mt-11 {
    margin-top: 0 !important;
  }

  .fullscreen_contacts.fullscreen_contacts th:nth-child(4),
  .fullscreen_contacts.fullscreen_contacts td:nth-child(4),
  .fullscreen_contacts.fullscreen_contacts th:nth-child(5),
  .fullscreen_contacts.fullscreen_contacts td:nth-child(5) {
    display: none;
  }
}

@media (max-width: 1800px) {
  .contact-list-compressed tbody td.list-table--options.collapsed_table {
    padding-right: 7px !important;
    width: 10%;
  }
}

@media (max-width: 1280px) {
  .contact-list-compressed tbody td.list-table--options.collapsed_table {
    width: 11%;
  }
}

@media (max-width: 767px) {
  .add_contact_tabs .radio-feilds {
    margin-top: 0;
  }

  .ims-modal.white.contact-modal-width
    .pl-contacts
    button#click_add_Address_Button {
    font-size: 12px;
    padding: 0.5rem 0.8rem;
    margin-left: 1.3rem;
  }
}

@media print {
  ::-webkit-scrollbar {
    display: none !important;
  }
}

.contact-detail-trans .transaction_view_btn {
  margin-bottom: 0;
}
