.credit_note_details_menu .menu-component--menu.menu_top {
  left: 0px;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
  /* position: relative !important; */
}

.AppliedOnInvoices_menu {
  float: left;
  width: 100%;
}

.AppliedOnInvoices_menu thead tr {
  box-shadow: none !important;
  filter: drop-shadow(-2px 3px 3px rgba(0, 0, 0, 0.16));
  border-bottom: 1px solid #e9e9e9;
}

.AppliedOnInvoices_menu tbody tr:nth-child(even) {
  background-color: #fcfcf9;
}

.AppliedOnInvoices_menu tbody tr:nth-child(odd) {
  background-color: #fff !important;
}

.AppliedOnInvoices_menu thead tr th,
.AppliedOnInvoices_menu tbody tr td {
  float: left;
  width: 20% !important;
}

.AppliedOnInvoices_menu thead tr th:nth-child(2),
.AppliedOnInvoices_menu tbody tr td:nth-child(2) {
  float: left;
  width: 40% !important;
}

.dot {
  height: 6px;
  width: 6px;
  background-color: #bbb;
  border-radius: 50%;
  float: left;
  margin-left: unset !important;
}

.credit_status {
  display: flex;
  align-items: center;
  margin-bottom: 1%;
}

.credit_status_row {
  padding: 5rem 0 0 0rem;
}

.credit_status li {
  float: left;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.5;
  padding-left: 1rem;
}

.credit_data_row .details-header {
  padding-top: 12px;
}

.invoices_desc_left_child .country span.address_sec {
  height: auto;
}

.invoices_desc .float-left.w-40 {
  padding-bottom: 10px;
}

.expanded_view_creditnote td:nth-child(3) {
  word-break: break-word;
}

.expanded_view_creditnote td:nth-child(3) .list_items_desc {
  padding-right: 3px;
}

/* @media (max-width: 1700px) {
  .expanded_view_creditnote thead tr th:nth-child(3) {
    width: 28%;
  }
} */
@media(max-width:1440px) {
  .credit_data_row .details-header {
    display: unset;
  }
}

@media (min-width: 780px) and (max-width: 1024px) {

  .invoices_desc .float-left.w-40,
  .invoices_desc .float-left.w-60 {
    width: 50%;
  }

  .invoices_desc .float-left.w-60 .w-60 {
    width: 90%;
  }
}

/* @media (max-width: 1024px) {
  .expanded_view_creditnote thead tr th:nth-child(3) {
    width: 26%;
  }
} */

.layout--box.no-padding.credit_note_details {
  max-width: inherit !important;
}

@media (max-width: 768px) {

  .invoices_desc .float-left.w-40,
  .invoices_desc .float-left.w-60 {
    width: 100%;
  }

  .credit_status_row {
    padding: 2.5rem 0 0 0rem;
  }
}