.notdisplaying {
  opacity: 0.4;
  pointer-events: none;
}

.two-fields-row,
.three-fields-row {
  display: flex;
  /* justify-content: space-between;
  align-items: flex-start; */
  flex-wrap: wrap;
  /* margin-left: -10px;
  margin-right: -10px; */
  margin: 0 -10px;
}

.two-fields-row>div .inline-field,
.three-fields-row .form-field {
  margin-right: 0;
}

.three-fields-row .searchable-dropdown {
  border-radius: 4px !important;
}

.three-fields-row .form-field input {
  width: 100%;
}

.three-fields-row .searchable-dropdown__input,
.three-fields-row .searchable-dropdown__single-value {
  padding-left: 7px;
}

.two-fields-row .asyncPaginateParent input {
  padding-left: 3px !important;
}

.customer-payment-table thead tr {
  height: 40px;
  background: #fff;
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
}

.customer-payment-table tbody tr {
  border-bottom: 1px solid var(--border-light);
}

.customer-payment-table tbody tr:nth-child(even) {
  background: #fbfbf7;
}

.customer-payment-table thead tr th,
.customer-payment-table tr td {
  padding: 0 1rem;
}

.customer-payment-table thead tr td {
  vertical-align: middle;
  /* height: 100% !important;
    display: flex;
    align-items: center; */
  word-break: break-word;
}

.no-record {
  text-align: center;
  /* color: red; */
}
.payment-popup span {
  display: flex;
  align-items: center;
}

.payment-popup span span {
  width: 20px !important;
}

.customer-payment-listing thead tr th:nth-child(5),
.customer-payment-listing tr td:nth-child(5) {
  width: 13%;
  word-wrap: break-word;
}
.customer-payment-listing thead tr th:nth-child(6),
.customer-payment-listing thead tr td:nth-child(6){
  width: 20%;
}
.customer-payment-listing thead tr th:nth-child(7), .customer-payment-listing thead tr td:nth-child(7){
  width: 11%;
}
/* .customer-payment-listing thead tr th:nth-child(7),
.customer-payment-listing thead tr td:nth-child(7), */
.customer-payment-listing thead tr th:nth-child(8),
.customer-payment-listing thead tr td:nth-child(8)
{
  width: 15%;
}

.customer-payment-listing tr td {
  padding-right: 5px !important;
}

.cust-table-summary,
.right-col .single-summary-rec,
.table-title {
  display: flex;
  justify-content: space-between;
}

.cust-table-summary {
  padding: 7px 10px;
  clear: both;
}

.left-col span {
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
}

.right-col {
  min-width: 300px;
}

.single-summary-rec span:first-child {
  padding-right: 10px;
  flex: 0 0 50%;
  width: 50%;
  word-break: break-word;
  white-space: normal;
}

.single-summary-rec span:nth-child(2) {
  flex: 0 0 50%;
  width: 50%;
  text-align: right;
  word-break: break-word;
  white-space: initial;
}

.right-col .single-summary-rec.border-top {
  padding: 7px 0;
}

.right-col .single-summary-rec.border-bottom {
  padding: 7px 0;
}

.border-top {
  border-top: 1px solid #e9e9e9;
}

.mb-6 {
  margin-bottom: 6px;
}

.field-pay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 0.5rem;
}

.field-pay input {
  height: 3rem;
  text-align: right;
}

.field-pay .form-field {
  padding-bottom: 0.6rem;
}

.payFullbtn {
  color: #0071bc;
}

.payFullbtn:hover,
.view-detail button:hover,
.table-title button:hover {
  text-decoration: none;
  color: #000;
}

.item_view_click_btn:hover {
  text-decoration: underline;
}

.cust-payment-date>.inline {
  flex-direction: column;
  align-items: flex-start;
}

.cust-payment-date .date-range-picker.date-picker-main {
  position: unset;
}

.table-title button {
  cursor: pointer;
  color: rgb(0, 113, 188);
  font-size: 80%;
  font-weight: 400;
}

.view-detail {
  text-align: right;
}

.view-detail button {
  color: var(--blue-500);
  font-size: 1.2rem;
}

.displaying .action-buttons .dropdwon_with_button>button {
  opacity: 1;
}

.payment-invoice-main button.buttonDateRangeFilter {
  margin-left: 0;
}

.payment-invoice-main .icon_div svg {
  margin-right: 0.9rem !important;
}

.payment-invoice-main .tooltip_tooltip {
  width: 400px;
}

.cust-table-summary .w-70 {
  width: 65% !important;
}

.cust-table-summary .w-30 {
  width: 35%;
}

.payment-actions .dropdwon_options_sales_order.custom-dropdown.button-text .custom-dropdown-container {
  width: 92px;
  left: -5rem !important;
}

@media (min-width: 1001px) {

  .three-fields-row>div,
  .two-fields-row>div {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    flex: 1;
  }

  /* 
  .three-fields-row>div {
    width: 32%;
  } */
}

@media (max-width: 1000px) {

  .two-fields-row>div,
  .three-fields-row>div {
    width: 100%;
  }

  .two-fields-row,
  .three-fields-row {
    flex-direction: column;
    margin: 0;
  }

  .customer-payment-listing thead tr th:nth-child(3),
  .customer-payment-listing tr td:nth-child(3),
  .customer-payment-listing thead tr th:nth-child(5),
  .customer-payment-listing tr td:nth-child(5),
  .customer-payment-listing thead tr th:nth-child(7),
  .customer-payment-listing tr td:nth-child(7) {
    display: none;
  }
 html body .customer-payment-listing tr th:nth-child(4), 
 html body .customer-payment-listing tr td:nth-child(4){
    width: 40%;
  }
  .customer-payment-listing thead tr th:nth-child(6), .customer-payment-listing thead tr td:nth-child(6){
    width: 23%;
  }
  .customer-payment-listing thead tr th:nth-child(8), .customer-payment-listing thead tr td:nth-child(8){
    width: 21%;
  }
}

button.buttonDateRangeFilter {
  font-size: 1.2rem;
  margin-left: 10px;
  color: var(--blue-500);
}