:root {
  --nav-width: 19rem;
  --nav-collapse-width: 5.2rem;
  --nav-settings-width: 18.8rem;
}

.ims-navigation {
  position: sticky;
  top: var(--header-height);
  height: calc(100vh - var(--header-height));
  overflow-x: hidden;
  background: var(--navigation-background);
  flex: 0 0 var(--nav-width);
  transition: flex 0.15s ease-out;
  overflow-y: auto;
  scrollbar-width: thin;
}

.manage_oraganization_btn {
  font-size: 14px !important;
}

.ims-nav--space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: width 0.15s ease-out;
}

.ims-nav--space-between.slided-navigation {
  width: var(--nav-collapse-width);
}

.ims-navigation::-webkit-scrollbar {
  width: 8px;
}

.ims-navigation::-webkit-scrollbar-thumb {
  height: 45px;
  background: hsla(0, 0%, 53.3%, 0.4);
}

.ims-navigation.collapse-navigation {
  flex: 0 0 var(--nav-collapse-width) !important;
}

.ims-navigation.collapse-navigation .ims-nav--link-add {
  display: none;
}

.ims-nav--link span,
.ims-nav--item-button span {
  transition: all 0.15s ease-out;
  white-space: nowrap;
  font-size: 14px;
}

.ims-nav--link span.slide,
.ims-nav--item-button span.slide {
  opacity: 0;
  visibility: hidden;
}

.ims-nav--settings {
  position: absolute;
  left: var(--nav-collapse-width);
  transform: translateX(100%);
  top: 0;
  background: #3a4045;
  width: var(--nav-settings-width);
  z-index: 3000;
  height: 100%;
  bottom: 0;
  overflow-y: auto;
  /* transition: transform .35s ease-in-out; */
  /* transform: translateX(0); */
  transform: translateX(0);
}

.setting-nav-width {
  width: 138px !important;
}

.setting-nav-width ul {
  width: 100% !important;
  overflow: hidden;
}

.setting-nav-width ul li {
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
}

.ims-nav--settings::-webkit-scrollbar {
  width: 8px;
}

.ims-nav--settings::-webkit-scrollbar-thumb {
  height: 45px;
  background: hsla(0, 0%, 53.3%, 0.4);
}

.ims-nav--settings .ims-nav--link span {
  white-space: unset;
}

.ims-nav--slided-navigation-enter {
  transform: translateX(100%);
}

.ims-nav--slided-navigation-enter.ims-nav--slided-navigation-enter-active {
  transform: translateX(0);
  transition: transform 0.35s ease-in-out;
}

.ims-nav--slided-navigation-leave.ims-nav--slided-navigation-leave-active {
  transform: translateX(100%);
  transition: transform 0.35s ease-in-out;
}

.ims-nav--slided-navigation-leave {
  transform: translateX(0);
}

.ims-nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.ims-nav--settings .ims-nav--title {
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
}

.ims-nav--settings .ims-nav--link {
  font-size: 1.2rem;
}

.ims-nav--settings .ims-nav--sub-title {
  font-size: 1.2rem;
  background: rgba(82, 93, 100, 0.3);
  color: #e1e4e5;
  padding: 0.5rem;
  margin: 1rem;
  margin-top: 2rem;
}

.ims-nav li {
  position: relative;
  flex: 1;
  padding: 0 0.4rem 0 0.4rem;
  margin: 0.6rem 0;
}

.ims-nav--link.active svg path {
  fill: #ffffff !important;
}
.ims-nav--link.active i {
  color: #ffffff !important;
}

.ims-nav li .ims-nav--link.active .ims-nav-menu-text {
  color: #fff !important;
}

.ims-nav-menu-text {
  color: #000;
}

.ims-nav .ims-nav--hr {
  border-top: 1px solid transparent;
  /* border-top: 1px solid var(--navigation-link-hover); */
  margin: 0 1.2rem;
}

.ims-nav--link {
  color: var(--navigation-link-text-color);
  display: flex;
  align-items: center;
  outline: none;
  padding: 0.6rem;
  border-radius: 2px;
}

.ims-nav--link.active {
  background: #3bb54a;
  /* background: #0071BC; */
  color: var(--navigation-link-text-color-selected);
}

.ims-nav li:hover .ims-nav--link.active {
  background: #3bb54a;
}

.ims-nav--link-add {
  position: absolute;
  /* right: 2.5rem; */
  right: 10px;
  top: 6px;
  /* top: 20%; */
  /* transform: translateY(-50%); */
  visibility: visible !important;
  height: 16px;
  width: 16px;
}

.ims-nav--link-add svg {
  font-size: 20px;
  background-color: white;
  color: #8e8e8e;
  border: 1px solid #f3f4f5;
  border-radius: 4px;
  fill: #8e8e8e;
  transform: rotate(0deg);
  transition: 0.3s;
}

.ims-nav--link-add:hover svg {
  transform: rotate(90deg);
  fill: #1ca037;
}

.ims-nav--link:focus {
  outline: none;
  color: var(--navigation-link-text-color);
  text-decoration: none;
}

.ims-nav li:hover > .ims-nav--link-add {
  visibility: visible;
  line-height: 1;
}

/* .ims-nav--link-add:hover {
  background: #3bb54a;
  border-radius: 50%;
} */

.ims-nav--item-button {
  background: transparent;
  outline: none;
  border: 0;
  color: var(--navigation-link-text-color);
  display: flex;
  width: 100%;
  padding: 0.6rem;
  border-radius: 2px;
  align-items: center;
}

.ims-nav--item-button:hover {
  /*background: var(--navigation-link-hover);*/
  background: var(--navigation-link-bg-color-hover);
  /*color: var(--navigation-link-text-color);*/
  color: var(--navigation-link-text-color-hover);
}

.ims-nav--item-button.active {
  background: #3bb54a;
}

.ims-nav--item-button .item-expand-icon {
  margin-left: auto;
  transform: scale(1.5);
}

.ims-nav--item-ul {
  display: block;
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease-out;
}

.ims-nav--item-ul.show-ul {
  height: auto;
}

.ims-nav--item-ul.show-ul-settings {
  height: auto;
}

.ims-nav--item-ul.show-ul-settings .ims-nav--link.active span {
  font-size: 14px !important;
}

.ims-nav--item-ul.show-ul-settings .ims-nav--link.active span {
  color: #ffffff;
}

.ims-nav--item-ul.show-ul .ims-nav--link.active span {
  color: #ffffff;
}

.ims-nav--item-ul .ims-nav--link {
  margin-left: 1.2rem;
}

.ims-nav li:hover > .ims-nav--link {
  /*background: var(--navigation-link-hover);*/
  background: var(--navigation-link-bg-color-hover);
  /*color: var(--navigation-link-text-color);*/
  color: var(--navigation-link-text-color-hover);
}

.ims-nav--link-icon {
  flex-shrink: 0;
}

.ims-nav--link-icon > path,
.ims-nav--link-icon > circle,
.ims-nav--link-icon > g > g > path {
  fill: #000000 !important;
}

.ims-nav li:hover > .ims-nav--link {
  fill: #ffffff !important;
}

.ims-nav-menu-icon {
  /*margin-right: 1rem;*/
  width: 15px;
  /* text-align: center; */
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0;
}
.ims-nav-menu-icon i {
  width: 18px;
  font-size: 13px;
  text-align: center;
}
.ims-nav-menu-icon .ims-nav--link-icon {
  width: 18px;
}

.ims-nav-menu-icon > svg > path {
  fill: #000000 !important;
}

.ims-nav-menu-text {
  /* margin-left: 1rem; */
  margin-left: 0.5rem;
  line-height: 0;
  padding-top: 1px;
}

.ims-nav-menu-subtext {
  /* margin-left: 0.6rem; */
}

.ims-nav-expand-top-margin-plus3 {
  margin-top: 3px;
}

.ims-nav-expand-top-margin-minus3 {
  margin-top: -3px;
}

.ims-nav--link-icon--sale-return {
  width: 25px !important;
  height: 25px !important;
}

.left-direction-arrow {
  /* float: right; */
  position: relative;
  right: 0;
}

.right-direction-arrow {
  transform: rotate(180deg);
}

.nav-arrow {
  color: white;
  /* float: right; */
  width: 100%;
  text-align: right;
  /* padding: 0 10px 0 0; */
  margin: 10px 0px;
}

.nav-arrow svg {
  width: 45px;
  height: 25px;
  padding: 0 0px 0 0;
  /* border: 1px solid transparent; */
  border-top: 1px solid transparent;
  border-right: 0;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  transition: 0.3s ease-in;
  fill: #000;
  background-color: #dfe1e5;
}

.nav-arrow:hover svg {
  fill: #000;
}

.nav-arrow:hover svg {
  /* padding: 0 15px 0 0; */
  /* border: 2px solid #4e4e4e; */
  /* border: 1px solid #555A5E; */
  /* border-top: 1px solid #555A5E; */
  border-right: 0;
  /* border-left: 1px solid #555A5E; */
  /* border-bottom: 1px solid #555A5E; */
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 0 10px 0 0;
}

.nav-arrow:hover {
  border-radius: 10px;
}

.collapse-navigation ul li:last-child span {
  margin-left: 9px;
}

.collapse-navigation ul li:nth-child(3) span svg {
  margin-left: 10px !important;
}

.collapse-navigation ul li:nth-child(7) span svg {
  margin-left: 9px !important;
}

.collapse-navigation ul li:nth-child(8) span svg {
  margin-left: 11px !important;
}

.collapse-navigation ul li:nth-child(9) span svg {
  margin-left: 9px !important;
}

.collapse-navigation ul li:nth-child(10) span svg {
  margin-left: 8px !important;
}

.collapse-navigation ul li:nth-child(11) span svg {
  margin-left: 7px !important;
}

.collapse-navigation ul li:nth-child(12) span svg {
  margin-left: 5px !important;
}

@media (max-width: 1199px) {
  .ims-navigation.ims-nav-mob.slide-in {
    transition: 0.5s left ease;
    left: 0;
    right: auto;
    z-index: 9999;
  }

  .ims-navigation.ims-nav-mob.slide-out {
    left: -100%;
    transition: 1s left ease;
  }

  .open-menu {
    position: fixed;
    top: 47px;
    left: 0;
    right: 0;
    opacity: 0;
    height: 100vh;
    z-index: 1;
    /* background-color: rgba(0, 0, 0, .5); */
    display: none;
  }

  .overlay-menu-open {
    display: block;
    opacity: 1;
    z-index: -1;
  }

  /* .ims-navigation.ims-nav-mob.slide-in{
  display: none;
} */
}

/*=============================
Icon Alignment of Left-Sidebar
===============================*/
.ims-navigation.collapse-navigation.slide-out .ims-nav li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ims-navigation.collapse-navigation.slide-out .ims-nav li .ims-nav--link,
.ims-navigation.collapse-navigation.slide-out
  .ims-nav
  li
  .ims-nav--item-button {
  width: 30px !important;
  text-align: center;
}

.collapse-navigation.slide-out ul li:nth-child(9) span svg,
.collapse-navigation .ims-nav--link-icon,
.collapse-navigation ul li:last-child span {
  margin-left: 0 !important;
}
.ims-navigation.collapse-navigation.slide-out .ims-nav--item-ul {
  display: none;
}
