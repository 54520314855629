.ims-modal-transition-enter {
  opacity: 0;
}

.ims-modal-transition-enter.ims-modal-transition-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}

.ims-modal-transition-leave {
  opacity: 1;
}

.ims-modal-transition-leave.ims-modal-transition-leave-active {
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.ims-modal-transition-enter .ims-modal {
  opacity: 0.5;
  transform: translate(-50%, -10%);
}

.ims-modal-transition-enter.ims-modal-transition-enter-active .ims-modal {
  opacity: 1;
  transform: translate(-50%, 5rem);
  transition: transform 150ms ease-in, opacity 150ms ease-in;
}

.ims-modal-transition-leave .ims-modal {
  opacity: 1;
  transform: translate(-50%, 5rem);
}

.ims-modal-transition-leave.ims-modal-transition-leave-active .ims-modal {
  opacity: 0.5;
  transform: translate(-50%, 1rem);
  transition: transform 150ms ease-out, opacity 150ms ease-out;
}

.ims-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5000;
  overflow-y: auto;
}
.ims-modal-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c0c0c0;
  box-shadow: inset 0 0 6px #c0c0c0;
  background-color: #efecec;
}



.ims-modal-container::-webkit-scrollbar {
  width: 9px;
}

.ims-modal-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px #dbdbdb;
  /*box-shadow: inset 0 0 6px #dbdbdb;*/
  box-shadow: inset 0 0 6px #c0c0c0;
  background-color: #c0c0c0;
}

/* Handle on hover */

.ims-modal-container::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}


.ims-modal {
  position: absolute;
  top: 0;
  left: 50%;
  background: #fff;
  min-width: 60rem;
  /*max-width: 87.3rem;*/
  border-radius: 4px;
  overflow: hidden;
  box-shadow: var(--shadow-hover);
  transform: translate(-50%, 5rem);
  opacity: 1;
  margin-bottom: 5rem;
  overflow: unset;
  padding: 0 2.4rem;
}

.ims-modal.large {
  min-width: 94rem;
  max-width: 90vw;
}

.dragDropPopupContainer .ims-modal {
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 5rem;
  transform: none;
  /* overflow: hidden; */
}

.dragDropPopupContainer .ims-modal.so-package-popup {
  overflow: unset;
}

.dragDropPopupContainer.ims-modal-transition-leave.ims-modal-transition-leave-active .ims-modal,
.dragDropPopupContainer.ims-modal-transition-enter .ims-modal {
  transform: none !important;
}

.ims-modal.large.contacts_modal_view,
.ims-modal.return-receive-popup {
  width: 98rem;
  min-width: unset !important;
}

.contacts_modal_view table.list-table.linked-row tbody tr td:last-child  .list-table--link span {font-size: 14px !important;font-weight: 400;}
.ims-modal.bulk {
  min-width: 45rem;
  width: 70vw;
}

.ims-modal--title {
  background: #323639;
  padding: 1.6rem 0;
  color: #fff;
  font-size: 2rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2rem;
}

.ims-modal--title .close-button {
  width: 40px;
  display: flex;
  justify-content: space-between;
}

.ims-modal--title .close-button a {
  float: left;
}

.shipment_header {
  background: #323639;
  /* padding: 0.6rem 0; */
  color: #fff;
  font-size: 2rem;
  display: block !important;
  float: left;
  width: 100%;
}

.ims-modal.receive_modal .payment_modal {
  margin: 8px 0 15px 0;
}

.shipment_header .payment_modal table {
  margin-bottom: 10%;
}

.shipment_header span {
  float: left;
  top: 6px;
  position: relative;
}

.shipment_header span svg {
  position: relative;
  top: -4px;
}

.shipment_header .jjgjgjgg {
  float: right;
}

.shipment_header .jjgjgjgg span {
  margin-right: 0 !important;
  float: left !important;
  position: unset !important;
}

.shipment_header .jjgjgjgg .close-button {
  float: right;
  top: 6px;
  position: relative !important;
  width: 18px;
}

.shipment_header .jjgjgjgg svg {
  top: 0px !important;
}

.ims-modal--title .close-button button {
  text-align: right;
  outline: none;
  border: 0;
  margin: 0;
  background: transparent;
}

.ims-modal--title .close-button button:hover svg {
  fill: #fff;
  stroke: #fff;
  stroke-width: 2;
}

/*commenting for resolving a ticket ( SIQ - 2082 ) */

.ims-modal--body {
  padding: 1.6rem 0;
}

.ims-modal--body.modal-pdf {
  padding: 0;
}

.ims-modal--action {
  display: flex;
  padding: 1.6rem 0;
  justify-content: flex-end;
  /* float: right; */
  border-top: 1px solid var(--border-light);
}

.ims-modal--action--confirm,
.ims-modal--action--cancel,
.ims-modal--action--delete,
.ims-modal--action--delete-cancel {
  color: white;
  margin-right: 1.6rem;
}

.button.ims-modal--action--confirm:hover {
  background: #fff !important;
  color: #000 !important;
  box-shadow: var(--hover-box-shadow);
}

.button.ims-modal--action--delete:hover {
  background: #fff !important;
  color: #000 !important;
  box-shadow: var(--hover-box-shadow);
}

.ims-modal--action--confirm {
  background: var(--blue-500);
}

.ims-modal--action--cancel {
  background: var(--background);
  color: var(--text-secondary);
  border: 1px solid var(--border-dark);
}

.ims-modal--action--cancel:hover {
  background-color: var(--hover);
}

.ims-modal--action--delete {
  /* background: var(--red-500); */
  background: #33a348 !important;
  transition: 0.3s;
}

.ims-modal--action--delete-cancel {
  background-color: var(--background);
  color: var(--text-secondary);
  border: 1px solid var(--border-dark);
  transition: 0.3s;
}

.button.ims-modal--action--delete-cancel:hover {
  background: #fff !important;
  color: #000 !important;
  box-shadow: var(--hover-box-shadow);
  border: 1px solid transparent !important;
}

.ims-modal form {
  width: 100%;
}

.ims-modal.no-padding .ims-modal--body {
  padding: 0;
}

.ims-modal.white .ims-modal--title {
  background: #fff;
  color: var(--black);
  border-bottom: 1px solid var(--border-light);
  font-size: 1.4rem;
  font-weight: 500;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ims-modal.white .ims-modal--title .close-button button>svg {
  height: 2rem;
  width: 2rem;
  fill: var(--icon-primary);
  transform: rotate(0deg);
  transition: 0.3s;
}

.ims-modal.white .ims-modal--title .close-button button:hover svg {
  fill: initial;
  stroke: initial;
  stroke-width: initial;
  transform: rotate(90deg);
}

.show-icon {
  vertical-align: sub;
}

.show-icon path {
  fill: #3e3e3e !important;
}

.invoices-large {
  min-width: 120rem !important;
}

.invoices-large textarea {
  width: 48.5rem;
}

.confitm_button {
  background: #33a348 !important;
  transition: 0.3s;
}

.cancel_button {
  background-color: var(--background);
  color: var(--text-secondary);
  border: 1px solid var(--border-dark);
  transition: 0.3s;
}

.form hr {
  border-top: 1px solid var(--border-light) !important;
}

/* .jjgjgjgg .secondry_buttons_group button svg{
  top:0px !important;
} */

#next-btn {
  margin-left: 10px;
}

@media (min-width: 1280px) {
  .dragDropPopupContainer .ims-modal.large {
    max-width: 1248px;
  }

  .dragDropPopupContainer .ims-modal.medium {
    max-width: 955px;
  }

  .dragDropPopupContainer .ims-modal.w-1288,
  html body div.w-1288 {
    max-width: 1288px;
  }
}
@media(max-width:1800px){
  .ims-modal-container .contacts_modal_view .right-tabs-section .list-table--wrapper {
    height: calc(100vh - 90px);
    overflow: auto;
}
.ims-modal-container .contacts_modal_view .right-tabs-section-inner .list-table--wrapper .transaction_table{
  max-height: calc(100vh - 100px) !important;
}
}
@media (max-width: 1280px) {

  .so-invoice-popup,
  .ims-modal.large {
    min-width: 94vw !important;
  }

  .so-invoice-popup .invoices-large {
    min-width: auto !important;
  }

  .so-invoice-popup .form-table--container {
    margin-right: 0;
  }

  .so-invoice-popup .action-buttons {
    margin-right: 2rem !important;
  }

  .ims-modal-container {
    z-index: 9999;
  }

  .textarea-invoice {
    display: block;
    width: 50%;
  }

  .inline-terms-and-conditions {
    width: 50%;
  }

  .textarea-so-terms {
    display: block !important;
  }

  .invoice-popup-set {
    max-width: 90vw;
  }
}

@media (min-width: 992px) and (max-width: 1260px) {
  .so-tbletabs .searchable-dropdown__placeholder {
    font-size: 12px;
  }
}

@media (min-width: 1124px) and (max-width: 1280px) {
  .ims-modal.ship-modal-res-popup {
    min-width: auto !important;
  }
}
@media(max-width:1138px){
  .ims-modal-container .contacts_modal_view .right-tabs-section-inner .list-table--wrapper .transaction_table{
    max-height: calc(100vh - 135px) !important;
  }
}
@media (max-width: 992px) {
  .invoices-large .form-field.inline-field:nth-child(3n) {
    margin-right: 3rem !important;
  }

  .so-invoice-popup .summary_table .w-60,
  .so-invoice-popup .summary_table .w-40 {
    width: 50%;
  }

  .reports--body .table {
    min-width: 1080px;
  }
  .reports--body.fifo-report-main .table{
    min-width: 1340px;
  }

  .so-tbletabs,
  .invoice-popup-set .sales_return_table {
    overflow: auto;
    clear: both;
  }

  .so-tbletabs tbody tr:last-child,
  .invoice-popup-set .sales_return_table tbody tr:last-child {
    padding-bottom: 100px;
  }

  .invoice-popup-set .sales_return_table::-webkit-scrollbar-track,
  .so-tbletabs::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #c0c0c0;
    box-shadow: inset 0 0 6px #c0c0c0;
    background-color: #efecec;
  }

  .invoice-popup-set .sales_return_table::-webkit-scrollbar,
  .so-tbletabs::-webkit-scrollbar {
    height: 9px;
  }

  .invoice-popup-set .sales_return_table::-webkit-scrollbar-thumb,
  .so-tbletabs::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    /*box-shadow: inset 0 0 6px #dbdbdb;*/
    box-shadow: inset 0 0 6px #c0c0c0;
    background-color: #c0c0c0;
  }

  /* Handle on hover */

  .invoice-popup-set .sales_return_table::-webkit-scrollbar-thumb:hover,
  .so-tbletabs::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
  }

  html body .so-tbletabs table,
  .invoice-popup-set .sales_return_table table {
    width: 1020px !important;
  }

  .invoice-popup-set.create_invoice_popup.so-invoice-popup .form-field.inline-field:nth-child(3n) {
    margin-right: 0 !important;
  }

  .create-invoice-sec .form-field.inline-field:nth-child(4) {
    margin-left: 0;
  }

  .ship-modal-res-popup .form-field {
    width: 100%;
    float: left;
  }

  /* .so-tbletabs table > thead > tr > th, .so-tbletabs table > tbody > tr > th, .so-tbletabs table > tfoot > tr > th, .so-tbletabs table > thead > tr > td, .so-tbletabs table > tbody > tr > td, .so-tbletabs table > tfoot > tr > td {
    white-space: nowrap;
} */
}

@media (max-width: 786px) {
  .invoice-popup-set.so-invoice-popup .create-invoice-sec .form-field.inline-field {
    width: 100%;
  }

  .middle-row {
    flex-direction: column;
  }
}