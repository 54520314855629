.preference {
  display: grid;
  grid-template-columns: 24rem auto;
  grid-column-gap: 0 !important;
}

.preference .close-icon-pos {
  top: 11px !important;
}

.preference .ims-sidenav {
  background: #f0f2f5;
  height: calc(100vh - 110.5px);
  float: left;
  width: 100%;
  display: block;
}

.preference .ims-sidenav .ims-sidenav--link {
  float: left;
  width: 98%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  position: relative;
  border-bottom: 1px solid #dfe1e5;
}

.preference .ims-sidenav .ims-sidenav--link.active {
  background-color: #306ab4;
  color: #fff;
}

.preference .card-header {
  padding: 1.5rem;
  height: 5rem;
}

.preference .card {
  height: auto;
}

.preference .card-body {
  color: var(--black);
  padding: 1rem 2rem;
  font-size: unset;
}

.preference .card:hover {
  box-shadow: none;
}

.preference .pref--hr {
  margin: 2rem 0;
}

.preference .form-field {
  grid-template-columns: auto;
  align-items: unset;
}

.preference .textarea {
  min-width: 42rem;
  min-height: 15rem;
}

.preference .form-label {
  text-align: left;
}

.genral_tab_content {
  padding: 20px 0;
  height: calc(100vh - 192px);
  position: relative;
  overflow: auto;
  overflow-x: hidden;
}

.genral_tab_content .action-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
}

.genral_tab_content input {
  float: left;
  top: 3px;
  margin-right: 10px !important;
  left: 0;
}

.genral_tab_content .radio-btn label {
  padding-left: 30px;
  margin-bottom: 10px !important;
}

.genral_tab_content .radio-btn {
  display: block !important;
}

.genral_tab_content .radio-circle {
  top: 1px;
}

.mb_5 {
  margin-bottom: 20px;
}

.mt_0 {
  margin-top: 0 !important;
}

.general_tabs_set textarea#terms {
  font-weight: 400;
}

.general_tabs_set label.form-label {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}

/* .dashboard-preferences {
    clear: both;
    float: left;
    height: 100%;
} */
/*
.dashboard-actions {
    width: 100%;
    clear: both;
} */

/* @media(max-width:1800px){ */
/* .dashboard-preferences .dashboard-preferences {
    max-height: 700px;
    float: left;
    width: 100%;
    overflow-y: auto;
} */

.dashboard-preferences .genral_tab_content {
  height: unset;
}
.dashboard-preferences {
  padding-top: 19px;
}
.dashboard-preferences h4 {
  margin-bottom: 5px;
}
.dashboard-preferences .genral_tab_content {
  padding: 0;
}
.dashboard-preferences .sale-return-preference--label-text {
  font-size: 14px;
  font-weight: 400;
}

.dashboard-preferences label {
  margin-bottom: 6px !important;
}
.dashboard-actions {
  float: left;
  width: 100%;
  position: relative;
  padding-right: 2rem;
}
.dashboard-actions .action-buttons {
  position: unset;
}
.dashboard-preferences
  .form-field
  .searchable-dropdown
  .searchable-dropdown__option {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  padding: 4px 10px;
}
.dashboard-preferences .form-field .searchable-dropdown .searchable-dropdown__single-value {
  font-weight: 400;
}
/* } */
