.alert {
  background: var(--blue-100);
  border: 1px solid var(--blue-800);
  padding: .9rem 1.6rem;
  width: 40rem;
  box-shadow: var(--shadow-light);
  /*word-break: break-all;*/
  margin-bottom: 2px !important;
  border-radius: 2px !important;
}

.alert.full-width {
  width: 100%;
}

.alert.green {
  /*background: var(--green-status);*/
  background: #e5ffe9;
  border: 1px solid var(--green-800);
}

.alert.green .alert--title, .alert.green .alert--body {
  /*color: var(--green-status-text);*/
  color: black;
}

.alert--title {
  color: var(--blue-900);
  font-weight: 600;
  line-height: 2.9rem;
  font-size: 1.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert--title svg {
  font-size: 1.8rem;
}

.alert--search-icon {
  margin-right: 1rem;
}

.alert--body {
  color: var(--blue-900);
  letter-spacing: .25px;
  line-height: 2.9rem;
}