.import {
  padding: 0 2rem;
}

.import-header {
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
}

.import-body {
  margin: 3rem 0;
}

.import-footer {
  margin: 2rem 0;
  text-align: right;
}

.import_summary_loader {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column;
  /* padding-left: 40rem; */
}
