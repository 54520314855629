.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown.dot-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-dropdown-container {
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: auto;
  right: 0;
  padding: 1rem 0;
  min-width: 16rem;
  line-height: 3rem;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #eeeeee;
  box-shadow: 0 2px 4px 0 #eeeeee;
}

.custom-dropdown-container.down {
  top: 3rem;
  right: 1rem;
}

.custom-dropdown-container.up {
  right: 3rem;
}

.custom-dropdown.dot-icon > button {
  display: flex;
  justify-content: center;
  padding: 0;
  transform: rotate(90deg);
  margin: 0;
  width: 3rem;
}

.custom-dropdown.dot-icon > button:hover {
  background-color: transparent;
}

.custom-dropdown.dot-icon > button > svg {
  font-size: 2rem;
}

.custom-dropdown .button + .custom-dropdown-container {
  height: 0;
  display: none;
}

.custom-dropdown .button-active + .custom-dropdown-container {
  visibility: visible;
  opacity: 1;
  height: auto;
  display: unset;
}
.custom-dropdown
  .button-active
  + .custom-dropdown-container
  span.custom-dropdown-set {
  font-size: 1.2rem;
  margin-bottom: 0;
}
.custom-dropdown
  .button-active
  + .custom-dropdown-container
  .custom-dropdown-set:hover {
  color: #306ab4 !important;
}
.custom-dropdown.button-text
  .button
  + .custom-dropdown-container
  .custom-dropdown--options {
  height: 0;
  margin: 0;
  border: 0;
  padding: 0;
}

.custom-dropdown-container > .custom-dropdown--options,
.custom-dropdown.button-text
  .button-active
  + .custom-dropdown-container
  .custom-dropdown--options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #3d3d3d;
  text-decoration: none;
  border: 0;
  outline: 0;
  background-color: transparent;
  text-align: left;
  font-size: 1.2rem;
  height: auto;
  padding: 0 1rem;
  white-space: nowrap;
  margin: 0.5rem 0;
  position: relative;
  padding-left: 40px;
  font-weight: 400;
}

.search-bar .custom-dropdown-container > .custom-dropdown--options,
.custom-dropdown.button-text
  .button-active
  + .custom-dropdown-container
  .custom-dropdown--options {
  color: #000 !important;
  padding: 0 0.5rem 0 0.8rem !important;
  margin: 0 !important;
  font-size: 14px;
  position: relative;
  font-weight: 400;
}

.custom-dropdown-container > .custom-dropdown--options:hover,
.custom-dropdown.button-text
  .button-active
  + .custom-dropdown-container
  .custom-dropdown--options:hover {
  color: #306ab4 !important;
  background-color: #dfe1e5;
  border-radius: 0;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0rem !important;
}

/* Addtion to new filter */

/* ==================== */
.custom-dropdown.button-text .custom-dropdown-container {
  right: auto;
  left: 1rem;
  min-width: 115%;
  border-top: 0;
  top: 3rem;
  padding: 0.5rem 0 0.75rem;
  min-width: 100%;
}

.transaction_Dropdwon .custom-dropdown-container {
  right: auto;
  left: 4px !important;
  min-width: 95% !important;
  border-top: 0;
  top: 3rem;
  padding: 0.5rem 0 0.75rem;
}

.transaction_Dropdwon.custom-dropdown.button-text .button-active {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.custom-dropdown.button-icon .custom-dropdown-container {
  padding: 0.5rem 0 0.75rem;
  top: 4rem;
}

.custom-dropdown.button-icon > .button {
  padding: 0.2rem;
  border-radius: 0.4rem;
}

.custom-dropdown.button-icon > .button > svg:first-child {
  width: 2rem;
  height: 1.875rem;
  fill: var(--blue-500);
  margin: 0;
}

.custom-dropdown.button-icon
  .custom-dropdown-container
  > .custom-dropdown--options {
  padding: 0 1rem;
  white-space: nowrap;
  margin: 0.5rem 0;
  align-items: center;
  color: #3d3d3d;
}

.custom-dropdown.button-icon svg {
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.5rem;
  fill: #3d3d3d;
}

.custom-dropdown.button-text .button {
  padding: 0.65rem 1rem;
  /* font-size: 2rem; */
  width: 100%;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  text-align: left;
}

.custom-dropdown.button-text .button-active {
  /* border: 1px solid #eee !important; */
  border-bottom: 0;
  /* box-shadow: 0 2px 4px 0 #eeeeee; */
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/* .custom-dropdown.button-text .button-active, .custom-dropdown.button-text > button.button-active:hover {
  background: none;
} */

.custom-dropdown.button-text .button > svg {
  fill: var(--blue-500);
  vertical-align: middle;
}

/* ==================== */

.custom-dropdown-container .custom-dropdown--tagline {
  margin: 0 0.5rem 0 0.8rem;
  color: var(--grey);
  display: block;
}

.custom-dropdown-container > button[disabled],
.custom-dropdown-container > .custom-dropdown--options.button-disabled {
  opacity: 0.4;
  color: dimgray;
  cursor: not-allowed;
}

.custom-dropdown > .button {
  border-radius: 0;
}

.custom-dropdown > .button:hover {
  background-color: var(--button-hover);
}

.custom-dropdown .button-active > svg {
  fill: var(--blue-500);
}

.list-header-dropdown-sort--btn.button {
  width: 100%;
  border-radius: 0;
}

.custom-dropdown--header {
  font-size: 1.2rem;
  color: #3d3d3d;
  padding: 0 1rem 1.2rem;
  line-height: 1.6rem;
  /* TODO: var assign */
  border-bottom: 1px solid #d8e7f1;
}

.custom-dropdown--footer {
  font-size: 1.2rem;
  color: #3d3d3d;
  padding: 1.2rem 1rem 0;
  line-height: 1.6rem;
  border-top: 1px solid #d8e7f1;
  white-space: nowrap;
}

.custom-dropdown--footer a {
  color: #3d3d3d;
}

.custom-dropdown--footer svg {
  width: 1.75rem;
  height: 1.8rem;
  margin-right: 0.4rem;
  fill: #3d3d3d;
}

.list-header-dropdown--option-label {
  padding-left: 1rem;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  user-select: none;
  line-height: 3.5rem;
}

.list-header-dropdown--option-label > span {
  position: relative;
  margin-left: -2.5rem;
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
  border: 1px solid #589dd8;
}

.list-header-dropdown--option-label > span > svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  transform: scale(1.3);
  color: var(--seebiz-light-blue);
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.list-header-dropdown--option-label > input[type='checkbox'] {
  opacity: 0;
}

.list-header-dropdown--option-label
  > input[type='checkbox']:checked
  + span
  > svg {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

/* added order icon */

.custom-dropdown--options.ascending:after,
.custom-dropdown--options.descending:after {
  right: 1rem;
}

.custom-dropdown-icon {
  position: absolute !important;
  left: 5px !important;
  width: 17px !important;
  height: 17px !important;
  transform: rotate(0) !important;
  top: 6px !important;
  left: 10px !important;
}

.custom-dropdown--options {
  font-weight: 400;
}

.reversee {
  top: auto !important;
  bottom: 100% !important;
}

/* 
.add_trasaction .button svg path{
  fill: #fff;
} */

.add_trasaction .button svg :hover {
  fill: #adb6bc !important;
}

.add_trasaction .list-table--options .button {
  padding: 1rem 0 1rem 1.45rem !important;
}

/* .add_trasaction .custom-dropdown-container {
  cursor: pointer;
} */

.custom-dropdown.transaction_Dropdwon.mr-10.abcd.add_trasaction.button-text
  .button
  svg
  path {
  fill: #fff;
}

.custom-dropdown.transaction_Dropdwon.mr-10.abcd.add_trasaction.button-text
  .button:hover
  svg
  path {
  fill: #adb6bc;
}

/* .custom-dropdown.transaction_Dropdwon.mr-10.abcd.add_trasaction.button-text .button:hover{
  color:#000 ;
} */
.search-bar .custom-dropdown-container > .custom-dropdown--options,
.custom-dropdown.button-text
  .button-active
  + .custom-dropdown-container
  .custom-dropdown--options {
  font-size: 12px;
}
@media(max-width:1600px){
  .custom-dropdown-container .custom-dropdown--tagline{
    line-height: 19px;
    font-size: 13px;
  }
}