.home-header {
  display: flex;
  justify-content: space-between;
  height: 4rem;
  align-items: center;
  position: relative;
  top: 2rem;
  z-index: 100;
  padding: 0 10%;
  margin: 0 auto;
}


.home-header-nav-container {
  height: 100%;
}
.home-header-nav {
  display: grid;
  grid-template-columns: repeat(2, minmax(5rem, auto));
  grid-gap: 4rem;
  height: 100%;
}
.home-header-nav a {
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
}
a.home-header-nav--sign-in {
  background-color: white;
  color: var(--blue-500);
  height: 100%;
  padding: 0 2rem;
  border-radius: 4px;
}

@media only screen and (max-width: 1200px) {
  .home-header {
    width: 100%;
    padding: 0 1.5rem;
  }
  .home-header-nav {
    grid-gap: 1rem;
  }
}