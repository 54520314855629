.receives--bill-status-column {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  white-space: nowrap;
}

.receives--bill-status-column a {
  display: inline-block;
  color: var(--green);
}

.recieve_popup .list-table.form-table {
  width: 100% !important;
}

.recieve_popup .list-table.form-table .with-close--btn .close-btn.button {
  top: 10px;
}
.received-item-popup .section-details--info {
  width: 80% !important;
  display: block;
  padding-right: 10px;
}

.received-item-popup tbody tr.table--row td.pointerIn:first-child span.list-table--item--col > div {
  align-items: center;
}
.received-item-popup .section-details--info .details_fields div:first-child {
  width: 15% !important;
}

.received-item-popup .section-details--info .details_fields div:last-child {
  width: 75% !important;
}
.received-item-popup .section-details--info .receive_modal_fields_value{
  display:flex;
}
.received-item-popup .section-details--info .receive_modal_fields_value {
  width: 100% !important;
}

.receive_modal {
  width: 47% !important;
}

.receive_modal_fields_key {
  width: 40% !important;
  text-align: left;
  display: flex;
  /* flex-direction: row-reverse; */
  font-size: 14px !important;
}

.receive_modal_fields_value {
  width: 60% !important;
}

.receive_modal_headeR_icon {
  display: flex;
  align-items: center;
}

.details_receive {
  margin-top: 2% !important;
  margin-bottom: 3% !important;
}

a:hover {
  color: #000;
}

.receive_convert_to_bill {
  padding: 0.2rem 0.5rem;
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 1.2rem;
  display: inline-block;
  word-break: keep-all;
  width: 94px;
  text-align: center;
  background-color: #33A348;
  color: #FFF;
  transition: 0.3s;
  border: 1px solid #33A348;
}

.receive_convert_to_bill:hover {
  color: #000;
  background-color: #FFF;
  border: 1px solid #000;
}

.sales-return-main.purchase-order-set .menu-component--menu.menu_top {
  width: 99.8% !important;
  margin-left: 0 !important;
}

.purchase-popup-set .input-feedback {
  height: 35px;
  padding: 4px 0;
}
.purchase-popup-set .with-close--btn input {border: 1px solid var(--border-dark);}
.purchase-popup-set .list-table .form-field {
  padding: 0 6px 17px 0;
}
.BillsFormPopup .form-fields-container .form-field.error .input-feedback {bottom: -1.0rem;}

@media screen and (max-width: 1024px) {
  .recieve_popup {
    margin-right: 0;
  }

  html body .purchase-order-set.purchase-order-vendor,
  .purchase-order-set {
    display: inline-block;
    width: 48%;
    margin-right: 19px;
  }
  .purchase-order-set.po-summary{
    margin-right: 0;
  }

  .purchase-order-set .inline-field {
    width: 100% !important;
    display: block;
  }
}

@media(max-width:991px) {
  .purchase-order-set {
    margin-right: 12px;
  }
  .purchase-order-set.po-summary{
    margin-right: 0;
  }
}