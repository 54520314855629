.add_credit_note .table-header th {
  line-height: 1.42857143 !important ;
  background: #fff !important;
  border: 0 !important;
}
.add_credit_note.form-table.list-table th,
.add_credit_note.form-table.list-table td {
  border: 0 !important;
}
.add_credit_note.form-table td {
  height: auto !important;
  border: 0 !important;
}

.add_credit_note.form-table .with-close--btn .close-btn.button {
  top: 4px;
}
.add_creditnote_modal_Width .__dragDrop .__drag-righ,.add_creditnote_modal_Width .__dragDrop {width: 100%;}
.add_credit_note.form-table td:nth-child(1) {
  padding: 0.4rem 0.8rem 0.4rem 0.2rem;
}
.cn-totals span.semi-bold {
  font-size: 16px;
  top: -3px;
  position: relative;
}

.add_credit_note.form-table .list-table--amount {
  min-width: 8% !important;
}
.add_credit_note tbody tr {
  padding: 5px;
}
.add_credit_note .with-close--btn .form-field {
  padding: 0 0 0 30px;
}

.red_color {
  color: red;
}
.add_credit_note .with-close--btn input {
  border: 1px solid #dfe1e5;
  border-radius: 4px;
}
.add_credit_note tbody tr td:last-child {
  /* padding-top: 5px !important; */
}
.add_credit_note tbody tr:not(:first-child) td:last-child {
  padding-right: 5px !important;
}
.add_credit_note .list-table--item--col span {
  max-width: 100%;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
}
.last_input input {
  height: 3.8rem !important;
}
.w-97 {
  width: 97% !important;
}

.pd-left {
  padding-left: 3% !important;
}
.add_credit_note td:nth-child(5) .form-input.small {
  border-color: #878787 !important;
}

.title_check {
  padding-left: 1rem;
}

.directly_sold_pointer {
  cursor: pointer;
}
.add_credit_note .discount_switch .form-field div input {
  padding-right: 0;
}
.add_credit_note tr td:nth-child(3),
.add_credit_note tr td:nth-child(4) {
  flex-direction: column;
  align-items: flex-start !important;
}
.horizontal-items-table.add_credit_note.list-table.form-table th:nth-child(5) {
  width: 11% !important;
  padding-left: 4px
}
.horizontal-items-table.add_credit_note.list-table.form-table th:nth-child(7) {
  width: 13% !important;
  text-align: left;
}
.add_creditnote_modal_Width .__dragDrop .__drag-righ,.add_creditnote_modal_Width .__dragDrop {width: 100%;}
.add_creditnote_modal_Width .__dragDrop .__drag-righ{
  min-width:unset;
}
html body .cn-totals {
  padding-right: 0;
}
.cn-totals .semi-bold{
  width: 40%;
  flex: 0 0 40%;
}
.cn-totals .total-price--value{
  width: 60%;
  flex: 0 0 60%;
}

/* @media(max-width:1199px){
  .add_creditnote_modal_Width .__dragDrop .__drag-righ, .add_creditnote_modal_Width .__dragDrop{
    padding: 0rem 1rem 0rem 0.2rem;
  }
} */
@media(max-width:1139px){
  .credit-note-set .single-calander-main {right: -1px;}
}
@media(max-width:1024px){
  .cn-totals .flex-25{
    width: 40%;
    flex: 0 0 40%;
  }
  .cn-totals  .total-price--value{
    width: 60%;
    flex: 0 0 60%;
  }
}
@media (max-width: 991px) {
  .add_creditnote_modal_Width .total-price--value {
    font-size: 2rem;
    margin-right: 0;
  }
}
@media(max-width:768px){
  .uiux-invoice-form-main .__dragDrop .__drag-righ{
    max-width: 212px;
  }
}
