.form-field {
  position: relative;
  padding: 0.8rem 0;
}

.form-table .form-field {
  min-width: 12rem;
  min-height: 3.2rem;
}

.form-table--container {
  margin-right: 2.4rem;
}

.form-field.inline-field {
  display: inline-flex;
  flex-direction: column;
  margin-right: 3rem;
  vertical-align: top;
}

.smallWidthClass {
  width: 42% !important;
}

.smallWidthClass .form-field:nth-child(3) {
  margin-right: 0;
}

.Shipment_modals_fields {
  width: 80rem !important;
}

.Shipment_modals_fields .form-field:nth-child(3),
.Shipment_modals_fields .form-field:nth-child(6) {
  margin-right: 0;
}

.Shipment_modals_fields .text_area_postion .textarea {
  width: 488px;
}

.Shipment_modals_fields .action-buttons {
  margin-right: -0.5rem;
}

.add_address_width {
  width: 81rem !important;
}

.add_address_width .form-field:nth-child(3),
.add_address_width .form-field:nth-child(6) {
  margin-right: 0;
}

.uiux-so-address_popup.add_address_width .form-field:nth-child(3),
.uiux-so-address_popup.add_address_width .form-field:nth-child(6) {
  margin-right: 3rem;
}

.add_address_width .text_area_postion .textarea {
  width: 488px;
}

.add_address_width .action-buttons {
  margin-right: -0.5rem !important;
}

.d_inline_flex {
  display: inline-flex;
  flex-direction: column;
  margin-right: 3rem;
  vertical-align: top;
}

.form-input-wrapper {
  position: relative;
}

.form-input {
  display: block;
  position: relative;
  height: 40px;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.searchable-dropdown {
  border: 1px solid #dfe1e5 !important;
  /* width: 225px !important; */
  height: 40px;
}

.side-data--right .searchable-dropdown {
  width: 70px !important;
}

.searchable-dropdown__control {
  border: 0 !important;
  border-color: transparent;
  height: 100% !important;
}

.page-count-header .searchable-dropdown {
  width: auto !important;
  border: 0 !important;
  height: 30px !important;
}

.page-count-header .searchable-dropdown__control {
  /* border: 1px solid var(--text-field-border) !important; */
  /* border-color: var(--text-field-border); */
  height: 100% !important;
  border: 0 !important;
  font-weight: 500;
}

.searchable-dropdown .css-yk16xz-control:hover {
  border: 1px solid #878787;
}

.form-input ::placeholder {
  color: #777676;
}

.form-input:focus ::placeholder {
  color: #000000;
}

.form-input.xs {
  width: 7.2rem;
}

.form-input.small {
  width: 10.4rem;
}

.form-input.medium {
  width: 17.6rem;
}

.form-input.large {
  width: 229px;
}

.form-input.full {
  width: 100%;
}

.form-table .form-input {
  width: 100%;
  border-color: transparent;
  border-radius: 0;
}

.form-table .form-input.xs {
  min-width: 7.2rem;
  max-width: 7.2rem;
}

.form-table .form-input.small {
  min-width: 10.4rem;
  max-width: 16rem;
}

.form-input:hover,
.form-input:focus,
.form-input:focus-within {
  border-color: #878787;
}

.form-input.small:hover,
.form-input.small:focus,
.form-input.small:focus-within {
  border-color: #878787 !important;
}

.form-label {
  color: var(--text-primary);
  margin-bottom: 0.8rem;
  text-transform: capitalize;
}

.form-field.radio-field .radio-container {
  width: unset;
  display: flex;
  align-items: center;
  margin: 0;
  height: 3.2rem;
}

.form-field.radio-field .radio-container .radio-btn {
  margin: 0 2rem;
}

.form-input:focus {
  outline: 0;
}

.form-input.number {
  text-align: right;
}

.textarea {
  resize: none;
}

.form-input.textarea {
  height: 10rem;
  max-width: 100%;
  scrollbar-width: thin;
}

.form-input.textarea > .input-feedback {
  position: absolute;
  bottom: 5px !important;
}

.text_area_postion .textarea {
  margin-bottom: 20px;
}

.text_area_postion .input-feedback {
  position: absolute;
  bottom: 5px !important;
}

.form-input.required {
  border: 1px solid var(--red-500);
}

.form-field.form-input--grid-reset {
  display: inline-block;
  width: 100%;
}

.form-field.form-input--grid-reset.error .input-feedback {
  margin-top: 0;
}

.align-baseline {
  align-items: baseline;
}

.form-field.error .form-input {
  border: 1px solid var(--red-500) !important;
}

.form-field.error .select__control {
  border: 1px solid var(--red-500);
}

.form-field.error .searchable-dropdown .searchable-dropdown__control {
  border: 1px solid var(--red-500) !important;
}

.form-field.error .input-feedback {
  position: absolute;
  font-size: 1.1rem;
  color: var(--red-500);
  bottom: -0.8rem;
  white-space: nowrap;
}

.password-toggle-view {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  top: 50%;
  transform: translateY(-10px);
}

.form-field--bottom-text {
  font-size: 1rem;
  color: var(--grey-text);
  margin-top: -2rem;
  display: inline-block;
  word-break: break-word;
}

button.form-field--side-btn {
  position: absolute;
  left: 64%;
  bottom: 0.8rem;
  padding: 0.8rem;
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 1.2rem;
  top: 100%;
  z-index: 99;
  background-color: transparent;
}

button.form-field--side-btn:hover {
  background-color: transparent !important;
}

button.form-field--side-btn:focus {
  font-weight: 600;
}
.asyncPaginateParent > div {
  border-radius: 4px !important;
  border-color: #dfe1e5 !important;
  min-height: 40px;
}

@media (max-width: 1200px) {
  .ims-modal-container .add_address_width .form-field:nth-child(3),
  .ims-modal-container .add_address_width .form-field:nth-child(6) {
    margin-right: 3rem;
  }
}
