.float-left {
  float: left;
}

.w-100 {
  width: 100%;
}

.text-center {
  text-align: center;
}

.position-relative {
  position: relative;
}

.mb-30 {
  margin-bottom: 30px;
}

.py_3 {
  padding: 20px 0px;
}

/* Radio buttons  start*/

.slect_duration {
  margin: auto;
  width: 380px;
}

.slect_duration .background {
  background: #dfe1e5;
  float: left;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slect_duration .background p {
  float: left;
  margin-bottom: 0;
  font-size: 16px;
}

.slect_duration .background .radio_botton {
  /* padding: 0 20px; */
  float: left;
}

.slect_duration .background .radio_botton input[type='radio'] {
  height: 20px;
  width: 20px;
  margin: 0 5px;
}

.slect_duration .background .radio_botton label {
  font-size: 16px;
  margin: 0 5px;
  position: relative;
  top: -3px;
}

/* Radioa buttons end */

/* .p-2{padding: 0 10px;} */

.subscription-title {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

.chooseplan {
  background: none repeat scroll 0 0 #f3f3f3;
  border-bottom: 1px solid #d3d3d3;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  /* padding: 10px 0; */
}

.chooseplan span {
  padding: 10px 15px;
  border-right: 1px solid #d3d3d3;
  float: left;
  color: #999;
}

.chooseplan p {
  padding-left: 10px;
  margin: 0;
  float: left;
  line-height: 41px;
  color: #999;
}

.chooseplan.selected p {
  color: #000;
}

.chooseplan.selected span {
  color: #000;
}

.button_groups {
  border: 1px solid #33a348;
  width: 150px;
  margin: auto;
  display: flex;
  flex-direction: row;
  padding: 1px;
  border-radius: 20px;
}

.button_groups button {
  height: 40px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_groups button.active {
  background-color: #33a348;
  color: #fff;
  border-radius: 20px;
}

.button_groups span {
  position: absolute;
  top: 25%;
  left: 22%;
  color: #33a348;
  width: 100%;
}

.packages {
  padding: 0 0;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.border_custom {
  float: left;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 5px solid transparent;
}

.active_plans_table {
  border: 5px solid #d5f1d9 !important;
}

.active_plans_table .packages_plan_inner {
  border: 1px solid transparent !important;
}

.packages_plan_inner {
  /* -webkit-box-shadow: 0px 0px 17px 0px rgba(204,204,204,1);
    -moz-box-shadow: 0px 0px 17px 0px rgba(204,204,204,1);
    box-shadow: 0px 0px 17px 0px rgba(204,204,204,1);  */
  border: 1px solid;
}

.features {
  padding: 0 10px !important;
  position: absolute;
  bottom: 0;
  left: 0;
}

.packages_plan {
  float: left;
  width: 30%;
  padding: 0 5px;
}

.faetures_heading {
  border-bottom: 1px solid #e3e3e3;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
}

/* .features ul{padding-bottom: 30px;} */

.features ul li {
  border-bottom: 1px solid #efefef;
  color: #000;
  padding: 5px 0;
}

.features ul li:last-child {
  border-bottom: 0;
}

.custom_height {
  /* height: 460px; */
}

.packages_plan_inner {
  padding: 10px;
  border-radius: 5px;
}

.packages_plan_inner .packages_name {
  font-size: 20px;
  font-weight: 900;
  color: #33a348;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.packages_plan_inner .price {
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin-bottom: 40px;
  position: relative;
}

.packages_plan_inner .price span {
  position: absolute;
  top: 105%;
  font-size: 14px;
  color: #999;
  left: 0;
  float: left;
  width: 100%;
  font-weight: 400;
}

.packages_plan_inner .upgrade button {
  background-color: #33a348;
  color: #fff;
  margin-bottom: 10px;
  padding: 5px 10px;
  /* text-transform: uppercase; */
  float: left;
  width: 100%;
}

.packages_plan_inner .upgrade button .button_plan_image {
  float: left;
  width: 25%;
}

.packages_plan_inner .upgrade button .button_plan_desc {
  float: left;
  width: 75%;
  padding-left: 20px;
  position: relative;
  top: 3px;
}

/* .greenbtn {
  box-shadow: 0px 20px 10px -13px #cdcdcd;
} */

/* .packages_plan_inner ul{padding: 20px 0;} */

.packages_plan_inner ul li {
  border-bottom: 1px solid #efefef;
  color: #000;
  padding: 10px 0;
  box-shadow: 0px 15px 10px -13px #cdcdcd;
}

.mb_10 {
  margin-bottom: 10px !important;
}

.packages_plan_inner ul li:last-child {
  border: 0;
  box-shadow: none !important;
}

.margin-payment-review {
  margin: 2% 5%;
}

.info_plan {
  font-size: 12px;
  /*text-transform: uppercase;*/
  position: relative;
  top: -6px;
}

.subscribe {
  font-size: 18px;
}

.margin-payment-review > div {
  line-height: 3rem;
  display: flex;
  justify-content: space-between;
}

.margin-payment-review > hr {
  margin: 2%;
}

.ml-50 {
  margin-left: 50%;
}

.button-package-review {
  float: right;
  text-align-last: right;
}

.package-plans-actions {
  display: flex;
  justify-content: flex-end;
}

.ml-15 {
  margin-left: 15px;
}

.font-small {
  font-size: 95% !important;
}

.note-payment {
  justify-content: flex-end;
  margin-bottom: 10px;
}

.redbtn {
  border-radius: 3px;
  background-color: #0062a3 !important;
}

.greenbtn {
  border-radius: 3px;
}

.plan_svg svg {
  position: relative;
  top: 2px;
}

.purcahser_details {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.purcahser_details .image {
  float: left;
  width: 100px;
  margin: auto;
  height: 100px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid var(--background);
  border-radius: 50%;
  overflow: hidden;
}

.purcahser_details .image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
  padding: 0 15px;
}

.purcahser_details .desc {
  float: left;
  width: 90%;
  padding: 25px 5px;
}

.purcahser_details .desc .name {
  float: left;
  width: 100%;
  font-weight: bold;
}

.purcahser_details .desc .email {
  float: left;
  width: 100%;
}

.package_plan_pricing {
  float: left;
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}

.package_plan_pricing .left_sec {
  float: left;
  width: 30%;
  border-right: 5px solid #29823a;
  height: 100%;
  padding-left: 10%;
}

.package_plan_pricing .right_sec {
  float: left;
  width: 70%;
  padding: 0px 20px;
  position: relative;
  top: 27%;
}

.package_plan_details p {
  font-weight: lighter;
  font-size: 36px;
  margin-bottom: 20px;
}

.pricing_total {
  margin-bottom: 15px;
}

.pricing_total .font_weight_medium {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}

.fs_16 {
  font-size: 14px;
  color: #000;
}

.right_sec .pricing_total {
  float: left;
  width: 33.33%;
  margin-bottom: 40px;
}

.text_dark {
  color: #000;
}

.note {
  float: left;
  width: 100%;
  background-color: #f3f3f5;
  padding: 10px;
  margin-bottom: 20px;
}

.note .about_sub {
  float: left;
  width: 100%;
  margin-bottom: 10px;
  font-weight: medium;
}

.note input[type='checkbox'] {
  float: left;
  margin: 0 0 0 0 !important;
  height: 17px;
  width: 17px;
}

.note p {
  margin-bottom: 0;
  font-size: 14px;
}

.note p a,
.note label a {
  color: #306ab4;
  text-decoration: underline;
}

/* .support{position: fixed;right: 10px;bottom: 0;width: 300px;background-color: #33a348;} */

.ft_16 {
  font-size: 14px !important;
  margin-bottom: 0 !important;
  font-weight: 400 !important;
}

.ft_14 {
  font-size: 14px;
  font-weight: lighter;
}

.pd_10 {
  padding: 10px;
}

.cross_icon {
  position: absolute;
  right: 50px;
  top: 20px;
}

/* payment methods popups */

.stripe-imgbox {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 13px;
  /* box-shadow: 0 5px 18px 2px rgb(168 175 165 / 31%); */
  padding: 10px;
  margin-bottom: 50px;
  margin: 30px auto;
}

.stripe-imgbox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup-stripe-main {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.popup-stripe-right button,
.popup-stripe-left button {
  float: left;
  text-align: center;
  display: block;
  /* max-width: 130px !important; */
  float: none;
  margin: 0 auto;
}

.popup-stripe-right,
.popup-stripe-left {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 50%;
}

.popup-stripe-main button.action-buttons.next-btn {
  margin-right: auto !important;
}

.popup-stripe-left {
  border-right: 1px solid var(--border-light);
}

.stripe-imgbox:hover img {
  /* transform: scale(1.2); */
}

.stripe-imgbox img {
  /* transition: .9s ease-in;
  transform: scale(1.0); */
}

button.action-buttons.next-btn.btn-paypal {
  background-color: #ffc439;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.action-buttons.next-btn.btn-paypal svg {
  width: 70px;
  height: 100%;
}

button.action-buttons.next-btn.btn-stripe,
button.action-buttons.next-btn.btn-paypal {
  min-width: 200px;
  min-height: 45px;
  border-radius: 5px;
}

button.action-buttons.next-btn.btn-stripe {
  background: #635bff;
}

button.action-buttons.next-btn.btn-stripe:hover {
  background: #7a73ff;
  color: #fff;
}

button.action-buttons.next-btn.btn-stripe span {
  font-weight: 900;
  letter-spacing: 0.4px;
}

/* updated plan table  */

.btn-coupon {
  min-width: 150px;
  border: 1px solid #000;
  min-height: 40px;
  transition: 0.2s ease;
  border-radius: 3px !important;
}

.btn-coupon:hover {
  background-color: #33a348;
  color: #fff;
  border: 1px solid #33a348;
}

.plan-main {
  text-align: center;
  padding-bottom: 29px;
}

p.plan-pay {
  font-size: 20px;
  color: #dc2a2a;
}

p.plan-discount {
  font-size: 16px;
  color: #000;
  margin-bottom: 25px;
}

.packages-new-blocks .packages_plan {
  width: 32.2%;
  position: relative;
}

.packages-new-blocks .packages_plan_inner .upgrade button .button_plan_desc {
  width: 100%;
  text-align: center;
  padding: 0;
}

.packages-new-blocks .subscribe {
  font-size: 16px;
  letter-spacing: 0.21rem;
  line-height: 21px;
  margin-bottom: 8px;
}

.packages-new-blocks .info_plan {
  color: #fff;
}

.packages-new-blocks .packages_name {
  color: #222629;
  text-transform: capitalize;
  margin-bottom: 3px;
  padding-top: 20px;
}

.packages-new-blocks .price {
  font-size: 45px;
  color: #306ab4;
  font-weight: 300;
}

.packages-new-blocks .price span {
  letter-spacing: 0.3rem;
}

.packages-new-blocks {
  position: relative;
}

.packages-new-blocks .price span:before {
  position: absolute;
  content: '';
  /* border: 1px solid #DFE1E5; */
  width: 134px;
  height: 1px;
  top: 30px;
  left: 84px;
  background-color: #dfe1e5;
}

.packages-new-blocks .upgrade {
  padding-top: 9px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plans-desc {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #000;
  font-weight: bold;
  padding-top: 14px;
  position: relative;
}

html body .plan-desc-list li {
  border-bottom: 0;
  box-shadow: none;
  background-image: url(https://i.ibb.co/qkVSvFF/tick-icon.png);
  background-repeat: no-repeat;
  padding-left: 25%;
  text-align: left;
  background-position: 44px 12px;
  font-size: 14px;
}

.plans-desc:after {
  position: absolute;
  content: '';
  /* border: 1px solid #DFE1E5; */
  width: 300px;
  left: 4px;
  height: 1px !important;
  top: 45px;
  background: #dfe1e5;
}

.plan-desc-list {
  padding-top: 14px;
}

.packages-new-blocks .packages_plan .border_custom {
  padding: 0;
  /* border-radius: 5px; */
  border: 0;
}

.packages-new-blocks .packages_plan_inner {
  border: 1px solid #dfe1e5;
}

.packages-new-blocks .upgrade button {
  transition: 0.3s ease;
  background-color: #0062a3 !important;
  max-width: 300px;
  margin: 0 auto;
}

.packages-new-blocks .packages_plan:hover {
  cursor: pointer;
}

.packages-new-blocks .packages_plan:hover .info_plan {
  color: #ffed9d;
}

.packages-new-blocks .packages_plan:hover .price {
  color: #3bb54a !important;
}

.packages-new-blocks .packages_plan:hover .plan-line-through p {
  color: #000000;
}

.packages-new-blocks .packages_plan:hover .upgrade button {
  background: #000 !important;
}

.coupon-success {
  color: #33a348;
  font-size: 16px;
}

.coupon-active-block:not(:empty) {
  float: left;
  width: 100%;
  text-align: center;
  padding: 10px 0 20px 10px;
  font-size: 16px;
  color: #202020;
}

.plans-yearly-set div.upgrade button,
html body .coupon-active-block:not(:empty) + div.upgrade button {
  background-color: #fff7d5 !important;
  padding: 3px 0;
}

.plans-yearly-set div.upgrade .subscribe,
html body .coupon-active-block:not(:empty) + div.upgrade button .subscribe {
  color: #dc2a2a;
}

.plans-yearly-set div.upgrade .info_plan,
html body .coupon-active-block:not(:empty) + div.upgrade button .info_plan {
  color: #000;
  font-weight: 500;
  top: -10px;
}

.packages-new-blocks
  .packages_plan:hover
  .coupon-active-block:not(:empty)
  + div.upgrade
  .subscribe {
  color: #fff;
}

.packages-new-blocks
  .packages_plan:hover
  .coupon-active-block:not(:empty)
  + div.upgrade
  .info_plan {
  color: #ffed9d;
}

.plans-yearly-set .plan-save-set,
html body .coupon-active-block:not(:empty) + div.upgrade button .plan-save-set {
  position: absolute;
  right: -1px;
  background: #dc2a2a;
  font-size: 13px;
  top: -11px;
  min-width: 79px;
  padding: 4px 8px;
  border-radius: 4px;
  text-transform: capitalize;
}

.coupon-active-amount {
  float: left;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 8px 0 5px 0;
}

.plan-line-through {
  text-decoration: line-through;
}

.plan-line-through p {
  font-size: 20px;
  color: #707070;
  float: left;
  width: 100%;
  text-align: center;
  text-decoration: line-through;
}

.packages-new-blocks .packages_plan.package-basic .price {
  color: #33a348;
  float: left;
}

html body .packages_plan .upgrade button.greenbtn.current-plan-block {
  background: #33a348 !important;
}

html body div.subscribe.current-plan {
  color: #ffed9d !important;
}

.current-plan-block span.info_plan {
  color: #fff !important;
}

.hover-bar {
  position: relative;
}

.hover-bar:before {
  position: absolute;
  content: '';
  background: #f3f3f3;
  height: 20px;
  left: 0.06rem;
  top: 0.04rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  opacity: 0;
  width: 0;
  right: 0;
  /* animation: ; */
}

.packages-new-blocks .packages_plan:hover .hover-bar:before {
  width: 99.6%;
  /* transition: width .3s ease-in;
  opacity: 1; */
  animation: packageAnim 0.4s ease-in-out 0s 1 normal forwards;
}

.link-monthly {
  text-align: center;
  text-decoration: underline;
  display: block;
  color: var(--blue-500);
  cursor: pointer;
}

.link-monthly:hover {
  text-decoration: underline;
  color: var(--blue-500);
  cursor: pointer;
}

.packages_plan.current_package {
  pointer-events: none;
}

button.btn.btn-coupon:focus {
  outline: 0;
}

@keyframes packageAnim {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .plans-desc:after {
    width: 274px;
  }
}

@media (max-width: 890px) {
  .packages-new-blocks .packages_plan {
    width: 44%;
  }

  .packages-new-blocks {
    flex-wrap: wrap;
  }

  .packages-new-blocks .packages_plan:nth-child(3) {
    padding-top: 3%;
  }

  html body .plan-desc-list li {
    padding-left: 23%;
  }

  .packages_plan_inner ul li:last-child div p {
    margin-bottom: 0px;
  }

  .packages_plan_inner ul li:last-child div span {
    font-size: 11px;
  }

  .plans-desc:after {
    left: 29px;
  }
}

@media (max-width: 768px) {
  .packages-new-blocks .packages_plan {
    width: 50%;
  }

  .plans-desc:after {
    left: 22px;
  }

  .organization-table {
    float: left;
    width: 100%;
  }

  .organization-table .package-item-info .with-actions {
    min-width: 560px;
  }

  .package-item-info.package_details_table {
    /* max-width: 560px; */
    overflow-x: auto;
    display: block;
  }

  .package-item-info.package_details_table.po-details-main,
  .bills-table-main.package-item-info.package_details_table {
    max-width: 100%;
  }

  .bill_set_main .table.details-table th:last-child,
  .bill_set_main .table.details-table td:last-child {
    padding-right: 0 !important;
  }
}
.bills-table-main .custom_paddding{
  padding: 15px 15px 9px;
}
