.dashboard_so_summary {
  display: flex;
  flex-direction: row;
}

.gray_background {
  background-color: #e3e3e3;
}

.no_margin {
  margin: 0;
}

.so_sum_data {
  padding: 10px;
  /* margin: 6px 0px; */
}

.fs_20 {
  font-size: 20px;
}

.fs_14 {
  font-size: 14px;
}

.mb__10 {
  margin-bottom: 10px;
}

.bold__ {
  font-weight: 700;
}

.so_summ_sr_cn p {
  margin-bottom: 0;
  margin-right: 10px;
}

.color_black {
  color: #000;
}

.text__underline {
  text-decoration: underline;
}

.so_summ_sr_cn div span {
  margin-top: 2px;
}

.UIUX-span-break,
.so_summ_sr_cn div span {
  word-break: break-word;
  display: flex;
}

.so_summ_sr_cn {
  /* margin: 25px 50px; */
}

/* .dashboard_so_summary .recharts-wrapper svg{
  height: 328px !important;
} */

.so-summary-custom-tooltip {
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 10px;
  float: left;
  width: 130px;
}

.so-summary-intro {
  border-top: 1px solid #f5f5f5;
  margin: 0;
  float: left;
  width: 100%;
}

.so-summary-label {
  color: #000;
  float: left;
  width: 100%;
  text-align: left;
  position: relative;
  left: -5px;
  text-transform: capitalize;
}

.so-summary-desc {
  margin: 0;
  color: rgb(3, 62, 146);
  float: left;
  width: 100%;
  text-align: left;
}

.box_10 .so-summary-custom-tooltip {
  min-width: 150px;
  max-width: 220px;
  width: auto !important;
  line-height: unset;
  -webkit-box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 0px 5px 0px rgba(0, 0, 0, 0.15);
}

.box_10 .so-summary-custom-tooltip span {
  width: 100%;
  float: left;
  font-size: 30px;
}

.box_10 .so-summary-custom-tooltip .so-summary-label {
  font-size: 12px;
  color: #a9a9a9;
  left: 0 !important;
  word-break: break-word;
}

.box_10 .so-summary-custom-tooltip .so-summary-label.first_parent {
  margin-bottom: 5px;
}

.box_10 .so-summary-custom-tooltip .so-summary-label span.first {
  color: #50a349;
}

.box_10 .so-summary-custom-tooltip .so-summary-label span {
  color: #d53232;
}

.box_10 .so-summary-custom-tooltip .so-summary-intro {
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

.no_sales_order_graph {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
@media (max-width: 1466px) {
  .UIUX-span-break span:nth-child(2) {
    flex: 0 0 95%;
  }

  .UIUX-span-break span:nth-child(1) {
    flex: 0 0 5%;
    padding-right: 9px;
  }
}
@media (max-width: 1366px) {
  .puprchase_data .mt_10px {
    margin-top: 8px;
  }
}
@media (max-width: 1024px) {
  .sales-summary-right h3.bold__.no_margin.fs_20 {
    font-size: 16px;
  }

  .sales-summary-right h4.mt_0.mb__10.fs_20 {
    font-size: 18px;
  }

  .sales-summary-right .so_summ_sr_cn {
    padding-left: 3rem !important;
  }
}
