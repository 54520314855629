.tax-list {
  margin: 3rem 0;
}

.delivery_modal_title {
  display: flex;
  justify-content: space-between;
  width: 23.6%;
  /* align-items: center; */
}

.edit_delivery_modal_title {
  display: flex;
  justify-content: space-between;
  width: 18.2%;
  /* align-items: center; */
}

.add_new_deleivery svg {
  height: 17px;
  width: 17px;
}

.add_new_deleivery .form-field.inline-field {
  margin-bottom: 20px !important;
}

.add_new_deleivery .action-buttons {
  margin: 0 !important;
  padding: 1.5rem 0 !important;
  border-top: 1px solid var(--border-light);
  float: left !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.deliverymethod_table_icon {
  top: 5px !important;
}

@media(max-width:1260px) {
  .add_new_deleivery .edit_delivery_modal_title, .add_new_deleivery .delivery_modal_title {
    display: flex;
    align-items: center;
    width: auto !important;
    justify-content: flex-start;
  }
  .add_new_deleivery .edit_delivery_modal_title span, .add_new_deleivery .delivery_modal_title span {
    margin-right: 10px;
  }

  /* .add_new_deleivery .delivery_modal_title {
    width: 28% !important;
  }

  .add_new_deleivery .edit_delivery_modal_title {
    width: 22%;
  } */

  .edit-delivery-popup .form-input.large, .add_new_deleivery .form-input.large {
    width: 100%;
  }
}

@media(max-width:768px) {
  .add_new_deleivery .edit_delivery_modal_title {
    width: 25%;
  }
}