.page-one {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
}

.page-one-title {
  margin-left: 15%;
}

.page-one-img {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 8rem;
}
.page-one-img img {
  width: 100%;
  position: absolute;
  left: 15%;
}

@media only screen and (max-width: 1200px) {
  .page-one {
    display: flex;
    flex-direction: column;
  }

  .page-one-title {
    margin-left: 0;
    align-items: center;
    padding-top: 5rem;
  }

  .page-one-img {
    width: 65vw;
    padding-top: 0;
  }
  .page-one-img img {
    position: relative;
    left: 0;
  }
}