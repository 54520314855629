html body .unsync-table {
  position: unset;
}
.unsync_ordersTable table {
  z-index: 0;
}
.so_cross_icon {
  top: 15px;
  z-index: 999;
}
.unsync_ordersTable td {
  padding-left: 15px;
}

.unsync_ordersTable td:first-child .list-table--link,
.unsync_ordersTable th:first-child {
  padding-left: 10px !important;
}
.unsync_ordersTable tbody tr td:nth-child(6) {
  text-align: center;
}
html body .unsync_ordersTable thead tr th:nth-child(5) {
  padding-left: 3px !important;
}
html body .unsync_ordersTable .lopi th:nth-child(6) {
  text-align: center;
  padding-left: 1% !important;
}
.unsync-order-popup table.lopi.list-table {
  box-shadow: none;
  position: unset;
}
/* @media(max-width:1138px){
  .unsync_ordersTable th:nth-child(1) {
    width: 15%;
}

.unsync_ordersTable th:nth-child(2) {
    width: 28% !important;
}

.unsync_ordersTable th:nth-child(4) {
    width: 20%;
}

html body .unsync_ordersTable.sales_order_table_listing  thead tr th:nth-child(5) {
    width: 14% !important;
}                                                                         
}
@media (max-width:768px){
  .unsync_ordersTable th:nth-child(3) {
    width: 14%;
}
.unsync_ordersTable th:nth-child(2) {
  width: 25% !important;
}
html body .unsync_ordersTable .lopi th:nth-child(6){
  padding-left: 0% !important;
}
} */
.sync-logo .store-name {
  font-size: 14px;
  display: inline-block;
  /* padding-left: 5px; */
  font-weight: bold;
}
.unsync_ordersTable thead th:nth-child(1) {
  width: 15%;
}
.unsync_ordersTable thead th:nth-child(5),
.unsync_ordersTable tbody tr td:nth-child(5) {
  text-align: center;
  width: 8%;
}
.unsync_ordersTable thead th:nth-child(6) {
  width: 5%;
  text-align: center;
}
.unsync_ordersTable thead th:nth-child(4),
.unsync_ordersTable tbody tr td:nth-child(4) {
  width: 15%;
  text-align: right;
  padding-right: 25px !important;
}
.hover-undeline:hover {
  color: #0071bc;
  text-decoration: underline;
}
