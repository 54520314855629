.icon-selectAll {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.selectAll-space {
  margin-left: 10px;
}

.selectCheckbox {
  margin-left: 20px;
}

.module-name-pad-left {
  padding-left: 0.7% !important;
}

.group-header>input {
  margin-right: 2% !important;
}

#ViewSelectAll {
  margin-right: 4% !important;

}

.add_role_form {
  height: calc(100vh - 419px);
  overflow: auto;
  border: 1px solid #eee;
}

.add_role_form table thead {
  position: sticky;
  top: 0;
  background-color: #FFF;
  z-index: 99;
}

.add_role_form table thead tr {
  float: left;
  width: 100%;
  padding-left: 4rem;
}

.add_role_form table thead tr th {
  float: left;
  width: 17.5%;
  font-weight: bold;
  color: #000;
}

.add_role_form table thead tr th:first-child {
  width: 30%;
}

.bold_add_role {
  font-weight: bold;
  color: #000;
}

.add_role_form table tbody tr {
  float: left;
  width: 100%;
  padding-left: 4rem;
  border-top: 1px solid var(--border-light)
}

.add_role_form table tbody tr td {
  float: left;
  width: 17.5%;
  color: #000;
  border: 0 !important;
}

.add_role_form table tbody tr td:first-child {
  width: 30%;
}

.add_role_form .custom-checkbox>span {
  border: 1px solid #cbcbcb !important;
}

.custom-checkbox>span>svg {
  color: #000;
}

.action_buttons_add_role {
  margin-right: 2rem !important;
}

.add_role_form table tbody tr td.group-header {
  float: left;
  width: 100%;
}

.group-header-parent {
  background-color: #f8f8f8;
}

.add_role_form table tbody tr .group-header {
  background-color: transparent !important;
  color: #000;
  font-weight: bold;
}

.add_role_form table tbody tr .group-header>input {
  margin-right: 0.5% !important;
  float: left;
}

.add_role_form table tbody tr .group-header>svg, .add_role_form table tbody tr .group-header>img {
  float: left;
}

.new-role-table td:not(.group-header):first-child {
  padding-left: 2.8rem;
}

/* .add_role_form table tbody tr .group-header{
  display: unset !important;
  float: left;
  width: 100%;
  padding-left: 10rem;
} */

.svg-Down svg {
  margin-top: 3px;
}

.salesperson-role.usearccess-sec-main label.custom-checkbox,
.salesperson-role.usearccess-sec-main,
.salesperson-role.usearccess-sec-main span.salesperson-info {
  display: flex;
  align-items: center;
}

.salesperson-role.usearccess-sec-main span.salesperson-info svg {
  margin: 0 8px;
}