.fileUploader {
  padding: 0.8rem;
  border: 1px dashed var(--border-dark);
  border-radius: 4px;
  text-align: center;
  height: 10rem;
  width: 10rem;
  font-size: 1.2rem;
  background-color: var(--background);
  cursor: pointer;
}

.fileUploader:hover {
  border-color: var(--blue-500);
}

.fileUploader:focus-within {
  border: 1px solid var(--blue-500);
  outline: 0;
}

.fileUploader_circle {
  border: 0;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  font-size: 2rem;
  color: var(--icon-primary);
  display: flex;
  align-items: center;
  justify-content: center;
}