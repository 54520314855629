.pdf-header {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.pdf-button--new-tab {
  margin-right: 2rem;
  font-size: 1.4rem;
  background-color: var(--blue-500);
  color: var(--navigation-link-text-color);
  padding: .8rem 1.4rem;
  border-radius: 4px;
  transition: var(--transition-quick);
}
.pdf-button--new-tab:hover,
.pdf-button--new-tab:focus-within {
  background-color: var(--blue-500-hover);
  color: white;
  text-decoration: none;
}
@media(max-width:991px){
  .print-pdf-popup.ims-modal.large{
    min-width: 90%;
  }
}