.page-number-container,
.page-number-container > button,
.page-number {
  /* display: flex;
  align-items: center; */
  float: left;
  position: relative;
  top: 1px;
}

.page-number--num {
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.page-number--num.pointer:hover {
  background: var(--button-hover);
}

.page-number-arrow--icon {
  width: 2rem;
  height: 2rem;
}
.page-number-arrow--icon path {
  stroke: var(--blue-500);
  stroke-width: 2px;
  fill: none;
}

.page--active {
  color: var(--blue-500);
  position: relative;
}
.page--active:after {
  content: "";
  width: 50%;
  text-align: center;
  position: absolute;
  bottom: 1px;
  border-width: 0 0 1px;
  border-style: solid;
  left: 50%;
  transform: translateX(-50%);
}
.mr-3 {
  margin-right: 10px;
}
.ml_3 {
  margin-left: 10px;
}
.left_arrow {
  padding: 9px 0px 9px 5px !important;
}
.right_arrow {
  padding: 9px 5px 9px 0px !important;
}
.bg_white {
  background-color: #fff;
}
.show_total {
  float: left;
  color: #000;
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-left: 0px;
  font-weight: 500;
  padding-right: 10px;
}
.px_3 {
  padding: 0 5px;
}
.show_total{
  position: relative;
}
.totalcount3{
  position: absolute;
  right: 0;
  bottom: -39px;
  display: none;
  width: 140px;
  color: #FFF;
  background-color: #000;
  padding: 5px 0;
  text-align: center;
  transition: 0.3s;
  z-index: 9999999;
  font-size: 12px;
}
.show_total:hover .totalcount3{
  display: block;
}
