.employees-form .form-field {
  grid-template-columns: 8rem 1fr;
}

.employees-form .form-field-multiple.name {
  grid-template-columns: 8rem 10rem 1fr 1fr;
  width: 100%;
}
.employees-form .form-field-multiple.phone {
  grid-template-columns: 8rem 1fr 1fr;
  width: 100%;
}