.auth--page {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f0f2f5;
  flex-direction: column;
  padding: 2rem 0;
}

/* .auth--page img {
  width: 150px;
} */

.auth--page .auth--form .image-block {
  width: 9rem;
  height: 9rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 5px;
  border-radius: 50%;
  border: 1px solid #eeeeee;
  position: relative;
}

.auth--logo-circle {
  border-radius: 50%;
  background: var(--background);
  width: 10rem;
  height: 10rem;
  text-align: center;
  margin-left: 1rem;
}

.auth--page .auth--form .image-block-fit {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
  background-position: center center;
}

.auth--logo-fit {
  height: 10rem;
  width: 10rem;
  float: left;
  border-radius: 5rem;
  border: 1px solid var(--icon-primary);
  padding: 0 10px;
  margin-left: 10px;
  overflow: hidden;
}

.auth--logo {
  margin-bottom: 2rem;
}

.auth--terms {
  margin-top: 2rem;
  text-align: center;
  width: 40rem;
  color: #525d66;
  font-size: 1.2rem;
}

.auth--form-holder {
  width: 40rem;
  background: #fff;
  border: 1px solid #d3dee6;
  border-radius: 4px;
  padding: 4rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}

.auth--form-holder.md-width {
  width: 52rem;
}

.auth--message {
  text-align: center;
  margin-bottom: 2rem;
}

.auth--message h5 {
  margin-top: 1.5rem;
}

.auth--logout {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.auth--form .form-field.inline-field-mr-2 {
  margin-right: 1rem !important;
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
}

.button--full-width {
  margin-top: 2rem;
  width: 100%;
  background: var(--blue-500);
  color: #fff;
  padding: 0.7rem 2rem;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  border: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
}

.button--full-width:focus {
  background: #fff;
  box-shadow: var(--hover-box-shadow);
  color: #000;
}

.button--small-width {
  background: var(--blue-500);
  color: #fff;
  padding: 0.7rem 2rem;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  border: 0;
  margin-top: 1rem;
  margin-right: 1.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
}

.button--small-width:focus {
  background: #fff;
  box-shadow: var(--hover-box-shadow);
  color: #000;
}

.button--no-background {
  background: transparent;
  color: #333;
  outline: none;
  box-shadow: none;
  border: 0;
  margin: 1rem 1rem 0 0;
  padding: 0 0 0 0.3rem;
}

.button-gray-background {
  color: #333;
  background: #f0f2f5;
  border: 1px solid #d3dee6;
  padding: 0.7rem 2rem;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  margin-top: 1rem;
  margin-right: 1.5rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}

.reg_fiscal_year_field .searchable-dropdown__menu {
  z-index: 9999;
}

.reg_vase_currency_field .searchable-dropdown__menu {
  z-index: 9999;
}

.reg_timezone_field .searchable-dropdown__menu {
  z-index: 9999;
}

.reg_business_type_field .searchable-dropdown__menu {
  z-index: 9999;
}
.img--preview div > div {
  border-radius: 50%;
  background-size: cover;
}
.auth--form .button--image-delete {
  right: 16px;
  top: 14px;
}
@media (max-width: 1199px) {
  .auth--form .form-field.inline-field,
  .auth--form .form-field.inline-field input,
  .auth--form .form-field.inline-field-mr-2,
  .auth--form .form-field.inline-field.base_field,
  .auth--form input.large,
  .auth--form .searchable-dropdown.large,
  .first-login-form-inner .form-field.inline-field .form-input.medium,
  .first-login-form-inner .form-field.inline-field,
  .first-login-form-inner .form-field .searchable-dropdown.large,
  .first-login-form-inner .form-field .form-input.large,
  html body .first-auth-form .form-field.inline-field-mr-2,
  html body .first-auth-form .form-field.inline-field,
  html body .first-auth-form .form-input-wrapper input,
  .first-auth-form .form-field .searchable-dropdown.large {
    width: 100% !important;
    display: block;
  }
}
