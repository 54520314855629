.avatar {
  background-color: var(--background);
  min-height: 8rem;
  max-height: 8rem;
  min-width: 8rem;
  max-width: 8rem;
  border-radius: 50%;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--icon-primary);
  text-transform: uppercase;
}
.avatar:focus-within {
  border: 1px solid var(--blue-500);
  outline: 0;
}