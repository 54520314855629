.small_loader {
  -webkit-animation: spinSmallLoader 1s linear infinite;
          animation: spinSmallLoader 1s linear infinite;
  border: 3px solid #ddd;
  border-top: 3px solid #589dd8;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  
}

@-webkit-keyframes spinSmallLoader {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinSmallLoader {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

