.pur-sales-info {
  margin-top: 3rem;
  margin-left: 2rem;
}

.mt-2 {
  margin-top: 2rem;
}

.pur-sales-info .bold {
  font-size: 16px !important;
  text-transform: capitalize;
}

.item-purchase-sales-info {
  float: left;
  width: 100%;
}

.item-purchase-sales-info div {
  width: 20%;
  float: left;
}

.item-purchase-sales-info div:nth-child(even) {
  width: 80%;
  float: left;
}

.w-100 {
  width: 100% !important;
}

.item-purchase-sales-info div:nth-child(even) div {
  font-size: 14px;
  text-decoration: underline;
  color: var(--blue-500);
  line-height: 20px;
  cursor: pointer;
}
.item-purchase-sales-info div:nth-child(even) div:hover{
  ackground-color: transparent !important;
    color: #000;
    /* color: var(--blue-500-active); */
    /* transition: var(--transition-quick); */
}
