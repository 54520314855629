.dashboard-container {
  margin: 2px 0px 0 4px;
}

/* new design inital screen */

.intital_screen .left_section {
  float: left;
  width: 25%;
  background: #f8f8f8;
  height: calc(100vh - 99px);
  padding: 30px 20px;
}

.intital_screen .right_section {
  float: left;
  width: 75%;
  height: calc(100vh - 99px);
}

.intital_screen .left_section .onboarding-welcome--title {
  background-color: transparent !important;
  padding: 0 !important;
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.intital_screen .left_section .onboarding-welcome--title p {
  font-family: Roboto;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #000;
  float: left;
  width: 100%;
}

.our_goal {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.our_goal h2 {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  color: #949494;
}

.intital_screen .left_section .onboarding-welcome--body {
  padding: 0;
}

.intital_screen .left_section .listing_section {
  float: left;
  width: 100%;
}

.intital_screen .left_section .listing_section h2 {
  float: left;
  width: 100%;
  font-size: 16px;
  margin: 0 0 20px 0;
}

.intital_screen .left_section .listing_section ul {
  float: left;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.intital_screen .left_section .listing_section ul li {
  width: 231px;
  height: 45px;
  background: #fff;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
  border-radius: 20px;
  margin-bottom: 30px;
  transition: 0.3s;
}

.intital_screen .left_section .listing_section ul li:hover {
  background: #306ab4;
}

.intital_screen .left_section .listing_section ul li:hover span a {
  color: #fff;
}

.intital_screen .left_section .listing_section ul li:hover span svg path {
  fill: #fff;
}

.intital_screen .left_section .listing_section ul li.last {
  width: 303px;
}

.intital_screen .left_section .listing_section ul li span {
  float: left;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}

.intital_screen .left_section .listing_section ul li span a {
  float: left;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  color: #000;
}

.intital_screen .left_section .listing_section ul li span a svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.intital_screen .left_section .listing_section ul li span a svg path {
  fill: #000;
}

.intital_screen .left_section .listing_section ul li .onboarding-welcome--icon {
  float: left;
}

.intital_screen .right_section {
  padding: 30px 20px;
}

.intital_screen .right_section .information_section {
  float: left;
  width: 100%;
}

.intital_screen .right_section h1 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  color: #000;
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
}

.intital_screen .right_section p {
  float: left;
  width: 100%;
  max-width: 80%;
  font-family: Roboto;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #000;
}

.video_section {
  margin: 30px 0;
  float: left;
  width: 100%;
}

.video_section .video_section_inner {
  margin: auto;
  width: 60%;
}

.video_section .video_section_inner h3 {
  font-family: Roboto;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  margin-bottom: 20px;
  color: #000;
  float: left;
  width: 100%;
}

/* new design inital screen  */

.dashboard-container.onboarding {
  max-width: 110rem;
}

.dashboard_box .loader {
  height: 100%;
}

.onboarding-welcome--title {
  background-color: var(--border-light);
  padding: 2.4rem;
}

.onboarding-welcome--title h1 {
  margin: 0;
  margin-bottom: 0.8rem;
  font-size: 2rem;
  color: var(--blue-900);
}

.onboarding-welcome--title p {
  color: var(--blue-900);
  margin: 0;
}

.onboarding-welcome--body {
  padding: 1.6rem 2.4rem;
}

.onboarding-welcome--body h2 {
  font-size: 1.6rem;
  margin: 0;
}

.onboarding-welcome--body li {
  display: grid;
  align-items: center;
  grid-gap: 1.6rem;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  margin-top: 2.4rem;
}

.onboarding-welcome--icon {
  height: 4.8rem;
  width: 4.8rem;
  background-color: var(--blue-100);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onboarding-welcome--icon svg path {
  fill: var(--blue-900);
}

.onboarding-welcome--side-content-1 {
  /* background-image: url("./../../Assets/Img/OnboardingWelcome.png"); */
  background-size: contain;
  background-repeat: no-repeat;
}

.onboarding-welcome--side-content-1 p {
  width: 90%;
}

.onboarding-welcome--side-content-1 h2,
.onboarding-welcome--side-content-2 h2 {
  font-size: 1.6rem;
  margin: 0;
  margin-bottom: 0.8rem;
}

.onboarding-welcome--side-content-1 p {
  margin-bottom: 1.6rem;
}

.onboarding-welcome--side-content-1 .button--primary {
  width: fit-content;
}

.onboarding-welcome--side-content-2 .layout--box--body {
  display: grid;
  grid-template-columns: 20rem auto;
}

.onboarding-welcome--video-wrap img {
  width: 100%;
}

.onboarding-welcome--side-content-2--details {
  padding: 1.6rem;
}

.top-header.dashboard-header {
  background-color: #e3e3e3;
  /* padding: 1rem 2rem;     commented this for ticket SIQ-2440 wIDTH OF HEADER SHOULD BE SAME */
  padding: 0.8rem 2rem;
  box-shadow: none;
  /* margin-top: 1.6rem; */
  height: auto;
  margin-bottom: 4px;
}

.dashboard-header h3 {
  font-weight: bold;
  color: #000;
}

.dashboard-overview .overview-card {
  margin: 0;
}

.summary-title {
  font-size: 2rem;
}

.dashboard-reports {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45rem, 1fr));
  grid-gap: 2rem;
}

.summary-subtitle-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: #fff;
  border-radius: 4px;
}

.summary-subtitle {
  font-size: 1.4rem;
}

.summary-subtitle.bold {
  color: var(--text-primary);
}

.summary-timeline-dropdown a {
  color: var(--black);
  text-decoration: none;
  font-size: 1.2rem;
}

.summary-timeline-dropdown ul {
  left: auto;
  right: 0;
  border-radius: 4px;
  border: 1px solid #eeeeee;
}

.summary-timeline-dropdown ul li a {
  line-height: 2.4rem;
  outline: 0;
}

.dashboard--loader {
  min-height: 10rem;
}

.chart-dashborad {
  float: left;
  width: 100%;
}

.chart-dashborad .chart-dashborad-header {
  float: left;
  width: 20%;
}

.chart-dashborad .chart-dashborad-graph {
  float: left;
  width: 80% !important;
}

.dashboard_box_width_full {
  float: left;
  width: 100%;
  padding-right: 5px;
}

.dashboard_box a {
  color: #000;
}

.dashboard_box_width_full .data_section {
  height: 344px;
  padding: 20px 0px 0px 0px;
}

.dashboard_box_width_full .data_section .height_100 {
  height: 100%;
  float: left;
  width: 100%;
  border-top: 1px solid #e9e9e9;
}

.triple_dash_box {
  height: auto !important;
}

.triple_dash_box .left_section {
  float: left;
  width: 30%;
  height: 323px;
}

.triple_dash_box .left_section .so_sum_data {
  height: 33.33% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.triple_dash_box .left_section .so_sum_data:nth-child(2) {
  background-color: #fbfbf7;
}

.triple_dash_box .right_section {
  float: left;
  width: 70%;
  height: 323px;
}

.triple_dash_box .right_section .align_items_ {
  height: 100%;
  float: left;
  width: 100%;
}

.triple_dash_box .right_section .so_summ_sr_cn {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4rem;
}

.triple_dash_box .right_section .so_summ_sr_cn:first-child {
  border-bottom: 1px solid #e9e9e9;
}

.so_summ_sr_cn .material-icon {
  margin-left: 0 !important;
}
.alert_icon {
  display: flex;
  background-color: #3bb54a;
  border-radius: 20px;
  align-items: center;
  padding: 8px 15px 8px 5px;
  cursor: pointer;
}
.alert_icon:hover {
  background: #fff !important;
  box-shadow: var(--hover-box-shadow);
  color: #000;
  border: 0.5px solid;
}

.alert_icon span {
  font-size: 14px;
  color: white;
}
.alert_icon .material-icon {
  color: yellow !important;
  font-size: 18px;
  margin-right: 5px;
}
.alert_icon:hover span {
  color: #000;
}
.alert_icon:hover .material-icon {
  color: #000 !important;
}
/*for HD*/

@media screen and (min-width: 1800px) {
  .dashboard-reports {
    grid-template-columns: repeat(auto-fit, minmax(65rem, 1fr));
    /* grid-gap: 4rem; */
  }
}

/*for HD+*/

@media screen and (min-width: 2400px) {
  .dashboard-reports {
    grid-template-columns: repeat(auto-fit, minmax(100rem, 1fr));
    /* grid-gap: 4rem; */
  }
}

.dashboard-header svg {
  width: 2.1rem !important;
}

.fill-green {
  fill: green;
}

.dash_viewpdates {
  width: 1153px;
  max-height: 828px;
  min-height: 350px;
}

.dash_viewpdates .ims-modal--body {
  float: left;
  width: 100%;
  max-height: 770px;
  min-height: 350px;
  overflow: auto;
  scrollbar-width: thin;
}

.dash_viewpdates .menu-history {
  max-height: unset;
  height: 100%;
  scrollbar-width: thin;
}

.dash_viewpdates .activity_parent:last-child {
  margin-bottom: 1.6rem;
}

.dash_viewpdates .activity_new .left {
  height: auto;
}

.dash_viewpdates .activity_new .left {
  width: 15%;
}

.dash_viewpdates .activity_new .right {
  width: 85%;
}

.dash_viewpdates .activity_new .right {
  height: auto;
  padding-left: 10rem;
  padding-right: 2rem;
  word-break: break-word;
  text-align: justify;
  margin-bottom: 0 !important;
}

.dash_viewpdates .activity_new .left small {
  top: 0;
}

.dash_viewpdates .activity_new .right .desc {
  font-weight: bold;
}

.dash_viewpdates .activity_new .border_structure {
  float: left;
  width: 10%;
  position: absolute;
  /* height: 80px; */
  left: 0;
  top: 0;
  height: 100%;
}

.dash_viewpdates .activity_new .left .date {
  top: 0;
  margin-top: 8px;
}

.dash_viewpdates .activity_new .right .desc {
  top: 0;
  margin-top: 8px;
}

HEAD .box_6 .date-range-picker.date-picker-main {
  left: -17.9rem;
}

.sales-summary-right .left_section h3.bold__.no_margin.fs_20 {
  word-break: break-all;
}

@media (max-width: 1600px) {
  .box_8-main-set .sizeandweight {
    font-size: 15px;
  }

  .dashboard_box_header .date-picker--button {
    margin-left: 8px !important;
    font-size: 11px;
  }

  .box_1 .date-range-picker.date-picker-main,
  .box_2 .date-range-picker.date-picker-main,
  .box_3 .date-range-picker.date-picker-main,
  .box_4 .date-range-picker.date-picker-main,
  .box_5 .date-range-picker.date-picker-main,
  .box_6 .date-range-picker.date-picker-main,
  .box_8 .date-range-picker.date-picker-main {
    /* left: -21.8rem; */
  }
}

.dashboard_mobile-box .date-range-picker.date-picker-main {
  /* left: 0; */
}

@media (max-width: 1600px) {
  .dashboard_mobile-box .date-range-picker.date-picker-main {
    /* left: -22rem; */
  }

  .box_8-main-set .sizeandweight {
    font-size: 15px;
  }

  .dashboard_box_header .date-picker--button {
    margin-left: 8px !important;
    font-size: 11px;
  }
}

@media screen and (min-width: 21px) and (max-width: 1550px) {
  .intital_screen .left_section .listing_section ul li {
    width: 100%;
    margin-bottom: 15px;
  }

  .intital_screen .left_section .listing_section ul li.last {
    width: 100%;
  }

  .video_section_inner iframe {
    height: 240px;
    width: 100%;
  }
}

.dashboard_box .access-denied {
  margin-top: 0 !important;
}

.dashboard_box .access-denied img {
  width: 9rem !important;
  height: 9rem !important;
}

@media (max-width: 1600px) {
  html body .dashboard_top_customer {
    padding: 20px 20px 40px 45px;
  }
}

@media (max-width: 1440px) {
  .dashboard_mobile-box .dashboard_box_header p:first-child,
  .box-cus-main .date-picker--button {
    font-size: 12px;
    padding-left: 3px;
    border-left: 8px solid transparent;
  }

  .box-cus-main p {
    font-size: 12px;
  }

  .dashboard_mobile-box span.dashboard_box_header_dropdown {
    font-size: 12px;
  }

  .dashboard_mobile-box .custom-dropdown {
    font-size: 12px;
  }

  .dashboard_box:hover .dashboard_box_header p:first-child {
    border-left: 8px solid green;
  }

  .dashboard_mobile-box .custom-dropdown.button-text .button > svg {
    width: 16px;
  }

  .dashboard_mobile-box .custom-dropdown button.button {
    padding: 8px 1px 0 2px;
  }
}

@media screen and (min-width: 21px) and (max-width: 1400px) {
  .intital_screen .right_section p {
    max-width: 80%;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* text-align: justify; */
  }

  .box_1 .date-range-picker.date-picker-main,
  .box_2 .date-range-picker.date-picker-main,
  .box_3 .date-range-picker.date-picker-main,
  .box_6 .date-range-picker.date-picker-main {
    /* left: -24rem !important; */
  }

  .dashboard_mobile-box .dashboard_box_header p:first-child,
  .box-cus-main .date-picker--button {
    font-size: 13px;
    padding-left: 10px;
    border-left: 8px solid transparent;
  }

  .box-cus-main p {
    font-size: 12px;
  }

  .dashboard_mobile-box span.dashboard_box_header_dropdown {
    font-size: 12px;
  }

  .dashboard_mobile-box .custom-dropdown {
    font-size: 12px;
  }

  .dashboard_box:hover .dashboard_box_header p:first-child {
    border-left: 8px solid green;
  }

  .dashboard_mobile-box .custom-dropdown.button-text .button > svg {
    width: 16px;
  }

  .dashboard_mobile-box .custom-dropdown button.button {
    padding: 8px 1px 0 2px;
  }

  .box_5 .date-range-picker.date-picker-main,
  .box_4 .date-range-picker.date-picker-main {
    /* left: -22.8rem !important; */
  }
}

@media (max-width: 1366px) {
  .dashboard_box_cus-set .dash_info_div {
    height: 100%;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    align-items: center;
    right: 1rem;
  }

  /* .dashboard_box_cus-set .nav_tabs a {
    width: 145px !important;
    font-size: 11px;
} */
  .box_8-main.box_8-main-set .dashboard_top_customer {
    padding: 20px 18px 27px 40px;
  }

  .box-cus-main .data_section > div {
    padding-right: 10px;
  }

  .box-cus-main .dashboard_receivables_child:first-child:after {
    left: auto;
    right: -19px;
  }

  .dashboard_top_customer_mob {
    padding-left: 13px;
  }

  .dashboard_box_cus-set ul.nav_tabs {
    padding-left: 23px;
  }

  .dashboard_box_width_two span.dashboard_info_text {
    font-size: 11px;
  }

  .dashboard_box_header .date-picker--button {
    margin-left: 0px !important;
    margin-top: 1px;
    border: 1px solid var(--border-dark);
  }

  .dashboard_mobile-box .dashboard_box_header p:first-child,
  .box-cus-main .date-picker--button {
    font-size: 11px;
  }

  .dashboard_top_customer_mob span.w_130px {
    width: 100px;
  }

  .dashboard_customer-main img.dashboard_top_customer_img {
    max-width: 90px;
    max-height: 90px;
  }

  .dashboard_top_customer .float-left.w-30.image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1280px) {
  .dashboard_box_width.dashboard_mobile-box {
    width: 50%;
  }

  .dashboard_box_width_two.dashboard_box_cus-set {
    width: 100%;
  }

  .dashboard_box_width.dashboard-mob-inner {
    display: flex;
    width: 100%;
  }

  .dashboard-mob-inner .dashboard_box.box_8 {
    width: 42%;
    margin-right: 3px;
  }

  .dashboard-mob-inner .dashboard_box.box_9 {
    width: 58%;
  }

  .height_100.sales-order-main .dashboard_so_summary {
    width: 60% !important;
  }

  .height_100.sales-order-main .dashboard_so_summary + div {
    width: 40% !important;
  }

  .box_4 .date-range-picker.date-picker-main,
  .box_5 .date-range-picker.date-picker-main,
  .box_1 .date-range-picker.date-picker-main,
  .box_2 .date-range-picker.date-picker-main,
  .box_3 .date-range-picker.date-picker-main,
  .box_6 .date-range-picker.date-picker-main {
    /* left: -25rem !important; */
  }

  .dashboard_mobile-box .date-range-picker.date-picker-main {
    /* left: -24.5rem; */
  }

  .dashboard_mobile-box .box_5 .date-range-picker.date-picker-main {
    /* left: -25.3rem; */
  }
}

@media (max-width: 1199px) {
  .dash_viewpdates.recent-updated-popup-set {
    width: 1050px;
  }

  .dash_viewpdates.recent-updated-popup-set {
    width: 1100px;
    margin: auto;
  }

  .dash_viewpdates.recent-updated-popup-set {
    /* width: 980px; */
    width: 85%;
    min-height: auto;
    max-height: initial;
  }

  .dash_viewpdates.recent-updated-popup-set .ims-modal--body {
    overflow-y: auto;
    max-height: 400px;
  }

  .sales-summary-right .left_section h3.bold__.no_margin.fs_20 {
    font-size: 16px;
  }

  .dashboard_box_cus-set .dashboard_box_header_dropdown,
  .dashboard_box_cus-set .dashboard_box_header p,
  .dashboard-mob-inner .dashboard_box_header,
  .dashboard-mob-inner .custom-dropdown.button-text .button,
  html body .triple_dash_box.box_10 .button {
    font-size: 12px;
  }

  .dashboard_box_cus-set .align_items_ a span {
    word-break: break-all;
  }
}

@media (max-width: 1099px) {
  .dashboard_customer-main .dashboard_top_customer_img {
    max-width: 80px;
    max-height: 80px;
  }

  .dashboard_top_customer_mob h4.semi-bold.mb_3px {
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .box_8-main.box_8-main-set .dashboard_top_customer {
    padding: 20px 15px 15px 15px;
  }

  .box_8-main-set .sizeandweight {
    font-size: 13px;
  }

  .dash_viewpdates.recent-updated-popup-set .activity_new .right {
    padding-left: 6rem;
    padding-right: 2rem;
  }

  .dashboard-mob-inner .dashboard_box.box_8 {
    font-size: 12px;
  }

  .dashboard_box_cus-set span {
    font-size: 13px !important;
    margin-right: 8px;
  }

  .dashboard_top_customer_det.dashboard_top_customer_mob {
    padding-left: 10px !important;
  }

  .dashboard_top_customer .float-left.w-30.image {
    padding-left: 10px !important;
    margin-left: 15px;
  }

  .dashboard_box_cus-set .align_items_ a span {
    width: 40%;
  }

  .dashboard_top_customer_mob span.w_130px {
    width: 90px;
  }

  .dashboard_box_width.dashboard_mobile-box:nth-child(2n) {
    padding-right: 4px;
  }

  .dashboard_box_width.dashboard_mobile-box:nth-child(odd) {
    padding-left: 4px;
  }

  .dashboard_box_width_full.dashboard_box_cus-set,
  .dashboard_box_width.dashboard-mob-inner,
  .dashboard_box_width_two.dashboard_box_cus-set {
    padding-left: 5px;
  }

  /* 
  .intital_screen .left_section {
    width: 30%;
  }

  .intital_screen .right_section {
    width: 70%;
  } */

  .our_goal h2 {
    font-size: 20px;
    line-height: 25px;
  }

  .intital_screen .left_section,
  .intital_screen .right_section {
    width: 100%;
  }

  .intital_screen .right_section,
  .intital_screen .left_section {
    height: auto;
  }

  .intital_screen .left_section .onboarding-welcome--title {
    margin-bottom: 0;
  }

  .intital_screen .right_section .video_section {
    margin: 10px 0;
  }

  .video_section .video_section_inner {
    width: 75%;
  }
}

@media (max-width: 991px) {
  .orders-table-list td.check:nth-child(2) {
    display: none;
  }

  .dashboard_box_cus-set th:nth-child(2) {
    display: none;
  }
}

@media (max-width: 899px) {
  .height_100.sales-order-main .dashboard_so_summary + div,
  .height_100.sales-order-main .dashboard_so_summary,
  .dashboard_box_width.dashboard_mobile-box,
  .dashboard-mob-inner .dashboard_box.box_8,
  .dashboard-mob-inner .dashboard_box.box_9 {
    width: 100% !important;
  }

  .dashboard_box_width.dashboard_mobile-box:nth-child(2n) {
    padding-left: 4px;
  }

  .dashboard_box_width.dashboard-mob-inner {
    flex-direction: column;
  }

  .dashboard_box_width_full.dashboard_box_cus-set .data_section {
    height: 100%;
  }

  .dashboard_customer-main .float-left.w-30.image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dashboard_customer-main .dashboard_top_customer_img {
    margin-left: auto;
  }

  .intital_screen.float-left.w-100 {
    display: flex;
    flex-direction: column;
  }

  /* Dashboard Sales section responsiveness  */

  .intialscreen_innerpages {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    overflow-y: auto;
    height: calc(100% - 45px);
  }

  html body .intialscreen_innerpages .left,
  html body .intialscreen_innerpages .right {
    height: auto;
    width: 100%;
    overflow: unset;
    padding: 40px 15px;
  }
}

@media print {
  html {
    overflow: inherit !important;
    height: auto !important;
  }

  .top-header,
  .dashboard-header,
  .ims-navigation,
  .header-mobile {
    display: none !important;
  }

  a[href]:after {
    content: '';
  }

  .app-content {
    overflow: inherit !important;
  }
  .dashboard_box.box_8,
  .dashboard_box.box_9,
  .dashboard_box_width.dashboard_mobile-box,
  .dashboard-mob-inner,
  .height_100.sales-order-main .dashboard_so_summary + div,
  .height_100.sales-order-main .dashboard_so_summary {
    width: 50% !important;
    display: inline-block !important;
  }

  html body .sales-order-main .dashboard_so_summary,
  html body .sales-order-main .sales-summary-right {
    /* width: 49.5% !important;
    margin: auto !important;
    display: inline-block !important;
    margin-bottom: 5px !important;
    float: left !important; */
  }
  html body .dashboard-mob-inner {
    width: 100% !important;
    float: left;
  }
  html body .dashboard-mob-inner .dashboard_box.box_8,
  html body .dashboard-mob-inner .dashboard_box.box_9 {
    width: 49.5% !important;
    margin: auto !important;
    display: inline-block !important;
    margin-bottom: 5px !important;
  }
  html body .dashboard-mob-inner .dashboard_box.box_9 {
    float: right !important;
  }
  .recharts-wrapper,
  .recharts-wrapper svg {
    width: 100% !important;
  }
  /* html body .dashboard-mob-inner .data_section {
    width: 50% !important;
  } */
  /* .dashboard_box.box_9 {
    width: 100% !important;
  } */

  html body .dashboard_box:hover .dashboard_box_header p:first-child {
    border-left: 5px solid green;
  }
}
