.form-table--invoice-terms {
  width: 100%;
  border-collapse: separate;
  /* border-spacing: 2rem; */
}

.form-table--invoice-terms td {
  position: relative;
  padding: .8rem 0;
}

.form-table--invoice-terms .form-field.error .input-feedback {
  white-space: nowrap;
  top: 100%;
  bottom: 0;
  margin: 0;
}

.button.invoice-terms--add-button {
  color: var(--blue-500);
}

.invoice-terms--action-buttons {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  grid-gap: 1.6rem;
  margin: 2rem 0;
}