div.add-organization-component .timezone-picker {
  display: block !important;
}

div.add-organization-component .timezone-picker-textfield input {
  border-color: #ccc;
  border-radius: 4px;
}

div.timezone-picker {
  width: 100%;
}

.organization-form-hr {
  margin: 2rem 0;
  border: 0;
}

.organization-btn-set {
  line-height: 10px !important;
  min-width: 158px;
  height: 32px !important;
  padding: 2px 0 0 22px !important;
}

/* a.organization-btn-set:focus, */

.organization-btn-set:hover {
  color: #000 !important;
}

a.organization-btn-set:focus {
  color: #fff !important;
  text-decoration: none;
}

.organization-btn-set svg.switch_icon {
  top: 0 !important;
  font-size: 16px;
}

/* .organization-btn-set svg.switch_icon {
  top: 5px !important;
} */

/* .organization-main svg {
  position: absolute;
  top: -14px;
  left: -16px;
} */

span.organization-label-set {
  position: absolute;
  top: 8px;
  right: 35px;
}

span.organization-platform {
  position: absolute;
  top: 10px;
  right: 120px;
}
.organization-main svg {
  position: absolute;
  top: -14px;
  left: -16px;
}

.icon_top {
  top: 7px !important;
}

@media (max-width: 1138px) {
  span.organization-label-set {
    right: 29px;
  }

  .name_organization,
  .about_organization.text-center.float-left.w-100 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
  }
}

@media (max-width: 899px) {
  .margin-top-lg {
    margin-top: 2rem;
  }
}
