.pagination-container {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  position: sticky;
  bottom: 0;
  left: 0;
  /* width: 100%; */
  /* background-color: white; */
  /* padding: var(--body-padding); */
  /* height: 7rem; */
  z-index: 500;
  float: left;
/* margin-top: 3px; */
  /* width: 10%; */
}
  