.dropship_modal thead tr th:last-child, .dropship_modal tbody tr td:last-child {
  padding-right: 10px !important;
}

.dropship_modal thead tr th label span {
  left: -0.4rem;
}

.dropship-popup-inner {
  width: 100%;
  display: block;
}

.dropship-popup-inner .dropship_modal thead th:nth-child(2) {
  width: 41%;
}

.dropship-popup-inner .dropship_modal thead th:nth-child(3), .dropship-popup-inner .dropship_modal thead th:nth-child(4),
.dropship-popup-inner .dropship_modal thead th:nth-child(5), .dropship-popup-inner .dropship_modal thead th:nth-child(6) {
  width: 15%;
}

@media(max-width:768px) {
  .uiux-dropship-popup .dropship_modal {
    min-width: 1000px;
  }

  .uiux-dropship-popup .dropship_modal td:nth-child(2) {
    min-width: 170px;
  }

  .uiux-dropship-popup .dropship_modal td:nth-child(4) {
    min-width: 150px;
  }

  .dropship-popup-inner {
    overflow: auto;
  }
}