.default-attached-file {
  display: inline-flex;
  align-items: center;
  margin: 0.8rem 0;
  background: var(--background);
  border-radius: 4px;
  font-weight: 500;
  padding: 0.8rem;
}

.default-attached-file svg {
  margin-right: 0.8rem;
}

.email_info{
  position: absolute !important;
  top: 14.5% !important;
  left: 9% !important;
}
.email .full_width input{
  width: 100% !important;
}
.info_icon_email{
  fill: #000;
  opacity: 0.4;
}

.info_icon_email:hover{
  fill: #306ab4;
  stroke: #306ab4;
}

.email_dropdown .select__menu{
  z-index: 99 !important;
}

.email .css-b8ldur-Input, .email .css-1g6gooi {
  width: 100%;
}

.email .css-b8ldur-Input .select__input, .email .css-1g6gooi .select__input {
  width: 100%;
}