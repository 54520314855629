.theekhai {
  height: 10rem;
  width: 10rem;
  float: left;
  border-radius: 5rem;
  border: 1px solid var(--icon-primary);
  padding: 0 10px;
  margin-left: 10px;
  overflow: hidden;
}
.organization--img + div > div,
.profile_image div > div {
  border-radius: 50%;
  background-size: cover;
}
/* .ImagePreview_imagePreview__2GppM {
  border-radius: 50%;
} */
.organization--img + div > div .button--image-delete {
  top: 10px;
  right: 15px;
}
.organization-form-set .form-image .ImagePreview_imagePreview__2GppM {
  border-radius: 50%;
}
.organization-form-setl .form-image .button--image-delete {
  top: 10px;
  right: 15px;
}
.theekhai .image-block {
  /* width: 12rem; */
  /* margin-left: 1rem; */
  /* border-radius: 4px; */
  width: 100%;
  /* background-size: contain; */
  background-repeat: no-repeat;
  /* background-image: none; */
  /* background-size: auto; */
  background-size: 100%;
  background-position: center center;
  float: left;
  height: 100%;
  margin-left: 0 !important;
}

.basecurrency-disabled-message {
  float: left;
  width: 100%;
  font-size: 1.2rem;
  /* margin-left: 25rem; */
  /*margin-top: -.5rem;*/
  margin-top: 0rem;
  color: var(--dark-grey);
}

.input-zero {
  display: block;
  height: 0;
  width: 0;
  border: 0;
  outline: none;
}

.add_logo_title {
  margin-left: 2%;
}

.currnecy_info {
  fill: #000;
  stroke: #000;
  width: 15px;
  height: 15px;
}

.currnecy_info {
  fill: #000;
  stroke: #000;
  width: 15px;
  height: 15px;
}

.currency_field {
  position: absolute !important;
  /* right: 108px; */
  top: 1px;
  z-index: 1000;
  left: 46%;
}

.base_field {
  position: relative !important;
  padding: 0 !important;
}

.currnecy_info {
  fill: #000;
  opacity: 0.4;
}

.currnecy_info:hover {
  fill: #306ab4;
  stroke: #306ab4;
}

.org_type .searchable-dropdown__menu {
  z-index: 10000;
}

button.cross-btn-set {
  top: 9%;
  right: 11%;
  left: auto;
}
.currency-field-main .currency_field {
  top: 10px;
  left: 117px;
  right: auto;
}
.currency-field-main .form-field.error .input-feedback {
  bottom: -1.7rem;
}

@media (max-width: 1199px) {
  .currency_field.orgination-currency {
    display: none;
  }
}

@media (max-width: 899px) {
  .currency_field.orgination-currency {
    left: auto;
    right: 0;
  }

  .basecurrency-disabled-message {
    text-align: left;
    margin-left: 0;
  }
}
