/* .contact-transaction--header {
  display: grid;
  grid-template-columns: repeat(2, 25rem);
  margin-bottom: 2rem;
}

.contact-transaction--filter {
  display: flex;
  align-items: center;
} */
.contact-transaction--dropdown-title {
  color: var(--grey);
}

.contact-transaction--header .custom-dropdown .button {
  font-size: 1.4rem;
}

.sub_tabs_table_transaction .contact-transaction--header {
  margin-bottom: 0 !important;
}

.mt_0 {
  margin-top: 0 !important;
}

.table--row th.right-align {
  /* padding-right: 3%; */
  text-align: right !important;
}

/* .transaction-table-inner th:nth-child(2) span,
.transaction-table-inner th:nth-child(3) span,
.transaction-table-inner th:nth-child(4) span
{
  display: none;
} 
 .transaction-table-inner th:nth-child(2):after {
  content: 'Order No.';
}
.transaction-table-inner th:nth-child(3):after{
  content: 'Customer';
}
.transaction-table-inner th:nth-child(4):after{
  content: 'Qty';
}  */
/* .transaction-table-inner th:nth-child(3):after{
  content: 'xyz';
} */
.smallWidthClass.upload_img_popup {
  min-width: 80%;
  max-width: 80%;
}

button.btn-load-set {
  text-align: center;
  width: 100%;
  background: #306ab4 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90px;
  margin: 0 auto;
  color: #fff;
  font-weight: 200;
  height: 29px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 16%);
  transition: 0.3s;
  /* border-radius: 7px; */
  margin-top: 20px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
button.btn-load-set:hover {
  background: #fff !important;
  box-shadow: var(--hover-box-shadow);
  color: #000;
}

.items-transaction tr th:nth-child(1),
.items-transaction tr th:nth-child(2),
.items-transaction tr th:nth-child(4) {
  width: 11%;
}
.items-transaction tr th:nth-child(3) {
  width: 40%;
}
.items-transaction tr th:nth-child(5) {
  width: 18%;
}
.items-transaction th:last-child {
  text-align: center;
}
@media (max-width: 1600px) {
  .contact-transaction.sub_tabs_table_transaction
    .section-header--dropdown
    .custom-dropdown {
    max-width: 200px;
  }
  .items-transaction tr th:nth-child(1),
  .items-transaction tr th:nth-child(2),
  .items-transaction tr th:nth-child(4) {
    width: 13%;
  }
  .items-transaction tr th:nth-child(3) {
    width: 38%;
  }
}

@media (max-width: 1440px) {
  .items-transaction tr th:nth-child(1),
  .items-transaction tr th:nth-child(2),
  .items-transaction tr th:nth-child(4) {
    width: 17%;
  }
  .items-transaction tr th:nth-child(3) {
    width: 33%;
  }
}
@media (max-width: 1280px) {
  .items-transaction tr th:nth-child(1),
  .items-transaction tr th:nth-child(2),
  .items-transaction tr th:nth-child(4) {
    width: 18%;
  }
  .items-transaction tr th:nth-child(3) {
    width: 31%;
  }
  .items-transaction tr th:nth-child(5) {
    width: 19%;
  }
}
@media (max-width: 768px) {
  .items-transaction tr th:nth-child(1),
  .items-transaction tr th:nth-child(2),
  .items-transaction tr th:nth-child(4) {
    width: 14%;
  }
  .items-transaction tr th:nth-child(3) {
    width: 36%;
  }
}
