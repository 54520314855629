.sub_tabs_table_transaction .contact-transaction--header {
  /* display: grid;
  grid-template-columns: repeat(2, 25rem);
  margin-bottom: 2rem; */
  float: left;
  width: 100%;
}

.sub_tabs_table_transaction .contact-transaction--filter , .sub_tabs_table_transaction  .section-header--dropdown {
  /* display: flex;
  align-items: center; */
  float: left;
  width: 170px;
  /* background-color: #FFF; */
  display: block;
  margin-right: 10px;
}
.sub_tabs_table_transaction  .section-header--dropdown .custom-dropdown{
  margin: 0;
  width: 100%;
}
.sub_tabs_table_transaction  .section-header--dropdown .custom-dropdown button{border: 0 !important;}
.sub_tabs_table_transaction .custom-dropdown.button-text .custom-dropdown-container{
  left: 0;
  top: 3.5rem;
}

.transaction_view_btn{
  position: absolute;
    bottom: 0;
    right: 0rem;
}
.right-tabs-section-inner .list-table--wrapper .transaction_table {
  max-height: calc(100% - 68px) !important;
}
.ims-modal-container .right-tabs-section-inner .list-table--wrapper .transaction_table {
  max-height: calc(100% - 0px) !important;
}
.right-tabs-section-inner .list-table--wrapper table thead tr th:last-child{
  text-align: center !important;
  text-align-last:center !important ;
}
.right-tabs-section-inner .list-table--wrapper table tbody tr td:last-child span{
  width: 100%;
  display: block;
  text-align: center;
}
.right-tabs-section-inner .list-table--wrapper table tbody tr td:last-child span span{
  width: 94px !important;
  float: none !important;
  display: inline-block !important;
}
.contact-transaction--filter{height: 35px;}
.sub_tabs_table_transaction .contact-transaction--filter .custom-dropdown{width: 100%;}
.sub_tabs_table_transaction .contact-transaction--filter .custom-dropdown button{float: left;width: 100%;background-color: #FFF;color: #000;height: 100%;}
.sub_tabs_table_transaction .contact-transaction--filter .custom-dropdown.button-text .button>svg{position: absolute;right: 10px;fill: #000;}
.sub_tabs_table_transaction .contact-transaction--filter .custom-dropdown.button-text .button>svg path{fill: #000;}
.contact-transaction--dropdown-title {
  color: var(--grey);
}
.contact-transaction--header .custom-dropdown .button {
  font-size: 1.4rem;
}
/* added css for sub tabs bottom buttoon view all */
.transaction {       
  background-color: #306AB4 !important;
  float: right;
  margin: 20px 0;
}

.transaction:hover {
  /* background: var(--hover-background); */
  background-color: #fff !important;
  box-shadow: var(--hover-box-shadow);
  color: #000;
}
.contact_purchase_receives table tbody tr td:last-child .list-table--link span{
  padding: 0.2rem 0.5rem;
  /* float: right; */
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 1.2rem;
  display: inline-block;
  word-break: keep-all;
  /* width: 94px; */
  text-align: center;
}
