.dashboard_pkg_shipment {
  justify-content: space-around;
}

.pl_3 {
  padding-left: 3rem;
}

.dashboard_pkg_shipment .dashborad_sales_info_invoices:first-child {
  height: 215px;
}

.dashboard_pkg_shipment .dashborad_sales_info_invoices a span:last-child {
  font-weight: 700;
}

.dashboard_pkg_shipment a span:last-child:hover {
  color: #306ab4 !important;
  text-decoration: underline;
}

@media (min-width: 1281px) and (max-width: 1460px) {
  .dashboard_pkg_shipment .dashborad_sales_info_invoices {
    height: 250px !important;
  }

  .dashboard_pkg_shipment .dashborad_sales_info_invoices:first-child {
    height: 239px !important;
  }
}