.dashboard_footer{
  display: inline-flex;
  flex-direction: column;
  /* position: relative; */
  margin-top: 5px;
  width: 100%;
  padding: 10px;
  background: #E9E9E9;
}
.dashboard_footer p {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: small;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 12px;
  color: #000;
}
.dashboard_footer p a{
  color: #000;
  margin-left: 5px;
  margin-right: 5px;
}
.dashboard_footer p:first-child{
  /* margin-top: 10px; */
  /* padding-top: 10px; */
}