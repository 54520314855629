.container-contact-overviw {
  margin-left: 2%;
}

.container-contact-overviw .custom-dropdown.dot-icon>button {
  transform: unset;
}

.details_row {
  border-bottom: 1px solid #dfe1e5;
  padding: 5px 0;
  float: left;
  width: 80%;
  display: flex;
  color: #000;
  /* align-items: center !important; */
}

.details_data {
  color: #000;
  font-size: 13px !important;
  margin-bottom: 3px !important;
  font-weight: weight;
  float: left;
  width: 100%;
  /* margin-bottom: 10px; */
  margin-left: 7px;
}

.details_data>span {
  font-size: 13px !important;
}

.package_address .details_row {
  align-items: normal !important;
}

.right_sec li {
  /* width: 23rem; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-summary>p {
  padding: 0 5px 0 2px;
  line-height: 25px;
}


.lh-20 {
  line-height: 20px;
}

/* contact info redesign */
.contact-info-main {
  float: left;
  width: 100%;
  max-width: 500px;
}
.contact-info-row .contact-info-left p,
.contact-info-row .contact-info-right p{
  margin-bottom: 0;
  color: #000;

}
.contact-info-row .contact-info-left,
.contact-info-row .contact-info-right {
  flex: 0 0 50%;
}
.contact-info-row {
  margin-bottom: 15px;
}
.lh-25{
  line-height: 25px;
}
.contact-info-row .contact-info-right p {
  padding: 0 4px;
  white-space: normal;
}

@media(max-width:1440px){
  .contact-info-main{
    max-width: 360px;
  }
}
@media(max-width:1138px){
  .contatcs_all_details{
    width: 100%;
  }
}
@media(max-width:991px){
  .contact-info-main {
    max-width: 578px;
}
}
@media(max-width:768px){
  .contact-info-main {
    max-width: 434px;
}
}