.empty-table {
  display: flex;
  align-items: center;
  padding: 12rem;
  flex-direction: column;
  text-align: center;
}

.empty-table h5 {
  color: var(--text-secondary);
}