.help_section {
  -webkit-box-shadow: -2px -2px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -2px -2px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: -2px -2px 2px 0px rgba(0, 0, 0, 0.15);
  overflow: auto;
  /* padding: 0 10px; */
}

.helpSupport {
  float: left;
  width: 100%;
}

.px-10 {
  padding: 0 15px;
}

.cursor_pointer {
  cursor: pointer;
}

.helpsection_inner {
  height: 75px;
  transition: 0.3s;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.helpsection_inner a {
  float: left;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.helpsection_inner .desc {
  font-family: Roboto;
  font-weight: normal;
  font-size: 14px;
  /* text-align: left; */
  color: #000;
  text-align: center;

}

.help_section .heading {
  height: 50px;
  display: flex;
  align-items: center;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.help_section .heading_inner {
  width: 100%;
  float: left;
  height: 2px;
  background-color: #ccc;
}

.help_section .heading_inner span {
  position: absolute;
  top: 15px;
  background: #FFF;
  padding-right: 15px;
}

.helpsection_inner:hover {
  background-color: #306AB4;
}

.helpsection_inner:hover .icon svg path {
  fill: #FFF;
}

.helpsection_inner:hover .desc {
  color: #FFF;
}

.need_assiatance {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}

.need_assiatance ul {
  float: left;
  width: 100%;
  list-style: none;
}

.need_assiatance ul li {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.need_assiatance ul li:last-child {
  margin-bottom: 0;
}

.need_assiatance ul li a {
  width: 100%;
  float: left;
  color: #000;
}

.need_assiatance ul li a svg {
  margin-right: 15px;
  float: left;
  position: relative;
  top: 2px;
}

.need_assiatance ul li a:hover {
  color: #306AB4;
  text-decoration: underline;
}

.videotutorials {
  float: left;
  width: 100%;
  /* margin-bottom: 30px; */
}

.more_Vidoes {
  float: left;
  width: 100%;
  margin: 10px 0;
  text-align: center;
}

.more_Vidoes a {
  color: #306ab4;
}

.ring_us {
  float: left;
  width: 100%;
  padding-bottom: 15px !important;
}

.ring_us .heading {
  margin-bottom: 0;
}

.ring_us .anytime span {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}

.ring_us a {
  float: left;
  width: 100%;
  color: #222629;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.ring_us a svg {
  float: left;
  position: relative;
  top: -3px;
  margin-right: 10px;
}

.user_manual {
  float: left;
  width: 100%;
  position: absolute;
  /* left: 0; */
  top: 14.4rem;
  transition: 0.3s ease-in;
  background: #FFF;
  z-index: 99;
  border-top: 3px solid #306ab4;
  padding: 0 15px;
}

.user_manual .info {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user_manual .info span {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  text-align: left;
  color: #000;

}

.user_manual .info button {
  float: right;
}

.search {
  margin-bottom: 20px;
}

.search {
  position: relative;
}

.search input {
  float: left;
  width: 100%;
  border-radius: 18px;
  height: 36px;
  background: #f0f2f5;
  border: 0.5px solid #808080;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0));
  padding: 0 40px 0 15px;
}

.search svg {
  position: absolute;
  right: 18px;
  width: 18px;
  height: 18px;
  top: 7px;
}

.search input:active, .search input:focus-visible, .search input:focus {
  outline: 0;
  border: 0;
}

.search input:focus {
  outline: 0;
  border: 0;
}

.user_manual_options {
  float: left;
  width: 100%;
}

.user_manual_options a {
  float: left;
  font-weight: normal;
  font-size: 14px;
  line-height: 34px;
  text-align: left;
  color: #000;
  transition: 0.3s;
}

.options-- {
  height: calc(100vh - 303px);
  overflow: auto;
}

.user_manual_options a:hover {
  color: #306ab4;
  text-decoration: underline;
}

.video_inner .image {
  height: 73px;
  margin-bottom: 15px;
  border: 1px solid #707070;
  border-radius: 5px;
}

.video_inner:nth-child(odd) {
  padding-right: 7.5px;
}

.video_inner:nth-child(even) {
  padding-left: 7.5px;
}

.video_inner .image a {
  float: left;
  width: 100%;
  height: 100%;
}

.video_inner .image a img {
  float: left;
  width: 100%;
  height: 100%;
  object-fit: cover;
}