.page-404 {
  display: grid;
  grid-template-rows: 1fr 5fr auto;
  min-height: 100vh;
  justify-content: center;
  text-align: center;
  align-content: space-between;
}

.page-404--message {
  color: var(--grey);
  font-size: 1.6rem;
}

.page-404--logo {
  padding-top: 3rem;
  width: 28rem;
  padding-left: 5rem;
}

.page-404--footer {
  padding-bottom: 2rem;
}

.page-404--title {
  padding: 2rem;
}