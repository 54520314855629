.radio-container {
  margin: 0.8rem 0;
  z-index: 0;
}
.radio-container.inline {
  display: flex;
}
.radio-container.inline .radio-btn {
  margin: 0;
  padding-right: 2rem;
  margin-right: 2rem;
}