.shipment-info-card--header {
  display: grid;
  grid-template-columns: 5rem auto;
  grid-column-gap: 1rem;
  align-items: center;
}

.shipment-info-card--body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 2rem 0;
}

.shipment-info-card--note {
  margin: 0.5rem 0 !important;
}

.shipment-info-card--button-delete {
  outline: none;
  box-shadow: none;
  border: none;
  color: var(--alert-error);
  background: none;
  margin: 0;
  padding: 0;
}

.row-flex{
  display: flex !important;
  padding: 10px 10px !important;
  grid-gap: 0rem !important;
}
.shipment_padding{
  padding: 10px 0px;
}


.shipment_padding .inner_padding{
  padding: 5px 0px;
}
.shipment_padding hr{
  border-top: 1px solid var(--border-light) !important;
}
.shipment-info-card--footer{
  /* float: left; */
  width: 100%;
  margin-bottom: 10px;
  margin-top: 8px;
}
.shipment-info-card--footer button{
  float: right;
  width: auto;
  margin: 0.8rem 0 1rem 0 !important;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid rgb(223, 225, 229);
  /*background-color: rgb(223, 225, 229);*/
  background-color: var(--blue-500);
  /*color: rgb(162, 162, 163);*/
  color: #ffffff;
  text-decoration: none;
}

.ims-modal--title span:first-child{float: left}
.ims-modal--title span:last-child{float: right;position: relative;}
.ims-modal--title span:last-child a{
  position: absolute;
  left: -2.5rem;
  line-height: 0;
  top: 3px;
}
.ims-modal--title span svg{
  fill: #000 !important;
}
/* .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td[data-heading="Invoice No."]{
  cursor: pointer;
} */

.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr .pointerOut:first-child{
  cursor: text !important;
}
.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr .pointerIn:first-child{
  cursor: pointer !important;
  color: #306ab4;
  font-weight: 500;
}
.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr .pointerIn:first-child:hover{
  text-decoration: underline !important;
}