@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Roboto:wght@300;400;500;700;900&display=swap');

:root {
  --blue-500-hover: #0062a3;
  --blue-500-active: #004b7d;
  --seebiz-light-blue: #0080cd;
  --header-height: 5rem;
  --black: #3d3d3d;
  --grey: #95989a;
  --dark-grey: #7e7e7e;
  /*--border-blue: #589dd8;*/
  --border-blue: #dfe1e5;
  --border-blue-light: #d8e7f1;
  --border-blue-dark: #97b7cc;
  --button-hover: #ecf7ff;
  --table-head: #f8f8f8;
  --green-status: #e4f0e6;
  --red-status: #fce5e1;
  --orange-status: #f8e7c9;
  --neutral-status: #d4e7fa;
  --draft-status: #e1e4e5;
  --receiving-status: #ffd6bf;
  --green-status-text: #1f5927;
  --red-status-text: #802e20;
  --orange-status-text: #916818;
  --neutral-status-text: var(--blue-500);
  --draft-status-text: #525d66;
  --receiving-status-text: #1f5927;
  --input: #c7dceb;
  --body-padding: 0 2rem;
  --small-padding: 1rem;
  --block-margin: 0 0 1rem;
  --transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  --transition-quick: all 0.15s ease-in-out;
  --shadow-hover: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 2px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  --row-selected: #e5f4ff;
  --text-grey: #3d3d3d;
  --text-grey-light: #95989a;
  /*--navigation-background: #222629;*/
  --navigation-background: #ffffff;
  /*--navigation-link-text-color: #e1e4e5;*/
  --navigation-link-text-color: #000000;
  --navigation-link-bg-color-selected: #3bb54a;
  --navigation-link-bg-color-hover: #e9e9e9;
  --navigation-link-text-color-selected: #ffffff;
  --navigation-link-text-color-hover: #000000;
  --navigation-link-hover: #ffffff;
  --navigation-link-text-hover: #ffffff;
  /* --------------------------------------------------------*/
  /* new design vars ( MARCH-APRIL 2019 ) */
  /* --------------------------------------------------------*/
  --background: #dfe1e5;
  --text-primary: #303940;
  --text-secondary: #4b575e;
  --text-secondary-button: white;
  --text-tertiary: #cbd2d6;
  --text-field-border: #bcc6cc;
  --icon-primary: #adb6bc;
  --border-dark: #d3dee5;
  --border-light: #edf3f7;
  --selected: #e6eff5;
  --hover: #ebf4fa;
  --disabled: #f0f3f5;
  --box-margin: 1.6rem 2.4rem;
  --link-hover: #0071bc;
  --blue-900: #0b456b;
  --blue-800: #2e5f80;
  --blue-500: #0071bc;
  --grey-500: #878787;
  --blue-100: #e6f2fa;
  --green-800: #6fb879;
  --red-500: #ad4937;
  --red-400: #cc533e;
  --grey-text: #525d66;
  --light-grey-text: #cbd1d6;
  --hover-background: #157dd8;
  --alert-error: #802e20;
  --alert-success: #1f5927;
  --grey-box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  --shadow-medium: 0px 2px 8px rgba(0, 0, 0, 0.08);
  --hover-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  --button-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-light: 0px 2px 8px rgba(0, 0, 0, 0.04);
  --padding-vertical: 1.6rem;
  --padding-horizontal: 2.4rem;
  --margin-right: 2.4rem;
  --padding: var(--padding-vertical) var(--padding-horizontal);
  --letter-spacing: 0.25px;
}

/* Keyword values */

/* scrollbar-width: auto;
scrollbar-width: thin;
scrollbar-width: none; */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #c0c0c0;
  box-shadow: inset 0 0 6px #c0c0c0;
  background-color: #efecec;
}

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px #dbdbdb;
  /*box-shadow: inset 0 0 6px #dbdbdb;*/
  box-shadow: inset 0 0 6px #c0c0c0;
  background-color: #c0c0c0;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

html {
  overflow: auto;
  height: 100vh;
}

body {
  font-family: 'Roboto', 'Lato';
  height: 100%;
  color: var(--text-primary);
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
}

hr {
  margin: 0.8rem 0;
  border-top: 1px solid var(--border-light);
}

ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

th {
  font-weight: 500;
  white-space: nowrap;
}

h4 {
  font-weight: 400;
}

input::placeholder {
  color: #aaa;
}

button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: inherit;
  background-color: transparent;
}

.button {
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  border-radius: 4px;
}

.button:hover {
  background-color: var(--hover);
}

.underline {
  text-align: left;
  white-space: nowrap;
}

.underline:hover {
  text-decoration: underline;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.4;
}

input[disabled] {
  background-color: #eeeeee;
  color: var(--grey);
}

input[type='number'] {
  text-align: right;
}

section {
  padding: 0.8rem 0;
}

.hidden {
  display: none;
}

.w-80 {
  width: 80%;
}

.modal-section--title {
  padding-bottom: 0.8rem;
}

.error-status {
  color: var(--red-500);
}

.grid-1-1-auto-fr {
  display: grid;
  grid-template-columns: auto 1fr;
}

.grid-1-1-fr-fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid--column-gap-2 {
  grid-column-gap: 2rem;
}

.grid--center {
  justify-content: center;
  align-items: center;
}

.padding--regular {
  padding: var(--padding);
}

.padding--regular-horizontal {
  padding: 0 var(--padding-horizontal);
}

.margin--regular-right {
  margin-right: var(--margin-right);
}

.button--primary {
  /* Hassan asked me do it */
  display: flex;
  align-items: center;
  height: 3.2rem;
  background: #33a348 !important;
  color: #fff;
  padding: 0.8rem 2rem 0.8rem 1.8rem;
  border-radius: 4px;
  padding: 0.8rem 2rem 0.8rem 1.8rem;
  border-radius: 2px;
  outline: none;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  line-height: 1;
  transition: 0.3s;
  position: relative;
}

.button--primary svg {
  position: relative;
  left: -4px;
  top: -1px;
  font-size: 19px;
  fill: #fff !important;
}

.button--primary.payment-record svg {
  vertical-align: middle;
}

.button--primary:hover svg {
  fill: #000 !important;
}

.button--border {
  border: 1px solid var(--blue-500);
  color: var(--blue-500);
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  background: transparent;
  text-decoration: none;
  display: inline-block;
  margin-right: 1rem;
}

.button--image-delete {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 2rem !important;
  height: 2rem;
  right: 0;
  top: 0;
  transform: translate(1rem, -1rem);
  border-radius: 50%;
  box-shadow: var(--grey-box-shadow);
  padding: 0;
  /* may temp */
  /* right: 13px;
  top: 7px; */

}

.button--image-delete svg {
  fill: #000000;
}

.button--image-delete:hover {
  background: var(--blue-500-active);
}

.button--image-delete:hover svg {
  fill: #fff;
}

.button--primary:hover {
  background: #fff !important;
  box-shadow: var(--hover-box-shadow);
  color: #000;
}

.border-bottom {
  border-bottom: 1px solid var(--border-light);
  padding-bottom: 2rem;
}

.grey {
  color: var(--grey);
}

.grey-text {
  color: var(--grey-text);
}

.text-secondary {
  color: var(--text-secondary);
}

.color-inherit {
  color: inherit !important;
}

.font-1_6rem {
  font-size: 1.6rem;
}

.font-1_2rem {
  font-size: 1.2rem;
}

.material-icon {
  margin: 0 1rem;
  font-size: 2.5rem;
}

.white-icon {
  fill: transparent;
  stroke: white;
  stroke-width: 2px;
  transition: fill 0.15s ease-in-out, stroke 0.1s ease-in-out;
}

button:hover .white-icon,
a:hover .white-icon,
.white-icon:hover {
  fill: white;
  stroke: transparent;
}

.white,
button:hover svg.white {
  fill: white;
}

.white-stroke {
  fill: none;
  stroke: white;
  stroke-width: 2px;
}

.blue-icon {
  fill: #306ab4;
  stroke: transparent;
  stroke-width: 2px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

button:hover .blue-icon,
a:hover .blue-icon,
.blue-icon:hover {
  fill: var(--blue-500);
  stroke: transparent;
}

.blue {
  fill: #fff;
  color: #fff;
  background-color: #33a348;
  border-radius: 1px !important;
  padding: 10px !important;
  transition: 0.3s;
}

.blue svg {
  height: 20px;
  width: 20px;
  fill: #fff;
  top: -1px;
  position: relative;
}

.blue:hover,
.blue:focus {
  background: #fff;
  box-shadow: var(--hover-box-shadow);
  color: #000;
}

.blue:hover svg,
.blue:focus svg {
  fill: #000;
}

.m-left {
  margin-left: 1rem;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inline {
  display: inline-flex;
  align-items: center;
  white-space: pre-wrap;
  width: auto;
  position: relative;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.empty-data {
  padding: 5rem 0;
  font-size: 2rem;
  text-align: center;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.empty-msg {
  padding: 1rem 0;
  font-size: 1.5rem;
  text-align: center;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
}

.text-white {
  color: #fff;
}

.bold {
  font-weight: 700;
  color: var(--grey-text);
}

.semi-bold {
  font-weight: 500;
}

.word-break {
  word-break: break-word;
}

.text-small {
  font-size: 12px;
}

.pointer {
  cursor: pointer;
}

.single-item_table-set {
  max-width: 100%;
}

.pointer:hover {
  /* testing */
  background-color: transparent !important;
  color: #000;
  /* color: var(--blue-500-active); */
  /* transition: var(--transition-quick); */
}

/* html body .table th.pointer:hover {
  background-color: #fff !important;
} */

/* .pointer:hover svg path{
stroke: #000 !important;
} */

.link {
  color: var(--blue-500);
}

.link--underline {
  color: unset;
}

.link--underline:hover {
  color: unset;
  text-decoration: underline;
}

.link:hover {
  color: var(--blue-500-active);
  cursor: pointer;
}

.ascending,
.descending {
  position: relative;
}

.so-asc-active .ascending:after {
  content: '\25BE';
  transform: rotate(-180deg);
  display: inline-block;
}

.ascending:after {
  content: '\25B4';
  color: var(--blue-500-active);
  margin-left: 0.4rem;
  position: relative;
  font-size: 16px;
}

.descending:after {
  content: '\25BE';
  color: var(--blue-500-active);
  margin-left: 0.4rem;
  position: relative;
  font-size: 16px;
}

.inactive > td:not(:last-child) {
  opacity: 0.6;
}

hr.form-hr {
  margin: 3rem 0;
  border-top: 1px solid var(--input);
}

/*<-----form footer button*/

.form-footer--btn {
  border: 1px solid var(--border-blue);
  border-radius: 2px;
  padding: 1rem 2rem;
  line-height: 2rem;
  text-decoration: none;
  outline: 0;
  color: var(--blue-500);
  transition: 0.15s ease-out;
}

.form-footer--btn:hover {
  text-decoration: none;
}

.cancel-btn {
  background: var(--background);
  /* border: 1px solid var(--border-dark); */
  box-sizing: border-box;
  box-shadow: var(--button-box-shadow);
  border-radius: 2px;
  padding: 0.5rem 1.6rem;
  margin-left: 1.6rem;
  color: var(--text-secondary);
  transition: 0.3s;
}

.cancel-btn:hover,
.cancel-btn:focus {
  /* background-color: var(--hover); */
  background-color: #fff;
}

.dropship-main + .form-field.inline-field .input-feedback {
  top: 9px;
  bottom: 0;
  position: relative;
}

.bills-main-set {
  width: 20%;
}

.purchase-order-set.purchase-order-vendor,
.invoice-main-set.invoices-popup-set {
  width: 24%;
}

.bills-main-set .form-field.inline-field,
.purchase-order-set.purchase-order-vendor .form-field.inline-field,
.invoice-main-set.invoices-popup-set .form-field.inline-field {
  width: 100%;
  padding: 8px 24px 13px 0;
}

.add_adjustment .css-19jh2ze-option {
  background: #fff;
  text-align: center;
}

.package-plans-actions .cancel-btn {
  max-width: 74px;
}

.button--secondary {
  background: #306ab4;
  border: 1px solid #306ab4;
  box-sizing: border-box;
  box-shadow: var(--button-box-shadow);
  border-radius: 2px;
  padding: 0.5rem 1.6rem;
  margin-left: 1.6rem;
  color: var(--text-secondary-button);
}

.button--secondary:hover {
  background-color: var(--hover);
}

.next-btn {
  /* background: var(--blue-500); */
  background: #33a348;
  color: #fff;
  padding: 0.5rem 1.6rem;
  border-radius: 2px;
  outline: none;
  /* margin-left: 1.6rem; */
  /* border: 1px solid var(--blue-500); */
  /* border: 1px solid #3bb54a; */
  box-shadow: var(--button-box-shadow);
  transition: 0.3s;
}

.next-btn:hover,
.next-btn:focus {
  /* background: var(--hover-background); */
  background: #fff;
  box-shadow: var(--hover-box-shadow);
  color: #000;
}

/*form footer button----->*/

.body-padding {
  padding: var(--body-padding);
}

.checkbox--option-label {
  padding-left: 1.5rem;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  user-select: none;
}

.checkbox--option-label > span {
  position: relative;
  margin-left: -2.5rem;
  margin-right: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
  /*border: 1px solid #589dd8;*/
  border: 1px solid #000000;
}

.checkbox--option-label > span > svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  transform: scale(1.3);
  /*color: var(--seebiz-light-blue);*/
  fill: black !important;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.checkbox--option-label > input[type='checkbox'] {
  opacity: 0;
}

.checkbox--option-label > input[type='checkbox']:checked + span > svg {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

.close-btn.button {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  color: var(--red-500);
  font-size: 20px;
  padding: 0;
}

.form-focus .close-btn.button:focus {
  /* border: 1px solid black; */
  /* border-radius: 50%; */
   border: 0.1rem dashed #d0d0d0;
   border-radius: 0;
}
.form-focus .close-btn.button{
  margin-left: 5px;
}
.close-btn.button:hover {
  background-color: transparent;
}

.close-btn.button:hover svg .right-align,
.table--row th.right-align {
  text-align: right;
}

.widthClass svg {
  margin-right: 10px;
  float: left;
  margin-top: 1px;
}

.center-align {
  text-align: center;
}

.layout--body {
  float: left;
  width: 100%;
  margin: 0 !important;
  scrollbar-width: thin;
}

.button--primary.payment-record {
  background: #306ab4 !important;
  transition: 0.3s;
}

.button--primary.payment-record:hover {
  color: #306ab4 !important;
  box-shadow: var(--hover-box-shadow);
  background: #fff !important;
}

.right-align--margin {
  margin-left: auto !important;
}

.h-center {
  display: flex;
  justify-content: center;
}

.word-break-all {
  word-break: break-all;
}

.word-break-word {
  word-break: break-word;
}

.grid-row-gap-none {
  grid-row-gap: 0;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.margin {
  margin: 0.8rem 0;
}

html body .margin-top-lg,
.margin-top-lg {
  margin-top: 4rem;
}

.margin-top-md {
  margin-top: 2rem;
}

.margin-bottom-md {
  margin-bottom: 2rem;
}

.margin-vertical-md {
  margin: 2rem 0;
}

.margin-horizontal-sm {
  margin: 0 0.5rem;
}

.margin-bottom-sm {
  margin-bottom: 1rem;
}

.margin-bottom-xs {
  margin-bottom: 0.5rem;
}

.description_field {
  width: 100% !important;
}

.margin-top-sm {
  margin-top: 0.8rem;
}

.margin-vertical-sm {
  margin: 1rem 0;
}

.margin-left-md {
  margin-left: 1rem;
}

.margin-right-md {
  margin-right: 1rem;
}

.float-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
  display: inline-block;
}

.status {
  padding: 0.2rem 0.5rem;
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 1.2rem;
  display: inline-block;
  word-break: keep-all;
  width: 94px;
  text-align: center;
}

.status--transparent {
  padding: 0;
  background: transparent !important;
}

.red {
  color: var(--red-500);
}

.green {
  color: var(--alert-success);
}

.status--draft,
.status--void {
  color: var(--draft-status-text);
  background: var(--draft-status);
}

.status--unpaid,
.status--not-shipped,
.status--overdue {
  color: var(--red-status-text);
  background: var(--red-status);
}

.status--closed,
.status--complete,
.status--paid,
.status--received,
.status--shipped {
  color: var(--green-status-text);
  background: var(--green-status);
}

.status--receiving {
  color: var(--receiving-status-text);
  background: var(--receiving-status);
}

.status--sent,
.status--confirmed,
.status--cancelled,
.status--delivered {
  /* color: var(--neutral-status-text); */
  background: var(--neutral-status);
}

.status--onhold,
.status--partially-received,
.status--partially-paid,
.status--issued {
  color: var(--orange-status-text);
  background: var(--orange-status);
}

.order-number {
  color: var(--blue-500);
}

.header--button {
  background: var(--blue-500);
  color: #fff;
  padding: 1rem 1.2rem 1rem 0rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  transition: all 0.15s ease-in-out;
}

.header--button.no-icon {
  padding: 1rem 1.2rem;
}

.header--button:hover {
  background: var(--blue-500-active);
  color: #fff;
}

.header--button > svg {
  fill: #fff;
  margin-bottom: 0.3rem;
}

/* temporary modal style */

.modal-content {
  border-radius: 3px;
}

.modal-header {
  background: #f2f2f2;
  padding: 3rem 1.5rem;
  border-bottom: 1px solid var(--border-blue-light);
}

.modal-title {
  text-transform: uppercase;
  font-size: 1.6rem;
}

.subtitle {
  margin-bottom: 0.8rem;
  font-weight: 500;
  color: var(--text-primary);
}

/* @media screen and (max-width: 700px) {
  html,
  body {
    font-size: 8px;
  }
} */

.loader {
  margin-top: 10px;
}

.returnreceivefont {
  /* font-size: 1.4rem; */
  font-size: 14px;
  color: #000;
}

.package-item-info {
  float: left;
  width: 100%;
}

.package-item-info table tbody tr {
  border-bottom: 0 !important;
}

/* .sss {
  float: left;
    width: 100%;
    max-height: 36vh;
    overflow: auto;
    scrollbar-width: auto;
    scrollbar-color: #C7DCEB #dfedf7; 
} */

.detail_component_inner {
  overflow-x: auto;
}

.detail_component_inner {
  /* margin-top: 1%; */
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 0.3s;
  height: calc(100vh - 97px);
  overflow: auto;
  scrollbar-width: thin;
  position: relative;
  scrollbar-color: #c0c0c0 #efecec;
  /* float: left;
    width: 100%;
    height: calc(100vh - 20.9rem);
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #C7DCEB #dfedf7;  */
}

/* @media screen and (min-width:21px) and (max-width:1565px){
  .list-table--wrapper{height: calc(100vh - 22rem);}
} */

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.dropdwon-width-bill .searchable-dropdown {
  width: 225px !important;
}

.overflow-x {
  overflow-x: auto;
}

.sss .custom-dropdown {
  top: -6px;
}

/* Add new table class */

.horizontal-items-table thead,
.horizontal-items-table tbody {
  float: left;
  width: 100%;
}

.horizontal-items-table tr {
  float: left;
  width: 100%;
}

.horizontal-items-table tr th,
.horizontal-items-table tr td {
  float: left;
  width: 15% !important;
}

.discount-added tr th,
.discount-added tr td {
  float: left;
  width: 15% !important;
}

/* .horizontal-items-table tr th:nth-child(1),
.horizontal-items-table tr td:nth-child(1),
.horizontal-items-table tr th:nth-child(2),
.horizontal-items-table tr td:nth-child(2) {
  width: 20% !important;
  float: left;
} */
/* .sales-order-summary-main .table.details-table td:last-child {
  padding-right: 1.6rem !important;
} */

.horizontal-items-table.add_credit_note.list-table.form-table
  tr
  th:nth-child(1),
.horizontal-items-table.add_credit_note.list-table.form-table
  tr
  td:nth-child(1) {
  width: 25% !important;
  float: left;
  font-size: 13px;
}

.horizontal-items-table.add_credit_note.list-table.form-table
  tr
  th:nth-child(2),
.horizontal-items-table.add_credit_note.list-table.form-table
  tr
  td:nth-child(2) {
  width: 15% !important;
  float: left;
  font-size: 13px;
}

.horizontal-items-table.add_credit_note.list-table.form-table tr th,
.horizontal-items-table.add_credit_note.list-table.form-table tr td {
  width: 12% !important;
  float: left;
  font-size: 13px;
}

.horizontal-items-table tr th:nth-child(1),
.horizontal-items-table tr td:nth-child(1) {
  width: 33% !important;
  float: left;
}

.horizontal-items-table tr th:nth-child(2),
.horizontal-items-table tr td:nth-child(2) {
  width: 20% !important;
  float: left;
}

.horizontal-items-table tr th:nth-child(3),
.horizontal-items-table tr td:nth-child(3),
.horizontal-items-table tr th:nth-child(4),
.horizontal-items-table tr td:nth-child(4) {
  width: 10% !important;
}

.horizontal-items-table tr th:nth-child(5),
.horizontal-items-table tr td:nth-child(5) {
  width: 12% !important;
}

.add_sales_reurn.horizontal-items-table.form-table.list-table thead tr th,
.add_sales_reurn.form-table.list-table tbody tr td {
  float: left;
  width: 17.5% !important;
}

.add_sales_reurn.horizontal-items-table.form-table.list-table
  thead
  tr
  th:first-child,
.add_sales_reurn.form-table.list-table tbody tr td:first-child {
  float: left;
  width: 30% !important;
}

.horizontal-items-table .searchable-dropdown.medium,
.horizontal-items-table .searchable-dropdown.small {
  min-width: 100%;
  word-break: break-word;
}

.horizontal-items-table .searchable-dropdown .searchable-dropdown__control {
  font-size: 13px;
}

.horizontal-items-table .form-table .form-field,
.horizontal-items-table .form-field {
  min-width: 100% !important;
}

.horizontal-items-table .list-table--amount {
  min-width: 15% !important;
}

.horizontal-items-table tr th:last-child {
  text-align: right;
}

/* .discount-added .list-table--amount {
  min-width: 15% !important;
} */

.sales-return tr th,
.sales-return tr td {
  float: left;
  width: 20% !important;
}

.horizontal-items-table .list-table--item--col {
  min-width: 100%;
  font-size: 13px;
}

/* Discount added Start */

.discount-added thead,
.discount-added tbody {
  float: left;
  width: 100%;
}

.discount-added tr {
  float: left;
  width: 100%;
}

.discount-added tr th,
.discount-added tr td {
  float: left;
  width: 15% !important;
}

.discount-added tr th:last-child,
.discount-added tr td:last-child {
  width: 10% !important;
  min-width: 10% !important;
  max-width: 10% !important;
  text-align: right;
}

/* .discount-added tr th,
.discount-added tr td {
  float: left;
  width: 11% !important;
} */

.discount-added tr th:nth-child(1),
.discount-added tr td:nth-child(1) {
  width: 26% !important;
  float: left;
}

.discount-added tr th:nth-child(2),
.discount-added tr td:nth-child(2) {
  width: 20% !important;
  float: left;
}

.discount-added tr th:nth-child(3),
.discount-added tr td:nth-child(3),
.discount-added tr th:nth-child(5),
.discount-added tr td:nth-child(5) {
  width: 10% !important;
}

.discount-added tr th:nth-child(4),
.discount-added tr td:nth-child(4),
.discount-added tr th:nth-child(6),
.discount-added tr td:nth-child(6) {
  width: 12% !important;
}

.discount-added .searchable-dropdown.medium,
.discount-added .searchable-dropdown.small {
  min-width: 100%;
}

.discount-added .searchable-dropdown .searchable-dropdown__control {
  font-size: 13px;
}

.discount-added .form-table .form-field,
.discount-added .form-field {
  min-width: 100% !important;
}

.discount-added .list-table--amount {
  min-width: 11% !important;
}

.discount-added tr th:last-child {
  text-align: right;
}

.discount-added .list-table--amount .close-btn.button,
.discount-added .with-close--btn .close-btn.button {
  left: 100% !important;
}

.discount-added .list-table--item--col {
  min-width: 100%;
  font-size: 13px;
}

/* Discount Added End */

/* .horizontal-items-table td {
  height: auto;
} */

.bill tr th,
.bill tr td {
  width: 12% !important;
}

.bill .list-table--amount {
  min-width: 12% !important;
}

.horizontal-items-table .form-input.xs,
.discount-added .form-input.xs {
  min-width: 50%;
  max-width: 50%;
}

.discount--dropdown .searchable-dropdown__control {
  margin: 0 !important;
}

/* .horizontal-items-table .searchable-dropdown.xs {
  min-width: 50%;
} */

.horizontal-items-table .side-data--right {
  width: 50%;
}

.horizontal-items-table .side-data--right .searchable-dropdown.xs {
  min-width: 100%;
  width: 100% !important;
}

.horizontal-items-table .form-input.small {
  width: 100% !important;
  min-width: 100% !important;
}

.discount-added .side-data--right {
  width: 50%;
}

.discount-added .side-data--right .searchable-dropdown.xs {
  min-width: 100%;
  width: 100% !important;
}

.discount-added .form-input.small {
  width: 100% !important;
  min-width: 100% !important;
}

.section-header--title-icon {
  float: left;
}

.tabs-section {
  float: left;
  width: 100%;
  /* height: calc(100vh - 10vh);
background: #FFF;
overflow: auto; */
}

.tabs-section .left-tabs-section {
  float: left;
  width: 35%;
  height: calc(100vh - 22vh);
  background: #fff;
}

.tabs-section .right-tabs-section {
  float: left;
  /* padding: 0px 15px; */
  /* overflow: overlay; */
  width: 100%;
  /* padding: 0px 15px; */
  /* overflow: overlay; */
}

.left-tabs-section .layout--box {
  box-shadow: none !important;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid var(--border-dark);
}

.left-tabs-section .layout--box:last-child {
  border-bottom: 0;
}

.tabs-section .right-tabs-section .contact-transaction {
  height: 100%;
  overflow: auto;
}

.tabs-section .right-tabs-section .right-tabs-section-inner {
  float: left;
  width: 100%;
  /* background: #fff; */
  border-radius: 4px;
  /* height: calc(100vh - 22vh); */
}

.tab-component--content {
  padding: 0 10px;
  /*max-height: 45vh;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;*/
}

/* .tabs-section
  .right-tabs-section
  .right-tabs-section-inner
  .tab-component--content { 
  overflow: unset;
float: left;
} */

.tabs-section .list-table th:last-child,
.tabs-section .list-table td:last-child {
  padding-right: 1.2rem !important;
}

.packages-table-data td tr:nth-child(3),
.packages-table-data td tr:last-child {
  text-align: right;
  padding-right: 0 !important;
}

#dateFrom {
  cursor: default;
}

#dateTo {
  cursor: default;
}

.right-section-box {
  padding: 1.6rem 1.4rem !important;
}

.right-section-box table tbody tr td:nth-child(3),
.right-section-box table tbody tr td:last-child {
  padding-right: 0 !important;
  text-align: right !important;
}

.right-section-box .custom-dropdown .button {
  padding-right: 0 !important;
  text-align: right;
}

.images-uploading {
  /* float: left; */
  width: 100%;
}

.images-uploading .form-field {
  float: left;
  width: 12%;
}

.images-uploading .form-field .FileUploader_fileUploader__2qxre {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0 !important;
}

.FileUploader_fileUploader__2qxre {
  background-color: transparent !important;
}

.images-uploading .form-field .FileUploader_fileUploader__2qxre svg {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  transition: 0.3s;
}

.images-uploading .form-field .FileUploader_fileUploader__2qxre:hover svg {
  height: 24px !important;
  width: 24px !important;
}

.FileUploader_fileUploader__2qxre {
  border-color: var(--icon-primary) !important;
}

/* .FileUploader_fileUploader__2qxre:hover{
  background-color: #306ab4 !important;
} */

.images-uploading .ImagePreview_imagePreviewContainer__2bW7B {
  float: left;
  width: 88%;
  overflow: auto;
  overflow-y: hidden;
}

.right_align_box .layout--box table tbody tr td:nth-child(3),
.right_align_box .layout--box table tbody tr td:nth-child(4) {
  padding-right: 0;
  text-align: right;
}

.right_align_box .layout--box {
  padding: 1.6rem 1.4rem !important;
}

.right_align_box .layout--box table tbody tr td button {
  padding-right: 0 !important;
}

/* .list-table--sort-header span:after{
    content: '\25BE';
    color: var(--blue-500-active);
    margin-left: 0.4rem;
    position: absolute;
    font-size: 30px;
    top: -12px !important;
    left: -25px !important;
}
.descending:after{font-size: 24px;} */

.medium_large > div > input {
  width: 229px !important;
}

.mr_10 {
  margin-right: 45px !important;
}

.medium_large > textarea {
  width: 500px !important;
  height: 220px !important;
}

.mt_5 {
  margin-top: 5px !important;
}

.mt_178 {
  margin-top: -178px;
}

.mt_178 .input .full input {
  width: 100% !important;
}

.mr_0 {
  margin-right: 0 !important;
}

.details_icons {
  float: left;
  margin-right: 8px;
}

.item_adjustment_table .sub_tabs_transaction_main thead th:last-child {
  float: none !important;
}

.item_adjustment_table .sub_tabs_transaction_main thead th {
  line-height: 1.42857143 !important;
}

.item_adjustment_table .list-table--item--col {
  max-width: 35rem !important;
}

.item_adjustment_table table {
  position: relative;
}

.item_adjustment_table table thead th {
  height: 43px !important;
  position: sticky !important;
  top: -1px;
  z-index: 1;
  background: #e9e9e9;
}

.height_custom_adjustment {
  max-height: calc(100vh - 533px);
  overflow: auto;
  float: left;
  width: 100%;
  padding-left: 20px;
}

.css-1n7v3ny-option {
  font-weight: 400;
  /* background-color: transparent !important; */
  /* background-color: #dfe1e5 !important; */
}
html body .page-view-dropdown-container .css-1n7v3ny-option:hover,
.page-view-dropdown-container .searchable-dropdown__option--is-selected,
.searchable-dropdown__option:hover,
.form-table--container .searchable-dropdown__option--is-focused,
.form-table--container .searchable-dropdown__option:hover,
.asyncPaginateParent .css-1n7v3ny-option {
  background-color: #dfe1e5 !important;
}
html body .page-view-dropdown-container .css-1n7v3ny-option {
  background-color: transparent !important;
}
.css-yt9ioa-option {
  font-weight: 400;
}

.css-9gakcf-option {
  font-weight: 400;
  background-color: transparent !important;
  color: #000 !important;
  position: relative;
}
.delete_icon {
  width: 1.3rem !important;
}

.salesreturn_popup .searchable-dropdown__control {
  max-width: 290px;
  width: 100%;
}

@media screen and (min-width: 21px) and (max-width: 1600px) {
  /* .horizontal-items-table tr th,

  .horizontal-items-table tr th,
  .horizontal-items-table tr td {
    font-size: 12px;
  } */
}

.invoices_desc {
  float: left;
  width: 100%;
}

.text_right {
  text-align: right;
}

.invoices_desc .invoices_desc_right_child {
  float: left;
  width: 40%;
}

.invoices_desc .invoices_desc_right_child .left_Sec {
  float: left;
  width: 50%;
  /* margin-bottom: 10px; */
  color: #000 !important;
}

.invoices_desc .invoices_desc_right_child .text_underline {
  color: #306ab4 !important;
}

.invoices_desc_left_child {
  float: left;
  width: 60%;
}

.invoices_desc_right_child {
  float: left;
  width: 40%;
}

.align_top {
  position: relative;
  top: -2px;
}

.float-left {
  float: left;
}

.w-100 {
  width: 100%;
}

.w-5 {
  width: 5%;
}

.w-95 {
  width: 95%;
}

.font_bold {
  font-weight: bold;
}

/*for resoleving an Issue in Table  */

.bill_2 {
  color: #33a348;
  font-size: 16px;
  padding: 10px 0px;
  font-weight: 500;
}

.border_bottom_custom {
  border-bottom: 1px solid #dfe1e5;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.invoices_desc_left_child .color_custom {
  padding: 5px 0px;
  word-break: break-all;
}

.left_sender_Sec .icon {
  /* float: left;
  width: 6%; */
  margin-right: 10px;
}

.left_sender_Sec span {
  /* float: left;
  width: 94%; */
}

.left_sender_Sec span:first-child {
  text-decoration: underline;
}

.invoices_desc_left_child .color_custom .icon {
  float: left;
  /* width: 10%; */
  margin-right: 10px;
}

.invoices_desc_left_child .color_custom span {
  float: left;
  width: 90%;
  word-break: break-word;
}

.invoices_desc_right_child .bt_custom:not(:last-child) {
  border-bottom: 1px solid #dfe1e5;
}

.invoices_desc_right_child .left_Sec {
  padding: 5px 0px;
}

/* .list-table th:not(:first-child) {
  padding: 1.6rem 0 1.6rem 1.2rem;
  height: 36px;
  line-height: 22px;

} */
.add-item-variant.list-table th,
.invoice-table-set .list-table th {
  /* padding: 1.6rem 0 1.6rem 1.2rem; */
  height: 36px;
  line-height: 22px;
}

.invoices_desc_right_child .left_Sec:nth-child(2) {
  text-align: right;
}

/* .invoices_desc_left_child .color_custom:not(:first-child) svg{
  margin-right: 5px;
} */

.custom_padding {
  padding: 10px;
}

.custom_paddddding {
  padding: 10px 1px;
}

.padddding_right {
  padding-right: 11% !important;
}

.invoice_details {
  /* float: left; */
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.invoice_details .left_sender_Sec {
  /* float: left;
  width: 70%; */
  display: flex;
  flex: 0 0 70%;
  width: 100%;
}

.invoice_details .left_sender_Sec .image {
  /* float: left;
  width: 20%;
  padding: 4px 10px;
  height: 8rem; */
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.invoice_details .left_sender_Sec .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.invoice_details .left_sender_Sec .desc {
  /* float: left;
  width: 80%; */
  flex: 1;
}

.invoice_details .left_sender_Sec .des-row {
  display: flex;
}

.bt_custom .section-details--info-title {
  font-size: 14px;
}

.mb_2 {
  margin-bottom: 8px;
}

.bg_ {
  background-color: #f9f9f9;
  padding: 10px 20px 10px 10px;
}

.text_underline {
  text-decoration: underline;
  color: #306ab4 !important;
}

/* .invoice_section .close-icon-pos {
  top: -1px !important;
} */

.heading_color_custom {
  color: #33a348 !important;
}

.invoice_details .right_sender_Sec {
  /* float: left;
  width: 30%; */
  display: flex;
  flex: 0 0 30%;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
}

.right_sender_Sec_heading {
  float: left;
  width: 100%;
  text-align: right;
  font-size: 23px;
  color: #5d5d5d;
  font-weight: 500;
  text-transform: uppercase;
}

.table_view_invoice .table-header {
  box-shadow: 0 0 10px #ccc;
}

.table_view_invoice .table-header tr {
  border-left: 1px solid var(--border-dark);
  border-right: 1px solid var(--border-dark);
}

.table_view_invoice .table-header th {
  background-color: #fff;
  line-height: 1.42857143 !important;
}

.mw {
  max-width: 100% !important;
}

.table_view_invoice tbody tr:nth-child(even) {
  background-color: #fcfcf9;
}

.border_top_light {
  border-top: 1px solid var(--border-light) !important;
}

.adjust_grid_gap .section-details--footer-amount > div {
  grid-template-columns: 4fr 1fr;
}

.credit-note-summary.adjust_grid_gap .section-details--footer-amount > div {
  grid-template-columns: 5fr 1fr;
}

.table_view_invoice .list-table--item--col span {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* height: 130px; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  /*white-space: pre-line;*/
  /* word-break: break-word;
  text-align: justify; */
  white-space: break-spaces;
}

.list-table--item--col span {
  width: 100%;
  /* height: 130px; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap */
  word-break: break-word;
  white-space: break-spaces;
}

.invoice_number {
  float: left;
  width: 100%;
  text-align: right;
}

.transaction_Dropdwon button {
  align-items: center;
  height: 3.2rem;
  background: #306ab4 !important;
  color: #fff;
  padding: 0.8rem 2rem 0.8rem 1.8rem;
  border-radius: 2px !important;
  outline: none;
  border: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
  line-height: 1;
  transition: 0.3s;
  position: relative;
  color: #fff;
  width: fit-content !important;
  margin: 0 5px;
  font-size: 14px !important;
  border-left: 0;
}

.transaction_Dropdwon button:hover {
  background: #fff !important;
  color: #306ab4;
  box-shadow: var(--hover-box-shadow);
}

.mr-10 {
  margin-right: 10px;
}

.mr-10 button {
  padding: 1rem 0 1rem 1rem !important;
}

/*.pl_ep thead tr th:nth-child(6){
      padding-left: 1% !important;
    }*/

.pl_cp thead tr th:nth-child(2) {
  width: 18%;
}

.pl_cp thead tr th:nth-child(5) {
  padding-left: 7% !important;
}

.pl_ep thead tr th:nth-child(2) {
  width: 7%;
}

.pl_ep thead tr th:nth-child(3) {
  width: 16%;
}

.pl_ep thead tr th:nth-child(4) {
  width: 16%;
}

.pl_ep thead tr th:nth-child(5) {
  width: 50%;
}

.color_custom {
  color: #000;
}

.transaction_Dropdwon .custom-dropdown-container {
  left: 4px;
  top: 3.3rem !important;
  width: 100%;
}

.transaction_Dropdwon .custom-dropdown--options {
  background-color: #fff !important;
  padding: 10px 0px !important;
  height: 30px !important;
  box-shadow: none !important;
}

.transaction_Dropdwon button.active {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.transaction_Dropdwon button svg {
  margin-left: 6px;
  /*margin-right: 3px;*/
}

.contacts_address {
  display: block !important;
  float: left;
  width: 100%;
}

.contacts_address .card:first-child {
  width: 100% !important;
  min-height: 5rem;
  flex: 0 0 100% !important;
}

.ims-modal-container .contacts_address .card:first-child {
  width: 100% !important;
  min-height: 5rem;
}

.ims-modal-container .right-tabs-section .list-table--wrapper {
  position: relative;
  min-height: 5rem;
  float: left;
  width: 100%;
  height: calc(100vh - 346px);
  overflow: auto;
}

/* .contacts_address .card{height: 250px;} */

.contacts_address .card:first-child {
  height: auto !important;
}

.contact_person .contacts_address .card:first-child {
  height: auto !important;
}

.contact_person .contacts_address .card {
  height: 300px;
}

.contacts_address .card .card-add {
  position: absolute;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
  color: #000 !important;
  padding-left: 0 !important;
}

.contacts_address .card .card-add .avatar,
.contacts_address .card .card .avatar {
  min-height: 2rem !important;
  max-height: 2rem !important;
  min-width: 2rem !important;
  max-width: 2rem !important;
  background: var(--icon-primary);
  margin-right: 1.5rem;
}

.contacts_address .card .card-add svg {
  height: 2.5rem;
  width: 2.5rem;
  position: relative;
  /* left: -17px; */
  top: -2px;
  padding: 3px;
  transition: 0.3s;
  margin-right: 5px;
}

/* .contacts_address .card .card-add svg path {
  fill: #000;
} */

/* .contacts_address .card .card-add svg:hover {
  background-color: #306ab4;
} */

/* .contacts_address .card .card-add svg:hover path {
  fill: #fff;
} */

.card-body div,
.card-add div {
  color: #000;
  font-weight: 500;
}

.contacts_address .card {
  float: left;
  /* width: 33.33%; */
  border: 0 !important;
  width: 33%;
}

.contacts_address .card .card-header {
  border-bottom: 0 !important;
  padding-left: 0;
}

.list-table--options {
  text-align: left;
  padding-left: 0px !important;
}

.list-table--settings {
  text-align: right;
  padding-right: 10px !important;
}

.invoices_desc_right_child .left_Sec:nth-child(5),
.invoices_desc_right_child .left_Sec:last-child {
  margin-bottom: 0 !important;
}

.alphabatical_listing {
  position: absolute;
  right: 0px;
  top: 44px;
  width: 35px;
  text-align: center;
  background-color: #f5f5f5;
  height: calc(100vh - 142px);
}

.alphabatical_listing td {
  scroll-behavior: smooth;
  position: fixed;
  margin-bottom: 0;
  cursor: pointer;
  width: 35px;
  text-align: center;
  background-color: #f5f5f5;
  -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  height: 100%;
}

.alphabatical_listing td a {
  scroll-behavior: smooth;
  cursor: pointer;
  transition: 0.3s;
  color: #000;
  height: 3.1%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.alphabatical_listing td a:hover {
  font-weight: 900;
  font-size: x-large;
  /* margin-right: 10px; */
  padding-right: 7px;
  scroll-behavior: smooth;
  border-radius: 7px;
  backface-visibility: hidden;
  background-color: #fff;
}

.hoverstyle {
  font-weight: 900;
  font-size: x-large;
  padding-right: 7px;
  scroll-behavior: smooth;
  border-radius: 7px;
  backface-visibility: hidden;
  background-color: #fff;
}

.menu-component--menu.menu_top {
  white-space: initial;
}

.name_align {
  display: flex;
  align-items: center;
  margin-left: 4px;
  color: #000;
}

.list-table--link span {
  color: #000;
}

.scrollbar_style {
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
  /* overflow: auto; */
  white-space: pre-wrap;
}

.custom_svg_setting {
  width: 22px !important;
  left: 8px !important;
  top: 4px !important;
}

.delelte_shipment {
  width: 26px !important;
  height: 20px !important;
  top: 2px !important;
  left: 3px !important;
}

.shipment_modal .ims-modal {
  width: 35% !important;
  min-height: 320px;
}

.bill-payments_menu tbody tr td:nth-child(4) {
  padding-left: 4.5% !important;
}

.bill_listing .status span.pointer,
.Cn-listing-main .status span.pointer,
.expanded_view_bill .status span.pointer,
.expanded_view_creditnote .status span.pointer,
.expanded_view_sale_return .status span.pointer,
.fullscreen.sale_return_listing .status span.pointer,
.expanded_view_invoice .status span.pointer,
.package-list-compressed .status span.pointer,
.invoice-listing-fullscreen th.status span.pointer {
  display: block;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 21px) and (max-width: 1600px) {
  /* .horizontal-items-table tr th,

  .horizontal-items-table tr th,
  .horizontal-items-table tr td {
    font-size: 11px;
  } */

  html body span.status {
    width: 75px;
    font-size: 10px !important;
  }
  html body .sales_return_popup span.status {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80px;
  }
}

@media screen and (min-width: 21px) and (max-width: 1300px) {
  .search-holder {
    width: 438px;
  }

  .search-head
    .search-bar--dropdown
    .custom-dropdown.button-text
    .button-active,
  .search-bar
    .search-bar--dropdown
    .custom-dropdown.button-text
    .custom-dropdown-container {
    width: 438px;
    min-width: 438px !important;
  }
}

.option--with-image {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.option--with-image .image {
  float: left;
  width: 20%;
  height: 3.5rem;
}

.option--with-image .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.option--with-image .desc {
  float: left;
  width: 80%;
  color: #000;
  padding: 0 1rem;
  font-weight: 400;
  font-size: 13px;
  word-break: break-word;
}

.searchable-dropdown__option {
  float: left;
}

/* Start : to remove increase decrease arrows from input field */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}

.receive_item_name_ {
  float: left;
  width: 526px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 526px;
  text-overflow: ellipsis;
  display: block;
}

.attachemnt_buttons .custom-dropdown-container div:last-child {
  border-top: 1px solid #efefef;
}

.searchable-dropdown .searchable-dropdown__value-container {
  padding: 0 1rem;
}

.feilds_so .searchable-dropdown .searchable-dropdown__value-container {
  padding: 0;
}

.ims-modal-container #printSO,
.ims-modal-container #printPackage,
.ims-modal-container .credit_note_details,
.ims-modal-container #printSR {
  width: 100% !important;
  /* margin-bottom: 41px !important; */
  float: left;
}

.ims-modal-container table td[data-heading='Warehouse'] {
  width: 22%;
}

.details-table td[data-heading='Warehouse'] {
  width: 22%;
}

.ims-modal-container table td[data-heading='Discount'] {
  width: 80px;
}

.details-table td[data-heading='Discount'] {
  width: 80px;
  text-align: center;
}

.ims-modal-container table td[data-heading='SKU'] {
  width: 125px;
}

.details-table td[data-heading='SKU'] {
  width: 125px;
}

.ims-modal-container table td[data-heading='Quantity'] {
  width: 14%;
}

HEAD .details-table td[data-heading='Quantity'],
.details-table td[data-heading='Amount'] {
  width: 11%;
}

.details-table td[data-heading='Price'] {
  width: 10%;
}

.invoice-summary.details-table td[data-heading='Price'] {
  width: 11%;
  /* padding-right: 1.5rem !important; */
}

html body .invoice-summary.table.details-table td:last-child {
  padding-right: 2rem !important;
}

.sales-order-summary-main .details-table td[data-heading='Price'] {
  width: 11%;
  /* padding-right: 16px; */
}

.details-table td[data-heading='.Qty'] {
  width: 14%;
}

.lopi th.status {
  display: revert !important;
  font-size: 14px !important;
  width: 90px !important;
  text-align: center !important;
}

.item_modal_dec.global_textarea {
  height: 60px;
  overflow: unset;
  resize: unset !important;
}

.po-details-main .table.details-table td:last-child,
.po-details-main .details-table td[data-heading='Price'] {
  width: 11%;
  padding-right: 1.7rem !important;
}

.searchable-dropdown__menu {
  border: 1px solid #dddddd;
  /*background-color: #C0C0C0;*/
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
}

.purchase-order-main .details-header--info {
  word-break: break-word;
}

.po-details-top {
  float: left;
  width: 100%;
  background: #f9f9f9;
}

.po-details-top .package_address {
  margin-top: 0 !important;
}

.contact-modal-width .pl-contacts {
  padding-left: 0.5rem;
}
@media (max-width: 1800px) {
  .invoice-summary.table.details-table td:last-child {
    padding-right: 0.6rem !important;
  }
}

@media screen and (max-width: 1536px) {
  .alphabatical_listing td a {
    height: 3%;
  }
}

@media (max-width: 1600px) {
  .pl_cp thead tr th:nth-child(2) {
    width: 21%;
  }

  .invoice-summary.details-table td[data-heading='Price'] {
    padding-right: 1.6rem !important;
  }

  .sales-order-summary-main .details-table td[data-heading='Price'] {
    padding-right: 9px;
  }

  .po-details-main .details-table td[data-heading='Price'] {
    width: 11.3%;
  }
}

@media screen and (min-width: 21px) and (max-width: 1400px) {
  .right-tabs-section-inner .tab-component--content .padding-summarry {
    width: 100% !important;
  }

  .sales-order-summary-main .details-table td[data-heading='Price'] {
    padding-right: 11px;
  }

  .po-details-main .details-table td[data-heading='Price'] {
    width: 12%;
  }

  .credit_data_row .section-details--info.position-relative.mtb.cn-summary-info,
  .credit_data_row .details-header {
    width: 50%;
  }
}

@media (max-width: 1366px) {
  .sales-order-summary-main .details-table td[data-heading='Price'] {
    padding-right: 3px;
  }
}

@media (max-width: 1366px) {
  .po-details-main .details-table td[data-heading='Price'] {
    width: 13%;
  }
}

@media screen and (max-width: 1280px) {
  .alphabatical_listing td a {
    height: 2.9%;
  }

  .mt_178 {
    margin-top: 0;
  }

  .pl_ep thead tr th:nth-child(2) {
    width: 15%;
  }

  .pl_ep tr .status--not-shipped {
    width: 80px !important;
  }

  .po-details-main .details-table td[data-heading='Price'] {
    width: 11%;
  }
}

.right-align {
  text-align: right !important;
}

@media (max-width: 1138px) {
  .contact-modal-width .pl-contacts .flex-25 {
    flex: 0 0 50%;
    width: 50%;
  }

  .sales-order-summary-main .table.details-table td:last-child {
    padding-right: 2rem !important;
  }

  .sales-order-summary-main .details-table td[data-heading='Price'] {
    padding-right: 10px;
  }

  .po-details-main .table.details-table td:last-child,
  .po-details-main .details-table td[data-heading='Price'] {
    width: 13% !important;
  }

  html body .po-details-main .details-table td[data-heading='Price'] {
    padding-right: 1rem !important;
  }

  html body .po-details-main .table.details-table td:last-child {
    padding-right: 0.3rem !important;
  }

  .uiux_cancel_item_popup tr td.position-relative .adjust_width {
    border: 0 !important;
  }

  .uiux_cancel_item_popup tr td.position-relative .adjust_width input {
    width: 100%;
    max-width: 100%;
  }

  html body .invoice-listing-fullscreen th:nth-child(2) {
    width: 7.4% !important;
  }

  html body .purchase-order-fullscreen.global-status-listing th:nth-child(2) {
    width: 7.4% !important;
  }

  html body .bill_listing th:nth-child(2) {
    width: 7.3% !important;
  }

  .bill_listing th:nth-child(7),
  .bill_listing td:nth-child(7) {
    padding-right: 7px !important;
  }
}

@media (max-width: 1024px) {
  .sales-order-summary-main .details-table td[data-heading='Price'] {
    padding-right: 0;
  }
  .contact-modal-width .pl-contacts .flex-25 {
    flex: 0 0 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  html body .po-details-main .details-table td[data-heading='Price'],
  .sales-order-summary-main .table.details-table td:last-child {
    padding-right: 0 !important;
  }

  html body .po-details-main .table.details-table td:last-child {
    width: 14% !important;
    padding-right: 0 !important;
  }

  .po-details-main .table.details-table td:last-child,
  .po-details-main .details-table td[data-heading='Price'] {
    width: 15% !important;
  }

  html body .invoice-listing-fullscreen th:nth-child(2) {
    width: 13% !important;
  }
  html body .fullscreen.so_main_list TH:nth-child(2) {
    width: 14.8% !important;
  }
}

.package-plans-actions .next-btn {
  max-width: 84px;
}

.uiux_cancel_item_popup .close-btn.button.button_right {
  height: 100%;
  place-items: center;
  display: grid;
}

.uiux_cancel_item_popup tr td.position-relative {
  vertical-align: middle;
}

.uiux_cancel_item_popup .list-table.form-table {
  width: 100%;
}

.ims-modal.white.large.uiux_cancel_item_popup {
  padding-right: 40px;
}

.uiux_cancel_item_popup tr td.position-relative .adjust_width {
  border: 0 !important;
}

html body .form.purchaseorder-form .middle-row {
  padding-top: 0;
}

.package-plans-actions .next-btn {
  max-width: 84px;
}

.uiux_cancel_item_popup .close-btn.button.button_right {
  height: 100%;
  place-items: center;
  display: grid;
}

.uiux_cancel_item_popup tr td.position-relative {
  vertical-align: middle;
}

.uiux_cancel_item_popup .list-table.form-table {
  width: 100%;
}

.ims-modal.white.large.uiux_cancel_item_popup {
  padding-right: 40px;
}

.create_bills_feild .middle-row {
  margin-top: 10px;
}

/*======================================
    Listing Restructure Global style            
======================================*/
.customer-payment-listing th:nth-child(2),
.fullscreen.global-status-listing th:nth-child(7),
.fullscreen.global-status-listing th:nth-child(6),
.bill_listing th:nth-child(3),
.bill_listing th:nth-child(2),
.Cn-listing-main thead tr th:nth-child(2),
.fullscreen.sale_return_listing th:last-child,
.fullscreen.sale_return_listing th:nth-child(2),
.invoice-listing-fullscreen th:nth-child(2),
.package-list-main th:nth-child(2),
.fullscreen.global-status-listing th:nth-child(2) {
  width: 5% !important;
}

.Cn-listing-main thead tr th:nth-child(3),
.fullscreen.sale_return_listing th:nth-child(6),
.fullscreen.sale_return_listing th:nth-child(4),
.fullscreen.sale_return_listing th:nth-child(3),
.invoice-listing-fullscreen th:nth-child(6),
.invoice-listing-fullscreen th:nth-child(5),
.Cn-listing-main thead tr th:nth-child(5),
.Cn-listing-main thead tr th:nth-child(6),
.invoice-listing-fullscreen th:nth-child(3),
.package-list-main th:nth-child(4),
.package-list-main th:nth-child(3),
.fullscreen.global-status-listing th:nth-child(3) {
  width: 7%;
}

html body .bill_listing th:nth-child(5),
html body .invoice-listing-fullscreen th:nth-child(4),
.fullscreen.global-status-listing th:nth-child(4) {
  width: 45% !important;
}

.package-list-main th:nth-child(5) {
  width: 71% !important;
}
.Cn-listing-main thead tr th:nth-child(4),
html body .fullscreen.sale_return_listing th:nth-child(5) {
  width: 65% !important;
}
html body .fullscreen.sale_return_listing th:nth-child(8) {
  width: 13% !important;
}
.customer-payment-listing th:nth-child(3),
.bill_listing th:nth-child(4),
.fullscreen.global-status-listing.purchase-order-fullscreen th:nth-child(3) {
  width: 8% !important;
}
.customer-payment-listing th:nth-child(3),
.customer-payment-listing td:nth-child(3)
{ 
  text-align: center;
}

html.invoice-listing-fullscreen th:nth-child(9),
.fullscreen.global-status-listing th:nth-child(8) {
  width: 15% !important;
}
.package-list-main .status a {
  text-align: center;
  width: 100%;
  justify-content: center;
}

/*======================================
    Form Restructure Global style            
======================================*/
.uiux-so-invoice-popup .modal_invoice_title_align,
.create_package,
.create_invoice.w-100,
.uiux-bills-container,
.bill-table-width,
.form-fields-container .form-field .searchable-dropdown,
.form-fields-container .form-field .form-input {
  width: 100% !important;
}

html .so-package-main .action-buttons,
.uiux_so_package_table {
  margin-right: 2.4rem !important;
}

.pr_2 {
  padding-right: 2px;
}

.form-fields-container {
  margin: 0 -8px;
}

.po-terms-and-cond {
  padding-left: 7px;
  padding-right: 7px;
}

.uiux-invoice-form .d-flex > .form-field,
.po-term-set .po-terms-and-cond,
.po-term-set .form-field,
.bills-fields-container .d-flex .form-field,
.form-fields-container > .form-field {
  padding-left: 8px;
  padding-right: 8px;
}
.bills-fields-container .form-field.error:nth-child(3) .input-feedback {
  top: 82px;
  bottom: auto;
}
.form-fields-container .form-field > label {
  margin-bottom: 0.8rem !important;
  font-size: 14px !important;
}

.form-fields-container .radio-btn label {
  font-size: 14px !important;
}

.po-term-set .po-terms-and-cond .form-field,
.form-field > .form-field {
  padding: 0;
}

.form-field .css-1fhf3k1-control {
  min-height: 40px !important;
}

.form-fields-container .radio_buttons label.form-label {
  margin-top: unset !important;
}

button.view-details-btn-1 {
  text-align: right;
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 1.2rem;
}

.details-loader {
  position: absolute;
  top: 46px;
  left: 171px;
}

.uiux-invoice-form .form-field.textarea-so-terms {
  padding-top: 0;
}

.form-field .searchable-dropdown {
  border-radius: 4px !important;
}

.invoice-inner-row .terms-po + div {
  height: 100px;
}

.invoice-textarea-height {
  height: 108px;
}

.invoice-edit-reason {
  margin-top: -15px;
}

.invoice-edit-reason .invoice-edit-reason-text-area #edit_reason{
    height: 10rem;
    scrollbar-width: thin;
    width: 100%;
  }

.terms_error .form-field.error .input-feedback {
  bottom: -1.7rem;
}

.uiux-bills-container {
  float: left;
}

.asyn-paginate-custom .css-1wa3eu0-placeholder {
  margin-left: 7px !important;
}

.asyn-paginate-custom .css-1uccc91-singleValue {
  margin-left: 6px !important;
}

.asyn-paginate-custom .css-1hwfws3 {
  padding-left: 5px !important;
}
.contact-modal-width .flex-25 {
  flex: 0 0 33.33%;
  width: 33.33%;
}

.form-fields-container
  .form-field.error
  .asyn-paginate-custom
  + .input-feedback {
  bottom: -1.7rem;
  /* font-size: 1rem; */
}

.form-fields-container.shipment-form-main .form-field.error.flex-66,
.new-recieve-payment-popup .form-field.error.flex-66 {
  padding-bottom: 20px;
}

.form-fields-container.shipment-form-main
  .form-field.error.flex-66
  .input-feedback,
.new-recieve-payment-popup .form-field.error.flex-66 .input-feedback {
  bottom: 0rem;
}
.popup-height {
  height: 810px;
  overflow: auto;
}
.popup-height-max{
  max-height: 810px;
  overflow: auto;
}
/*======================================
    Invoice popup Restructuring             
======================================*/
.uiux-so-invoice-popup .modal_invoice_title_align {
  justify-content: unset;
}

.uiux-invoice-form-main .form-table--container,
.uiux-so-invoice-popup .form-table--container {
  margin-right: 0;
}
@media (min-width: 1600px) {
  .layout--box.uiux-form-redesign {
    max-width: 72%;
  }
}
@media (max-width: 1600px) {
  .customer-payment-listing th:nth-child(3),
  .customer-payment-listing th:nth-child(2),
  .invoice-listing-fullscreen th:nth-child(2),
  .package-list-main th:nth-child(2),
  .fullscreen.global-status-listing th:nth-child(2),
  .Cn-listing-main thead tr th:nth-child(3),
  .bill_listing th:nth-child(3),
  .bill_listing th:nth-child(2),
  .fullscreen.sale_return_listing th:nth-child(6),
  .fullscreen.sale_return_listing th:nth-child(2),
  .invoice-listing-fullscreen th:nth-child(6),
  .invoice-listing-fullscreen th:nth-child(5),
  .invoice-listing-fullscreen th:nth-child(3),
  .package-list-main th:nth-child(4),
  .package-list-main th:nth-child(3),
  .fullscreen.global-status-listing th:nth-child(3) {
    width: 8% !important;
  }

  .bill_listing th:nth-child(4),
  .fullscreen.sale_return_listing th:nth-child(4),
  .fullscreen.sale_return_listing th:nth-child(3) {
    width: 9% !important;
  }

  .Cn-listing-main thead tr th:nth-child(2),
  .fullscreen.global-status-listing th:nth-child(7),
  .fullscreen.so_main_list th:nth-child(6) {
    width: 7% !important;
  }

  .Cn-listing-main thead tr th:nth-child(7),
  .fullscreen.global-status-listing.purchase-order-fullscreen th:nth-child(3) {
    width: 12% !important;
  }
  .sales_order_table_listing table thead tr th:nth-child(5) {
    padding-left: 1.5rem !important;
  }

  html body .invoice-listing-fullscreen th:nth-child(4) {
    width: 40% !important;
  }

  .invoice-listing-fullscreen td:nth-child(8) div {
    padding: 0 6px;
  }

  .Cn-listing-main thead tr th:nth-child(4) {
    width: 55% !important;
  }
  .global-status-listing {
    height: 100%;
  }
  .pagination-route.unsync-pagination-main .page-view-dropdown-container {
    width: 8rem;
  }
  .pagination-route.unsync-pagination-main
    .page-view-dropdown-container
    .searchable-dropdown {
    height: 35px !important;
  }
}

@media (max-width: 1440px) {
  .uiux-so-invoice-popup .invoices-large {
    min-width: 110rem !important;
  }
  .package-list-main th:nth-child(4) {
    width: 10% !important;
  }
  .Cn-listing-main thead tr th:nth-child(3),
  .Cn-listing-main thead tr th:nth-child(5) {
    width: 9% !important;
  }
}

@media (max-width: 1366px) {
  .fullscreen.sale_return_listing th:nth-child(4),
  .fullscreen.sale_return_listing th:nth-child(3) {
    width: 10% !important;
  }

  html body .fullscreen.sale_return_listing th:nth-child(5) {
    width: 60% !important;
  }
}

@media (max-width: 1280px) {
  .uiux-so-invoice-popup .invoices-large {
    min-width: 100rem !important;
  }

  .fullscreen.global-status-listing th:nth-child(2) {
    width: 9% !important;
  }
  .package-list-main th:nth-child(3) {
    width: 10% !important;
  }
  .package-list-main th:nth-child(5) {
    width: 60%;
  }
  .package-list-main th:nth-child(4) {
    width: 11% !important;
  }
  .invoice-listing-fullscreen th:nth-child(5) {
    width: 9% !important;
  }
  /* .package-list-main th:nth-child(2){
    width: 9%;
  } */
}

@media (max-width: 1199px) {
  .uiux-so-invoice-popup .invoices-large {
    min-width: 100% !important;
    width: 100%;
  }

  .uiux_so_package_table {
    margin-right: 0;
  }

  .layout--box.uiux-form-redesign {
    max-width: 100%;
  }

  html body .uiux_invoice_edit_form.create_invoice.w-100 {
    width: unset !important;
    min-width: 1020px !important;
  }

  .pl-contacts .flex-25 {
    flex: 33.33%;
    width: 33.33%;
  }

  html body .invoice-listing-fullscreen th:nth-child(4) {
    width: 45% !important;
  }

  .package-list-main th:nth-child(3) {
    width: 9% !important;
  }

  .creditnote_listing tr td:nth-child(7) .list-table--link {
    padding-left: 12px;
  }
}

@media (max-width: 1024px) {
  .details-loader {
    left: 209px;
  }

  html body .uiux_invoice_edit_form.create_invoice.w-100 {
    min-width: 936px !important;
  }

  .icons_sales_order {
    width: 80px;
  }

  .fullscreen.global-status-listing th:nth-child(3) {
    width: 11%;
  }

  .pl-contacts .flex-25 {
    flex: 50%;
    width: 50%;
  }
}

@media (min-width: 1260px) and (max-width: 1599px) {
  .layout--box.uiux-form-redesign {
    max-width: 96%;
  }
}
@media (max-width: 991px) {
  .customer-payment-listing th:nth-child(2) {
    width: 10% !important;
  }
}
@media (max-width: 768px) {
  .details-loader {
    left: auto;
    right: 90px;
  }
  /* .layout--box.uiux-form-redesign, */
  .salesreturn_popup .searchable-dropdown__control {
    max-width: 100%;
  }

  /* .form-fields-container{
    margin: 0;
  } */
  .item-group-form button.group_delete_btn {
    top: 6px;
  }

  .fullscreen.sale_return_listing th:nth-child(2),
  .invoice-listing-fullscreen th:nth-child(6),
  .package-list-main th:nth-child(2),
  .package-list-main th:nth-child(3),
  .fullscreen.global-status-listing th:nth-child(2) {
    width: 14% !important;
  }
  .package-list-main th:nth-child(5) {
    width: 65%;
  }
  .package-list-main th:nth-child(4) {
    width: 16% !important;
  }

  .invoice-listing-fullscreen th:nth-child(2),
  .purchase-order-fullscreen.fullscreen.global-status-listing th:nth-child(2) {
    width: 12% !important;
  }

  .pl-contacts .flex-25 {
    flex: 100%;
    width: 100%;
  }

  .invoice-textarea-height {
    height: auto;
  }

  html .so-package-main .action-buttons,
  .uiux_so_package_table {
    margin-right: 0 !important;
  }

  .invoice-listing-fullscreen th:nth-child(5) {
    padding: 0 5px !important;
  }

  .fullscreen.sale_return_listing td:nth-child(7) div,
  .invoice-listing-fullscreen td:nth-child(5) {
    text-align: center;
  }

  .fullscreen.sale_return_listing th:nth-child(7) a {
    justify-content: center;
  }

  html body .bill_listing thead tr th:nth-child(7) {
    text-align: center;
  }
  .customer-payment-listing th:nth-child(2) {
    width: 11% !important;
  }
}

/*======================
========================*/

.po-details-main .custom-padding,
.bills-table-main .custom-padding {
  padding: 15px 0 8px;
  clear: both;
}

.po-receives-menu td.pointerIn:last-child {
  text-align: center;
}

/* .bills-table-main .custom-padding{
  padding: 15px 0 8px;
  clear: both;
} */
@media (min-width: 1600px) and (max-height: 1920px) {
  html body .preference .preference-sn .sale-return-preference--label-text {
    width: 19% !important;
  }
  html body .preference .preference-sn .form-field {
    width: 81%;
  }

  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1) {
    width: 13%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 40%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(4),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5) {
    text-align: center;
    width: 14%;
  }
  /* packages menu */
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 13%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 55%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 12%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2) {
    width: 10%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 50%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 10%;
  }
}
.menu_top_inner_content
  .sub_tabs_transaction_main.po-bills-menu
  thead
  tr
  th:nth-child(4),
.menu_top_inner_content
  .sub_tabs_transaction_main.po-bills-menu
  tbody
  tr
  td:nth-child(4),
.menu_top_inner_content
  .sub_tabs_transaction_main.salesReturn_menu
  thead
  tr
  th:nth-child(6),
.menu_top_inner_content
  .sub_tabs_transaction_main.salesReturn_menu
  tbody
  tr
  td:nth-child(6),
.menu_top_inner_content
  .sub_tabs_transaction_main.salesReturn_menu
  thead
  tr
  th:nth-child(5),
.menu_top_inner_content
  .sub_tabs_transaction_main.salesReturn_menu
  tbody
  tr
  td:nth-child(5),
.menu_top_inner_content
  .sub_tabs_transaction_main.Invoices_menu
  thead
  tr
  th:nth-child(5),
.menu_top_inner_content
  .sub_tabs_transaction_main.Invoices_menu
  tbody
  tr
  td:nth-child(5),
.menu_top_inner_content
  .sub_tabs_transaction_main.Packages_menu
  thead
  tr
  th:nth-child(4),
.menu_top_inner_content
  .sub_tabs_transaction_main.Packages_menu
  tbody
  tr
  td:nth-child(4) {
  text-align: center;
}
@media (min-width: 1500px) and (max-height: 1599px) {
  html body .preference .preference-sn .sale-return-preference--label-text {
    width: 20% !important;
  }
  html body .preference .dashboard-preferences .preference-sn .form-field {
    width: 75% !important;
  }
  html
    body
    .preference
    .dashboard-preferences
    .preference-sn
    .sale-return-preference--label-text {
    width: 25% !important;
  }

  html body .preference .preference-sn .form-field {
    width: 80%;
  }

  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1) {
    width: 13%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 40%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(4),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5) {
    text-align: center;
    width: 14%;
  }
  /* packages menu */
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 13%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 55%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 12%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2) {
    width: 10%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 50%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 10%;
  }
}
@media (min-width: 1500px) and (max-height: 1920px) {
  .view-purchase-popup .menu_top_inner_content .sub_tabs_transaction_main.po-bills-menu thead tr th:nth-child(4),.view-purchase-popup .menu_top_inner_content .sub_tabs_transaction_main.po-bills-menu tbody tr td:nth-child(4) {
    width: 14%;
} 
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1) {
    width: 10%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 40%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(4) {
    width: 8%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(6),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(6),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(4) {
    width: 10%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(3) {
    width: 34%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(1) {
    width: 11%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(3) {
    width: 27%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(2) {
    width: 50%;
  }
  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(1), .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu thead tr th:nth-child(1) {
    width: 13%;
}

}
@media (min-width: 1300px) and (max-width: 1499px) {
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 14%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(4) {
    width: 11%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2) {
    width: 13%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 38.5%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 32%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(6),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(6),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(5) {
    width: 12%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(3) {
    width: 18%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(3) {
    width: 18%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(2) {
    width: 45%;
  }
  html body .preference .preference-sn .sale-return-preference--label-text {
    width: 23% !important;
  }
  html body .preference .preference-sn .form-field {
    width: 77%;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 15%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(3)
    .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2) {
    width: 13%;
  }

  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 37%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 25%;
  }
  html body .preference .preference-sn .sale-return-preference--label-text {
    width: 30% !important;
  }
  html body .preference .preference-sn .form-field {
    width: 70%;
  }
}
@media (max-width: 1199px) {
  html body .preference .dashboard-preferences .preference-sn .form-field {
    width: 75% !important;
  }
  html
    body
    .preference
    .dashboard-preferences
    .preference-sn
    .sale-return-preference--label-text {
    width: 25% !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 16%;
  }

  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2) {
    width: 14%;
  }

  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 38.5%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 22%;
  }
  html body .preference .preference-sn .sale-return-preference--label-text {
    width: 18% !important;
  }
  html body .preference .preference-sn .form-field {
    width: 72%;
  }

  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1) {
    width: 13%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 40%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(4),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5) {
    text-align: center;
    width: 14%;
  }
  /* packages menu */
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 13%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 55%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 13%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2) {
    width: 13%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 42%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }

  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.salesReturn_menu
    tbody
    tr
    td:nth-child(3) {
    width: 25%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 27%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(1) {
    width: 12%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-bills-menu
    tbody
    tr
    td:nth-child(3) {
    width: 17%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    tbody
    tr
    td:nth-child(2) {
    width: 35%;
  }
}
@media (min-width: 700px) and (max-width: 767px) {
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 16%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.po-receives-menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2) {
    width: 13%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 39%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(5),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 26%;
  }

  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(1),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(1) {
    width: 17%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(2) {
    width: 38%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(4),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Invoices_menu
    thead
    tr
    th:nth-child(5) {
    text-align: center;
    width: 14%;
  }
  /* packages menu */
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(1),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(1) {
    width: 17%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(3),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(3) {
    width: 50%;
  }
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(2),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    thead
    tr
    th:nth-child(4),
  .view-details-popup
    .menu_top_inner_content
    .sub_tabs_transaction_main.Packages_menu
    tbody
    tr
    td:nth-child(4) {
    width: 14%;
  }
}
/* Prev sold and avl style changes by sir munaim */
.create-po-package td:nth-child(4) .previous_sold-po {
  margin-top: 15px;
}
.create-po-package
  td:nth-child(4)
  .previous_sold-po.po-prev-toopltip
  > .table-extra-data,
.create-po-package td:nth-child(4) .previous_sold-po.po-prev-toopltip {
  margin-top: 0px;
}
.create-po-package
  td:nth-child(4)
  .previous_sold-po.po-prev-toopltip
  > .table-extra-data
  > div {
  margin-top: 13px;
}
.uiux-bills-container
  .bill.bill-table-width
  td:nth-child(5)
  .bill-prev-purchase
  .table-extra-data
  > div,
.create-po-package td:nth-child(3) .table-extra-data > div,
.create-po-package td:nth-child(4) .previous_sold-po > div,
.uiux-invoice-form-main
  .uiux_invoice_edit_form.horizontal-items-table
  .previous_sold
  .invoice-tooltip-main
  > div,
.uiux-so-invoice-popup
  .uiux_invoice_edit_form
  td:nth-child(4)
  .invoice-tooltip-main
  > div,
.prev-avl-field .previous_sold .table-extra-data {
  font-weight: 500;
  color: var(--text-primary);
  line-height: 15px;
  font-size: 1.1rem;
}
/* .uiux-bills-container .bill.bill-table-width td:nth-child(5) .bill-prev-purchase .table-extra-data >div {
  line-height: 16px;
} */


.ims-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 5000;
  overflow-y: auto;
}
/* Targeting WebKit-based browsers */
.ims-modal-container::-webkit-scrollbar-track {
  background-color: #efecec;
}

.ims-modal-container::-webkit-scrollbar {
  width: 9px;
}

.ims-modal-container::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #c0c0c0;
}

.ims-modal-container::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

/* Targeting Firefox */
.ims-modal-container {
  scrollbar-color: #c0c0c0 #efecec;
  scrollbar-width: thin;
}
.ims-modal-container .form-focus .close-btn.button{
  margin-left: 0px;
}

/* Targeting Edge and IE */
/* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ims-modal-container {
    scrollbar-base-color: #c0c0c0;
    scrollbar-3dlight-color: #efecec;
    scrollbar-highlight-color: #efecec;
    scrollbar-track-color: #efecec;
    scrollbar-arrow-color: #000000;
    scrollbar-shadow-color: #c0c0c0;
    scrollbar-darkshadow-color: #c0c0c0;
  }
} */
