.search-result {
  /*color: var(--black);*/
  color: black;
  /*word-break: break-all;
  margin-left: 3rem;*/
}

u{
  font-size: 15px;
  font-weight: 400;
}