.imagePreviewContainer {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  grid-column-gap: 1.6rem;
  align-content: flex-end;
  margin-bottom: 0.8rem;
  margin-left: 1.6rem;

}

.imagePreview {
  position: relative;
  height: 10rem;
  width: 10rem;
  background-size: contain;
  background-position: center;
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  background-repeat: no-repeat;
  /* border-radius: 50%; */
}

.closeIconTransition{
  transform: rotate(0deg);
  transition: .3s ease-in-out;
}
.closeIconTransition:hover{
  transform: rotate(90deg);
}

.organization__img{
  width: 100%;
  height: 100%;
  object-fit:contain ;
}
.imagePreviewContainer  .Organization_img__outer  {
  width: 100px;
  height: 100px;
  overflow: hidden;
  padding: 15px;
  /* border-radius: 50%; */
}

/* .Organization_img__outer{
  overflow: hidden;
} */