.inline-terms-and-conditions {
  display: flex !important;
  flex-direction: column !important;
}

.uiux_so_addform .searchable-dropdown .searchable-dropdown__value-container {
  padding: 0 .8rem !important;
}

.radio_buttons {
  display: flex;
}

.radio_buttons label {
  margin-bottom: 0;
}

.radio_buttons.radio-SO div {
  margin-top: 0 !important;
  margin-left: 10px !important;
}

.inline-terms-and-conditions .form-field.inline-field {
  padding-top: 0;
}


.ml-2 {
  margin-left: 2% !important;
}

.mt-10 {
  margin-top: 10%;
}


.pr-0 {
  padding-right: 0 !important;
}

.radio-SO {
  margin-top: 0.3rem;
}

.radio-SO div div {
  margin-left: 0rem !important;
}

.radio-SO div {
  margin-top: 0.5rem !important;
}

.previous_sold .form-field {
  /* margin-top: 1.5rem; */
  margin-bottom: 1.2rem;
}

.bill_error {
  margin-bottom: 0.8rem;
}

.form-field.inline-field.textarea-so-terms textarea {
  width: 22.5rem !important;
  width: 490px !important;
}

.so-mg-1 {
  margin-bottom: 1rem;
}

.saleOrder_title_svg .ims-modal--title svg {
  margin-bottom: 2px;
}

.ims-modal.white.contact-modal-width {
  width: 72.3% !important;
}

.ims-modal.white.contact-modal-width .add_contact_tabs {
  height: calc(100vh - 200px);
}

.ims-modal.white.contact-modal-width .add_contact_tabs .layout--body {
  height: calc(100vh - 300px) !important;
}

.ims-modal.white.contact-modal-width .add_contact_tabs .layout--body .layout--box {
  height: 100%;
}

.so_view_invoices .contact svg,
.sales-return-main.sales_order_set .more_details ul li:nth-child(4) svg {
  transform: rotate(-89deg);
}

.modal_BulkItems_title_align {
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  width: 15%;
}

.modal_BulkItems_title_align span svg {
  height: 20px;
  width: 16px;
}

.sales-order-dropdown button.custom-dropdown--options.dropdown-cus-set {
  width: 100% !important;
}

html body .sales-order-dropdown svg.custom-dropdown-icon {
  left: 6px !important;
}

html body .sales-order-dropdown span.label-set {
  position: absolute;
  left: 23%;
}

.create_sales_order .searchable-dropdown__placeholder {
  padding-left: 7px;
}

.create_sales_order .searchable-dropdown__input {
  padding-left: 7px;
}

.create_sales_order .searchable-dropdown__single-value {
  padding-left: 7px;
}

.middle_row_set .description_box {
  margin-right: 28px;
}

.middle-row.middle_row_set .description_box textarea {
  width: 480px !important;
}

/* .sales-order-main-set>.form-field.inline-field:nth-child(2),
.sales-order-main-set>.form-field.inline-field:nth-child(3) {
  margin-right: 0;
} */

.so-customer-address .so-customer-address-section .searchable-dropdown .searchable-dropdown__value-container {
  padding: 0 0.7rem !important;
}

.view_pending_popup td {
  padding: 1rem 0rem;
}

.view_pending_popup  a {
  text-align: center;
  display: block;
}
.view_pending_popup th:first-child {
  width: 12%;
}

.view_pending_popup th:nth-child(2),.view_pending_popup td:nth-child(2) {
  width: 18%;
 
}
.view_pending_popup th:first-child ,
.view_pending_popup td:first-child {
  padding-left: 0.8rem;
}
.view_pending_popup th:nth-child(5), .view_pending_popup td:nth-child(5){
  width:20%;
}
.view_pending_popup th:nth-child(4), .view_pending_popup td:nth-child(4){
  width: 18%;
}
.view_pending_popup th:last-child, .view_pending_popup td:last-child{
  width: 20%;
}
.btn-container {
  float: left;
  text-align: center;
  display: block;
  width: 100%;
}
.view_pending_popup th:nth-child(3), .view_pending_popup td:nth-child(3) {
  width: 15%;
  text-align: center;
}
.view_pending_popup a {
  text-align: center;
  display: block;
  max-width: 70px;
  margin: 0 2rem 0 auto;
}

.view_pending_popup th:nth-child(2),.view_pending_popup td:nth-child(2)
{
  text-align: center;
}
.view_pending_popup th:last-child, .view_pending_popup th:last-child,
.view_pending_popup th:last-child, .view_pending_popup td:last-child {
  text-align: right;
}
.view_pending_popup th:last-child, .view_pending_popup th:last-child{
  padding-right: 2rem;
}
.view_pending_popup .issued_SO_PO {
  /* max-height: 18vh; */
  max-height: 78vh;
  height: unset;
  scrollbar-width: thin;
scrollbar-color: #c0c0c0 #efecec;
}
/* .view_pending_popup .issued_SO_PO ::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px #c0c0c0;
  box-shadow: inset 0 0 6px #c0c0c0;
  background-color: #efecec;
}

.view_pending_popup .issued_SO_PO ::-webkit-scrollbar {
  width: 9px;
}
.view_pending_popup .issued_SO_PO ::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 6px #dbdbdb;
  /*box-shadow: inset 0 0 6px #dbdbdb;*/
  /* box-shadow: inset 0 0 6px #c0c0c0; */
  /* background-color: #c0c0c0; */
/* }  */
.view_pending_popup {
  max-height: 1100px !important;
  /* height:250px !important; */
}
.view_pending_popup .loader {
  max-height: 64px;
}
.view_pending_popup .empty-table{
  padding: 0.6rem;
}
.view_pending_popup th:nth-child(4), .view_pending_popup td:nth-child(4), .view_pending_popup th:nth-child(5), .view_pending_popup td:nth-child(5) {
  text-align: right;
}
.ims-modal.view_pending_popup {
  /* left: 0;
  right: 0;
  margin: 0 auto;
  top: 5rem;
  -webkit-transform: none;
  transform: none; */
  max-width: 955px;
  overflow: unset;
  /* display: flex;
  flex-direction: column;
  width: 100% !important; */

}
.ims-modal-container.pending-invoice-popup{
  overflow: auto;
}

@media (max-width: 1280px) {
  .blue {
    padding: 7px !important;
  }

  .so-addcontact-popup .pl-contacts {
    padding-left: 0;
  }

  .invoice-popup-set.UIUX-addtax-popup .form-input.large,
  .invoice-popup-set.UIUX-addtax-popup .tax-form .inline-field:nth-child(2),
  .invoice-popup-set .tax-form .inline-field:nth-child(3) input,
  .bulk_item_popup .ims-modal--title .modal_BulkItems_title_align,
  .bulk_item_popup .ims-modal--title {
    width: 100%;
  }

  .bulk_item_popup .ims-modal--title {
    padding-bottom: 5px;
  }

  .invoice-popup-set .tax-form {
    display: flex;
    flex-wrap: wrap;
  }

  .action-buttons {
    float: right;
    flex: 0 0 100%;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    margin-right: 0 !important;
  }

  .invoice-main-set button.view-details-btn {
    left: auto;
    right: 23px;
  }

  .action-buttons.So-actions {
    padding-right: 0;
  }
}

@media(min-width:891px) {
  .bulk-add-items--item-row .word-break-all {
    padding-right: 21px;
  }
}

@media (max-width: 1199px) {
  html body .sidepanel {
    width: 28%;
  }

  .add-new-tax-popup input {
    width: 100% !important;
  }
  .view_pending_popup .issued_SO_PO{
    max-height: 74vh;
  }
}

@media (max-width: 1099px) {
  .form-field.form-table--container.salesorder_main_form {
    padding-right: 20px;
  }

  .middle-row .inline-terms-and-conditions {
    padding: 0 0 0 13px;
  }

  .salesorder_add_actions .action-buttons {
    margin-right: 1.7rem !important;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(3),
  .menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr td:nth-child(3),
  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(2) {
    white-space: break-spaces;
  }

  .large.bulk.invoice-popup-set .bulk-add-items--item-row .check_icon {
    right: 4px;
    width: 16px;
  }

  .sales-order-main-set>div:not(.sales-order-inner) {
    width: 50%;
  }

  .sales-order-main-set button.view-details-btn {
    right: 2px;
    left: auto;
  }

  .sales-order-main-set+.form-field.form-table--container {
    width: 100% !important;
  }

  .purchase-order-set.purchase-order-vendor button.view-details-btn {
    left: auto;
    right: 3px;
  }

  html body .warehouse_add_new .form-field.inline-field:nth-child(5) {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .salesorder-form-set .form-field.inline-field:nth-child(odd) {
    margin-right: 15px;
  }

  .middle-row .inline-terms-and-conditions {
    padding: 0;
  }

  .salesorder-delivery-popup .ims-modal--body {
    padding-top: 0;
  }

  .so-addcontact-popup .add_contact_tabs_info h1 {
    height: 6rem;
    padding-left: 5rem;
  }

  .so-addcontact-popup .add_contact_tabs_info {
    width: 25%;
  }

  html body div.so-addcontact-popup .layout--body {
    width: 75% !important;
  }


  .view-package-popup.ims-modal.large .main-table-w,
  div.invoice-popup-set.contact-modal-width .form-field.inline-field .form-input.large {
    width: 100%;
  }

  .view-package-popup.ims-modal.large .pckage-module-inner {
    padding-right: 20px;
  }

  .salesorder-delivery-popup.ims-modal.large .delivery_modal_title,
  .new-payment-inner-popup.large .payment_modal_title {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
  }

  .salesorder-delivery-popup.ims-modal.large .delivery_modal_title span,
  .new-payment-inner-popup.large .payment_modal_title span {
    margin-right: 10px;
  }

  .so_view_invoices .left_sender_Sec .icon+span {
    padding-left: 5px;
  }

  .view_items_popup .detail_component_inner.detais-component-main {
    overflow: unset;
  }

  .cus-popup-style .package-item-info.sales_return_table {
    overflow-x: auto;
  }

  .cus-popup-style .package-item-info.sales_return_table td.right-align:nth-child(2) input {
    width: 160px;
  }

  .form-field.inline-field.description_box+.inline-terms-and-conditions {
    padding-left: 16px;
  }
}

@media (max-width: 890px) {

  .new-sales-account form.form.account-form .float-left.w-100.mb_3,
  .warehouse_add_new .form-section--body,
  .create_new_account_inventory .account-form>div,
  .add_new_tax .tax-form,
  .new-recieve-payment-popup .payment-form-set>div,
  /* .ims-modal.white.Shipment_modals_fields form, */
  .add_address_width form.address-form>div {
    display: flex;
    flex-wrap: wrap;
  }

  html body .warehouse_add_new .action-buttons {
    padding-right: 0 !important;
  }

  .salesorder-form-set .horizontal-items-table tr td:nth-child(1) {
    width: 29% !important;
  }

  .salesorder-form-set .horizontal-items-table tr td:nth-child(5) {
    width: 16% !important;
  }

  .blue {
    padding: 7px;
  }

  .ims-modal.white.address-popup-set .action-buttons {
    margin-right: 0.1rem !important;
  }

  .new-recieve-payment-popup .action-buttons,
  .shipment-popup-set-main .action-buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding: 0;
    margin-right: 0 !important;
  }

  .new-recieve-payment-popup .payment-record textarea#notes,
  .shipment-popup-set-main .text_area_postion textarea#notes {
    margin-bottom: 10px;
  }

  .invoice-popup-set .tax-form .inline-field:nth-child(3) {
    padding-right: 0;
  }

  .add_address_width form.address-form>div .form-field.inline-field:nth-child(even) .searchable-dropdown {
    width: 100%;
  }

  .form.shipment-create-form .form-field.error .searchable-dropdown,
  .shipment-popup-set-main .form-field.error .searchable-dropdown {
    margin-bottom: 10px;
  }

  .form.shipment-create-form .form-field.error .input-feedback {
    bottom: 18px;
  }

  .create_sales_order.horizontal-items-table td:nth-child(1) {
    width: 29% !important;
  }

  .create_sales_order.horizontal-items-table td:nth-child(5) {
    width: 16% !important;
  }

  .form-field.inline-field.description_box+.inline-terms-and-conditions {
    padding-left: 0;
  }

  html body .sidepanel {
    width: 39% !important;
  }

  .add_new_tax .tax-form .form-field:nth-child(1) {
    margin-bottom: 0 !important;
  }

  .form-field.inline-field.textarea-so-terms {
    margin-right: 0;
  }

  .salesorder-form .form-field.inline-field {
    display: flex;
    width: 100%;
  }

  .modal_BulkItems_title_align,
  .salesorder-form .so_feilds .searchable-dropdown.large,
  .salesorder-form .customer-name-fileds,
  form.form.salesorder-form.feilds_so .so-form-inner .searchable-dropdown.large,
  .salesorder-form .form-field.inline-field .so-customer-address.inline-field>div {
    width: 100% !important;
  }

  .bulk-add-items .right_sec .header_bulk_item .left_sec {
    width: 65%;
  }

  .bulk-add-items .right_sec .header_bulk_item .right_sec {
    width: 35%;
  }

  .bulk-add-items--item-row .list-table--item--col span {
    font-size: 12px;
    padding: 0 5px 0 7px;
  }

  .bulk-add-items--item-row .word-break-all {
    padding-right: 15px;
    width: 25%;
    font-size: 12px;
  }

  .bulk-add-items--item-row .list-table--item--col {
    max-width: 75%;
    min-width: 75% !important;
  }
}

@media (max-width: 768px) {
  .salesorder-form .form-field.form-table--container {
    width: 100% !important;
    float: left;
  }

  .salesorder-form .customer-name-fileds button.view-details-btn {
    right: 0;
    left: auto;
  }

  .salesorder-form .customer-name-fileds+.form-field.inline-field {
    margin-top: 16px;
  }

  .invoice-popup-set.UIUX-addtax-popup .form-input.large {
    width: 100%;
  }

  .action-buttons.Po-actions,
  .action-buttons.So-actions {
    padding-right: 0;
  }

  .create-so-table.create-so-form .__drag-righ {
    max-width: initial;
  }
  .view_pending_popup th:first-child{
    width: 19%;
  }
  .view_pending_popup .issued_SO_PO{
    max-height: 70vh;
  }
  .view_pending_popup th:nth-child(2), .view_pending_popup td:nth-child(2){
    width: 8%;
  }
  .view_pending_popup th:last-child, .view_pending_popup td:last-child,
  .view_pending_popup th:nth-child(4), .view_pending_popup td:nth-child(4), .view_pending_popup th:nth-child(5), .view_pending_popup td:nth-child(5){
    width: 18%;
  }
}

button.view-details-btn {
  position: absolute;
  right: auto;
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 1.2rem;
  top: auto;
  left: 62%;
  bottom: -13px;
}

.customer-name-fileds {
  display: inline-block;
  position: relative;
}

p.text-limit-set {
  font-size: 13px !important;
  word-break: break-all;
  white-space: break-spaces;
}

.salesorder-form button.view-details-btn {
  bottom: -20px;
}

.salesorder-form.so-main-form .close-btn.button {
  top: 4px;
}