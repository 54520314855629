.current-currency {
  margin-left: 0 !important;
}

.current-currency--current {
  background: var(--border-dark);
  color: var(--black);
  font-size: 1rem;
  margin-left: 1rem;
  border-radius: .2rem;
  padding: .2rem;
}
.system_settings.list-table td{
  padding: 0 0px !important;
  line-height: 6px !important;
}
.system_settings.list-table tbody tr td:first-child , .system_settings.list-table thead tr th:first-child{
  padding-left: 4rem !important;
  /* position: relative; */
}
.system_settings.list-table .current-currency--current{
  position: absolute;
  left: 50%;
  background-color: #D5F1D9 !important;
  padding: 7px;
  top: 25%;
  font-weight: bold;
}