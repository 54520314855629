.sales_order_table_listing .global-status-listing {
  height: auto;
}

.add-sales-container {
  width: 100%;
}

.nav-tabs li.active a,
.nav-tabs li.active a:focus {
  background-color: #f5f5f5;
}

div.items-workable table th.adjust-item--close-btn,
.adjust-item--close-btn {
  padding: 0;
  width: 4rem;
}

.so-list-exp-tabs .panel {
  background-color: transparent;
}

.so-list-exp {
  margin-top: -2rem;
}

.so-list-exp-tabs ul.nav.nav-tabs {
  padding: 0;
}

.so-exp-options {
  position: absolute;
  right: 0;
  top: 0;
}

.so-exp-options>.dropdown>button {
  transform: rotate(90deg);
  background: transparent;
  border: 0;
  font-size: 1.7rem;
}

.so-exp-body {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 4rem;
}

.so-list-exp-overview-row {
  display: grid;
  grid-template-columns: 17rem 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;
}

/*<-----customer address*/

.so-customer-address {
  margin: 0.8rem 0;
  display: block !important;
  float: left;
  /* display: grid;
  grid-template-columns: repeat(2, 20rem);
  grid-gap: 3rem; */
}

.so_feilds input {
  width: 225px !important;
  border-radius: 4px;
  /* padding: 0 10px !important; */
}

.so_feilds .searchable-dropdown.large {
  width: 225px;
  border-radius: 4px !important;
}

.so_feilds .searchable-dropdown.large .searchable-dropdown__control {
  width: 100%;
  border-radius: 4px !important;
  padding: 0 10px;
}

.so_feilds .so-customer-address .searchable-dropdown {
  border-radius: 4px !important;
}

.so_feilds .so-customer-address .searchable-dropdown .searchable-dropdown__control {
  border-radius: 4px !important;
}

.so_feilds .searchable-dropdown.large input {
  width: 100%;
  border-radius: 4px !important;
}

.middle-row textarea {
  width: 490px !important;
}

.mt_0 {
  margin-top: 0 !important;
}

.middle-row .inline-terms-and-conditions {
  margin-top: 30px;
  /* margin-left: 1rem; */
}

.middle-row .inline-terms-and-conditions textarea {
  width: 172px !important;
  margin-top: 0px;
}

.so-customer-address .so-customer-address-section {
  width: 225px !important;
  float: left !important;
  position: relative;
}

.so-customer-address .so-customer-address-section:first-child {
  margin-right: 3rem;
}

.so-customer-address .so-customer-address-section .form-field.inline-field {
  width: 100% !important;
}

.so-customer-address .so-customer-address-section .form-field.inline-field .searchable-dropdown.medium {
  width: 100% !important;
  border-radius: 4px !important;
}

.so-customer-address .so-customer-address-section .form-field.inline-field input {
  width: 100% !important;
}

.create_sales_order tbody tr td:nth-child(3) {
  max-width: 25rem !important;
}

.create_sales_order {
  width: 100% !important;
}

.create_sales_order td {
  width: auto !important;
}

.create_sales_order thead {
  line-height: 1.42857143;
  background: transparent !important;
}

.create_sales_order.form-table.list-table th,
.create_sales_order.form-table.list-table td {
  border: 0 !important;
}

.create_sales_order.form-table.list-table td span input {
  border: 1px solid #e0e2e5;
}

.create_sales_order tbody tr td {
  height: auto !important;
  padding: 10px 5px !important;
}

.create_sales_order tbody tr td:last-child {
  margin-top: 10px;
}

/* .create_sales_order .form-input.small {
  border-radius: 4px !important;
  border: 1px solid #e0e2e5;
} */

.discount-added .list-table--item--col span {
  max-width: 100%;
  text-overflow: unset !important;
  overflow: unset !important;
  white-space: unset !important;
}

.create_sales_order .searchable-dropdown {
  border-radius: 4px !important;
}

.create_sales_order .searchable-dropdown .searchable-dropdown__control {
  border-radius: 4px !important;
}

/* .create_sales_order tbody tr td:nth-child(6) .css-26l3qy-menu {
  width: 120px;
} */

.so-customer-address--icon {
  font-size: 1.7rem;
  box-sizing: content-box;
  cursor: pointer;
  fill: var(--icon-primary);
  position: absolute;
  right: 0;
}

.so-customer-address--icon:hover {
  /*fill: #5e5e5e;*/
  fill: var(--blue-500);
}

.so-customer-address-section {
  word-break: break-all;
}

/*customer address----->*/

/*<-----discount*/

.so-discount-head {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 1rem;
  align-items: center;
  margin: 2rem 0 1rem;
}

.so-discount-head>label.control-label {
  padding: 0;
}

/*discount----->*/

/*<-----terms and conditions radio buttons in so-add & edit*/

.so-tc-radio {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2rem;
  align-items: center;
}

.so-tc-radio .radio-container {
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  grid-gap: 1rem;
}

/*terms and conditions radio buttons in so-add & edit----->*/

/*<-----total tax in so-add & edit*/

.so-total-tax {
  text-align: right;
}

.so-total-tax-container {
  display: inline-grid;
  grid-gap: 1rem;
}

.so-total-tax-body:first-child {
  margin-top: 2rem;
}

.so-total-tax-body:last-child {
  margin-bottom: 2rem;
}

.so-total-tax-body {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

.purchase-compressed td:nth-child(4) span div,
.compressedscreen.salesorder_listing td:nth-child(4) span div {
  height: 100%;
  display: flex;
  align-items: center;
}

.purchase-compressed td .list_items_desc>.status,
.sales_order_compressed td .list_items_desc>.status {
  padding: 0;
}

.purchase-order-set .details-header--info .float-left.w-100,
.sales-return-main.sales_order_set .details-header--info .float-left.w-100 {
  display: flex;
  align-items: center;
}

/*total tax in so-add & edit----->*/

.so-selected-items-row {
  display: grid;
  grid-template-columns: minmax(8rem, 2fr) repeat(4, minmax(5rem, 1fr)) 5rem;
}

.so-adjust-item--close-btn {
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.icons_sales_order {
  float: left;
  /* width: 200px; */
  width: 150px;
  margin-left: 2%;
  margin-right: 2%;
}

.icons_sales_order div {
  float: left;
  width: 25%;
  height: 25px;
  text-align: center;
}

div.icons_sales_order>div>svg {
  /* width: 17px;
  height: 17px; */
  width: 18px;
  height: 18px;
  margin-top: 2px;
}

.sales_address {
  background-color: #f9f9f9;
  padding: 10px 0px !important;
  margin-bottom: 20px;
}

.sales_address .more_details {
  width: 50% !important;
  padding: 0 20px !important;
  /* max-height: 240px; */
  overflow: auto;
  /* overflow: auto; */
}

.sales_address .more_details span {
  width: 100% !important;
  border-bottom: 1px solid #dfe1e5;
  padding: 5px 0;
  color: #306ab4;
  font-size: 14px;
  font-weight: 400;
}

.sales_address .more_details .left_sec {
  width: 100% !important;
}

.sales_address .more_details .left_sec span {
  border: 0 !important;
  padding: 0 !important;
  color: #000;
  word-break: break-word;
  width: 90% !important;
}

.sales_address .more_details li:first-child span {
  font-weight: 500 !important;
}

.asyncPaginateParent input {
  padding-left: 6px !important;
}

.sales_order_info {
  float: left;
  width: 100%;
  /* margin-bottom: 20px; */
  padding-top: 50px;
}

.sales_order_info .details-header {
  float: left;
  width: 60%;
  display: block !important;
  border-bottom: 0 !important;
}

.sales_order_info .details-header .details-header--title {
  color: #33a348;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.sales_order_info .section-details--info-title {
  font-size: 14px;
}

.ims-modal-container .sales_order_info .details-header {
  width: 60%;
}

.ims-modal-container .sales_order_info .section-details--info {
  /* width: 50%;               commented this so to saleorder info and details header are aligned */
  width: 40%;
  padding-right: 10px;
}

.ims-modal-container .fields_data {
  width: 100%;
}

.sales_order_info .more_details {
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
}

.sales_order_info .details_row {
  width: 100% !important;
  padding: 5px 0 !important;
}

.sales_address .details_row {
  border-bottom: 0 !important;
}

.sales_order_info .details_row:last-child {
  border-bottom: 0 !important;
}

.menu_top {
  float: left;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: #fff;
  z-index: 9;
}

.menu_top ul {
  /* padding-left: 7%; */
  padding-left: 0;
}

.ims-modal-container .menu_top ul {
  padding-left: 0;
}

.salesreturn_popup .list-table.form-table {
  width: 100%;
}

.menu_top_content {
  position: absolute;
  background: #fff;
  /* z-index: 999; */
  top: 39px;
  /* max-height: 400px;
  overflow: auto; */
  z-index: 1;
  float: left;
  width: 100%;
  border-top: 2px solid #306ab4;
}

.menu_top_inner_content {
  width: 100%;
  float: left;
  /* max-height: calc(100vh - 158px);
    overflow: auto; */
}

#printMe {
  padding: 0 !important;
}

.menu_top_inner_content .activity_parent {
  /* padding-left: 7rem; */
  padding-left: 0;
}

.ims-modal-container .menu_top_inner_content .activity_parent {
  padding-left: 0;
}

.ims-modal-container .activity_new {
  padding-left: 0;
}

.menu_top_inner_content .sub_tabs_transaction_main {
  margin: 0 0 8px 0 !important;
}

table.box-table thead {
  font-size: 14.5px !important;
}

.menu_top_inner_content table tbody tr th:last-child {
  padding: 6px !important;
}

.menu_top_inner_content .sub_tabs_transaction_main thead,
.menu_top_inner_content .sub_tabs_transaction_main tbody {
  float: left;
  width: 100%;
}

.menu_top_inner_content .sub_tabs_transaction_main thead tr {
  float: left;
  width: 100%;
  /* padding-left: 10%; */
  padding-left: 3%;
}

.ims-modal-container .menu_top_inner_content .sub_tabs_transaction_main thead tr {
  float: left;
  width: 100%;
  padding-left: 4%;
}

.ims-modal-container .menu_top_inner_content .sub_tabs_transaction_main tbody tr {
  float: left;
  width: 100%;
  padding-left: 4%;
}

.menu_top_inner_content .sub_tabs_transaction_main tbody tr {
  float: left;
  width: 100%;
  /* padding-left: 10%; */
  padding-left: 3%;
}

.menu_top_inner_content .sub_tabs_transaction_main tbody tr td {
  padding: 8px !important;
}

.menu_top_inner_content .sub_tabs_transaction_main thead tr {
  background: #fff !important;
  -webkit-box-shadow: 0px 9px 5px -6px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 9px 5px -6px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 9px 5px -6px rgba(0, 0, 0, 0.21);
}

.menu_top_inner_content .sub_tabs_transaction_main thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main tbody tr td {
  float: left;
  width: 14.28%;
}

.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td {
  float: left;
  width: 16.66%;
}

.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr td {
  float: left;
  width: 16.66%;
}

.menu_top_inner_content .sub_tabs_transaction_main.payments_menu thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main.payments_menu tbody tr td {
  float: left;
  width: 16.66%;
}

.menu_top_inner_content .sub_tabs_transaction_main.Payments_menu thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main.Payments_menu tbody tr td {
  float: left;
  width: 20%;
}

.menu_top_inner_content .sub_tabs_transaction_main.receives_menu thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main.receives_menu tbody tr td {
  float: left;
  width: 20%;
}

.menu_top_inner_content .sub_tabs_transaction_main.bill-payments_menu thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main.bill-payments_menu tbody tr td {
  float: left;
  width: 20%;
}

.menu_top_inner_content .sub_tabs_transaction_main.creditnotes_menu thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main.creditnotes_menu tbody tr td {
  float: left;
  width: 20%;
}

.menu_top_inner_content .sub_tabs_transaction_main.returnReceive_menu thead tr th,
.menu_top_inner_content .sub_tabs_transaction_main.returnReceive_menu tbody tr td {
  float: left;
  width: 25%;
}

.menu_top_inner_content .sub_tabs_transaction_main.Payments_menu .custom-dropdown .button {
  padding: 0 !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.receives_menu .custom-dropdown .button {
  padding: 0 !important;
}

/* packages */

.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr td:first-child {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr td:nth-child(2) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr td:nth-child(3) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr td:last-child {
  position: relative;
  /* top: 5px; */
}

/* Invoices */

.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:first-child {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(2) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(3) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(4) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:last-child {
  position: relative;
  /* top: 5px; */
}

/* Sales Return */

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:first-child {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(2) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(3) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(4) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(6) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:last-child {
  position: relative;
  /* top: 5px; */
}

/* bill */

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr td:first-child {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr td:nth-child(2) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr td:nth-child(3) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr td:nth-child(5) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr td:nth-child(6) {
  position: relative;
  top: 3px;
}

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr td:last-child {
  position: relative;
  top: 2px;
  left: -6px;
}

.menu_top_inner_content .sub_tabs_transaction_main tbody tr td:last-child button {
  padding: 0 1rem 0 40px;
}

.menu_top_inner_content .sub_tabs_transaction_main tbody tr td:last-child button div button {
  padding: 0 1rem !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.bill-payments_menu .custom-dropdown .button {
  padding: 0 !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.creditnotes_menu .custom-dropdown .button {
  padding: 0 !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.returnReceive_menu .custom-dropdown .button {
  padding: 0 !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.Payments_menu .payment_view,
.menu_top_inner_content .sub_tabs_transaction_main.returnReceive_menu .payment_view {
  /* color:var(--blue-500) !important; */
  color: #306ab4 !important;
  font-weight: 500;
}

.menu_top_inner_content .sub_tabs_transaction_main.Payments_menu .payment_view,
.menu_top_inner_content .sub_tabs_transaction_main.returnReceive_menu .payment_view:hover {
  /* color:var(--blue-500) !important; */
  color: #306ab4 !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.receives_menu .payment_view,
.menu_top_inner_content .sub_tabs_transaction_main.returnReceive_menu .payment_view {
  color: #306ab4 !important;
  font-weight: 500;
}

.menu_top_inner_content .sub_tabs_transaction_main.bill-payments_menu .payment_view,
.menu_top_inner_content .sub_tabs_transaction_main.returnReceive_menu .payment_view {
  /*color:var(--blue-500) !important;*/
  color: #306ab4 !important;
  font-weight: 500;
}

.menu_top_inner_content .sub_tabs_transaction_main thead tr th {
  line-height: 1.42857143 !important;
  background-color: transparent !important;
}

.menu_top_inner_content .sub_tabs_transaction_main tbody tr td .second-column {
  display: none;
}

.menu_top_inner_content .layout-box--button {
  height: 40px;
}

.menu_top_inner_content .sss .custom-dropdown {
  /* top:2px !important; */
}

.menu_top_inner_content .custom-dropdown .CaretDownButton {
  font-size: 13px;
}

.menu_top_inner_content .sss .custom-dropdown .button {
  padding: 0 !important;
}

.menu_top_inner_content .sub_tabs_transaction_main tbody tr:nth-child(even) {
  background-color: #fcfcf9;
}

.overflow_custom_height {
  /*max-height: calc(100vh - 164px);*/
  overflow: auto;
  scrollbar-color: #c0c0c0 #efecec;
  scrollbar-width: thin;
}

.box_shadow_custom {
  -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.d_none {
  display: none;
}

.menu_top .nav_menu {
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
  border-bottom: 0 !important;
  position: relative;
  height: 40px;
  background-color: #fff;
  margin-top: 0;
}

.menu_top .nav_menu li {
  margin-left: 0 !important;
  margin-top: 5px;
}

.menu_top .nav_menu a {
  border-radius: 0 !important;
  background: #fff !important;
}

.menu_top .nav_menu a svg {
  margin-right: 10px;
  width: auto;
  height: auto;
}

.menu_top .nav_menu a svg.history {
  height: 14px;
  width: 14px;
}

.menu_top .nav_menu a svg.package_icon {
  height: 16px;
  width: 16px;
}

.menu_top .nav_menu a svg.invoice {
  height: 14px;
  width: 14px;
  margin-top: -4px;
}

.menu_top .nav_menu a svg.salesreturn {
  height: 16px;
  width: 16px;
  margin-top: -4px;
}

.menu_top .nav_menu li.active a {
  background: #306ab4 !important;
  color: #fff;
}

.ims-modal-container .menu_top .nav_menu li a svg {
  fill: #000;
}

.menu_top .nav_menu li.active a svg {
  fill: #fff;
}

.salesordersummary table thead {
  box-shadow: 0 0 10px #ccc;
}

/* .salesordersummary table thead tr{
  border-left: 1px solid var(--border-dark);
  border-right: 1px solid var(--border-dark);
} */

.salesordersummary table thead th {
  background: #fff !important;
}

.salesordersummary table thead th {
  line-height: 1.42857143 !important;
}

.salesordersummary table .list-table--item--col span {
  width: 100%;
  /* overflow: hidden;
  text-overflow: ellipsis;*/
  /* white-space: nowrap; */
}

.salesordersummary table tbody tr:nth-child(even) {
  background: #fcfcf9;
}

.package_button {
  background-color: #306ab4 !important;
  color: #fff;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.package_button svg {
  margin-right: 5px;
  fill: #fff !important;
  font-size: 17px;
  margin-left: 0;
}

.package_button:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #fff;
  border-left: 0px;
}

.package_button:hover svg {
  fill: #000 !important;
}

.options-dropdown {
  position: absolute;
  left: 275px;
  white-space: initial;
}

.options-dropdown button {
  font-size: 0 !important;
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  padding: 0.2rem 0.2rem !important;
  background-color: #e9e9e9 !important;
}

.secondry_buttons_group .options-dropdown .checked-menu--dropdown button svg path {
  fill: #000 !important;
}

.options-dropdown .custom-dropdown--options {
  font-size: 14px !important;
  background-color: #fff !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/* .options-dropdown .custom-dropdown--options{
  padding-left: 40px !important;
} */

.options-dropdown .custom-dropdown.button-text .button-active+.custom-dropdown-container .custom-dropdown--options {
  padding-left: 40px !important;
  padding-right: 20px !important;
}

.black-fill {
  margin-right: 3px;
  fill: #000;
  font-size: 2rem;
}

.black-fill:hover {
  fill: #000 !important;
}

.sales_order_dropdwon .custom-dropdown-container {
  width: 130px !important;
}

.sales_order_dropdwon .custom-dropdown-container .custom-dropdown--options {
  width: auto !important;
}

.sales_order_dropdwon .custom-dropdown-container button {
  left: 22%;
}

.sales_order_dropdwon .custom-dropdown-container button svg {
  margin-left: 0 !important;
  margin-right: 0 !important;
  left: -18px !important;
}

.payment_view {
  cursor: pointer;
  /* color:var(--blue-500) !important; */
  color: #306ab4 !important;
  font-weight: 500;
}

.payment_view:hover {
  text-decoration: underline !important;
}

/* .sales_order_table_listing table thead tr th:nth-child(3) {
  width: 12%;
} */

/* .sales_order_table_listing table thead tr th:nth-child(4) {
  width: 32%;
} */

/* .sales_order_table_listing .compressedscreen thead tr th:nth-child(3) {
  width: 32%;
} */

.sales_order_table_listing table thead tr th:nth-child(5) {
  /* text-align: center !important; */
  padding-left: 2.5rem !important;
  /* width: 20% !important; */
  text-align: left !important;
}

.sales_order_table_listing table thead tr th:nth-child(6),
.sales_order_table_listing table thead tr th:nth-child(7) {
  text-align: center;
}

.sales_order_table_listing table thead tr th:nth-child(6) {
  padding-left: 1% !important;
  width: 7% !important;
}

/* .menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr td[data-heading="Package No."]{
  cursor: pointer;
} */

.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr .pointerOut:first-child {
  cursor: text !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr .pointerIn:first-child {
  cursor: pointer !important;
  color: #306ab4;
  font-weight: 500;
}

.menu_top_inner_content .sub_tabs_transaction_main.Packages_menu tbody tr .pointerIn:first-child:hover {
  text-decoration: underline !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr .pointerOut:first-child {
  cursor: text !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr .pointerIn:first-child {
  cursor: pointer !important;
  color: #306ab4;
  font-weight: 500;
}

.menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr .pointerIn:first-child:hover {
  text-decoration: underline !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.creditnotes_menu tbody tr .pointerOut:nth-child(2) {
  cursor: text !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.creditnotes_menu tbody tr .pointerIn:nth-child(2) {
  cursor: pointer !important;
  color: #306ab4;
  font-weight: 500;
}

.menu_top_inner_content .sub_tabs_transaction_main.creditnotes_menu tbody tr .pointerIn:nth-child(2):hover {
  text-decoration: underline !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr .pointerOut:first-child {
  cursor: text !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr .pointerIn:first-child {
  cursor: pointer !important;
  color: #306ab4;
  font-weight: 500;
}

.menu_top_inner_content .sub_tabs_transaction_main.bills_menu tbody tr .pointerIn:first-child:hover {
  text-decoration: underline !important;
}

.align_so_modalicons {
  display: flex;
  align-items: center;
}

.customer-name-fileds .css-1uccc91-singleValue {
  padding-left: 7px;
}

.asyn-paginate-custom .css-1pahdxg-control .css-1wa3eu0-placeholder {
  margin-left: 10px;
}

.css-2b097c-container .css-1wa3eu0-placeholder {
  margin-left: 4px;
}

.uiux_invoice_edit_form .css-1wa3eu0-placeholder {
  padding-left: 0;
}

.create_sales_order tbody tr:nth-child(even) {
  background-color: #fbfbf7;
}

.create_sales_order tbody tr .number-input {
  border: 1px solid #dfe1e5;
  border-radius: 4px;
}

.create_package tbody tr:nth-child(even) {
  background-color: #fbfbf7;
}

.create_invoice tbody tr:nth-child(even) {
  background-color: #fbfbf7;
}

.add_credit_note tbody tr:nth-child(even) {
  background-color: #fbfbf7;
}

.add_sales_reurn tbody tr:nth-child(even) {
  background-color: #fbfbf7;
}

.bulk-add-items--item-row:nth-child(even) {
  background-color: #fbfbf7;
}

.bulk-add-items--item-row {
  border-bottom: 1px solid var(--border-light);
}

.issued_SO_PO table tbody tr:nth-child(even) {
  background-color: #fbfbf7;
}

/* added the above css for different color of item list in add/edit/clone form of all modules. THis will be applied to even items in listing. */

.status .salesorder_compressed_status {
  width: 135px;
}

.salesorder_compressed_status span:nth-child(2) {
  padding: 0 5px !important;
}

.salesorder_compressed_status span:nth-child(3) {
  line-height: 1.8rem;
}

/* .salesorder_listing tbody tr td:nth-child(3) {
  width: 35%;
} */

/* .salesorder_listing tbody tr td:nth-child(4) {
  width: 30%;
} */

.menu_top_inner_content .sub_tabs_transaction_main.receives_menu tbody tr .pointerOut:first-child {
  cursor: text !important;
}

.menu_top_inner_content .sub_tabs_transaction_main.receives_menu tbody tr .pointerIn:first-child {
  color: #306ab4;
  font-weight: 500;
}

.so-customer-address .so-customer-address-section .searchable-dropdown .searchable-dropdown__value-container {
  padding: 0 0.7rem !important;
  /* fix any dropdown input line related issue     */
}

.lower_so_address {
  /* padding: 0 0.7rem; */
  padding: 0 0.7rem 0 0.1rem;
}

.lower_so_address div {
  word-break: break-word;
  white-space: inherit;
  font-weight: 400;
}

.colorless {
  color: transparent;
}

.compressedscreen.salesorder_listing thead th.status {
  text-align: left !important;
  padding-left: 3rem !important;
}

.sales-return-main.sales_order_set .menu-component--menu.menu_top {
  width: 99.8%;
  margin-left: 0% !important;
}

.sales_order_table_listing {
  float: left;
  width: 100%;
  height: 100%;
}

@media (max-width: 1600px) {
  .menu_top_inner_content .sub_tabs_transaction_main.sales_table_set tbody tr td:last-child button {
    padding: 0 1rem 0 15px !important;
  }

  button.custom-dropdown--options.dropdown-cus-set {
    width: 100%;
    display: flex;
    align-items: center;
  }

  button.custom-dropdown--options.dropdown-cus-set svg {
    position: initial !important;
  }

  span.label-set {
    margin-left: 10px;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tr td:nth-child(7) button.button {
    padding: 0 1rem 0 5px !important;
  }
}

@media (max-width: 1440px) {
  .sales-return-main.sales_order_set .menu_top ul {
    padding-left: 0%;
  }

  .sales_table_set.salesReturn_menu tr.table--row {
    padding-left: 5%;
  }

  .sales-return-main.sales_order_set tr.table--row {
    padding-left: 5% !important;
  }

  .sales_order_set .menu_top_inner_content .sub_tabs_transaction_main tbody tr td:last-child button,
  .menu_top_inner_content .sub_tabs_transaction_main.sales_table_set tbody tr td:last-child button {
    padding: 0 1rem 0 0px !important;
  }

  .sub_tabs_transaction_main tr td:last-child .custom-dropdown>button {
    padding-left: 0 !important;
  }

  .sales-return-main.sales_order_set .terms-and-condition,
  .sales-return-main.sales_order_set .notes {
    padding: 0.8rem 2.4rem 0rem 0;
  }

  /* .sales_order_set .custom-dropdown-container.down {
    padding-left: 10px;
  } */
  .sales-return-main.sales_order_set .salesReturn_menu span.custom-dropdown-set,
  .sales-return-main.sales_order_set .Invoices_menu span.custom-dropdown-set,
  .sales-return-main.sales_order_set .Packages_menu span.custom-dropdown-set {
    position: relative;
    right: -40px;
  }

  .view-sales-return-So .details-header {
    width: 100% !important;
  }
}

.so-view-ttems-tabs .list-table th:nth-child(4),
.so-view-ttems-tabs .list-table th:first-child {
  width: 10%;
}

.so-view-ttems-tabs .list-table th:last-child,
.so-view-ttems-tabs .list-table th:nth-child(2) {
  width: 11%;
}

.so-view-ttems-tabs .list-table th:last-child {
  width: 11% !important;
}

.so-view-ttems-tabs .list-table th:nth-child(3) {
  width: 40%;
}

.so-view-ttems-tabs .list-table th:nth-child(5) {
  width: 18%;
}

@media (max-width: 1366px) {
  .sales-return-main.sales_order_set tr.table--row {
    padding-left: 3% !important;
  }

  .sales-return-main.sales_order_set tr.table--row td[data-heading='Return Items'],
  .sales-return-main.sales_order_set tr.table--row td[data-heading='Add To Stock'] {
    text-align: center;
  }
}

@media (max-width: 1280px) {
  .status {
    min-width: 70px !important;
    margin-right: 8px;
    width: auto !important;
    font-size: 1.1rem !important;
    padding: 0.5em 0.5em !important;
  }

  .sales-return-main.sales_order_set .sale_Order_Details {
    width: 100% !important;
  }
}

@media (max-width: 1199px) {
  .sales_order_set .transaction_table_set td.pointerIn[data-heading='Warehouse'] {
    width: 18%;
  }

  .sales_order_set .transaction_table_set td.pointerIn[data-heading='price'] {
    width: 15% !important;
  }

  .sales_order_set .transaction_table_set td.pointerIn[data-heading='Price'] {
    width: 15%;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.sales_table_set tbody tr td:last-child button>div {
    width: 78px;
  }

  .sales_order_set .custom-dropdown-container.down {
    right: 6px !important;
  }

  .sales_order_set .sales_table_set.salesReturn_menu tr th:nth-child(5) {
    width: 13%;
  }

  .sales_order_set .sales_table_set.salesReturn_menu .transaction_table_set td[data-heading='Return Items'] {
    width: 12%;
  }

  .sales_order_table_listing table thead tr th:nth-child(2) {
    width: 10%;
  }

  .fullscreen tbody tr td:nth-child(8) {
    width: 10%;
  }

  .compressedscreen.salesorder_listing th:nth-child(2) {
    width: 21%;
  }

  .bills_menu tbody tr td:last-child .button {
    padding: 0 1rem 0 5px !important;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(2),
  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu thead tr th:nth-child(2) {
    width: 24%;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(3),
  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu thead tr th:nth-child(3) {
    width: 12%;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(4),
  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu thead tr th:nth-child(4) {
    width: 14%;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(5),
  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu thead tr th:nth-child(5) {
    width: 16%;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr th:nth-child(6),
  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(6) {
    display: none;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tr th:nth-child(3),
  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(3) {
    width: 25%;
  }

  /* .sales-return-main.sales_order_set .salesReturn_menu span.custom-dropdown-set,
  .sales-return-main.sales_order_set .Invoices_menu span.custom-dropdown-set,
  .sales-return-main.sales_order_set .Packages_menu span.custom-dropdown-set {
    top: 3px;
  } */

  /* .menu_top_inner_content .sub_tabs_transaction_main tbody tr td:last-child button {
    padding: 0 1rem 0 20px !important;
  } */

  /* .status {
    min-width: 80px !important;
  } */
  .compressedscreen.purchase-compressed td:nth-child(4) {
    text-align: right;
  }

  .sales_order_set .details_row:nth-child(3) span.details_data span {
    line-height: 19px;
  }

  /* .sales_order_table_listing .list-table--wrapper {
  height: calc(100vh - 90px)!important;
} */
}

@media (min-width: 1100px) and (max-width: 1160px) {
  .white.salesreturn_popup.large.invoice-popup-set .form-field.inline-field {
    margin-right: 2rem;
  }
}

@media (max-width: 1099px) {
  .white.salesreturn_popup.large.invoice-popup-set {
    min-width: 90% !important;
    max-width: 90% !important;
    padding: 0 2rem;
  }

  .salesreturn_popup .form-input.large {
    width: 212px;
  }

  .white.salesreturn_popup.large.invoice-popup-set .form-field.inline-field {
    margin-right: 0.6rem;
  }

  .middle-row .inline-terms-and-conditions {
    width: 100%;
    overflow: hidden;
  }

  .status .salesorder_compressed_status {
    width: 107px;
  }

  .create-invoice-sec .middle-row .inline-terms-and-conditions {
    width: 48%;
    padding-left: 0;
  }

  .create-invoice-sec .middle-row {
    flex-direction: initial;
  }

  /* 
  .status {
    min-width: 85px !important;
  } */

  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu tbody tr td:nth-child(3),
  .menu_top_inner_content .sub_tabs_transaction_main.Invoices_menu thead tr th:nth-child(3) {
    width: 14%;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tr th:nth-child(3),
  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(3) {
    width: 20%;
  }

  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tr th:nth-child(5),
  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(5),
  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tr th:nth-child(4),
  .menu_top_inner_content .sub_tabs_transaction_main.salesReturn_menu tbody tr td:nth-child(4) {
    width: 17%;
  }
}

@media (max-width: 1024px) {

  .transaction_table_set td,
  .sales_order_set .transaction_tab_table.transaction-table-inner tr th {
    font-size: 13px;
  }

  .view_items_popup .item-details-images-list .image_upladed {
    width: 6.6%;
    min-width: 57px;
  }

  .package-saleorder-view .salesReturn_menu tr th:nth-child(4),
  .package-saleorder-view .salesReturn_menu tr td:nth-child(4) {
    display: none;
  }

  .package-saleorder-view .salesReturn_menu tr th,
  .package-saleorder-view .salesReturn_menu tr td {
    width: 16.5% !important;
  }

  .uiux_so-fields {
    padding: 11px 0px 10px 0;
  }

  .salesorder-form .form-field.inline-field {
    margin-right: 14px;
  }

  .salesorder-form .customer-name-fileds {
    width: 23.4%;
    margin-right: 12px;
  }

  .salesorder-form .so-customer-address .so-customer-address-section:first-child {
    margin-right: 1.5rem;
  }
}

@media (max-width: 991px) {

  .sales_order_table_listing tr td:nth-child(3),
  .sales_order_table_listing tr td:nth-child(5) {
    width: 8%;
  }

  .sales_order_table_listing tr td:nth-child(2) {
    width: 9%;
  }

  .sales_order_table_listing tr td:nth-child(4) {
    width: 34% !important;
  }

  .sales_order_table_listing tr td:nth-child(1) {
    width: 4%;
  }

  .sales_order_table_listing tr td:nth-child(6),
  .sales_order_table_listing tr td:nth-child(7) {
    width: 6%;
  }

  .sales_order_table_listing tr td:nth-child(8) {
    width: 14%;
  }

  .ims-modal.white.large.view_items_popup {
    min-width: 85%;
    max-width: 85%;
  }

  .so-view-ttems-tabs .list-table th:nth-child(2),
  .so-view-ttems-tabs .list-table td:nth-child(2),
  .sales_order_table_listing table thead tr th:nth-child(3),
  .sales_order_table_listing table tbody tr td:nth-child(3) {
    display: none;
  }

  .so-view-ttems-tabs .list-table th:last-child,
  .so-view-ttems-tabs .list-table th:nth-child(2),
  .so-view-ttems-tabs .list-table th:nth-child(4),
  .so-view-ttems-tabs .list-table th:first-child {
    width: 13%;
  }
}

@media (max-width: 890px) {
  .sales_order_table_listing table thead tr th:nth-child(2) {
    width: 13%;
  }

  .so-view-ttems-tabs .list-table th:last-child,
  .so-view-ttems-tabs .list-table th:nth-child(2),
  .so-view-ttems-tabs .list-table th:nth-child(4),
  .so-view-ttems-tabs .list-table th:first-child {
    width: 15%;
  }

  /* .so-view-ttems-tabs .list-table th:nth-child(3) {
    width: 36%;
  } */
}

@media (max-width: 768px) {
  .sales_order_table_listing table thead tr th:nth-child(3) span.pointer {
    display: none;
  }

  .sales_order_table_listing table thead tr th:nth-child(3):before {
    content: 'Order No.';
  }

  .sales_order_table_listing table thead tr th:nth-child(4) {
    width: 40%;
  }

  .sales_order_table_listing table thead tr th:nth-child(3) {
    width: 15%;
  }

  .sales_order_table_listing table thead tr th:nth-child(6) {
    padding-left: 0 !important;
    width: 10% !important;
  }

  .sales_order_table_listing table thead tr th:nth-child(8) {
    width: 30% !important;
  }

  .sales_order_table_listing table thead tr th:nth-child(6) {
    padding-left: 0 !important;
    width: 10% !important;
  }

  .sales_order_table_listing .list-table th:not(:first-child) {
    padding: 1.6rem 0.6rem 1.6rem 0rem !important;
  }

  .sales_order_table_listing .icons_sales_order {
    width: 80px;
  }

  .view_items_popup .item-details-images-list .image_upladed {
    width: 9.8%;
    max-width: 57px;
  }

  .white.salesreturn_popup.large.invoice-popup-set .form-field.inline-field {
    width: 49%;
  }

  .salesreturn_popup .form-input.large {
    width: 100%;
  }

  .expanded_view_creditnote th.list-table--settings {
    width: 20px;
  }

  .expanded_view_creditnote th.list-table--settings {
    width: 20px;
  }

  .create_sales_order tbody tr td:nth-child(5) .searchable-dropdown__option+div {
    padding: 6px 10px;

  }

  .create_sales_order tbody tr td:nth-child(5) .searchable-dropdown__option>div {
    background-color: #eeeeee;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 25px;
    line-height: 24px;
    font-size: 12px !important;
    text-align: center;
  }

  .so_feilds.uiux_so-fields {
    padding-right: 0;
  }

  html body .create-so-table.horizontal-items-table th:nth-child(1) {
    width: 28.5% !important;
  }

  .sales_address {
    padding: 10px 0px 0 0 !important;
  }

  .so-view-ttems-tabs .list-table th:last-child,
  .so-view-ttems-tabs .list-table th:nth-child(2),
  .so-view-ttems-tabs .list-table th:nth-child(4),
  .so-view-ttems-tabs .list-table th:first-child {
    width: 18%;
  }

  .so-view-ttems-tabs .list-table th:nth-child(5) {
    width: 20%;
  }

  /* .sales_order_table_listing .infinite-scroll-component.contact-infinite-scroll table, */
  .sales_order_table_listing .infinite-scroll-component.contact-infinite-scroll,
  .sales_order_table_listing .infinite-scroll-component__outerdiv {
    height: 100% !important;
  }

  /* html body .create_sales_order.horizontal-items-table thead th:first-child {
    width: 29% !important;
  } */
}

.create_sales_order tbody tr td:nth-child(5) .searchable-dropdown__option+div {
  padding: 6px 10px;
}

.create_sales_order tbody tr td:nth-child(5) .searchable-dropdown__option>div {
  background-color: #eeeeee;
  border: 1px solid transparent;
  border-radius: 4px;
  height: 25px;
  line-height: 24px;
  font-size: 12px !important;
  text-align: center;
}

.warning-alert {
  display: flex;
  clear: both;
  justify-content: center;
  color: #000;
  padding: 7px 5px;
  position: relative;
  align-items: center;
  border-color: #ffecb5;
}

.warning-alert .closebtn {
  color: #000;
  position: absolute;
  right: 15px;
  top: 10px;
}

/*========================
Total Unsync order Alert
==========================*/
.unsync-alert {
  display: flex;
  clear: both;
  justify-content: center;
  background-color: #dcffde;
  color: #202020;
  padding: 7px 5px;
  position: relative;
  align-items: center;
  /* padding: 10px 0; */
}

span.sync-count {
  padding: 0.7rem 0.8rem;
  /* font-weight: 500; */
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.475rem;
  font-size: 14px !important;
  font-weight: bold;
  background: var(--neutral-status);
  background: #D3DEE5;
  background: #33A348;
  color: #fff;
  text-align: center;
  text-decoration: none;
}


.unsync-alert .unsync-desc {
  margin-right: 10px;
  text-align: center;
}

.unsync-alert .unsync-desc p {
  margin-bottom: 0;
  text-transform: capitalize;
  text-decoration: underline;
  font-style: italic;
  font-size: 13px;
}

.unsync-alert .unsync-desc a {
  color: #306ab4;
  position: absolute;
  right: 15px;
  top: 10px;
  text-decoration: underline;
  text-transform: capitalize;
}

.unsync-alert .unsync-desc a:hover {
  color: #000;
}

html body .uiux_so_table_summary thead tr th.pointer:hover {
  background: #fff !important;
}

@media (max-width: 1024px) {
  .create_sales_order tbody tr td:nth-child(5) .searchable-dropdown__option+div {
    padding: 6px;
  }
}

.sales-order-summary-main .layout-padding {
  padding: 17px 0 10px;
  clear: both;
}

.sales_order_table_listing .list-table--wrapper {
  height: calc(100vh - 105px) !important;
}