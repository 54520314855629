.stock_row {

  /* display: flex; */
  float: left;
  width: 100%;
  /* justify-content: space-between; */
  padding: 10px 0 25px 25px;

}

.stockfields{
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}

.quantity_box{
  float: left;
  width: 33.33%;
}

.upper_text{
  font-size: 16px;
  font-weight: 600;
}

.lower_text{
  color: #33a348;
  font-weight: bold;
  font-size: 18px;
  
}
.pr-10:last-child{
  padding-right: 0 !important;
}
@media(max-width:1440px){
  .stock_mob-row span.upper_text {
    font-size: 14px;
}
}
@media(max-width:1024px){
  .stock_row.stock_mob-row > div {
    width: 100%;
    margin-bottom: 10px;
}
.stock_row.stock_mob-row > div:last-child {
    margin-bottom: 0;
}
.items_main_set .mt_1.px_5 {
  margin-bottom: 0 !important;
}
.section-details--info .margin-bottom-xs {
  text-align: initial;
}
}
@media(max-width:991px){
.quantity_row.quantity-mob-row {
  flex-wrap: wrap;
}
.quantity_row.quantity-mob-row .quantity_box {
    margin-bottom: 15px;
    width: 50%;
}
}
@media(max-width:768px){
  .quantity_row.quantity-mob-row .quantity_box{
    width: 50%;
  }
  .quantity_row.quantity-mob-row{
    padding:10px 0px 0px 25px;
  }
.quantity_row.quantity-mob-row .quantity_box:last-child {
    margin-bottom: 0;
}
.stock_row.stock_mob-row {
  padding: 10px 0 0 25px;
}
}