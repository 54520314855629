@media(max-width:1024px) {
  .UIUX-item-single .section-details--info {
    width: 100% !important;
    padding-left: 0;
  }

  .UIUX-item-single .item-details-image--current {
    max-width: 340px;
  }
}

@media(max-width:768px) {
  .UIUX-item-single .item-details-image--current {
    max-width: 298px;
  }

  .UIUX-item-single .section-details--info,
  .float-left.w-70.UIUX-item-single {
    width: 100% !important;
  }

  .UIUX-item-single button.chip_btn.chip-btn-set {
    right: 60% !important;
  }

  .detais-component-main .section-details--info,
  .UIUX-item-single .section-details--info {
    padding-right: 10px;
  }
}