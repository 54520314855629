.custom-tooltip{
  margin: 0;
  line-height: 24px;
  border: 1px solid #f5f5f5;
  background-color: #ffffff;
  padding: 10px;
  float: left;
  width: 130px;
}

.intro {
  border-top: 1px solid #f5f5f5;
  margin: 0;
  float: left;
  width: 100%;
}
.label {
  color: #000;
  float: left;
  width: 100%;
  text-align: left;
  position: relative;
  left: -5px;
  text-transform: capitalize;
}

.desc {
  margin: 0;
  /* color: rgb(3, 62, 146); */
  float: left;
  width: 100%;
  text-align: left;
}

.box_9 .custom-tooltip.sales-purchase .sales {
  color: #50a349;
}

.box_9 .custom-tooltip.sales-purchase .purchase {
  color: #306AB4;
}