.sale-return-preference label{
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
  /* width: 30rem; */
}

.sale-return-preference--label-text {
  margin: unset !important;
  padding-left: 0rem;
  padding-right: 2rem;
}