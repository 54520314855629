.tax-list {
  margin: 3rem 0;
}

.payment_modal_title {
  display: flex;
  justify-content: space-between;
  width: 25%;
  /* align-items: center; */
}

.edit_payment_modal_title {
  display: flex;
  justify-content: space-between;
  width: 19%;
  /* align-items: center; */
}

.add_new_payment svg {
  height: 17px;
  width: 17px;
}

.add_new_payment .form-field.inline-field {
  margin-bottom: 20px !important;
}

.add_new_payment .action-buttons {
  margin: 0 !important;
  padding: 1.5rem 0 !important;
  border-top: 1px solid var(--border-light);
  float: left !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.payment_mode_table_icon {
  top: 5px !important;
}
@media(max-width:1260px) {
  .add_new_payment .edit_payment_modal_title, .add_new_payment .payment_modal_title {
    display: flex;
    align-items: center;
    width: auto !important;
    justify-content: flex-start;
  }
  .add_new_payment .edit_payment_modal_title span, .add_new_payment .payment_modal_title span {
    margin-right: 10px;
  }

}
@media(max-width:890px) {
  .add_new_payment span.payment_modal_title {
    width: 36%;
    display: flex;
    align-items: center;
  }
}