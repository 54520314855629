.card {
  width: 100%;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  background-color: white;
  position: relative;
  word-break: break-word;
  /* New UI In Overview tab extra spacing */
  /* min-height: 28rem; */
}

/* .card:hover {
  box-shadow: var(--shadow-hover);
} */

.card-add {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: var(--small-padding);
}

.card-add:hover {
  /* background: var(--hover); */
  color: var(--grey-text) !important;
}

.contacts_address .card-body {
  padding-left: 0;
  padding-right: 1.7rem;
}

.card-add:hover .avatar {
  background: var(--blue-500);
}

.card-add .avatar,
.card .avatar {
  min-height: 4rem;
  max-height: 4rem;
  min-width: 4rem;
  max-width: 4rem;
  background: var(--icon-primary);
}

.card-add .avatar svg {
  vertical-align: middle;
  font-size: 3rem;
  color: #fff;
}

.card .avatar {
  vertical-align: middle;
  font-size: 1.2rem;
  color: #fff;
}

/* .card-add:hover .avatar svg {
  color: var(--blue-500);
  transition: var(--transition-quick);
} */

.card-header {
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-blue-light);
  padding: 1.1rem;
}

.card-header--title {
  /* text-transform: uppercase; */
  color: var(--grey-text);
  font-weight: 600;
  font-size: 1.4rem;
}

.card-header--btn svg {
  font-size: 2rem;
  color: var(--grey);
}

.card-body {
  /*padding: 1.6rem;*/
  padding: 0rem 1rem;
  color: var(--text-primary);
  font-size: 1.2rem;
}

.card-body svg {
  font-size: 1.5rem;
}

.card-body div,
.card-add div {
  margin: var(--block-margin);
}
.card-body
  .genral_tab_content
  .searchable-dropdown__menu-list
  .searchable-dropdown__option:last-child,
.card-body .genral_tab_content .searchable-dropdown__menu-list {
  margin-bottom: 0;
}

.sale_return_policy .card-body div,
.card-add div {
  margin: 0 !important;
}

.ecom-header-main {
  /* width: 46%; */
}

.ecom-header-inner,
.ecom-header-main {
  display: flex;
  align-items: center;
}

.ecom-header-title p {
  border-right: 1px solid #878787;
  padding-right: 8px;
}

button.btn.btn-sync-setting {
  color: #306ab4;
  text-decoration: underline;
}

/* .disable-integration-sec, .disable-integration-sec select {
  height: 36px;
  min-width: 150px;
} */

/* .disable-integration-sec .select {
  min-width: 150px;
} */

.disable-integration-sec,
.disable-integration-sec .select select {
  background: #306ab4;
  color: #fff;
  border-color: #306ab4;
}

.disable-integration-sec .select option {
  background: #fff;
  color: #000;
}

.disable-integration-sec:hover {
  box-shadow: 0px 0px 5px 0px #c6c8cb;
  /* background: #fff; */
  /* border-color: #fff; */
  color: #000 !important;
}

.disable-integration-sec .select select:focus {
  outline: 0;
}

html body .disable-integration-sec .select select {
  color: #fff !important;
  transition: 0.4s ease;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}

.ecom-header-sync-sec button.btn.btn-sync-setting::after {
  position: absolute;
  content: '';
  border-right: 1px solid #878787;
  height: 20px;
  right: 0;
}

.ecom-header-sync-sec {
  position: relative;
}

.ecom-header-sync-sec .btn-sync-setting:hover {
  color: #000;
}

.ecom-header-sync-sec.ecom-noborder button.btn.btn-sync-setting:after {
  display: none;
}

button.btn.btn-sync-setting:focus {
  outline: 0;
}

button.btn.btn-sync-setting:active {
  box-shadow: none;
}

.disable-integration-sec .select {
  display: flex;
  align-items: center;
  justify-content: center;
}

.disable-integration-sec .select option {
  position: absolute;
  left: 0;
}

.ecom-header-logo img {
  width: 100%;
  max-width: 128px;
}

.ecom-header-logo span {
  /* padding-left: 4px; */
  font-weight: bold;
  color: #000;
  font-size: 14px;
  display: inline-block;
}

.ecom-integration .card-header {
  box-shadow: 0 0px 6px 2px rgb(168 175 165 / 31%);
  border-bottom: none;
}
