.invoices_desc .country + div {
  float: left;
  width: 100%;
}

.invoices_desc span.address_sec {
  height: auto;
}

.invoices_desc > .float-left.w-40 {
  padding-bottom: 12px;
}

.add_credit_note.list-table tbody tr,
.add_credit_note.list-table tbody tr td {
  vertical-align: middle;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.expanded_view_creditnote td:nth-child(3) .list_items_desc {
  display: flex;
}

.Io_popup_dragDrop .__dragDrop_iconParent {
  display: none;
}

.horizontal-items-table .searchable-dropdown.medium,
.add_cn_popup .__dragDrop .__drag-righ {
  max-width: 100%;
}

.add_cn_popup .horizontal-items-table .searchable-dropdown.medium {
  min-width: 20rem;
}
.add_cn_popup .deduction-field {
  padding-left: 5px !important;
}
.add_cn_popup .pd-left {
  padding-left: 3.2% !important;
}
.add_cn_popup .add_credit_note tbody tr:not(:first-child) td:last-child {
  padding-right: 0 !important;
}
.add_cn_popup.add_creditnote_modal_Width .w-70 {width: 60% !important;}

.add_cn_popup.add_creditnote_modal_Width .w-30 {width: 40%;}