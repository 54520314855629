.dashboard_receivables{
  float: left;
  width: 100%;
}
.dashboard_receivables_child{
  height: 100%;
  float: left;
  width: 50%;
  position: relative;
}
.dashboard_receivables_child:first-child:after{
  content: "";
  position: absolute;
  width: 1px;
  height: 123px;
  background-color: #EEEEEE;
  right: 0;
  top: 6rem;
}