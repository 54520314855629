div.toaster-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99991;
}

.toaster-body {
  padding: 2rem 2rem 2rem 5.5rem;
  position: relative;
  top: -1px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  min-width: 250px;
}

.toaster-icon {
  display: flex;
  align-items: center;
  position: absolute;
  left: -4rem;
  top: 0;
  height: 100%;
}
.toaster-emoji {
  width: auto;
  height: 8rem;
}
.toaster-icon-error {
  fill: #FFCCCB;
}
.toaster-icon-success {
  fill: #C7E9D0;
}

.error-type {
  text-transform: capitalize;
  font-weight: 500;
}

.toast-success {
  background-color: #E7F7ED;
  color: #6EA470;
  border: 1px solid #C7E9D0;
}

.toast-error {
  background-color: #FFE5E6;
  color: #D1737B;
  border: 1px solid #FFCCCB;
}

.toast-fall {
  animation-name: animate-down;
  animation-duration: 0.45s;
  animation-fill-mode: forwards;
}

.toast-rise {
  animation-name: animate-up;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

@keyframes animate-down {
  from {
    margin-top: -100px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes animate-up {
  from {
    margin-top: 0;
  }
  to {
    margin-top: -500px;
  }
}
