.orders-container {
  background-color: white;
  position: relative;
  border: 1px solid var(--border-blue-light);
  margin-top: 2rem;
  border-radius: 4px;
  box-shadow: var(--shadow-medium);
}

.orders-body {
  border: 0;
  border-radius: 4px;
  background: #fff;
}

.orders-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  padding: 1rem 0;
}

.order-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 2rem;
  margin: 1rem 0;
  line-height: 2;
  border-right: 1px solid var(--border-blue-light);
}

.order-box:last-child {
  border-right: 0;
}

.order--value {
  color: var(--text-grey);
  font-weight: 500;
  font-size: 2rem;
  word-break: break-all;
}

.order--title {
  font-size: 1.4rem;
}

.orders-table-body {
  font-size: 1.4rem;
  width: 100%;
}

.orders-table--title {
  color: var(--text-secondary);
  padding: 1rem;
}

.orders-table-head {
  background-color: var(--background);
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  height: 4rem;
}

.orders-table-list {
  border-bottom: 1px solid var(--border-light);
  height: 6rem;
}

.box_7 .orders-table-list {
  height: 5rem;
}

.box_7 .orders-table-list .check a {
  color: #306ab4 !important;
}

.box_7 .orders-table-list .check:hover a {
  text-decoration: underline;
}

.orders-table-list:last-child {
  border-bottom: 0;
}

.box_7 .orders-table-body thead tr th:first-child,
.box_7 .orders-table-body tbody tr td:first-child {
  /* text-align: right; */
  padding-left: 20px !important;
}

.box_7 .orders-table-body thead tr th:last-child,
.box_7 .orders-table-body tbody tr td:last-child {
  text-align: right;
  padding-right: 20px !important;
}

.box_7 .orders-table-body thead tr th:nth-child(4),
.box_7 .orders-table-body tbody tr td:nth-child(4) {
  text-align: center;
}

.box_7 .orders-table-body.orders-bills-table-body thead tr th:nth-child(5),
.box_7 .orders-table-body.orders-bills-table-body tbody tr td:nth-child(5) {
  text-align: right;
}

.box_7 .orders-table-body thead tr th:nth-child(3),
.box_7 .orders-table-body tbody tr td:nth-child(3) {
  width: 300px;
  word-break: break-all;
}

.box_7 .orders-table-body tbody tr:nth-child(even) {
  background-color: #fbfbf7;
}

.box_7 .lopi th {
  top: 0;
}

.box_7 .orders-table-body tbody tr td:nth-child(4) {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 5rem;
}

.orders-table-list--po {
  color: var(--blue-500);
}

.orders-table-head th,
.orders-table-list td {
  text-align: left;
  padding: 0 1rem;
}

.box_7 .orders-table-head th,
.box_7 .orders-table-list td {
  padding: 0;
}

.orders-table-list td {
  /* word-break: break-all; */
  min-width: 8rem;
}

.orders-table-list .status {
  background-color: unset;
  display: table-cell;
  font-size: inherit;
}

.summary-timeline--date-range.custom-dropdown.button-text > button {
  font-size: unset;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.summary-timeline-dropdown ul li a:hover {
  background-color: var(--button-hover);
}

.summary-timeline #custom-dropdown--icon {
  fill: var(--icon-primary);
}

.mt-2-5 {
  margin-top: 2rem !important;
}

.box_7 #printMe {
  height: 448px;
  overflow: auto;
  scrollbar-width: thin;
}

.orders-table-list.yes .status--draft,
.orders-table-list.yes .status--void {
  color: var(--draft-status-text);
  background: var(--draft-status);
}

.orders-table-list.yes .status--unpaid,
.orders-table-list.yes .status--not-shipped,
.orders-table-list.yes .status--overdue {
  color: var(--red-status-text);
  background: var(--red-status);
}

.orders-table-list.yes .status--closed,
.orders-table-list.yes .status--complete,
.orders-table-list.yes .status--paid,
.orders-table-list.yes .status--received,
.orders-table-list.yes .status--shipped {
  color: var(--green-status-text);
  background: var(--green-status);
}

.orders-table-list.yes .status--receiving {
  color: var(--receiving-status-text);
  background: var(--receiving-status);
}

.orders-table-list.yes .status--sent,
.orders-table-list.yes .status--confirmed,
.orders-table-list.yes .status--cancelled,
.orders-table-list.yes .status--delivered {
  /* color: var(--neutral-status-text); */
  background: var(--neutral-status);
}

.orders-table-list.yes .status--onhold,
.orders-table-list.yes .status--partially-received,
.orders-table-list.yes .status--partially-paid,
.orders-table-list.yes .status--issued {
  color: var(--orange-status-text);
  background: var(--orange-status);
}
@media (min-width: 1280px) and (max-width: 1460px) {
  .box_7 #printMe {
    height: 509px;
  }
}
@media (max-width: 1199px) {
  .orders-bills-table-body th:first-child span,
  .dashboard-purchase-tb th:nth-child(2) span,
  .dashboard-purchase-tb th:first-child span,
  .dashboard-invoice-tb th:first-child span,
  .dashboard-table-main th:nth-child(2) span,
  .dashboard-table-main th:first-child span {
    display: none;
  }

  .orders-bills-table-body th:first-child :before,
  .dashboard-purchase-tb th:nth-child(2):before,
  .dashboard-purchase-tb th:first-child:before,
  .dashboard-invoice-tb th:first-child:before,
  .dashboard-table-main th:first-child:before {
    position: absolute;
    background: #fff;
    top: 13px;
  }

  .dashboard-purchase-tb.UIUX_dashboard_purchase-table th:nth-child(2):before,
  .dashboard-table-main th:nth-child(2):before {
    content: 'Order No.';
    font-size: 13px;
  }

  .orders-bills-table-body th:first-child:before {
    content: 'Due Date';
    font-size: 13px;
  }

  .dashboard-purchase-tb th:first-child:before,
  .dashboard-invoice-tb th:first-child:before,
  .dashboard-table-main th:first-child:before {
    content: 'Date';
    left: 30px;
    font-size: 13px;
  }

  .dashboard-purchase-tb thead tr th:nth-child(2),
  .dashboard-invoice-tb thead th:nth-child(2),
  .dashboard-table-main th:first-child {
    width: 13.4%;
  }

  .orders-table .dashboard-table-main th:nth-child(2) {
    width: 13.3%;
  }

  .orders-table-body thead tr th:nth-child(3) {
    width: 345px;
  }

  .dashboard-invoice-tb thead th:nth-child(3) {
    width: 350px !important;
  }

  .dashboard-purchase-tb thead tr th:first-child {
    width: 12.8%;
  }
}

@media (max-width: 899px) {
  .dashboard_box_cus-set .orders-bills-table-body th:first-child {
    width: 110px;
  }

  .dashboard_box_cus-set .orders-bills-table-body th:nth-child(5) {
    width: 110px;
  }

  .dashboard_box_cus-set .orders-bills-table-body th:nth-child(6) {
    width: 130px;
  }
}

@media (max-width: 768px) {
  span.dashboard_info_text {
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    direction: rtl;
    width: 82px;
    font-size: 11px !important;
  }

  /* .dashboard_box_cus-set .nav_tabs a {
    width: 145px !important;
  } */
  .dashboard_box_cus-set .dash_info_div svg.dashboardTableFill {
    width: 13px;
    height: 13px;
  }

  .dashboard-table-main th:first-child,
  .dashboard-purchase-tb thead tr th:first-child {
    width: 14.8%;
  }

  .dashboard-invoice-tb thead th:nth-child(1) {
    width: 15% !important;
  }

  .dashboard_box_cus-set .double_dash_box .nav_tabs a {
    width: 156px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .dashboard_box_cus-set .double_dash_box .nav_tabs a {
    width: 143px;
    font-size: 11px;
  }
}
