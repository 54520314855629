.item-form .radio-container {
  display: flex;
  margin: 0;
}

 
.form-table.list-table.item-group-tbl th {
  padding: 0.4rem;
}

.item-group-tbl thead {
  height: 40px;
  line-height: initial !important;
}

.item-form .radio-container .radio-btn {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 1rem;
}

.item-form .button.item-add {
  font-size: 2rem;
  text-align: right;
  width: 100%;
}

.item-form--account-new {
  width: 10rem;
  justify-content: center;
  color: var(--blue-500);
}

.account_addnew {
  left: 76% !important;
}
html body .all-items-main .account_addnew {
  left: auto !important;
  right: 0;
}
.item-form--undo-icon {
  fill: white;
  stroke-width: 1px;
  stroke: #eeeeee;
}

/* .item-form .overForm {
  z-index: 200;
} */

.close-btn.button:hover svg.item-form--undo-icon {
  stroke: none;
  fill: var(--blue-500);
}

.adjust-width > td {
  width: 25% !important;
}

.full-width-item-name div input {
  width: 105rem !important;
}

.item-hr {
  margin: 0 !important;
  margin-bottom: -10px !important;
  margin-top: 2rem !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.ml-3 {
  margin-left: 4rem !important;
}

.items-add-textarea div textarea {
  height: 22rem !important;
}

.maximum_char {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin: 0 !important;
  text-align: right;
  display: inline-block;
}

.invoice_form_main .form-title {
  font-weight: 700;
}

/* .track_inv_checkbox { */
/* margin-left: 2rem; */
/* } */

.warehouse-select-items-form {
  width: 40rem !important;
}

.help {
  width: 17px !important;
  height: 17px !important;
  /* stroke: #000 !important; */
}

.trackDiv {
  margin-top: 1%;
}

.trackSpan {
  color: var(--grey-text);
  font-size: 1.3rem;
  color: #000 !important;
  font-weight: 0 !important;
  opacity: 0.4;
}

.form-section--title .trackDiv .trackSpan {
  font-weight: 400 !important;
}

.wh-location {
  width: 47rem !important;
}

.adjustment-form-textarea textarea {
  width: 48.5rem !important;
  height: 17rem !important;
}

.adjustment-form-table {
  width: 97.3% !important;
}

.adjustment-form-table th {
  line-height: 1.42857143;
}

.adjustment-qty {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* margin-top: 0.5rem; */
}

.adjustment-field {
  width: unset !important;
  min-width: unset !important;
}

.adjustment-field .number-input {
  border: 1px solid #dfe1e5;
}

.adjustment-field input {
  text-align: center;
}

.adjustment-icon-gray {
  margin-top: 0.7rem !important;
  background: #dfe1e5;
  border-radius: 3px;
  cursor: pointer;
}

.adjustment-icon-green {
  margin-top: 0.7rem !important;
  padding: 3px;
  cursor: pointer;
  border-radius: 3px;
  color: white;
  background: #33a348;
}

.add_new_item thead {
  background-color: transparent !important;
}

.add_new_item thead {
  line-height: 1.42857143;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
}

.add_new_item .form-input {
  top: 4px;
  border-radius: 4px;
}

.add_new_item.form-table.list-table td {
  /* padding: 15px 10px 15px 10px; */
  height: auto !important;
  vertical-align: top !important;
  padding-top: 5px;
}

.add_new_item.list-table tbody tr {
  height: 50px;
}

.add_new_item .remove_row_button {
  top: 25px;
}

.remove_row_button {
  position: absolute;
  top: 0;
  left: 104%;
}

.remove_row_button button svg {
  font-size: 25px;
  fill: black;
}

.add_adjustment thead {
  background: #fff !important;
}

.add_adjustment.list-table tbody tr {
  height: 70px;
  width: 70px;
}

.add_adjustment.form-table.list-table td {
  height: auto !important;
  vertical-align: middle !important;
}

.add_adjustment.form-table.list-table th,
.add_adjustment.form-table.list-table td {
  border: 0 !important;
}

.add_adjustment.form-table.list-table tbody tr:nth-child(even) {
  background-color: #fbfbf7;
}

.add_adjustment.form-table.list-table tr td:first-child {
  padding: 0.4rem 1rem !important;
}

.add_adjustment .remove_row_button {
  /* height: 70px; */
  top: 50%;
  margin-top: -8px;
}

.add_adjustment .form-field {
  /* min-width: unset; */
  max-width: 230px;
}

.item-form .tooltip_tooltip {
  width: 300px;
}

.tooltip_tooltip {
  display: none;
  left: 100%;
  background-color: #000;
  width: 150px;
  position: absolute;
  top: -23px;
  color: #fff;
  padding: 10px;
  font-size: 14px;
}

.icon_div {
  position: relative;
}

.icon_div:hover .tooltip_tooltip {
  display: block;
  z-index: 1;
}

.tooltip_tooltip:before {
  content: '';
  position: absolute;
  left: -19px;
  top: 30%;
  width: 0;
  height: 0;
  color: transparent;
  border: 10px solid #ccc;
  border-color: transparent transparent #000;
  transform: rotate(-88deg);
}

.possible_vendor_dropdown .possible_vendor_dropdown .searchable-dropdown__menu {
  z-index: 9999 !important;
}

.item_tax_dropdown .item_tax_dropdown .searchable-dropdown__menu {
  z-index: 9999 !important;
}

.add_new_item tbody tr td:last-child div:first-child input {
  border: 1px solid #d8d8d8 !important;
}

.add_new_item tbody tr td:last-child div:first-child {
  padding: 10px 10px 18px 10px;
}

.add_new_item tbody tr td:last-child div:first-child .input-feedback {
  bottom: -0.1rem;
}

.add_new_item tbody tr td:last-child div:first-child input:hover {
  border-color: #878787 !important;
}

.attribute_btn {
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 11px;
  text-align: left;
}

.group_option {
  width: 66%;
}

.add-items-desc-sec .group_option {
  width: 71%;
  flex: 0 0 71%;
  padding-left: 16px;
}

.add-items-desc-sec .form-field.inline-field.medium_large {
  width: 23%;
  margin-right: 0 !important;
}

@media (max-width: 1280px) {
  .full-width-item-name div input,
  .full-width-item-name,
  .full-width-item-tabs {
    width: 100% !important;
  }

  .mr-tabs {
    margin-right: 45px !important;
  }

  .remove-neg-m {
    margin: 0;
  }

  .item-form .mr_10 {
    margin-right: 17px !important;
  }

  .item-form .medium_large > textarea {
    width: 475px !important;
  }

  .item-form .items-add-textarea {
    margin-left: 1.5rem !important;
    margin-right: 0 !important;
  }
}

/* @media (min-width: 768px) and (max-width: 992px) {
  .item-form .medium_large > textarea {
    width: 215px !important;
  } */

.account_addnew.new_click_set {
  left: auto !important;
  right: 2px;
}

.group_main_set_top {
  display: flex;
}

.float-left.group_main_set {
  display: flex;
  flex-wrap: wrap;
  /* width: 100%; */
}

.description-set .mr_10 {
  margin-right: 0px !important;
}

div#react-autowhatever-1 {
  z-index: 999;
}

/* .form-table .form-input,
.horizontal-main-set .form-table .form-input {
  border-color: #dfe1e5 !important;
} */

button.attribute_btn.attribute_btn_set {
  background-color: #33a348;
  color: #fff;
  padding: 6px 11px;
  transition: 0.5s ease;
  text-transform: capitalize;
    font-style: italic;
}

button.attribute_btn.attribute_btn_set:hover {
  background: #fff;
  box-shadow: var(--hover-box-shadow);
  color: #000;
  font-style: unset;
}
.all-items-main .form-section--header {
  padding-left: 6px;
}
.items_sku button.form-field--side-btn {
  left: auto;
  right: 0;
}
button.group_delete_btn {
  margin-top: 3%;
  position: relative;
  top: 3px;
  right: -19px;
}

.combiation_set {
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
  margin: 4px 0 9px 0;
}

html body .horizontal-main-set th:first-child {
  width: 10% !important;
}

.table_row_desc td.left-align.no-padding:first-child {
  width: 18% !important;
}

html body .horizontal-main-set th:nth-child(3) {
  width: 10% !important;
  /* padding-left: 8.6%; */
}
.table_row_desc td.left-align.no-padding:nth-child(6),
.table_row_desc td.left-align.no-padding:nth-child(7),
.table_row_desc td.left-align.no-padding:nth-child(5) {
  width: 10% !important;
}

html body .horizontal-main-set th:nth-child(6) {
  width: 12% !important;
  text-align: left !important;
}

html body .horizontal-main-set th:nth-child(7) {
  width: 10% !important;
}
html body .horizontal-main-set .td-2 {
  width: 49px !important;
}
/* html body .horizontal-main-set th:nth-child(4) span.th-title,
html body .horizontal-main-set th:nth-child(4) .group-btn-sm {
  margin-left: -4px;
} */

/* html body .horizontal-main-set th:nth-child(8) span {
  position: relative;
  left: -21px;
} */

.td-group-inner_2 span.icon_div svg {
  position: relative;
  top: 5px;
}


.group-btn-sm {
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 11px;
  text-align: left;
}

.td-group-inner_2,
.td-group-inner {
  display: inline-block;
  position: relative;
}
.td-group-inner span.sku-span {
  font-size: 13px;
}

span.th-title {
  font-size: 13px;
}

.horizontal-main-set tr.table--row.table_row_desc {
  position: relative;
  height: 63px;
}

.horizontal-main-set td.list-table--amount.right-align {
  min-width: 5% !important;
  max-width: 5%;
  /* width: 5% !important; */
  /* position: absolute; */
  right: 0px;
  top: 14px;
  position: relative;
}

.horizontal-items-table.horizontal-main-set {
  position: relative;
}

button.attribute_btn.attribute_btn_set {
  margin-top: 25px;
}

.horizontal-main-set td.left-align.no-padding:nth-child(8) input,
.horizontal-main-set td.left-align.no-padding:nth-child(3) input,
.horizontal-main-set td.left-align.no-padding:nth-child(4) input {
  text-align: right;
}

html body .horizontal-items-table.horizontal-main-set .td-2 {
  width: 61px !important;
}

.item-name-set h3 {
  text-transform: capitalize;
  font-size: 15px;
  margin: 0;
  max-width: 300px;
  line-height: 20px;
  word-break: break-word;
  font-weight: 400;
}

td.item-name-set {
  vertical-align: top;
  margin-right: 4px;
  padding-right: 20px;
  width: 22%;
  padding-top: 8px;
  position: relative;
}

.newitem_form_set .form-field.right-align {
  padding: 10px 10px 18px 10px;
}

html body .newitem_form_set.list-table.border tbody {
  border-left: 0 !important;
  border-right: 0 !important;
}

.form-table.list-table.newitem_form_set th,
.form-table.list-table.newitem_form_set td {
  border: 0;
}

.newitem_form_set td.warehouse-select-items-form:first-child {
  border: 1px solid gray;
  /* width: 300px !important; */
  /* display: inline-block; */
  height: 43px !important;
}

.newitem_form_set + button.button.blue {
  background-color: transparent;
  color: var(--blue-500);
  font-size: 12px;
  padding: 3px 3px 0 0 !important;
  transition: 0.7s ease-in;
}

.newitem_form_set + button.button.blue svg {
  fill: var(--blue-500);
  width: 18px;
}

.newitem_form_set + button.button.blue:hover {
  box-shadow: unset;
  text-decoration: underline;
}

.d-inline {
  display: inline-block;
}

.group-table-set th:first-child {
  width: 25%;
}

.group-table-set th:nth-child(2) {
  width: 17.5%;
}

.group-table-set th:nth-child(3) {
  width: 29.6%;
}

.group-table-set th:nth-child(4) {
  width: 14.5%;
  display: inline-block;
}
td.inventory-status + td {
  width: 50% !important;
  vertical-align: top;
}

.group-table-set th:nth-child(2) .tooltip_tooltip {
  width: 300px;
  white-space: pre-wrap;
  top: -17px;
}

.group-table-set .tooltip_tooltip:before {
  top: 9%;
}

.group-table-set th:nth-child(5) {
  width: 15%;
  display: inline-block;
}

.newitem_form_set + button.button.blue:hover {
  box-shadow: unset;
  text-decoration: underline;
}

.d-inline {
  display: inline-block;
}

table.w-100.group-table-set {
  background: #e9e9e9;
  border: 1px solid var(--border-light);
}

.group-table-set th.d-inline {
  padding: 6px;
  height: 40px;
  vertical-align: middle;
}

.group-table-set .group-btn-sm {
  margin-top: -2px;
}

.group-table-set th:first-child {
  width: 24%;
}

.group-table-set th:nth-child(2) {
  width: 15%;
}

.group-table-set th:nth-child(3) {
  width: 31%;
}

.group-table-set th:nth-child(4) {
  width: 16%;
}

td.inventory-status + td {
  width: 50% !important;
  vertical-align: top;
}

.group-table-set th:nth-child(2) .tooltip_tooltip {
  width: 300px;
  white-space: pre-wrap;
  top: -17px;
}

.group-table-set .tooltip_tooltip:before {
  top: 9%;
}

.group-table-set th:nth-child(5) {
  width: 14%;
}

/* .group-table-set .group-btn-sm {
  margin-left: 3px;
} */

.detail-table-set tr {
  border-bottom: 1px solid var(--border-light);
}

.detail-table-set tr:last-child {
  border-bottom: 0;
}

html body .newitem_form_set.list-table.border tbody > tr td:nth-child(3),
html body .newitem_form_set.list-table.border tbody > tr td:nth-child(2) {
  width: 25% !important;
}

html body .newitem_form_set.list-table.border tbody > tr td:nth-child(2) > div,
html body .newitem_form_set.list-table.border tbody > tr td:nth-child(3) > div {
  padding-right: 0 !important;
}

.warehouse-name {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.warehouse-name .input-feedback {
  bottom: -2.6rem !important;
}

.detail-table-set tr {
  border-bottom: 1px solid var(--border-light) !important;
}

td.inventory-status {
  width: 11% !important;
  vertical-align: top;
  position: relative;
  /* top: 16px; */
  /* padding-top: 1.2%; */
}

.warehouse-name-desk,
.sale-order-ref {
  word-break: break-word;
}
@media(max-width:1600px){
  /* .remove_row_button.remove_item_set.icon_div span.icon_div{
    right: 7px;
  } */
  .group-set-main span.icon_div .tooltip_tooltip{
    right: auto;
    left: -125px;
    top: 21px;
    font-size: 11px;
    width: 112px;
  }
  .group-set-main span.icon_div .tooltip_tooltip:before{
    right: 3px;
    top: -19px;
    transform: rotate(2deg);
    left: auto;
  }
  /* .group-set-main span.icon_div {display: none;} */
}

@media (max-width: 1280px) {
  .warehouse-name-desk
  
  /* .sale-order-ref  */ {
    text-align: right;
    width: 50%;
  }
}

@media (min-width: 1280px) {
  .warehouse-name-desk,
  .sale-order-ref {
    width: 60%;
    text-align: right;
    /* line-height: 1.4; */
  }
}

@media (max-width: 1280px) {
  html
    body
    .adjustment-main-layout
    .adjustment-form-textarea
    .form-field.inline-field.adjustment-form-textarea.adjustment-description {
    margin-right: 0 !important;
  }

  .adjustment-main-layout .form-field.inline-field {
    margin-right: 17px;
  }

  html body .adjustment-main-layout .adjustment-form-textarea {
    margin-right: 20px !important;
  }

  form.form.item-form.item-group-form {
    padding-top: 13px;
  }

  .item-opening-stock-per-unit {
    /* color: var(--black); */
    color: var(--blue-500);
    white-space: nowrap;
    font-size: 11px;
    text-align: left;
    position: relative;
    top: -2px;
  }
}

@media (max-width: 1199px) {
 
  .item-form .medium_large > textarea {
    width: 100% !important;
  }

  .mt_178.custom-group-margin .form-field.inline-field:nth-child(1) {
    margin-right: 10px !important;
  }

  .mt_178.custom-group-margin .form-field.inline-field:nth-child(2) {
    margin-right: 0 !important;
    width: 50% !important;
  }

  /* .item-form-main .images-uploading .form-field {
    width: 22% !important;
  } */
  .invoice-popup-set.so-invoice-popup .create_invoice td:nth-child(5) {
    width: 15% !important;
  }

  .invoice-popup-set.so-invoice-popup .create_invoice td:nth-child(1) {
    width: 30% !important;
  }

  .menu_top_inner_content .sub_tabs_transaction_main thead tr th,
  .menu_top_inner_content .sub_tabs_transaction_main tbody tr td {
    width: 13.98%;
  }

  /* html body form.item-form.items-main-form .form-section--body > div.mt_178,
  html
    body
    form.item-form.items-main-form
    .form-section--body
    > div.mt_178
    > .form-field {
    width: 100% !important;
  }

  html body form.item-form.items-main-form .form-section--body > div.mt_178 {
    display: flex;
  }

  html
    body
    form.item-form.items-main-form
    .form-section--body
    > div.mt_178
    > .form-field {
    margin-right: 11px !important;
  } */

  /* .form.item-form .images-uploading .form-field {
    width: 20%;
  } */
}

@media (max-width: 1099px) {

  .item-form button.form-field--side-btn {
    left: auto !important;
    right: 0;
    display: block;
    text-align: right;
  }

  .margin-top-lg {
    margin-top: 2rem;
  }

  .invoice-popup-set.create_invoice_popup.so-invoice-popup
    .create_invoice
    td:nth-child(5) {
    width: 15% !important;
  }
}
@media (max-width: 992px) {
  .listable_item_adjustment th:nth-child(4),
  .listable_item_adjustment tr td:nth-child(4) {
    display: none;
  }

  .listable_item_adjustment tr th:nth-child(2) {
    width: 36%;
  }

  .listable_item_adjustment tr th:nth-child(3) {
    width: 23%;
  }

  .listable_item_adjustment tr th:nth-child(5) {
    width: 25%;
  }

  .listable_item_adjustment tr th:nth-child(6) {
    width: 25%;
  }

  .add-items-desc-sec .form-field.inline-field:nth-child(1) {
    width: 30% !important;
    margin-right: 0 !important;
  }
}

/* @media (max-width: 890px) {
  .adjustment-main-layout .form-field.inline-field,
  .adjustment-form-table,
  .adjustment-main-layout .form-input.large,
  .dropdwon-width-bill.adjustment-main-layout .searchable-dropdown,
  .form-field-set {
    width: 100% !important;
  }

  .adjustment-main-layout .form-input.large,
  .dropdwon-width-bill.adjustment-main-layout .searchable-dropdown,
  .adjustment-main-layout .form-field.inline-field {
    display: block;
  }

  .adjustment-main-layout .form-field.inline-field {
    margin-right: 0 !important;
  }

  .invoice-popup-set.create_invoice_popup.so-invoice-popup
    .create_invoice
    td:nth-child(1) {
    width: 30% !important;
  }
} */

@media (max-width: 768px) {
  html body .item-form button.form-field--side-btn {
    left: auto;
    justify-content: flex-end;
  }

  .add-items-desc-sec .form-field.inline-field:nth-child(1) {
    padding-right: 18px;
  }

  .group-table-set th:nth-child(3) {
    width: 28% !important;
  }

  .item-group-form .images-uploading .form-field {
    width: 16%;
  }

  .item-group-form .action-buttons {
    margin-right: 0% !important;
    margin-top: 0;
  }

  .group-table-set th:nth-child(4) {
    width: 17% !important;
  }
}

.inventory-status {
  position: relative;
}
