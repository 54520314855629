.tax-list {
  margin: 3rem 0;
}
.system_settings.list-table td{
  padding: 0 0px !important;
  line-height: 1.42px !important;
}
.system_settings.list-table tbody td{
  padding-left: 8px !important;
}
.system_settings.list-table tbody tr td:first-child , .system_settings.list-table thead tr th:first-child{
  padding-left: 4rem !important;
}
.add_new_tax svg{
  height: 17px;
  width: 17px;
}
.add_new_tax .form-field.inline-field{
  margin-bottom: 20px !important;
}
.add_new_tax .action-buttons {
  margin:0 !important;
  padding: 1.5rem 0 !important;
  border-top:1px solid var(--border-light);
  float: left !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}