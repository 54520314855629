.loader--center {
    margin-top: 0;
    position: absolute;
    left: 40%;
}


.return_reason_dropdown {
    width: 229px !important;
}

.add_sales_reurn .table-header th {
    line-height: 1.42857143 !important;
    background: #FFF !important;
    border: 0 !important;
}

.add_sales_reurn.form-table.list-table th, .add_sales_reurn.form-table.list-table td {
    border: 0 !important;
}

.add_sales_reurn.form-table td {
    height: auto !important;
    border: 0 !important;
}

.add_sales_reurn tbody tr {
    padding: 5px;
}

.add_sales_reurn .with-close--btn .form-field {
    padding: 0 0 0 30px;
}

.add_sales_reurn .with-close--btn input {
    border: 1px solid #dfe1e5;
    border-radius: 4px;
}

.add_sales_reurn tbody tr td:last-child {
    padding-top: 5px !important;
}

.add_sales_reurn.form-table .with-close--btn .close-btn.button {
    top: 9px;
}

/* .add_sales_reurn tbody tr:not(:first-child) td:last-child{
    padding-right: 5px !important;
    
} */
.add_sales_reurn.form-table td:nth-child(1) {
    padding: 0.4rem 0.8rem 0.4rem 0.2rem;
}

.add_sales_reurn .list-table--item--col span {
    max-width: 100%;
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    /* white-space: nowrap; */
}

.flex_direction_row {
    flex-direction: row !important;
    margin-top: 4.7rem !important;
}

.checkbox_with_label {
    position: relative;
    top: -2px;
    margin-right: 10px !important;
}

.margin_top_ {
    margin-top: -5rem !important;
}

.lh-0 {
    line-height: 0;
}

@media(max-width:1024px) {
    .sr-popup-inner.margin_top_ {
        margin-top: 0 !important;
    }
}