.invited-label, span.owner-label {
  display: inline-block;
  padding: .5em .85em;
  font-size: 1.2rem;
  /* font-weight: 600; */
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  margin-right: 20px;
  border-radius: 0.475rem;
  max-height: 24px;
}

span.owner-label {
  color: #1f5927;
  color: #000;
  background: var(--green-status);
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

.user-avatar {
  width: 35px;
  height: 35px;
  /* border-radius: 25px;
  background-color: #dfe1e5;
  padding: 9px 1px; */
}

.user-avatar img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.image_circle_dp {
  border-radius: 20px;
  width: 35px;
  height: 35px;
  /* background: #ccc; */
  border: 1px solid var(--background);
  padding: 5px;
  /* position: fixed; */
  overflow: hidden;
}

.sidepanel {
  height: 100vh;
  width: 17%;
  background: #fff;
  position: fixed;
  top: 50px;
  right: 0;
  -webkit-box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -3px 0px 5px 0px rgba(0, 0, 0, 0.25);
  /*display: block;*/
}

.sidepanel.panelhide {
  right: -2000px;
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  animation-duration: 0.3s;
}

.sidepanel.panelshow {
  right: 0;
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  animation-duration: 0.3s;
}

.intro-orginization {
  background-color: #eff5fc;
  height: 20rem;
  float: left;
  width: 100%;
  text-align: center;
}

.intro-orginization .image {
  float: left;
  width: 100%;
}

.intro-orginization .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0 5px;
}

.image_circle {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  padding: 5px;
  background: #FFF;
  border: 1px solid var(--background);
  overflow: hidden;
}

.intro-orginization ul {
  float: left;
  width: 100%;
}

.intro-orginization ul li {
  width: 50%;
  float: left;
  text-align: center;
  color: #fd1e1f;
}

.intro-orginization ul li:first-child {
  border-right: 1px solid #ccc;
  color: #306ab4;
}

.intro-orginization .image {
  height: 10rem;
  background: #eff5fc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-orginization .name {
  font-weight: bold;
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-inline: 10px;
}

.intro-orginization .user-email {
  font-weight: normal;
  color: #818284;
  margin-bottom: 5px;
  float: left;
  width: 100%;
  text-align: center;
  height: 2.2rem;
}

.oganization-list {
  float: left;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 250px);
}

.oganization-list li:first-child {
  padding: 10px 15px;
  font-weight: 400;
}

.oganization-list li.active {
  background-color: #306ab4;
  color: #fff;
  font-weight: 400;
}

.oganization-list li.active .checkcircle {
  /* stroke: #ffffff; */
  fill: #33a348;
  float: right;
}

.oganization-list li {
  float: left;
  width: 100%;
  padding: 3px 15px;
  /* font-size: 16px;
  font-weight: 600; */
  cursor: pointer;
}

.add-organziation {
  float: right;
  font-size: 2rem;
}

.checkcircle {
  /* stroke: #ffffff; */
  fill: #eff5fc;
  float: right;
  font-size: 2rem;
}

.circle_align {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 35%;
}

span.invited-label {
  background: var(--draft-status);
  color: #000;
}

.circle_align {
  display: flex;
}

@media(max-width:1750px) {
  .sidepanel {
    width: 20%;
  }

  .sidepanel.panelhide {
    right: -389px !important;
  }
}

@media screen and (min-width: 4000px) and (max-width: 6000px) {
  .sidepanel.panelhide {
    right: -1000px;
  }
}

@media screen and (min-width: 3000px) and (max-width: 4000px) {
  .sidepanel.panelhide {
    right: -700px;
  }
}

@media screen and (min-width: 2000px) and (max-width: 3000px) {
  .sidepanel.panelhide {
    right: -500px;
  }
}

@media screen and (min-width: 21px) and (max-width: 2000px) {
  .sidepanel.panelhide {
    right: -340px;
  }
}

@media(max-width:1500px) {
  .sidepanel {
    width: 23%;
  }
}

.small-sidepanel {
  font-size: smaller;
  font-weight: 400;
}

.display-flex {
  display: flex;
  justify-content: space-between;
}

.org-name {
  font-weight: 600;
  width: 63%;
}

@media(max-width:1280px) {
  .sidepanel {
    width: 24%;
  }
}

@media(max-width:1024px) {
  .sidebar-mob {
    width: 27%;
  }

  html body .sidepanel {
    width: 31% !important;
  }

  .circle_align svg.checkcircle {
    flex: 0 0 20%;
  }
}

.sidenav-overlay {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  opacity: 0;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, .5);
  display: none;
}

.sidenav-overlayshow {
  display: block;
  opacity: 1;
}