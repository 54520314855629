.checkbox-ims {
  margin: 0;
  position: relative;
  display: flex;
  width: 100%;
  user-select: none;
}

.checkbox-ims>span {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
  border: 1px solid #589dd8;
  left: 0;
  right: 0;
}

.checkbox-ims>input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

.checkbox-ims>span>svg {
  color: var(--seebiz-light-blue);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: scale(1.3);
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.checkbox-ims>input[type='checkbox']:checked+span>svg {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

.btn {
  border-radius: 0 !important;
}

.panel {
  border-radius: 0 !important;
}

.list-group-item {
  border-radius: 0 !important;
}

.error-field {
  border-color: #e04c4c !important;
}

.disabled {
  opacity: 0.4;
  color: dimgray;
  cursor: not-allowed;
  /* cursor: not-allowed !important; */
}

.tp-panel-head {
  border-bottom: 1px solid #eeeeee;
}

div.items-workable table th,
div.items-workable table td {
  padding: 1.5rem;
  vertical-align: middle;
  position: relative;
}

.small-table-text {
  font-size: 1rem;
  position: absolute;
  color: #c0c0c0;
  bottom: 2px;
}

.submit-buttons {
  text-align: right;
  margin: 2rem 0;
}

.left-submit-buttons {
  text-align: left;
  margin: 2rem 0;
}

.item-wrapper>.adjust-item--close-btn,
.adjust-item--close-btn {
  padding: 0;
  position: relative;
}

.adjust-item--close-btn>.close-btn.button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  height: auto;
}

.fw-normal {
  font-weight: normal;
}

.total-price--title {
  font-size: 1.7rem;
}

.total-price--value {
  font-size: 2.8rem;
}

.item-list--name {
  line-height: 3rem;
  color: var(--blue-500);
}

.item-list--sku {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin-top: -0.4rem;
}

.items-workable thead {
  border-bottom: 2px solid var(--input);
}

.item-info {
  display: flex;
  align-items: center;
}

.price-adjust-right {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
}

.list-exp-header {
  margin-bottom: 1rem;
  position: relative;
}

.list-exp-header .list-header-title {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.list-exp-title {
  font-size: 2rem;
  color: #3e3e3e;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 1.5rem;
}

.list-exp--img {
  width: 100%;
  padding-top: 100%;
}

.list-exp-subheading {
  font-size: 1.7rem;
  color: #7e7e7e;
  padding: 1rem 1.5rem;
  border-bottom: 2px solid #eeeeee;
  /*box-shadow: inset 0 -3px 1px -2px #ccc;*/
}

.list-exp-section {
  margin: 1.5rem 0;
}

.list-exp-row--s {
  display: grid;
  grid-template-columns: 6rem 1fr;
  margin: 1rem 0;
  grid-gap: 1rem;
}

.list-exp-row {
  display: grid;
  grid-template-columns: minmax(10rem, 1fr) 3fr;
  margin: 1rem 0;
}

.list-exp-table-head,
.list-exp-table-body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
}

.list-exp-table-body>div:first-child {
  text-align: left;
}

/*<-----order summary table*/

.list-exp-table-summary {
  width: 100%;
  line-height: 4rem;
}

.list-exp-table-summary-head {
  border-bottom: 1px solid #eeeeee;
}

.list-exp-table-summary--row {
  display: grid;
  grid-template-columns: minmax(8rem, 4fr) repeat(auto-fit, minmax(7rem, auto));
  grid-gap: 1rem;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
}

.list-exp-table-summary--row-name {
  font-weight: 500;
  color: var(--blue-500);
  line-height: 3rem;
}

.list-exp-table-summary--row-sku {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin-top: -1rem;
}

.list-exp-price-container {
  margin-top: 3rem;
}

.list-exp-price-adjust {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 4rem;
}

/*order summary table----->*/

/*<-----header with button*/

.new-header-with-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-header-with-btn--btn,
.new-header-with-btn--btn a {
  display: flex;
  align-items: center;
}

.new-header-with-btn--btn span {
  margin-right: 8px;
}

/*header with button----->*/

/*<-----list expansion tab section*/

.exp-tab-table {
  width: 100%;
}

.exp-tab-table thead {
  border-bottom: 1px solid #eeeeee;
}

.exp-tab-table--row {
  position: relative;
  display: grid;
  grid-template-columns: minmax(10rem, 2fr) repeat(auto-fit, minmax(8rem, 1fr)) 4rem;
  grid-gap: 2rem;
  line-height: 4rem;
}

.exp-tab-table--row-dropdown {
  position: absolute;
  right: 0;
}

.exp-tab-table--row-dropdown--btn {
  transform: rotate(90deg);
  background: transparent;
  border: 0;
  font-size: 1.7rem;
}

.exp-tab-table--row-dropdown--btn:focus {
  background-color: transparent;
}

/*list expansion tab section----->*/

/*<-----items moved to trash*/

.removed-item--header {
  cursor: pointer;
}

.removed-item--header>svg {
  font-size: 2rem;
  margin-right: 1rem;
}

/*items moved to trash----->*/

.restore-btn-container {
  display: flex;
  justify-content: flex-end;
}

.restore-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--green-status);
  color: white;
  padding: 0.7rem 1rem;
  cursor: pointer;
  width: 8rem;
}

/*<-----custom checkbox*/

.custom-checkbox {
  position: relative;
  display: inline-flex;
  cursor: pointer;
}

.custom-checkbox>span {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
  border: 1px solid #589dd8;
}

.custom-checkbox>span>svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  transform: scale(1.3);
  color: var(--seebiz-light-blue);
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.custom-checkbox>input[type='checkbox'] {
  opacity: 0;
}

.custom-checkbox>input[type='checkbox']:checked+span>svg {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

/*custom checkbox----->*/

.table li {
  list-style: none;
}

.table-body-scroll {
  max-height: calc(100vh - 26rem - 1px);
  /*overflow: auto;*/
  overflow: visible;
  display: block;
}

.table-body-scroll-full {
  height: calc(100vh - 27rem - 1px);
  overflow: auto;
  /*overflow: visible;*/
  display: block;
}

/*<-----headers*/

.header-title {
  height: 5rem;
  font-size: 2rem;
  color: #3e3e3e;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.subheading {
  font-size: 1.6rem;
  color: #7e7e7e;
}

/*headers----->*/

/*<-----chips*/

.chips-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 5.5rem;
}

.chip {
  display: flex;
  margin-right: 1.6rem;
  padding: 0.8rem 0.6rem 0.8rem 0.8rem;
  background-color: var(--background);
  border-radius: 4px;
  line-height: 2;
  margin-top: 0.8rem;
}

.chip-delete {
  border: 0;
  outline: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: var(--icon-primary);
}

.chip-delete svg {
  width: 2.4em;
  height: 2.4rem;
}

.chip-label {
  padding-left: 0.5rem;
}

/*chips----->*/

/*<-----files input*/

input[type='file'].input-files {
  display: none;
}

.input-files--btn {
  display: block;
  color: var(--blue-500);
  cursor: pointer;
  margin-top: 0.8rem;
}

/*files input----->*/

button:hover svg {
  /* fill: var(--blue-500); */
  /* commenting this to disable fill color on icons in three dot menu all system*/
  transition: all 0.3s ease-in-out;
}

.remove_row_button>button:focus {
  /*fill: var(--blue-500);*/
  /* commenting this to disable fill color on icons in three dot menu all system*/
  /* border: 0.1rem dashed #d0d0d0;; */
  border-radius: 0px;
}

/*<-----discount field*/

.discount--dropdown .searchable-dropdown__control {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
  margin-right: -1px;
}

/*<-----side data with field*/

.with-side-data--right {
  border-radius: 4px 0 0 4px;
}

.side-data--right {
  margin-left: -1px;
  display: flex;
  height: 3.2rem;
  align-items: center;
  border: 1px solid var(--text-field-border);
  border-radius: 0 4px 4px 0;
  word-break: keep-all;
}

.form-table .side-data--right {
  border-color: transparent;
  margin-left: 0;
}

.form-table .side-data--right .form-field {
  min-width: unset;
}

.with-side-data--left {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}

.side-data--left {
  display: flex;
  height: 3.2rem;
  align-items: center;
  padding: 0 1rem;
  border: 1px solid var(--text-field-border);
  border-radius: 4px 0 0 4px;
}

.side-data--left .searchable-dropdown {
  margin: 0 -1.1rem;
}

.table--summary {
  display: grid;
  grid-row-gap: 0.8rem;
  /*justify-content: right;
  text-align: right;*/
  margin: 0.8rem 0 0.8rem auto;
  max-width: 45rem;
  word-break: break-word;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.p_10 {
  padding: 10px;
}

.px_10 {
  padding: 0 10px;
}

.mt_3 {
  margin-top: 10px;
}

.mb_3 {
  margin-bottom: 10px;
}

.summary_table hr {
  border-top: 1px solid #dfe1e5 !important;
}

.pr {
  position: relative;
  top: 20px;
}

.details_header_left .details-header {
  padding: 2.4rem 0 2.4rem 0 !important;
}

:where(.purchase_order_details .purchase-order-main.details_header_left .details-header, .purchase-order-main > .section-details--info) {
  padding-top: 20px !important;
}

.purchase_order_details .purchase-order-main.details_header_left .details-header {
  padding-top: 20px !important;
}

.table.sub_tabs_transaction_main.details-table.bill_details_Table tbody tr {
  border-bottom: 1px solid var(--border-light) !important;
}

.summary_table input {
  width: 14.5rem !important;
}

.adjust_width {
  border: 1px solid #dfe1e5 !important;
}

.pr_total {
  position: relative;
  top: 9px;
  font-size: 16px;
}

.fs_16 {
  font-size: 16px !important;
}

/*side data with field----->*/

.image-uploader--dnd-icon {
  width: 100%;
  font-size: 3rem;
}

/*<-----react-offline-notify*/

.offline-toast-container {
  z-index: 9999;
}

/*react-offline-notify----->*/

.top-header {
  padding: 0 var(--padding-horizontal);
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: white; */
  background-color: #dfe1e5;
  /* box-shadow: var(--shadow-medium); */
  /* margin-bottom: 10px; */
}

.small-td-info {
  font-size: 1rem;
  display: block;
  color: var(--text-secondary);
}

.small-td-info-description {
  font-size: 1rem;
  display: block;
  /* text-align: justify; */
  margin-top: 10px;
}

.small-td-info-description.in-modal {
  min-width: 100%;
  max-width: 20rem;
}

.item_modal_dec {
  border: 1px solid #dfe1e5 !important;
  outline: none !important;
}

.item_modal_dec:focus {
  border: 1px solid #878787 !important;
  outline: none !important;
}

.item-level-discount {
  width: 15rem;
}

.baseline {
  align-items: baseline !important;
}

.new_item_icon,
.new_report_icon {
  position: relative;
  top: 2px;
}

.new_sales_return {
  position: relative;
  top: -2px;
}

.new_contact_icon {
  width: 17px;
}

.collapse-navigation .ims-nav--link-icon {
  margin-left: 8px;
}

.collapse-navigation .ims-nav-top-margin-plus {
  margin-top: 2px;
  margin-bottom: -2px;
}

.collapse-navigation .ims-nav-top-margin-plus2 {
  margin-top: 5px;
  margin-bottom: -5px;
}

.settings_nav_icon {
  position: relative;
  top: -2px;
  width: 16px;
  height: 16px;
}

.collapse-navigation .ims-nav-top-margin-minus {
  margin-top: -6px;
  margin-bottom: 4px;
}

.collapse-navigation .ims-nav-left-margin-plus2 {
  margin-left: 2px;
}

.collapse-navigation .ims-nav-left-margin-plus3 {
  margin-left: 3px;
}

.collapse-navigation .ims-nav-left-margin-plus4 {
  margin-left: 4px;
}

.collapse-navigation .ims-nav-menu-text {
  margin-left: 0px;
  display: none;
}

.collapse-navigation .item-expand-icon {
  display: none;
}

.discount_switch .form-field {
  float: left;
}

.discount_switch .form-field div {
  float: left;
  width: 100%;
  border: 1px solid #e0e2e5;
  padding: 5px;
  border-radius: 4px;
  transition: 0.3s;
}

.discount_switch .form-field div:hover,
.discount_switch .form-field div:focus-within {
  border: 1px solid #000 !important;
}

.discount_switch .form-field div .side-data--right .switch-wrapper:hover {
  border: 1px solid transparent !important;
}

.discount_switch .form-field div span {
  display: block !important;
  width: 100%;
  position: relative;
  float: left;
}

.discount_switch .form-field div input {
  float: left;
  height: 2.8rem !important;
  width: 50% !important;
  border: 1px solid transparent !important;
}

.discount_switch .form-field div .side-data--right {
  float: left !important;
  margin-left: 0 !important;
  display: block !important;
  height: 2.8rem;
  width: 50% !important;
  border: 0 !important;
}

.discount_switch .form-field div .side-data--right .switch-wrapper {
  float: left;
  width: 100%;
  height: 100%;
  padding: 0 !important;
}

.discount_switch .form-field div .side-data--right .switch-wrapper .switch-label {
  background: #e3e3e3;
  /* padding: 0.75rem 1.0rem; */
  border-radius: 0px !important;
  float: left;
  width: 50%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.discount_switch .form-field div .side-data--right .switch-wrapper .switch-label.switch-active {
  background: #3bb54a !important;
}

.mb_30 {
  margin-bottom: 30px;
}

.discount_switch_summary_table div {
  float: right;
  width: 14.5rem !important;
  border: 1px solid #e0e2e5;
  padding: 5px;
  border-radius: 4px;
  transition: 0.3s;
}

.discount_switch_summary_table div.input-feedback {
  border: 0;
  top: 78%;
}

.cancel_svg {
  width: 25px !important;
  height: 20px !important;
  margin-left: -1%;
}

.discount_switch_summary_table div.input-feedback:hover {
  border: 0 !important;
}

.discount_switch_summary_table div:hover,
.discount_switch_summary_table div:focus-within {
  border: 1px solid #878787 !important;
}

.discount_switch_summary_table .form-field div .side-data--right .switch-wrapper:hover {
  border: 1px solid transparent !important;
}

.discount_switch_summary_table div span {
  display: block !important;
  width: 100%;
  position: relative;
  float: left;
}

.discount_switch_summary_table div input {
  float: left;
  height: 2.8rem !important;
  width: 50% !important;
  border: 1px solid transparent !important;
}

.discount_switch_summary_table div .side-data--right {
  float: left !important;
  margin-left: 0 !important;
  display: block !important;
  height: 2.8rem;
  width: 50% !important;
  border: 0 !important;
}

.discount_switch_summary_table div .side-data--right .switch-wrapper {
  float: left;
  width: 100% !important;
  height: 100%;
  padding: 0 !important;
}

.discount_switch_summary_table div .side-data--right .switch-wrapper:hover {
  border: 0 !important;
}

.discount_switch_summary_table div .side-data--right .switch-wrapper .switch-label {
  background-color: #e3e3e3;
  /* padding: 0.75rem 1.0rem; */
  border-radius: 0px !important;
  float: left;
  width: 50%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  transition: background-color 0.3s ease;
}

.discount_switch_summary_table div .side-data--right .switch-wrapper .switch-label.switch-active {
  background-color: #3bb54a !important;
}

.bill-table-width {
  width: 97.5% !important;
}

/* Bill Enhancer Styling */

.create_bill_enchancer {
  /* width: 131rem; */
  width: 124rem;
  float: left;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package thead tr th:first-child {
  float: left;
  width: 25% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package .list-table--amount .close-btn.button {
  top: 5px;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package thead tr th:nth-child(2),
.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package thead tr th:nth-child(3) {
  float: left;
  width: 15% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package thead tr th {
  float: left;
  width: 9% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package tbody tr td:first-child {
  float: left;
  width: 25% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package tbody tr td:nth-child(2),
.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package tbody tr td:nth-child(3) {
  float: left;
  width: 15% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package tbody tr td {
  float: left;
  width: 9% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package tbody tr td:last-child {
  float: left;
  width: 9% !important;
  min-width: 9% !important;
}

.ims-modal--body .create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package tbody tr td:last-child {
  top: 10px;
}

.item-purchase-sales-info div:nth-child(2n) div {
  width: 80%;
}

/* .create_bill_enchancer
  .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package
  input {
  border: 1px solid #dfe1e5;
} */

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package .searchable-dropdown input {
  border: 0 !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package .searchable-dropdown input {
  border: 0 !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package tbody td tr span {
  position: relative;
  top: 9px;
}



.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field thead tr th:first-child {
  float: left;
  width: 25% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field thead tr th:nth-child(2),
.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field thead tr th:nth-child(3) {
  float: left;
  width: 15% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field thead tr th {
  float: left;
  width: 11.25% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field tbody tr td:first-child {
  float: left;
  width: 25% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field tbody tr td:nth-child(2),
.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field tbody tr td:nth-child(3) {
  float: left;
  width: 15% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field tbody tr td {
  float: left;
  width: 11.25% !important;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field tbody tr td:last-child {
  float: left;
  /* width: 11.25% !important;
  min-width: 11.25% !important; */
  margin-top: 10px;
}

.create_bill_enchancer .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field .list-table--amount .close-btn.button {
  top: 5px;
}

.create_bill_enchancer .searchable-dropdown__control {
  padding: 0 0.8rem !important;
}

.create_bill_enchancer input {
  padding: 0 1.4rem;
}

.create_bill_enchancer .searchable-dropdown .searchable-dropdown__value-container {
  padding: 0 0rem !important;
}

.fourcolum {
  float: left;
  width: 20%;
}

.fourcolum input {
  width: 236px !important;
}

/* Bill Enhancer Styling */

.w-109rem {
  min-width: 109rem !important;
}

.recieve_popup table thead {
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  background: #fff;
  line-height: 1.42857143 !important;
  box-shadow: 0 0 10px #ccc;
}

.recieve_popup .form-table.list-table th,
.recieve_popup .form-table.list-table td {
  border: 0;
}

.recieve_popup .form-table.list-table thead tr th,
.recieve_popup .form-table.list-table tbody tr td {
  float: left;
  height: auto;
  width: 17.5%;
}

.recieve_popup table {
  float: left;
  width: 100%;
}

.recieve_popup table thead,
.recieve_popup table tbody {
  float: left;
  width: 100%;
}

.recieve_popup table thead tr,
.recieve_popup table tbody tr {
  float: left;
  width: 100%;
}

.recieve_popup table tbody tr td {
  height: 6rem;
}

.recieve_popup table tbody tr td:nth-child(2),
.recieve_popup table tbody tr td:nth-child(3),
.recieve_popup table tbody tr td:nth-child(4) {
  position: relative;
  top: 7px;
  height: auto;
}

.recieve_popup .form-table.list-table thead tr th:first-child,
.recieve_popup .form-table.list-table tbody tr td:first-child {
  float: left;
  width: 30%;
}

.billed_popup table thead {
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  background: #fff;
  line-height: 1.42857143 !important;
  box-shadow: 0 0 10px #ccc;
}

.billed_popup .form-table.list-table tbody {
  border: 0 !important;
}

.billed_popup .form-table.list-table th,
.billed_popup .form-table.list-table td {
  border: 0;
}

.billed_popup .form-table.list-table td {
  border-bottom: 1px solid var(--border-light) !important;
}

.billed_popup .form-table.list-table {
  float: left;
  width: 98%;
}

.billed_popup table thead,
.billed_popup table tbody {
  float: left;
  width: 100%;
}

.billed_popup table thead tr,
.billed_popup table tbody tr {
  float: left;
  width: 100%;
  padding-right: 1px solid var(--border-light);
}

.billed_popup table tbody tr td {
  height: 4rem;
}

.billed_popup .form-table.list-table thead tr th,
.billed_popup .form-table.list-table tbody tr td {
  float: left;
  width: 20%;
  display: flex;
  align-items: center;
}

.billed_popup .form-table.list-table thead tr th:nth-child(2),
.billed_popup .form-table.list-table tbody tr td:nth-child(2) {
  float: left;
  width: 60%;
}

.billed_popup .receive-unbilled-table.form-table .searchable-dropdown.medium {
  min-width: 100% !important;
}

/* Attachments Button */

.attachemnt_buttons .custom-dropdown-container {
  width: 18rem !important;
  padding: 0 !important;
}

.attachemnt_buttons .custom-dropdown-container div {
  position: relative;
  float: left;
  width: 100%;
}

.attachemnt_buttons .custom-dropdown-container button {
  float: left;
  width: 100%;
  float: left;
  width: 100%;
}

.attachemnt_buttons .custom-dropdown-container button {
  float: left;
  width: 100%;
  color: #000;
  padding-left: 7px !important;
  text-align: left;
  margin: 0 !important;
  background-color: #fff !important;
  padding: 0 0 0 7px !important;
  box-shadow: none !important;
}

.attachemnt_buttons .custom-dropdown-container .icon_1 {
  position: absolute;
  right: 25px;
  width: 13px;
  height: 13px;
  top: 7px;
  cursor: pointer;
}

.attachemnt_buttons .custom-dropdown-container .icon_2 {
  position: absolute;
  right: 5px;
  width: 13px;
  height: 13px;
  top: 7px;
  cursor: pointer;
}

.attachemnt_buttons .custom-dropdown-container .form-field {
  padding: 0 !important;
}

.attachemnt_buttons .custom-dropdown-container div label {
  border-bottom: 1px solid #efefef;
  margin-top: 0;
  padding: 0 8px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  float: left;
  width: 100%;
}

.attachemnt_buttons .custom-dropdown-container div label svg {
  position: absolute;
  right: 5px;
  width: 16px;
  height: 16px;
  top: 5px;
}

.parent_custom_dropdown .custom-dropdown-container.down {
  top: 3.5rem;
}

.parent_custom_dropdown {
  position: relative;
}

.parent_custom_dropdown span {
  position: absolute;
  /* right: 6px;
  top: 2px; */
  right: 4px;
  top: -4px;
}

.new_bill_modal_title {
  display: flex;
  align-items: center;
}

.parent_custom_dropdown button {
  padding: 0.8rem 0.5rem !important;
}

.parent_custom_dropdown button svg {
  height: 18px;
  width: 18px;
}

.parent_custom_dropdown button:hover {
  box-shadow: var(--hover-box-shadow);
  background-color: #e9e9e9 !important;
  border-radius: 4px !important;
}

.attachemnt_buttons .custom-dropdown-container button:hover {
  box-shadow: none !important;
  background-color: transparent !important;
  color: #306ab4;
  margin: 0 !important;
  padding: 0 0 0 7px !important;
}

.attachment_span {
  font-weight: bold;
}

.bottom_attachment_area {
  float: right;
  padding: 5px 14px;
}

.bottom_attachment_area svg {
  position: relative;
  top: 3px;
}

.attachment_span {
  position: relative;
  top: -2px;
}

.display-flex-row {
  display: flex;
  flex-direction: row;
  /* Attachments Button */
}

.record-payment-form {
  width: 106rem !important;
}

.bill_listing thead tr th:nth-child(5) {
  width: 32% !important;
}

.expanded_view_bill thead tr th:nth-child(6) {
  padding-left: 1.5% !important;
}

.received-payment.compressed-list-main th:nth-child(2),
.compressedscreen.salesorder_listing th:nth-child(2),
.package-list-compressed th:nth-child(2),
.expanded_view_sale_return th:nth-child(2),
.expanded_view_creditnote th:nth-child(2),
.purchase_order_table_listing .compressedscreen thead tr th:nth-child(2),
.expanded_view_invoice th:nth-child(2),
.expanded_view_bill thead tr th:nth-child(2) {
  width: 16% !important;
}

.purchase_order_table_listing .compressedscreen thead tr th:nth-child(3),
.compressedscreen.salesorder_listing th:nth-child(3) {
  width: 45%;
}

.received-payment.compressed-list-main th:nth-child(3),
.expanded_view_invoice th:nth-child(3),
.package-list-compressed th:nth-child(3),
.expanded_view_creditnote th:nth-child(3),
.expanded_view_bill thead tr th:nth-child(3) {
  width: 52% !important;
}

.purchase_order_table_listing .compressedscreen thead tr th:nth-child(5),
.sales_order_table_listing table.compressedscreen thead tr th:nth-child(5) {
  width: 10%;
}

.package-list-compressed td:nth-child(3) .list-table--link .list_items_desc,
.expanded_view_bill td:nth-child(3) .list-table--link .list_items_desc,
.purchase-compressed td:nth-child(3) .list-table--link .list_items_desc {
  white-space: unset;
}

.received-payment.compressed-list-main th:nth-child(4) {
  width: 20%;
}

.invoice_listing thead tr th:nth-child(4) {
  width: 40% !important;
}


.expanded_view_invoice thead tr th:nth-child(5) {
  padding-left: 4% !important;
}

.expanded_view_sale_return tbody tr td:nth-child(5) {
  text-align: center;
  width: 10% !important;
}

.sale_return_listing thead tr th:nth-child(5) {
  width: 40% !important;
}

.sale_return_listing thead tr th:nth-child(8) {
  text-align: right;
  width: 10% !important;
}

.sale_return_listing tr td:nth-child(8) {
  text-align: right;
}

.creditnote_listing thead tr th:nth-child(2) {
  width: 10% !important;
}

.creditnote_listing thead tr th:nth-child(4) {
  width: 40% !important;
}

.expanded_view_creditnote thead tr th:nth-child(5) {
  padding-left: 1% !important;
  text-align: right;
  /* padding-left: 15% !important; */
}

.creditnote_listing thead tr th:nth-child(7) {
  text-align: right;
  width: 10% !important;
}

.creditnote_listing tr td:nth-child(7) {
  text-align: right;
}

.creditnote_listing thead tr th:nth-child(8) {
  text-align: right;
  width: 10% !important;
}

.creditnote_listing tr td:nth-child(8) {
  text-align: right;
}

.add_invite .form-field {
  width: auto !important;
}

.height_buttons_bottom {
  height: calc(100vh - 317px);
  overflow: auto;
}

.action_buttons_add_role {
  margin-right: 2rem !important;
}

.button_right {
  position: absolute;
  top: 0;
  left: 100%;
  color: var(--red-500);
}

.delivery_icon {
  height: 23px !important;
  width: 23px !important;
}

.ims-modal--body div div .detail_component_inner {
  height: unset !important;
  animation-duration: 0s !important;
  /* adjust the above line for animation tranisition delay, It will be applied to all modals. */
}


.inventory_Accounts {
  height: 17px !important;
  width: 17px !important;
  position: relative;
  top: 8px;
}

.currency_icon {
  width: 23px !important;
  height: 23px !important;
  top: 5px !important;
}

.align-qty-receive-right input {
  text-align: right;
  margin-top: 5px;
}

.invoice_form_main .form-field.inline-field.invoice-term-set {
  width: 22%;
  /* margin-right: 2.3rem; */
}

.header_right_Sec .page-count-header .searchable-dropdown {
  width: 75px !important;
  border: 0 !important;
  height: 30px !important;
  margin-right: 0;
  /* margin-left: 10px; */
}

.searchable-dropdown .searchable-dropdown__value-container {
  padding: 0 0.3rem;
}

.header_right_Sec .page-count-header .searchable-dropdown .css-1uccc91-singleValue {
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* details + listing changes by sir Munaim */
.expanded_view_creditnote th:nth-child(5),
.expanded_view_creditnote td:nth-child(5),
.expanded_view_bill th.text-right,
.expanded_view_bill td:nth-child(5) {
  display: none;
}

.__dragDrop .__drag-righ {
  min-width: 240px;
}

.height_custom_adjustment .details-table th:hover,
table.table.sub_tabs_transaction_main.details-table.group_items_table th.pointer:hover {
  background-color: #e9e9e9 !important;
  color: unset;
}

.td-hover-unset th:hover,
.uiux_bills_summary th:hover,
.po-details-main .details-table th:hover,
.cn-summary th:hover,
.uiux_sr_summary th:hover,
.received-payment-detail th:hover,
.uiux_invoice_table_summary th:hover,
.uiux_so_table_summary th:hover {
  background-color: #fff !important;
  color: unset;
}

/* Responsiveness of pagination */

@media (max-width: 1366px) {

  .h3,
  h3 {
    font-size: 20px;
  }

  .invoices_desc_left_child .country+div {
    display: block;
    float: left;
    width: 100%;
  }

  .invoices_desc_left_child .country+div .contact {
    width: 100%;
  }

  .invoices_desc_left_child .country+div .contact span {
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .alphabatical_listing td {
    height: 90% !important;
  }
}

@media (max-width: 1800px) {

  .po-details-main .table.details-table td:last-child,
  .po-details-main .details-table td[data-heading='Price'] {
    padding-right: 1.7rem !important;
  }

  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(3),
  .compressedscreen.salesorder_listing th:nth-child(3) {
    width: 42%;
  }
}

@media (max-width: 1600px) {

  .UIUX_listing_amount .details-table th:nth-child(1),
  .invoice-summary.details-table th:nth-child(1),
  .invoice-summary.details-table td:nth-child(1),
  .sales-order-summary-main.details-table th:nth-child(1),
  .sales-order-summary-main.details-table td:nth-child(1) {
    width: 30%;
  }

  .po-details-main th:nth-child(4),
  .invoice-summary.details-table th:nth-child(3),
  .invoice-summary.details-table td:nth-child(3),
  .invoice-summary.details-table th:nth-child(4),
  .invoice-summary.details-table td:nth-child(4),
  .invoice-summary.details-table th:nth-child(2),
  .invoice-summary.details-table td:nth-child(2)

  /* .sales-order-summary-main.details-table th:nth-child(2)  */
    {
    width: 8% !important;
  }

  /* .sales-order-summary-main.details-table th:nth-child(4) {
    width: 9%;
  } */

  .UIUX_report_table_inner .table-header td:nth-child(6),
  .UIUX_report_table_inner .table-header td:nth-child(7),
  .sales-order-summary-main.details-table td:nth-child(4),
  .sales-order-summary-main.details-table td:nth-child(5),
  .sales-order-summary-main.details-table td:nth-child(3)

  /* .sales-order-summary-main.details-table th:nth-child(3)  */
    {
    width: 10%;
  }

  .sales-order-summary-main.section-details--footer-amount div span:first-child {
    width: 80%;
  }

  .sales-order-summary-main.details-table th:nth-child(5) {
    width: 7%;
  }

  .invoice-summary.details-table th.invoice-discount-th {
    width: 3%;
  }

  .sales-order-summary-main.details-table td:last-child {
    padding-right: 2.1rem !important;
  }

  .sales-order-summary-main.details-table.invoice-summary td:last-child {
    padding-right: 2rem !important;
    width: 4%;
  }

  .sales-order-summary-main.details-table.invoice-summary th:last-child {
    width: 30px;
  }

  .po-details-main .table.details-table td:last-child,
  .po-details-main .details-table td[data-heading='Price'] {
    padding-right: 0.3rem !important;
  }

  .po-details-main .table.details-table td:last-child {
    padding-right: 0.4rem !important;
  }

  html body .po-details-main .table.details-table td:last-child {
    width: 11% !important;
    padding-right: 0.5rem !important;
  }

  html body .po-details-main .details-table td[data-heading='Price'] {
    padding-right: 0.1rem !important;
  }

  .received-payment.compressed-list-main th:nth-child(3),
  .expanded_view_invoice th:nth-child(3),
  .package-list-compressed th:nth-child(3),
  .expanded_view_creditnote th:nth-child(3),
  .expanded_view_bill thead tr th:nth-child(3) {
    width: 49% !important;
  }

  .received-payment.compressed-list-main th:nth-child(2),
  .compressedscreen.salesorder_listing th:nth-child(2),
  .package-list-compressed th:nth-child(2),
  .expanded_view_sale_return th:nth-child(2),
  .expanded_view_creditnote th:nth-child(2),
  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(2),
  .expanded_view_invoice th:nth-child(2),
  .expanded_view_bill thead tr th:nth-child(2) {
    width: 18% !important;
  }

  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(5),
  .sales_order_table_listing table.compressedscreen thead tr th:nth-child(5) {
    width: 7%;
  }

  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(3),
  .compressedscreen.salesorder_listing th:nth-child(3) {
    width: 37%;
  }
}

@media (max-width: 1366px) {

  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(3),
  .compressedscreen.salesorder_listing th:nth-child(3) {
    width: 36%;
  }
}

/*============error field styling=============*/
.purchase-order-set .form-field.error .input-feedback,
.create_bills_feild .form-field.error .input-feedback,
.uiux-invoice-form form>.invoice-main-set>.form-field.error .input-feedback,
.customer-name-fileds .input-feedback {
  text-align: right;
  right: 31px;
}

.asyn-paginate-custom.fieldError {
  border: 1px solid var(--red-500);
  border-radius: 4px;
}

/*============Wrapping Pricing Table=============*/

@media(max-width:1536px) {
  .header_right_Sec .page-count-header .searchable-dropdown {
    width: 60px !important;
  }

  .header_right_Sec .page-count-header .searchable-dropdown .css-1uccc91-singleValue {
    padding-left: 0;
  }

  .searchable-dropdown .searchable-dropdown__value-container {
    padding: 0 0.7rem;
    /* justify-content: center; */
  }
}

@media screen and (max-width: 1440px) {

  .po-details-main .details-table thead tr th:nth-child(3),
  .po-details-main .details-table tbody tr td:nth-child(3) {
    width: 10%;
  }

  .po-details-main .details-table thead tr th:nth-child(4),
  .po-details-main .details-table tbody tr td:nth-child(4),
  .po-details-main .details-table thead tr th:nth-child(5),
  .po-details-main .details-table tbody tr td:nth-child(5) {
    width: 13%;
    text-align: right;
  }
}

@media screen and (min-width: 991px) and (max-width: 1450px) {

  /* .porfile_2 .desc{
    padding-left: 15px;
  } */
  .porfile_2 .desc {
    padding-left: 15px;
  }

  .parent-component #listing_component {
    width: 35%;
  }

  .parent-component .detail_component {
    width: 65%;
  }

  html body .po-details-main .table.details-table td:last-child {
    padding-right: 0.7rem !important;
  }
}

@media(max-width:1400px) {
  #listing_component .section-header--dropdown .custom-dropdown {
    max-width: 75px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1400px) {
  .parent-component #listing_component {
    width: 35%;
  }

  .parent-component .detail_component {
    width: 65%;
  }

  /* #listing_component .section-header--pagination .section-header span .section-header--dropdown {
    max-width: 90px;
  } */



  .header_right_Sec .pagination-container {
    font-size: 12px;
  }

  #listing_component .pagination-input {
    width: 3rem;
  }

  /* .porfile_2 .image{
    width: 13%;
  } */
  .porfile_2 .desc {
    width: 87%;
  }
}

@media (max-width: 1280px) {

  .status .salesorder_compressed_status,
  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(4),
  .compressedscreen.salesorder_listing th:nth-child(4) {
    width: initial !important;
  }


  .expanded_view_bill thead tr th:nth-child(2),
  .expanded_view_creditnote th:nth-child(2),
  .expanded_view_invoice th:nth-child(2),
  .expanded_view_sale_return th:nth-child(2),
  .package-list-compressed th:nth-child(2),
  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(2),
  .received-payment.compressed-list-main th:nth-child(2) {
    width: 20% !important;
  }

  .compressedscreen.salesorder_listing th:nth-child(2) {
    width: 30% !important;
  }

  .expanded_view_bill thead tr th:nth-child(3),
  .expanded_view_creditnote th:nth-child(3),
  .expanded_view_invoice th:nth-child(3),
  .package-list-compressed th:nth-child(3),
  .received-payment.compressed-list-main th:nth-child(3) {
    width: 41%;
  }

  #listing_component .section-header--dropdown .custom-dropdown {
    max-width: 65px;
    min-width: 65px;
  }
}

@media (max-width: 1200px) {
  html body .uiux-po-drop .section-details--info.package_details {
    width: 50% !important;
  }

  html body .uiux-po-drop .po-details-main .table.details-table td:last-child {
    padding-right: 2.2rem !important;
  }
}

@media screen and (min-width: 991px) and (max-width: 1200px) {
  .parent-component #listing_component {
    width: 38%;
  }

  .parent-component .detail_component {
    width: 62%;
  }
}

/* Responsiveness of pagination */

.display-flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.sales-return-main .menu-component--menu.menu_top {
  position: initial;
  left: 0;
  width: 125.3%;
  margin-left: -13% !important;
}

.sales-return-main .menu_top_content.box_shadow_custom {
  position: relative;
  margin-top: -50px;
  margin-bottom: 40px;
  height: 100%;
}

.sales-return-main .sales_order_info {
  padding-top: 0px;
}

.sales-return-main .close_icon:first-child {
  display: none;
}

/* .detais-component-main .sales-return-main .menu_top_content.box_shadow_custom { */
/* min-height: 255px;
  max-height: 255px;
  overflow-y: auto;
  scrollbar-width: thin;
  z-index: 10;*/
/* } */

.h-255 {
  max-height: 255px;
  min-height: 255px;
}

.payment-desc-set p {
  word-break: break-all;
}

.default-attached-file span,
.chips-container .chip-label {
  max-width: 210px;
  min-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.default-attached-file,
.chips-container .chip {
  min-width: 290px;
  max-width: 290px;
}

@media (max-width: 1199px) {

  .sales-return-details .custom-dropdown-container .custom-dropdown--options:nth-child(1),
  .fullscreen.salesorder-main-list td.list-table--options button.custom-dropdown--options:nth-child(3),
  /* .expanded_view_bill .custom-dropdown-container .custom-dropdown--options:nth-child(3), */
  /* .sales-return-main .custom-dropdown-container .custom-dropdown--options:nth-child(1), */
  .expanded_view_sale_return .custom-dropdown-container button.custom-dropdown--options:nth-child(4),
  .expanded_view_sale_return .returnReceive_menu .pointerIn .custom-dropdown-container .custom-dropdown--options:nth-child(1),
  .expanded_view_creditnote td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(3),
  .invoice-scroll-set .creditnotes_menu button.custom-dropdown--options:nth-child(3),
  /* .expanded_view_invoice td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(4), */
  .pl_cp.pl_cp td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(3),
  /* .bill_listing td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(3), */
  /* .purchase_order_table_listing .lopi td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(2), */
  .creditnote_listing td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(3),
  .sale_return_listing.fullscreen td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(4),
  /* .linked-row.invoice_listing.invoice_listing td.list-table--options button.custom-dropdown--options:nth-child(4), */
  .linked-row.pl_ep.pl_ep td.list-table--options:last-child button.custom-dropdown--options:nth-child(3),
  .salesorder-mainlist .list-table--options .custom-dropdown-container.down .custom-dropdown--options:nth-child(3),
  .sales_order_set .salesReturn_menu td.pointerIn .custom-dropdown-container button.custom-dropdown--options:nth-child(4),
  .purchase-order-set .bills_menu td.pointerIn .custom-dropdown-container button.custom-dropdown--options:nth-child(2),
  .sales_return_details td.pointerIn button.custom-dropdown--options:nth-child(1),
  .sales_order_set .Packages_menu td.pointerIn .custom-dropdown-container button.custom-dropdown--options:nth-child(5),
  .sales_order_set .Invoices_menu td.pointerIn .custom-dropdown-container button.custom-dropdown--options:nth-child(3),
  /* #printMe svg#print, .secondry_buttons_group button[title="Print"], */
  .salesorder_listing.compressedscreen td.list-table--options button.custom-dropdown--options:nth-child(3),
  .salesorder_listing.compressedscreen td.list-table--options button.custom-dropdown--options:nth-child(3) {
    display: none;
  }

  .expanded_view_creditnote td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(2),
  .pl_cp.pl_cp td.list-table--options .custom-dropdown-container .custom-dropdown--options:nth-child(2) {
    display: block;
  }

  html body .bills-main-set .form-field.inline-field {
    padding: 8px 0 13px 0;
  }

  .so-invoice .order-no-set {
    width: 23%;
  }

  .so-edit-popup .form-field.inline-field:nth-child(2) {
    margin-top: 0;
  }

  .so-edit-popup .form-field.inline-field:nth-child(4),
  .so-invoice .form-field.inline-field:nth-child(4) {
    padding-top: 6px;
  }

  .so-edit-popup .order-no-set {
    width: 23%;
  }

  .invoice_form_main .form-field.inline-field.textarea-invoice {
    margin-right: 0;
  }

  .so-invoice-popup .modal_invoice_title_align {
    width: 100%;
  }

  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(3),
  .compressedscreen.salesorder_listing th:nth-child(3) {
    width: 39%;
  }

  .purchase_order_table_listing .compressedscreen tbody tr td:nth-child(4) span.status,
  .compressedscreen.salesorder_listing td:nth-child(4) span.status {
    margin-right: 0;
  }

  .compressedscreen.salesorder_listing td:nth-child(4) {
    text-align: right;
  }

  .create-so-form .__drag-righ {
    max-width: initial;
  }

  .purchase-order-set .form-field.error .input-feedback,
  .create_bills_feild .form-field.error .input-feedback,
  .uiux-invoice-form form>.invoice-main-set>.form-field.error .input-feedback,
  .customer-name-fileds .input-feedback {
    right: 0px;
  }

  .compressedscreen.salesorder_listing th:nth-child(2),
  .expanded_view_bill thead tr th:nth-child(2),
  .expanded_view_creditnote th:nth-child(2),
  .expanded_view_invoice th:nth-child(2),
  .expanded_view_sale_return th:nth-child(2),
  .package-list-compressed th:nth-child(2),
  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(2),
  .received-payment.compressed-list-main th:nth-child(2) {
    width: 18% !important;
  }

  .expanded_view_bill thead tr th:nth-child(3),
  .expanded_view_creditnote th:nth-child(3),
  .expanded_view_invoice th:nth-child(3),
  .package-list-compressed th:nth-child(3),
  .received-payment.compressed-list-main th:nth-child(3) {
    width: 49% !important;
  }

  .item-purchase-sales-info div:nth-child(2n) div {
    width: 100%;
  }

  /* .convert-bill-popup .SingleDatePicker_singleDatePicker_body__2JaHW, */
  .date-canalder-g .single-calander-main {
    right: 0;
  }
}

@media (max-width: 1099px) {

  .compressedscreen.salesorder_listing th:nth-child(2),
  .package-list-compressed th:nth-child(2),
  .expanded_view_sale_return th:nth-child(2),
  .expanded_view_creditnote th:nth-child(2),
  .purchase_order_table_listing .compressedscreen thead tr th:nth-child(2),
  .expanded_view_invoice th:nth-child(2),
  .expanded_view_bill thead tr th:nth-child(2) {
    width: 21% !important;
  }
}

@media(max-width:1024px) {

  /* .convert-bill-popup .SingleDatePicker_singleDatePicker_body__2JaHW, */
  .date-canalder-g .single-calander-main {
    right: auto;
  }
}

@media (max-width: 768px) {
  .searchable-dropdown .searchable-dropdown__value-container {
    padding: 0 0.1rem;
  }

  /* .header_right_Sec.float-right {
    width: 45% !important;
  } */

  .page-count-header .searchable-dropdown {
    width: 70px !important;
    font-size: 12px !important;
  }
}

@media (max-width: 720px) {
  .UIUX-item-single button.chip_btn.chip-btn-set {
    right: 4% !important;
  }
}

.unsync_salesOrder .layout--grid-container.grid-3-2.layout-padding {
  grid-template-columns: unset !important;
}

/* Design ammendment the discount label issue  by Sir Munaim*/
.discount_switch .form-field div input {
  padding-left: 0;
  padding-right: 0;
  width: 60% !important;
}

.discount_switch .form-field div .side-data--right {
  width: 40% !important;
  margin-left: 8% !important;
}

.header_right_Sec .searchable-dropdown__option {
  margin-bottom: 4px;
}

th.status {
  vertical-align: middle;
}

.purchase_order_table_listing .global-status-listing {
  height: unset;
}

.contact-detail-trans .list-table.linked-row td:nth-child(3) span {
  /* background: red; */
  max-width: 160px;
  display: inline-block;
}

.customized-tbl .list-table.linked-row tbody .table-row td:nth-child(3) span {
  max-width: 250px;
}

.customized-tbl .list-table.linked-row tbody .table-row td:nth-child(3) {
  width: 14%;
}

.no-customized-tbl .list-table.linked-row tbody .table-row td:nth-child(3) {
  width: 21%;
}