.item-details-images-container {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  float: left;
  /* width: 44%; */
  width: 36%;
  height: 346px;
  margin-bottom: 10px;
  position: relative;
}

.item-details-image--current {
  height: 100%;
  width: 100%;
  /* max-height: 335px !important;
  min-height: 335px !important; */
  /* object-fit: scale-down; */
  margin-bottom: 1rem;
  border: 1px solid var(--background);
  /* padding: 20px; */
  object-fit: contain;
}

.item_label {
  font-size: 1.38rem !important;
  font-weight: 400 !important;
}

.item-details-images-list {
  /* display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(5, 5rem);
  grid-template-rows: 5rem;
  grid-auto-rows: 4rem; */
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.item_fields_break {
  word-wrap: break-word;
  word-break: break-word;
}

.file_upload {
  float: left;
  width: 20%;
  padding: 5px;
  height: 65px;
  background: var(--background);
  transition: 0.3s;
}

.file_upload:hover {
  background-color: #306ab4 !important;
}

.file_upload:hover .FileUploader_fileUploader__2qxre {
  border-color: #fff !important;
}

.file_upload:hover svg {
  color: #fff;
}

.item-details-image-upload {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-details-image-upload svg {
  font-size: 2rem;
  color: var(--icon-primary);
}

.image_upladed {
  float: left;
  width: 20%;
  padding: 0px 5px;
  height: 65px;
}

.item-details-image {
  width: 100%;
  height: 100%;
  border: 1px solid var(--background);
  object-fit: contain;
  padding: 0 5px;
}

.item-details-image.selected {
  /* border: 1px solid var(--border-blue-dark); */
  /* New UI updated */
  border: 1px solid #33a348;
  object-fit: contain;
  padding: 0 5px;
}

.section-details--info {
  float: left;
  width: 40%;
  /* width: 56%; */
  padding-left: 20px;
}

.credit_note_details .details-header,
.credit_note_details .section-details--info {
  width: 50%;
}

.section-details--info .close_icon {
  height: auto;
  top: 4px;
}

.section-detail--info-main {
  float: left;
  width: 100%;
  padding: 3px;
  border-bottom: 1px solid #dfe1e5;
}

.section-detail--info-main {
  word-break: break-word;
}

.float-right {
  float: right;
}

.position-relative {
  position: relative;
}

.mt_1 {
  margin-top: 1%;
}

.px_5 {
  padding: 0 0 0 20px;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70% !important;
}

.w-50 {
  width: 50%;
}

.items_details .close_icon {
  height: auto !important;
  top: 15px !important;
}

.sub_tabs_table_transaction .contact-transaction--header {
  background-color: #f5f5f5;
  padding: 5px 10px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
}

.section-detail--info-main:last-child {
  border-bottom: 0 !important;
}

.text_justify {
  text-align: justify;
}

.chip_btn {
  border-radius: 50px;
  background-color: #1976d2;
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  position: absolute;
  right: 68.3%;
  /* top: 1%; */
  font-size: 10px;
}

.chip_btn_success {
  background-color: #5cb85c;
}

.view_item_groups_popup .border-right-set {
  width: 100% !important;
}

.view_item_groups_popup .item-group-main {
  align-items: center;
}

html body .inventory-track {
  background-color: var(--neutral-status);
  color: #000;
}

html body .inventory-untrack {
  background-color: var(--orange-status);
  color: #000;
}

html body .inventory-track,
html body .inventory-untrack {
  padding: 0.4em 0.85em;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.475rem;
  max-width: 90px;
  /* margin-top: 2px; */
  margin-bottom: 0;
}

.view_item_groups_popup .item-group-main .item-tag:nth-child(4n + 1) {
  margin-left: 0;
}

.view_item_groups_popup th:nth-child(2) {
  width: 20%;
}

.view_item_groups_popup th:nth-child(3),
.view_item_groups_popup th:nth-child(4),
.view_item_groups_popup th:nth-child(5) {
  width: 15%;
}

.view_item_groups_popup span.item_group_modal_title {
  padding-right: 2px;
  word-break: break-word;
}

@media (max-width: 1440px) {

  .detais-component-main .section-details--info,
  .sales_order_info .details-header {
    width: 50% !important;
  }
}

button.chip_btn.chip_btn_success,
button.chip_btn.chip-btn-set {
  right: 0% !important;
  left: auto;
}

@media (max-width: 1366px) {

  html body .items_group_main .section-details--info,
  .items_details .section-details--info {
    width: 54% !important;
  }

  .items_group_main .item-details-images-container,
  .item-details-images-container {
    width: 46% !important;
  }
}

@media (max-width: 1280px) {
  .transaction_tab_table th {
    font-size: 15px;
  }
}

@media (max-width: 1199px) {
  .close_icon.items-close {
    top: 4px !important;
    font-size: 20px;
    right: 15px;
  }

  .items_details .close_icon {
    top: 5px !important;
    font-size: 20px;
  }

  .items-tabs-responsive button.tab-component--button {
    margin: 0.3rem;
  }

  .transaction_tab_table th {
    font-size: 14px;
  }

  .items-main-form .images-uploading+.medium_large.mr_10.position-relative {
    width: 100% !important;
  }
}

@media (max-width: 1024px) {
  .item-details-images-container {
    width: 100%;
    height: 100%;
  }

  button.chip_btn.chip-btn-set {
    right: 51% !important;
    top: 0;
  }

  .detais-component-main .adjustment_summary_update .section-details--info,
  .detais-component-main .section-details--info {
    width: 100% !important;
    padding-left: 5px;
  }

  .parent-component #listing_component {
    width: 46%;
  }

  .parent-component .detail_component {
    width: 54%;
  }

  .item-details-image--current {
    max-width: 298px;
  }

  .item-details-images-list .image_upladed:first-child {
    padding-left: 0;
    padding-right: 0;
  }

  .item-details-images-list .image_upladed:nth-child(3),
  .item-details-images-list .image_upladed:nth-child(even) {
    padding-right: 0;
  }

  .ui-itemgroup-icon .close_icon,
  .items_details .close_icon {
    right: 0.8rem;
    position: fixed;
    top: 110px !important;
    background-color: #33a348;
    color: #fff;
    width: 43px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 18px;
    padding: 0;
    z-index: 99;
  }

  html body .item-group-fragment .close_icon {
    right: 8px;
  }

  .close_icon svg {
    position: relative;
    right: -11px;
  }

  .quantity_row.quantity-mob-row {
    padding: 10px 0px 0px 28px;
  }

  html body form.item-form.items-main-form .form-section--body>div.mt_178>.form-field:nth-child(2) {
    margin-right: 0 !important;
  }

  .item-details-images-list .file_upload {
    margin-left: 5px;
  }

  html body .item-form.items-main-form .form-section--body>div.form-field.inline-field,
  html body .item-form.items-main-form .form-section--body>div {
    width: 100% !important;
  }

  .items_details .section-detail--info-main:first-child {
    line-height: 20px;
  }

  .items_details button.chip_btn.chip-btn-set {
    right: 0 !important;
  }

  .items_details .close_icon {
    top: 13% !important;
  }

  .items_details .close_icon svg {
    top: 4px;
  }
}

@media (max-width: 991px) {
  .item-details-image--current {
    max-width: initial;
  }

  .item-details-images-list .file_upload {
    height: 65px;
  }
}

@media (max-width: 768px) {
  .items_main_set .section-details--info {
    padding-left: 0;
  }

  .items_sub_tabs.items-tabs-responsive {
    position: relative;
    top: 35px;
  }

  .activity_new.items_activity_set .left {
    width: 25%;
  }

  .activity_new.items_activity_set .border_structure {
    width: 13%;
  }

  .items-tabs-responsive .activity_new .right {
    width: 62%;
  }

  div.activity_new.items_activity_set {
    padding-left: 14px !important;
  }

  .item-details-images-list .file_upload

  /* .item-details-images-list .image_upladed  */
    {
    /* width: 8.2%; */
    min-width: 57px;
  }

  .chip_right {
    right: 73% !important;
  }

  .item_group_modal_title {
    align-items: center;
    display: flex;
    width: 90%;
    word-break: break-word;
  }

  button.chip_btn.chip-btn-set {
    right: 60% !important;
  }

  .items_details .float-left.w-80 {
    width: 100% !important;
    padding-right: 10px;
  }

  /* .item-details-images-list {
    padding-right: 30px;

  } */
}

@media (max-width: 720px) {
  button.chip_btn.chip-btn-set {
    right: 59% !important;
  }

  .item-details-image--current {
    max-width: 281px;
  }
}