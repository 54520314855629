/* .
.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3px;
  cursor: pointer;
  transition: all .5s ease-in-out;
}
.menu-btn__burger {
  width: 100%;
  height: 3px;
  background: #000;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: #000;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}
.menu-btn__burger::before {
  transform: translateY(-7px);
}
.menu-btn__burger::after {
  transform: translateY(7px);
}
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
} */

.tyt {
  float: left;
  width: 34px;
  position: relative;
  background-color: #dfe1e5;
  -webkit-box-shadow: 0px 0px 10px 0px #c6c8cb;
  -moz-box-shadow: 0px 0px 10px 0px #c6c8cb;
  box-shadow: 0px 0px 10px 0px #c6c8cb;
  height: 34px;
  padding: 0px 5px;
  transition: 0.4s;
}
.tyt .menu-btn {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.line {
  height: 3px;
  background-color: #000;
  margin-bottom: 4px;
  width: 22px;
  padding: 0 5px;
  transition: 0.5s;
}
.tyt:hover {
  background-color: #fff;
}
.hamburger_details {
  left: 0;
  width: 200px;
  background-color: #fff;
  position: absolute;
  top: 105%;
  visibility: hidden;
  border-radius: 10px;
  opacity: 0;
  box-shadow: 0 0 10px #ccc;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  padding: 10px 0px;
}
.hamburger_details a  {
  color: #000 !important;
  padding: 5px 0.5rem 5px 0.8rem !important;
  /* margin: 0 !important; */
  font-size: 12px;
  display: flex;
  width: 100%;
  
  text-decoration: none;
  border: 0;
  outline: 0;
  background-color: transparent;
  text-align: left;
  height: auto;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 5px;
  /* font-weight:500; */
  font-weight: normal;
  position: relative;
}
.hamburger_details button  {
  color: #000 !important;
  padding: 5px 0.5rem 5px 0.8rem !important;
  /* margin: 0 !important; */
  font-size: 12px;
  display: flex;
  width: 100%;
  color: #3d3d3d;
  text-decoration: none;
  border: 0;
  outline: 0;
  background-color: transparent;
  text-align: left;
  height: auto;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 5px;
  /* font-weight:500; */
  position: relative;
}
.hamburger_details button svg{
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 10px 0 0;
}
.hamburger_details a svg{position: absolute;right: 10px;top: 8px;}
.hamburger_details a:hover , .hamburger_details button:hover  {
  background-color: #dfe1e5;
}
.hamburger_details hr {
  margin: 2px 0 !important;
}
.hamburger_details .heading {
  color: #8e8e8e !important;
  font-weight: 500;
  padding: 2px 0.5rem 2px 0.8rem;
  margin-bottom: 5px;

}
.hamburger_details .heading:hover {
  background-color: transparent !important;
  font-weight: 500;
}
.open2 {
  opacity: 1;
  visibility: visible;
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
