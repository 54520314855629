.list-table {
  width: 100%;
  position: relative;
  /* z-index: 1; */
  /* overflow: hidden; */
}

.list-table.form-table {
  width: auto;
}

/* form fields with list table fixes */

.list-table .form-field {
  display: inline-flex;
  padding: 0;
  width: 100%;
}

.list-table .form-field.error .input-feedback {
  bottom: -1.6rem;
}

.form-table .form-field.error .input-feedback {
  right: auto;
  /* left: 0; */
}

.list-table.border tbody {
  border-left: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
}

.list-table.border thead {
  border-left: 1px solid var(--border-dark);
  border-right: 1px solid var(--border-dark);
}

.list-table.linked-row td {
  padding: 0;
}

.list-table.linked-row .list-table--link {
  display: block;
  /* padding: 1.2rem 0rem 1.2rem 1.2rem; */
}

.list_items_desc {
  word-wrap: break-word;
  white-space: break-spaces;
  word-break: break-word;
}

.total_count {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 0px 10px;
  -webkit-box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.35);
  float: left;
  width: 100%;
  text-align: right;
  height: 40px;
  line-height: 43px;
  font-weight: bold;
}

.total_count2 {
  float: left;
  width: 100%;
  text-align: right;
  height: 40px;
  line-height: 40px;
  padding: 0 1rem;
  font-weight: bold;
}

.list-table--wrapper {
  position: relative;
  min-height: 5rem;
  /* width: 100%;
  float: left;
  height: calc(100vh - 23.6rem);
  overflow: auto;
  scrollbar-width: thin;
scrollbar-color: #C7DCEB #dfedf7;  */
}

.list-table thead {
  /* background: var(--background); */
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  /* -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.35);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.35);
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.35); */
  background: #e9e9e9;
  line-height: 6px;
}

.list-table--item--col {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  line-height: 1.5;
  /* grid-template-columns: auto 1fr;
  grid-column-gap: 0.8rem; */
  word-break: break-word;
  /* width: -moz-max-content; */
  min-width: 100% !important;
  /* Commenting  because item name when compressed is breaking to new line - Babar */
  /* max-width: 20rem; */
}

.list-table--item--col img {
  width: 3.2rem;
  height: 3.2rem;
  /* object-fit: scale-down; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  object-fit: cover; */
  margin-right: 10px;
  object-fit: contain;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.list_items_desc span:nth-child(2) {
  line-height: 1.5;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  /* width: 220px; */
  max-width: 100%;
  padding: 0 20px 0 0 !important;
}

.list-table--user--col {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* grid-template-columns: auto 1fr;
  grid-column-gap: 0.8rem; */
  /* word-break: break-word; */
  /* width: -moz-max-content; */
  /* min-width: 20rem; */
  max-width: 100%;
}

.list-table--user--col img {
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-table th {
  font-weight: 600;
}

.list-table th:not(:first-child) {
  padding: 1.6rem 0rem 1.6rem 1.2rem;
}

.lopi th:not(:first-child) {
  padding: 0.9rem 0rem 0.9rem 0rem !important;
}

.list-table td {
  padding: 1.5rem 1rem;
  word-break: break-word;
}

/* .list-table th:first-child, .list-table td:first-child {
  padding-left: 0.6rem;
} */

.list-table th:last-child,
.list-table td:last-child {
  padding-right: 0rem;
}

.list-table--checkbox {
  /* width: 5rem; */
  padding-right: 0.6rem;
}

.list-table--checkbox-lable {
  margin: 0;
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  user-select: none;
  justify-content: center;
}

.list-table--checkbox-lable svg {
  height: 17px;
  width: 17px;
  border-radius: 1.5px;
}

/* .list-table tbody tr.selected svg rect{
  fill: #FFF !important;
  border: 1px solid;
  stroke: #000;
}
.list-table tbody tr.selected svg path{
  fill: #000 !important;
} */

/* .unchecked{height: 17px;width: 17px;border: 1px solid #000;} */
.list-table--checkbox-lable span svg {
  height: 17px;
  width: 17px;
}

.list-table--checkbox-lable > input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

.list-table--checkbox-lable > span {
  position: relative;
  width: 17px;
  height: 17px;
  border-radius: 2px;
  border: 1px solid #000;
  left: 0;
  right: 0;
  /* background: linear-gradient(180deg, #FFFFFF 0%, var(--background) 100%); */
}

.list-table tbody tr.selected .list-table--checkbox-lable > span {
  border: 0 !important;
}

/* .list-table tbody tr.selected .list-table--checkbox-lable>span svg{border: 1px solid !important;} */

.list-table tbody tr.selected .list-table--checkbox-lable > span {
  border: 0 !important;
}

/* .list-table tbody tr.selected .list-table--checkbox-lable>span svg{border: 1px solid !important;} */

.list-table tbody tr.selected .list-table--checkbox-lable > span {
  border: 0 !important;
}

/* .list-table tbody tr.selected .list-table--checkbox-lable>span svg{border: 1px solid !important;} */

.list-table--checkbox-lable > input[type='checkbox']:checked + span > svg {
  opacity: 1;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.collapsed_table {
  position: relative;
  left: -20px;
}

.ims-modal-container table thead tr th:first-child label span {
  left: -3px;
}

.list-table--checkbox-lable > span > svg {
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 0;
  left: -0.1rem;
  opacity: 0;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.list-table tbody tr {
  border-bottom: 1px solid var(--border-light);
}

.table-row:hover {
  background: #dcdee0 !important;
}

.form-table tbody tr:hover {
  background: transparent;
}

.list-table tbody tr.selected {
  background: var(--selected);
}

.list-table tbody tr.selected .list-table--checkbox-lable span {
  border: 1px solid !important;
}

.list-table tbody tr.selected svg rect {
  fill: #fff !important;
  /* border: 1px solid; */
  /* stroke: #000; */
}

.list-table--settings {
  /* width: 5rem; */
  text-align: end;
}

.list-table--settings span {
  margin-right: 7px;
}

.list-table--options {
  text-align: right;
}

/* .list-table--settings .custom-dropdown>.button{padding: 0 !important;} */

.custom-dropdown > .button {
  padding: 0.8rem 0rem;
}

.list-table--options .custom-dropdown .button:hover,
.list-table--settings .custom-dropdown .button:hover {
  background-color: transparent;
}

.list-table--settings .list-table--icon {
  text-align: right;
}

.list-table--link {
  color: unset;
  /* text-decoration: underline; */
}

.list-table--sort-header {
  color: unset;
}

.list-table .custom-dropdown svg {
  /* transform: rotate(90deg); */
  /* transform: rotate(90deg); */
  width: 25px;
  height: 25px;
  fill: #a3a3a3;
}

/* for testing purpose to fill the color to three dot meu in user tabs */
.list-table .custom-dropdown svg.custom-dropdown-icon {
  /* transform: rotate(90deg); */
  fill: #000;
}

.list-table .list-table--settings .custom-dropdown svg {
  transform: none;
}

.list-table--unit {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin-top: -0.4rem;
}

.form-table .list-table--amount {
  min-width: 9rem;
  max-width: 12.5rem;
}

.with-close--btn {
  position: relative;
}

.list-table--amount .close-btn.button,
.with-close--btn .close-btn.button {
  position: absolute;
  top: 0;
  left: 100%;
  color: var(--red-500);
}

.form-table td {
  height: 8rem;
}

.form-table.list-table th,
.form-table.list-table td {
  border: 1px solid var(--border-dark);
}

.form-table.list-table th {
  padding: 0.8rem;
}

.form-table.list-table td {
  padding: 0.4rem 0.8rem;
  vertical-align: top;
  word-break: break-all;
}

/* .character{
  background-color: #E3E3E3;
  margin-bottom: 6px;
  width: 25px;
  cursor: pointer;
  text-align: center;
} */

.lopi th {
  height: 43px !important;
  position: -webkit-sticky;
  position: sticky;
  top: -2px;
  z-index: 1;
  background: #fff;
  font-weight: bold;
  color: #000;
  /* z-index: 1001 !important */
  
}

/* .lopi.invoice_listing thead tr th , .lopi.invoice_listing tbody tr td{
  width: 15%;
}
.lopi.invoice_listing thead tr th:first-child , .lopi.invoice_listing tbody tr td:first-child{
  width: 5%;
}
.lopi.invoice_listing thead tr th:nth-child(7) , .lopi.invoice_listing tbody tr td:nth-child(7){
  width: 10%;
} */
.lopi.invoice_listing thead tr th:nth-child(8),
.lopi.invoice_listing tbody tr td:nth-child(8) {
  width: 10%;
  /* text-align: center !important; */
}
/* 
.lopi.invoice_listing tbody tr td:nth-child(8) .list-table--link span div {
  text-align: center;
} */

.lopi.invoice_listing thead tr th:nth-child(9),
.lopi.invoice_listing tbody tr td:nth-child(9) {
  width: 9%;
}

.lopi th:after {
  left: 0;
  float: left;
  width: 100%;
  bottom: 0px;
  position: absolute;
  padding: 2px;
  content: '';
  box-shadow: 0 4px 2px 0px rgba(0, 0, 0, 0.15);
}

.table_header_fix th {
  /* height: 43px !important; */
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1;
  background: #fff;
}

.section-header--pagination {
  height: 45px !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1002 !important;
  z-index: 2 !important;
  background: #e3e3e3 !important;
}
/* .contact-header-pagination{
  /* z-index: unset !important; */
  /* z-index: 9999 !important; */
/* }  */

/* .sorted-background{
  background: #cacace !important;
} */
.all-contacts-icon {
  cursor: pointer;
}

.list-table td {
  position: relative;
}

.border_contact {
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px 0px;
  /* background-color: #e3e3e3 !important; */
  width: 100%;
}

.view_items_popup {
  height: calc(100vh - 130px);
  overflow: hidden;
}

.view_items_popup .ims-modal--body {
  height: calc(100vh - 200px);
}

.view_items_popup .ims-modal--body .items_details .float-left.w-70 {
  width: 100% !important;
}

.item_view_click_btn {
  cursor: pointer !important;
  color: #0071bc;
}

.item_view_click_btn:hover {
  text-decoration: underline;
}

.create_invoice tr.table--row td:first-child div .list-table--item--col {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.create_invoice tr.table--row td:first-child div .list-table--item--col br {
  display: none;
}

.invoice-form span.list-table--item--col > span {
  width: 100%;
  position: relative;
  top: -41px;
  left: 15%;
  max-width: 85%;
}

.invoice-form {
  position: relative;
}

.items_main_list .list-table-icon span:first-child {
  width: 100%;
  flex: 0 60%;
}

.items_main_list .list-table-icon span:nth-child(2) {
  width: 100%;
  flex: 0 40%;
}
.show-bills th:nth-child(5),.show-bills td:nth-child(5) {
  padding-right: 1rem;
}
@media print {
  ::-webkit-scrollbar {
    display: none !important;
  }

  .list-table--wrapper,
  .main-layout-set {
    height: auto !important;
  }
}
@media(max-width:768px){
  .lopi.invoice_listing thead tr th:nth-child(9), .lopi.invoice_listing tbody tr td:nth-child(9){
    width: 15%;
  }
}