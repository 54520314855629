.bar-container {
  width: 190px;
  /* height: 10px; */
  /* margin: auto; */
  border-radius: 2.7px;
  background-color: #e9e9e9;
}

.mr__10 {
  margin-right: 10px;
}

.bar {
  background-color: #33a348;
  height: 10px;
  border-radius: 0px;
  width: 80%;
  transition: 0.3s;
}

.red_font {
  color: red;
}

.w_200px {
  width: 200px;
  float: left;
}

.dashboard_purchase_info {
  padding: 5px 15px;
}
.dashboard_purchase_info .puprchase_data .info-heading{
  width: 55%;
}
.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.dashboard_purchase_info a {
  color: #000;
}

.dashboard_purchase_info .puprchase_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.puprchase_data hr {
  border-top: 1px solid #eeeeee !important;
}

.purchase_bar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchase_bar h3 {
  margin-top: 0;
}

.dashboard_po_info_progress h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dashboard_po_info_progress h3 span {
  font-size: 24px;
}

.mb_10px {
  margin-bottom: 10px;
}

.mt_10px {
  margin-top: 10px;
}

.bardashboard_po_info_progress {
  text-align: center;
  margin-left: 30px;
  margin-top: 40px;
}

.text_align_right {
  text-align: right;
}

@media (max-width: 1700px) {
  .purchase-data-main .puprchase_data {
    width: 58%;
  }

  .purchase-data-main .purchase_bar {
    width: 42% !important;
  }

  .dashboard_po_info_progress {
    padding: 0 3.4rem;
  }
  .dashboard_po_info_progress h3 {
    display: flex;
    flex-wrap: wrap;
  }
  .dashboard_po_info_progress h3 span {
    font-size: 18px;
  }
}
@media screen and (max-width:1680px) {
  .dashboard_purchase_info .puprchase_data .info-heading{
    width: 40%;
  }
}
@media screen and (max-width:1600px) {
  .dashboard_purchase_info .puprchase_data .info-heading{
    width: 45%;
  }
}
@media screen and (max-width:1400px) {
  .dashboard_purchase_info .puprchase_data .info-heading{
    width: 40%;
    font-size: 13px;
  }
}
@media (max-width: 1366px) {
  .purchase-data-main .puprchase_data {
    width: 58%;
  }

  .purchase-data-main .purchase_bar {
    width: 42%;
  }
}
@media (max-width: 1280px) {
  .purchase-data-main .dashboard_po_info_progress {
    padding: 0 2.5rem;
  }
  .dashboard_purchase_info .puprchase_data .info-heading{
    width: 50%;
    font-size: 14px;
  }
}
