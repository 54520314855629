.password-reset-form-submit {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.password-reset-form-submit--btn {
  margin-right: 2rem;
}

/* .form-field.error .input-feedback {
  bottom: -1.1rem !important;
} */
.passsword--errors {
  margin-top: -5px !important;
}

.confirm-password-margin {
  margin-top: -2px;
}

/* .form-field .form-label {
  margin-top: 7px;
} */

.semi-bold .required-str {
  color: #b34343;
}

.password-reset-form-submit button {
  border: 0 !important;
}

.mismatch-passsword--errors .error {
  color: var(--red-500);
}