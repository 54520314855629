.app-main {
  height: calc(100vh - var(--header-height));
  display: flex;
}

.app-content {
  /* overflow: hidden; */
  overflow-x: hidden;
  width: 100%;
  /* background-color: var(--background); */
  background-color: #fff;
}

.blue_text {
  color: rgb(60, 60, 160);
  cursor: pointer;
}

.no_internet {
  text-align: center;
  margin-top: 10rem;
}

i.fas.fa-times.ims-mob-bars.fa-2x,
a.header-logo.navbar-mob-logo {
  display: none;
}
.buttonMobileIcon i.fas.ims-mob-bars {
  color: #000;
  font-size: 19px;
  font-weight: 400;
}

@media (max-width: 1280px) {
  .header {
    position: fixed;
    background-color: #fff;
  }

  .app-main {
    padding-top: 5rem;
    height: 100vh;
  }
}

@media (max-width: 1199px) {
  .app-content.scrollbar_style {
    width: 100%;
    /* overflow: hidden; */
  }

  .ims-navigation {
    position: fixed;
    width: 20rem;
    z-index: 999;
  }

  .ims-navigation.collapse-navigation button.nav-arrow.back-direction-arrow,
  button.nav-arrow.back-direction-arrow {
    position: fixed;
  }

  .ims-nav-mob button.nav-arrow {
    position: fixed;
    right: auto;
    left: 2%;
    width: auto;
    top: 0;
  }

  /* button.nav-arrow.back-direction-arrow {
    right: auto;
    left: 0;
    top: 0;
    width: 16%;
} */
  .ims-navigation.collapse-navigation {
    left: 100%;
  }

  .header {
    z-index: 100;
  }

  .header-mobile a.header-logo,
  .ims-nav-mob .nav-arrow svg {
    display: none;
  }

  i.fas.ims-mob-bars {
    color: #000;
    font-size: 25px;
    font-weight: 400;
  }

  .right-direction-arrow i.fas.fa-times.ims-mob-bars:before {
    content: '\f0c9';
  }

  .ims-nav-mob .nav-arrow {
    height: 50px;
    padding: 0;
    margin: 0;
  }

  .ims-nav--space-between {
    position: relative;
  }

  .header-mobile a.header-logo {
    margin-left: 0;
    position: absolute;
    left: 70px;
  }

  .ims-nav-mob .nav-arrow.right-direction-arrow {
    position: fixed;
    top: -0.3%;
  }

  .ims-nav-mob button.nav-arrow.back-direction-arrow {
    top: 5px;
  }

  .ims-nav-mob .ims-nav--space-between {
    height: auto;
    padding: 10px 0 0;
  }

  a.header-logo.navbar-mob-logo {
    margin-bottom: 5px;
  }

  a.header-logo.navbar-mob-logo img {
    width: 8rem;
  }

  i.fas.fa-times.ims-mob-bars.fa-2x,
  a.header-logo.navbar-mob-logo {
    display: block;
  }

  i.fas.fa-times.ims-mob-bars.fa-2x {
    position: relative;
    top: -3px;
  }

  .ims-navigation.ims-nav-mob {
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.25);
  }
}
