.dots {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

.dots--dot {
  background: #ADB6BC;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 5px;
}

.dots--dot.active {
  background: var(--blue-500);
}