.passsword--errors {
  list-style-type: circle;
  list-style: inside;
  margin: 0;
}

.passsword--errors li {
  color: grey;
  list-style: inside;
  font-size: 1.2rem;
}

.passsword--errors.touched li.error {
  color: #B34343;
}
.passsword--errors.touched li {
  color: #1F5927;
}
.position-static {
  position: static !important;
}