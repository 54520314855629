.matchingTable {
  border: 1px solid var(--border-dark);
  max-width: 60rem;
  margin: 0.8rem 0;
}
.matchingTable th,
.matchingTable td {
  padding: 1.6rem;
  text-align: left;
}
.matchingTable thead {
  background-color: var(--background);
  border-bottom: 1px solid var(--border-dark);
}