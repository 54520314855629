.search-holder {
  position: relative;
  max-width: 630px;
  margin: auto;
  width: 100%;
}

.search_header_filter {
  padding-left: 0 !important;
}

.search_header_filter button {
  background-color: #306ab4;
  color: #FFF;
  border-radius: 0;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
  width: 90px;
  padding: .5rem 1rem .5rem 1.5rem !important;
  font-size: 1.6rem !important;
}

@media screen and (max-width:768px) {
  .search_header_filter button {
    font-size: 1.5rem !important;
  }
}

.search_header_filter svg {
  fill: #306ab4;
}

.search-holder--search-result {
  position: absolute;
  top: 100%;
  left: 9.9rem;
  min-width: 53rem;
  background: #fff;
  border: 1px solid var(--border-dark);
  box-shadow: var(--grey-box-shadow);
  border-radius: 4px;
  min-height: 7rem;
  overflow-y: auto;
  max-height: 20rem;
  scrollbar-width: thin;
}

.search-holder--search-result li {
  cursor: pointer;
  margin-bottom: 1rem;
  padding: .8rem 1.6rem;
  word-break: break-all;
}

.search-holder--search-result li.no-result {
  padding: .8rem 1.6rem;
  cursor: unset;
}

.search-holder--search-result li.no-result {
  text-align: center;
  margin-top: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-holder--search-result li:last-child {
  margin-bottom: 0;
}

.search-holder--search-result li:hover {
  background: var(--hover);
}

.search-bar {
  /*background: var(--background);
  */
  /* padding: .3rem .5rem .3rem 1.5rem; */
  /* border-radius: 4px; */
  /* margin-left: 2.4rem; */
  /* display: grid; */
  /* grid-template-columns: auto minmax(auto, 40rem) auto; */
  /* align-items: center; */
  /* border: 1px solid #b8b9ba; */
  /*border-radius: 20px;*/
  /* border-radius: 2px; */
  height: 43px;
  display: flex;
  width: 100%;
  position: relative;
  top: -1px;
  /* overflow-y: hidden; */
}

.search-bar form {
  width: 100%;
  border: 1px solid #b8b9ba;
  height: 43px;
}

.search-bar form input {
  width: 100%;
  height: 100%;
  /*border-top-right-radius: 22px;*/
  border-top-right-radius: 2px;
  /*border-bottom-right-radius: 22px;*/
  border-bottom-right-radius: 2px;
  padding: .5rem .5rem .5rem 1rem;
}

.search-bar svg {
  position: absolute;
  right: 10px;
  top: 10px;
}

.search-bar svg path {
  fill: #306ab4;
}

/* .search-bar.focus {
  border: 1px solid var(--border-dark);
} */

.search-bar.focus .search-bar--input::placeholder {
  color: var(--black);
}

.search-bar.focus>svg>path {
  fill: var(--blue-500);
}

.search-bar--input {
  padding: .5rem;
  background: #f0f2f5;
  border: 0;
  outline: none;
  /* margin: 0 .9rem; */
  border-radius: 0;
  width: 100%;
}

.search-bar--dropdown {
  position: relative;
  padding-left: .5rem;
  height: 100%;
  width: 170px;
  /* left: -1px; */
}

.search-bar--dropdown svg {
  top: 12px;
  transform: rotate(270deg);
  transition: 0.3s;
}

.search-bar--dropdown svg path {
  fill: #FFF;
}

.custom-dropdown--options svg {
  transform: rotate(0);
  top: 2px;
  /* position: absolute; */
  right: 5px;
}

/* .custom-dropdown--options svg path {
  fill: #3e3e3e;
  

} */
.check_icon {
  top: 3px;
  /* top: 3px !important; */
}

.check_icon path {
  fill: #59B54B !important;
}

.search-bar--dropdown .custom-dropdown {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 170px;
}

.search-bar--dropdown .custom-dropdown button {
  height: 100%;
  /*border-top-left-radius: 2rem !important;*/
  /* border-top-left-radius: 2px !important; */
  border-bottom-left-radius: 0px;
  /*border-bottom-left-radius: 2rem !important;*/
  /* border-bottom-left-radius: 2px !important; */
}

.search-bar--dropdown .custom-dropdown button .custom-dropdown--options {
  color: #000;
  border-radius: 0;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0px;
  border-bottom-left-radius: 0rem !important;
}

/* .search-bar--dropdown:after {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 60%;
  background: var(--border-dark);
} */

.search-bar--dropdown .custom-dropdown.button-text .button {
  font-size: 1.4rem;
  /* color: var(--text-secondary); */
}

.search-bar--dropdown .custom-dropdown.button-text .button:hover {
  background: #306ab4;
}

.search-bar--dropdown .custom-dropdown.button-text .button-active {
  background: #fff !important;
  color: #000;
}

.search-bar--dropdown .custom-dropdown.button-text .button-active svg {
  transform: rotate(360deg) !important;
}

.search-bar--dropdown .custom-dropdown.button-text .button-active svg path {
  /* fill: #000; */
  fill: #306ab4;

}

.search-bar--dropdown .custom-dropdown.button-text .custom-dropdown-container {
  top: 4.8rem;
  left: 0;
  right: 0;
  min-width: 27rem;
}

.search-bar .search-bar--dropdown .custom-dropdown.button-text .custom-dropdown-container {
  min-width: 63rem !important;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.8s;
  border-radius: 0;
}

.search-bar--dropdown .custom-dropdown-container>.custom-dropdown--options, .search-bar--dropdown .custom-dropdown.button-text .button-active+.search-bar--dropdown .custom-dropdown-container .custom-dropdown--options {
  font-size: 1.4rem !important;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1.5rem !important;
}

.added_search {
  width: 185px !important;
}

.added_search .custom-dropdown {
  width: 185px !important;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media(max-width:768px) {
  /* .mob-searchbar form {
    border: 0;
    position: relative;
  }

.mob-searchbar input.search-bar--input {
    border: 1px solid  #b8b9ba;
    width: 0 !important;
    padding: 0 !important;
} */

  /* .mob-searchbar input.search-bar--input:focus {
    width: 124px !important;
} */
  /* .mob-searchbar .pointer {
  position: relative;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
} */
  .sidebar-mob {
    width: 40%;
  }

  .sidebar-mob .org-name {
    font-size: 13px;
    padding: 0 2px 0 0;
    line-height: 18px;
  }
}

.search-holder--search-result {
  left: 140px;
  right: 0;
  min-width: unset;
}



@media(max-width:890px) {
  html body .header-user, .header-responsive-main+.header-right .header-user {
    display: none;
  }
}