.passsword--errors {
  list-style-type: circle;
  list-style: inside;
  margin: 0;
}

.passsword--errors li {
  color: grey;
  list-style: inside;
  font-size: 1.2rem;
}

.passsword--errors.touched li.error {
  color: #b34343;
}

.passsword--errors.touched li {
  color: #1f5927;
}

html body .salesperson-role span {
  position: relative;
}
.resend-text {
  color: var(--blue-500);
  cursor: pointer;
  transition: 0.6s ease-in;
}
.resend-text:hover {
  color: #000;
}
