.page-three {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;
  align-items: center;
}

.page-three-title {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  text-align: center;
}

.page-three-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.page-three-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 1200px) {
  .page-three-title {
    padding: 5rem 1.5rem 0;
  }

  .page-three-img {
    width: 65vw;
  }
}