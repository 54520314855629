.red_color_font{
  color: red;
}
.green_color_font{
  color: #33A348;
}
.dashboard_payable .green_color_font{
  color:#306AB4;
}
.dashboard_receivables{
  height: 100%;
}
.count_progress_circle{
  border-radius: 45px;
  width: 90px;
  border: 1px solid #dfe1e5;
  height: 90px;
  text-align: center;
  /* padding-top: 11px; */
  margin: 0 auto 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.count_progress_circle p{
  color: #000;
}
.count_progress_circle_amount{
  color: #000;
}
.count_progress_circle_main p{
  font-weight: 500;
  margin-bottom: 0;
}
.colored_font{
  margin: 10px 15px;
}
.count_progress_circle_main{
  float: left;
  width: 100%;
  height: 100%;
}
.count_progress_circle_amount{
  margin-top: 15px;
  text-align: center;
}
.progress_percentage {
  /* margin-top: -8%;
  margin-right: -17%; */
  position: absolute;
  top: -4px;
  right: -4rem;
}
.mt_20px{
  margin-top: 20px;
}
.custom_width{
  width: 60% !important;
  margin: 10px auto;
  position: relative;
}
.custom_width p{
  color: #000;
}
.count_progress_circle_amount{
  font-weight: 900;
  color: #000;
}