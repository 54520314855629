.home-content {
  background: #0071bc;
  background: -webkit-linear-gradient(to bottom, #0071bc, #5CBEE1);
  background: linear-gradient(to bottom, #0071bc, #5CBEE1);
  position: relative;
  top: -4rem;
  margin-bottom: -4rem;
}
.home-content-title {
  color: white;
  text-align: center;
  display: grid;
  grid-gap: 3rem;
}
.home-content-title-heading {
  font-size: 3.5rem;
  margin-top: 10rem;
}
.home-content-desc {
  font-size: 1.6rem;
}
.home-content-title--btn:hover {
  background-color: #fa8c0e;
}

@media only screen and (max-width: 1200px) {
  .home-content {
    padding: 0 1.5rem;
  }

  .home-content-title-heading {
    font-size: 3.5rem;
  }
}

@media only screen and (min-width: 1800px) {
  .home-content-desc {
    font-size: 140%;
  }
}