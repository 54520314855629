.overlay-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 3000;
  background: rgba(255, 255, 255, .95);
  /* border: 1px solid #eee; */
}

.overlay-loader .loader {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
}