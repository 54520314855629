.so-sales-return-popup .detail_component_inner {
  /* min-width: 760px;
  max-width: 760px; */
  display: flex;
  float: none !important;
  width: unset !important;
  width: 892px !important;
  /* margin-left: 6%; */
}

.so-package-dropdown {
  max-width: 283px;
  width: 100%;
}
.so-details-info .details_row:nth-child(3) .section-details--info-title {
  width: 45%;
}
.so-details-info .details_row:nth-child(3) >div:nth-child(2) {
width: 55% !important;
text-align: right;
white-space: initial;
}

.create-so-invoice .invoice_form_main .form-field.inline-field.invoice-term-set {
  margin-right: 0;
  width: 21.7%;
}

.invoice_form_main .form-field.inline-field.invoice-term-set .searchable-dropdown,
.so-sales-return-popup.view-sales-return-So .layout.main-layout-set.details>div,
html body .view-sales-return-So .detail_component_inner {
  width: 100% !important;
}

.so-invoice-popup .invoice-term-set {
  margin-right: 2.5rem;
}

.so-sales-return-popup .layout.main-layout-set.details>div {
  width: 892px;
}

.sales-return-main-set.sales_return_popup .menu-component--menu.menu_top {
  position: relative;
  width: 100%;
  margin-left: 0 !important;
}

.sales_return_details {
  width: 85% !important;
}

.create-invoice-popupset .invoice-term-set {
  margin-top: 0;
}

/* 
.sales-order-summary-main .details-table th:nth-child(2) {
  width: 4% !important;
} */

/* .sales-order-summary-main th:first-child {
  width: 32%;
} */
.salesreturn_popup .with-close--btn .form-input.small {
  text-align: right;
}

.salesreturn_popup .searchable-dropdown__menu-list>div {
  text-align: left;
}

html body .so-package-popup .list-table.create_package tr th:nth-child(1),
html body .so-package-popup .list-table.create_package tr td:nth-child(1) {
  width: 33% !important;
}

html body .so-package-popup .list-table.create_package tr th:nth-child(5),
html body .so-package-popup .list-table.create_package tr td:nth-child(5) {
  width: 12% !important;
}

html body .so-package-popup .list-table.create_package tr th:nth-child(6),
html body .so-package-popup .list-table.create_package tr td:nth-child(6) {
  width: 15% !important;
}

.sales-order-summary-main .table.details-table td:last-child {
  padding-right: 1.6rem !important;
}

.so-invoice-popup .invoice-term-set .searchable-dropdown {
  max-width: 263px;
}

.salesreturn_popup td:nth-child(2) .form-field .searchable-dropdown {
  max-width: 160px;
}

@media (max-width: 1600px) {
  .sales-order-summary-main.details-table td:last-child {
    padding-right: 2.1rem !important;
  }

  .so-package-popup {
    max-width: 80rem;
  }

  .ims-modal.white.medium.so-package-popup.so-package-main {
    min-width: 76rem;
  }
}

@media (max-width: 1280px) {
  .uiux-invoice-popup-set .order-no-set+.form-field.inline-field {
    margin-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .UIUX-invoice-popup .modal_package_title_align {
    width: auto;
  }

  .po-details-main th,
  .sales-order-summary-main .details-table td {
    font-size: 12px;
  }

  .uiux-invoice-popup-set .order-no-set {
    width: auto;
  }

  /* Create invoice popup */
  .so-invoice-popup .invoice-main-set.invoices-popup-set .form-field.inline-field {
    padding: 0;
  }

  .create-invoice-popupset .order-no-set+.form-field.inline-field {
    margin-top: 0 !important;
  }

  .so-invoice-popup .invoice_form_main .form-field.inline-field.invoice-term-set,
  .so-invoice-popup form>div:not(.invoice-table-set, .middle-row, .UIUX_invoice_actions) {
    width: 31%;
    margin-right: 2rem;
  }

  .so-invoice-popup .searchable-dropdown.large,
  html body .so-invoice-popup form div.form-field input.form-input.large {
    width: 100%;
  }

  .so-invoice-popup form>div:not(.invoice-table-set, .middle-row):nth-child(3n) {
    margin-right: 0;
  }

  html body .so-invoice-popup div:nth-child(3) .form-field.inline-field {
    padding-top: 0;
  }

  /* Ending invoice popup */

  .create-so-invoice .order-no-set,
  html body .create-so-invoice form .form-field.inline-field,
  .create-so-invoice>.form-field.inline-field {
    width: 50% !important;
  }

  /* .create-so-invoice .form-field.inline-field.textarea-so-terms,
  .create-so-invoice .form-field.inline-field.textarea-so-terms textarea,
  .create-so-invoice .order-no-set .form-field.inline-field,
  .create-so-invoice .searchable-dropdown.large,
  .create-so-invoice .create-so-invoice .order-no-set,
  .create-so-invoice .form-field.inline-field.invoice-term-set .searchable-dropdown.large,
  .create-so-invoice .invoice-main-set.invoices-popup-set .form-field.inline-field,
  .create-so-invoice .invoice-main-set.invoices-popup-set .form-field.inline-field .searchable-dropdown,
  .create-so-invoice .invoice-main-set.invoices-popup-set,
  .create-so-invoice .invoice-main-set.invoices-popup-set input,
  .create-so-invoice .form-field.inline-field input {
    width: 100% !important;
  } */

  .create-so-invoice form>div {
    margin-right: 0 !important;
  }

  .create-so-invoice form>div:nth-child(even):not(.create-so-invoice .middle-row) {
    padding-right: 20px;
  }

  .create-so-invoice .form-field.inline-field.textarea-invoice {
    margin-right: 0.3rem;
    padding-right: 1.7rem;
  }

  .create-so-invoice .form-field.inline-field.textarea-invoice textarea {
    width: 100% !important;
    padding-right: 28px;
  }
  .salesreturn_popup .single-calander-main{
    right: 0;
  }
}

@media screen and (max-width:1280px) {
  .ims-modal.white.medium.so-package-popup.so-package-main,
  .ims-modal.white.medium.so_popup_dragDrop.salesreturn_popup {
    max-width: 94vw;
    min-width: 0;
  }
}
@media (max-width: 1138px) {
  html body .create-so-invoice form .form-field.inline-field:nth-child(2) {
    margin-top: 0;
    padding-top: 0;
  }

}


@media (max-width: 1024px) {
  .UIUX-invoice-popup .invoice-table-set th:nth-child(3) {
    padding-left: 1%;
  }

  .UIUX-invoice-popup .invoice-table-set th:nth-child(2),
  .UIUX-invoice-popup .invoice-table-set th:nth-child(4) {
    padding-left: 1.5%;
  }

  .uiux-invoice-popup-set .order-no-set {
    width: 48%;
  }

  html body .create-so-invoice .invoice-popup-main .form-field.inline-field.textarea-so-terms,
  .create-so-invoice .form-field.inline-field.textarea-invoice {
    width: 100% !important;
  }

  .create-so-invoice .form-field.inline-field:nth-child(odd),
  .create-so-invoice .invoice_form_main .form-field.inline-field.invoice-term-set {
    margin-right: 0 !important;
  }

  .create-so-invoice .middle-row .inline-terms-and-conditions {
    padding-left: 0;
  }

  .so-invoice-popup .invoice-main-set.invoices-popup-set {
    width: 100%;
  }

  .so-invoice-popup form>div:not(.invoice-table-set, .invoices-popup-set, .middle-row):nth-child(odd) {
    margin-left: 32px !important;
  }

  .so-invoice-popup form>div:not(.invoice-table-set, .middle-row) {
    margin-right: 0;
  }

  .so-invoice-popup .middle-row .inline-terms-and-conditions {
    padding: 0 0 0 25px;
  }

  html body .so-invoice-popup .invoice-popup-main .form-field.inline-field.textarea-so-terms {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .UIUX-invoice-popup .invoice-table-set th:nth-child(1) {
    width: 25.8% !important;
  }
  .salesreturn_popup .form-table--container{
    margin-right: 0;
  }
  .so-invoice-popup form>div:not(.invoice-table-set, .middle-row),
  .create-so-invoice .order-no-set,
  html body .create-so-invoice form .form-field.inline-field,
  .create-so-invoice>.form-field.inline-field {
    width: 100% !important;
  }

  .create-so-invoice .form-field.inline-field.textarea-invoice,
  .create-so-invoice form>div:nth-child(even):not(.create-so-invoice .middle-row) {
    padding-right: 0;
  }



  .so-package-main .form-field.form-table--container > div {
    /* min-width: 690px; */
    overflow: auto;
    display: block;
  }
}

/* .salesOrderFormPopup .__dragDrop_iconParent {
  display: none;
}

.so_popup_dragDrop .__dragDrop_iconParent {
  display: none;
}
} */
