.action-buttons {
  padding: 0.8rem 0;
  /*float: left;     commented because SIQ-1156*/
  margin: 5px 0px;
  float: right;
}
.add_contact_tabs .action-buttons {
  position: fixed;
  bottom: 10px;
  right: 5rem;
}

.action-buttons.padding {
  padding: 2.4rem;
}
/* .form-action-dropup{
  background: #33a348;
} */
.actions_buttons_sales_order_create {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.dropdwon_with_button {
  background-color: #33a348;
  border-radius: 4px;
  transition: 0.3s;
}
.dropdwon_with_button_button {
  float: left;
  /* background-color: #33a348; */
  color: #fff;
  height: 3rem;
  padding: 0 10px;
}
.dropdwon_options_sales_order {
  /* position: relative; */
  /* top: -4px; */
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  /* padding: 0.4rem 0; */
  float: left;
  height: 3rem;
}
.dropdwon_options_sales_order.custom-dropdown.button-text .button {
  padding: 1px !important;
  /* background-color: #33a348; */
  height: 100%;
  font-size: 0 !important;
}
.dropdwon_options_sales_order button svg {
  transform: rotate(180deg);
}
.dropdwon_options_sales_order button svg path {
  fill: #fff;
}
.dropdwon_options_sales_order.custom-dropdown.button-text
  .custom-dropdown-container {
  top: unset !important;
  bottom: 100% !important;
  left: -10.2rem !important;
  box-shadow: none !important;
  width: 124px;
}
.dropdwon_with_button:hover,
.dropdwon_with_button:focus-within {
  background: #fff !important;
  color: #000;
  box-shadow: var(--button-box-shadow);
}
.dropdwon_with_button:hover .dropdwon_with_button_button,
.dropdwon_with_button:focus-within .dropdwon_with_button_button {
  color: #000;
}
.dropdwon_with_button:hover svg path,
.dropdwon_with_button:focus-within svg path {
  fill: #000;
}
.form-action-dropup button:hover,
.form-action-dropup button:focus-within {
  background: transparent !important;
}
.background_blue {
  background-color: #306ab4 !important;
  color: #fff !important;
}

.background_blue:hover,
.background_blue:focus-within {
  background: #fff !important;
  color: #000 !important;
  box-shadow: var(--button-box-shadow);
}
.stopClickingPoSubmit {
  pointer-events: none;
}
