.layout {
  /* background: var(--background); */
  /* Background color scheme updated with New UI */
  background: #fff;
  /* Beacuse of Search  */
  height: 100%;
  /* when we do overflow hidden ( add new form of any module get stuck ) */
  overflow: auto;
  /* comment this padding beacuse of jerk */
  /* padding-bottom: 2rem; */
}

.layout.details .header {
  position: sticky;
  top: 0;
  z-index: 99;
}

.layout--title {
  max-width: 60rem;
  word-break: break-all;
}

.layout--title svg {
  margin-right: 1rem;
  vertical-align: middle;
  /* width: 2rem;
  height: 2rem; */
  width: 3rem;
  height: 3rem;
}

.layout--title svg path {
  fill: var(--black);
}

.layout--title h3 {
  display: inline-block;
  font-size: 2rem;
  margin: 0;
  vertical-align: middle;
}

.layout--body {
  margin: var(--box-margin);
}

.layout-body--box {
  margin: var(--box-margin);
  background: #fff;
  border: 1px solid var(--border-dark);
  box-sizing: border-box;
  /* shadows/medium */
  box-shadow: var(--shadow-medium);
  border-radius: 4px;
}

.layout--body-box {
  background: #fff;
  border: 1px solid var(--border-dark);
  box-sizing: border-box;
  /* shadows/medium */
  box-shadow: var(--shadow-medium);
  border-radius: 4px;
  min-height: 300px;
}

.layout--grid {
  background: #fff;
  /* border: 1px solid var(--border-dark); */
  box-sizing: border-box;
  /* shadows/medium */
  box-shadow: var(--shadow-medium);
  /* border-radius: 4px; */
  min-height: 300px;
  /* float: left; */
  /* width: 100%; */
}

.layout--grid.no-height {
  min-height: unset;
  height: unset;
  max-height: unset;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 0.3s;
  /* width: 35%; */
}

.layout--grid.transparent {
  background: transparent;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.layout--grid-container {
  display: grid;
  grid-gap: 1.6rem;
}

.layout--grid-container.layout-padding {
  padding: 2.4rem 2.4rem 2.4rem 2.4rem;
}

.layout--grid-container.grid-default {
  grid-template-columns: 5fr 2fr;
}

.layout--grid-container.grid-1-3 {
  grid-template-columns: minmax(auto, 28rem) 3fr;
}

.layout--grid-container.grid-1-1 {
  grid-template-columns: 1fr 1fr;
}

.layout--grid-container.grid-3-2 {
  grid-template-columns: 3fr 2fr;
}

.layout--grid-container.grid-3-0 {
  grid-template-columns: 3fr 0fr;
}

.layout--grid-container.grid-3-1 {
  grid-template-columns: 3fr 1fr;
}

.layout--grid-container.grid-1-2 {
  grid-template-columns: 1fr 2fr;
}

.layout--grid-container.grid-1-1-1 {
  grid-template-columns: 1fr 1fr 1fr;
}

.main-layout-set {
  /* overflow-x: hidden; */
  scrollbar-width: thin;
}

.layout.main-layout-set {
  position: relative;
}

button.button--primary.dash.float-left.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-boundary img {
  width: 100%;
}
@media(max-width:1199px){
  .main-layout-set{
    overflow-x: hidden;
  }
}
@media (max-width: 1080px) {
  .item-form .layout--grid-container.grid-1-1 {
    grid-template-columns: auto;
  }
}
