.ecom-integration {
  display: flex;
  background-color: #f0f2f5;
}

.integration-wrapper {
  flex: 0 0 83.2%;
  width: 100%;
  background-color: #fff;
}

.ecom-integration .close-icon-pos {
  top: 8px !important;
  z-index: 600;
}
html body .mapping-spacing {
  padding: 5px 10px;
}
.sync-history-pagination+.sync-history-dropdown .close-icon {
  top: 14px !important;
}
.popup-border-top{
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

nav.ecom-sidenav {
  background: #f0f2f5;
  flex: 0 0 16.8%;
  height: 100%;
  height: calc(100vh - 100px);
}

.ecom-integration .ecom-sidenav .ecom-sidenav--link {
  transition: all 0.3s ease-in-out;
  display: block;
  margin: 5px 0;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 16px;
}

.ecom-integration .ecom-sidenav .ecom-sidenav--link.active {
  background-color: #306ab4;
  color: #fff;
}

.ecom-main {
  /* width: 100%; */
  max-width: 1025px;
  border: 1px solid #f0f2f5;
  margin: 15px 20px;
}

.ecom-main-inner {
  width: 100%;
  display: flex;
  margin-bottom: 0;
  border-bottom: 1px solid #f0f2f5;
}

.ecom-main .ecom-left-sec {
  flex: 0 0 30%;
  margin: 0 !important;
  /* border-right: 1px solid #bbb; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ecom-main .ecom-left-sec img {
  margin-bottom: 20px;
  margin-top: 45px;
}

.ecom-main .ecom-right-sec {
  flex: 0 0 70%;
  margin-bottom: 0;
  padding: 0 15px;
  position: relative;
  border-left: 1px solid #f0f2f5;
}

.btn-style .fa-sync {
  font-size: 10px;
  margin-left: 2px;
}

.ecom-left-desc .ecom-integrate {
  font-weight: 400;
  margin-bottom: 5px;
}

.ecom-left-desc p {
  font-weight: 500;
}

.ecom-right-sec .ecom-right-desc .right-align {
  position: absolute;
  right: 20px;
  top: 20px;
  fill: #808080;
  cursor: pointer;
}

.ecom-right-desc {
  margin-top: 45px;
}

.ecom-right-desc h4 {
  font-weight: bold;
  margin: 0;
  font-size: 22px;
}

.ecom-right-desc h6 {
  font-size: 14px;
  font-weight: lighter;
}

.ecom-action-btns {
  text-align: right;
}

p.ecom-desc-set {
  padding-top: 13px;
}

.ecom-right-desc small {
  font-weight: 100;
  color: #565656;
}

.btn-ecomstore:hover {
  color: #fff;
}

.ecom-right-desc p {
  font-size: 14px;
  line-height: 28px;
}

.btn-ecomstore.btn-stores {
  background: #3bb54a;
}

/* table styles */
.general-table .ecom-table-set tbody tr td:nth-child(1) {
  width: 35%;
}

.general-table .ecom-table-set tbody tr td:nth-child(2) {
  width: 35%;
}

.general-table .ecom-table-set tbody tr td:nth-child(3) {
  width: 20%;
}

.ecom-details {
  display: inline-block;
  padding: 0 10px;
}

table.ecom-table-set {
  width: 100%;
}

.ecom-table-sec {
  margin-bottom: 0 !important;
}

table.ecom-table-set tbody tr {
  border-bottom: 1px solid var(--border-light);
}

table.ecom-table-set tbody tr:nth-child(even) {
  background-color: #fcfcf9 !important;
}

span.sync-store-inner {
  margin-left: 10px;
}

.sync-note {
  margin-left: 10px;
  font-weight: lighter;
}

.ecom-warehouse-main {
  width: 100%;
}

.remove_pagination .pagination-container.pagination-route.sync-history-pagination {
  display: none;
}

span.invalid-url {
  display: block;
  color: var(--red-500);
  font-size: 14px;
  padding: 6px 0;
}

.ecom_item_mapping .list-table {
  position: initial;
}
.ecom-tbl-mob.ecom-sync-table td:nth-child(2) .list-table--link {padding: 0.8rem 1rem 0.8rem 0rem;}
/*===========================
  Ecom Details header
=============================*/
.ecom-integration .card-header {
  padding: 0.4rem 1.1rem;
  min-height: 42px;
}

/*===========================
   Store Details Css
=============================*/
.warehouse-main {
  max-width: 1040px;
  margin: 20px 13px 20px 13px;
}

.warehouse-block {
  padding: 15px;
  background: #eff5fc;
  position: relative;
  margin-bottom: 10px;
  border-radius: 4px;
}

.warehouse-block:hover {
  background: transparent;
  outline: 1px solid #306ab4;
  transition: 0.3s ease-in;
}

.warehouse-block .warehouse-icon {
  margin: 15px 0;
}

.warehouse-block .warehouse-tooltip {
  position: absolute;
  top: 15px;
  right: 15px;
  fill: #c8cfd8;
  cursor: pointer;
}

.warehouse-block .warehouse-desc h3 {
  font-size: 20px;
  color: var(--blue-color);
  margin: 0;
  padding-bottom: 10px;
}

.warehouse-block .warehouse-desc .select {
  text-align: left;
  display: inline-block;
  outline: none;
}

.warehouse-block .__warehouse_desc {
  max-width: 480px;
  margin: 10px auto;
}

.warehouse-block .__warehouse_desc span {
  font-size: 12px;
  display: inline-block;
}

.ecom-warehouse-row {
  display: flex;
  flex-wrap: wrap;
}

.ecom-warehouse-row .warehouse-block-main {
  border: 1px solid #eee;
  flex: 0 0 32.8%;
  min-height: 260px;
  margin-bottom: 10px;
  margin-right: 8px;
  padding: 10px 20px;
  position: relative;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0);
}

.ecom-warehouse-row .warehouse-block-main:nth-child(2) span img,
.ecom-warehouse-row .warehouse-block-main:nth-child(3) span img {
  display: inline-block;
  margin-right: 7px;
}

.ecom-warehouse-row .warehouse-block-main .ecom-details-tooltip {
  position: absolute;
  top: 12px;
  right: 19px;
  fill: #c8cfd8;
  cursor: pointer;
}

.ecom-warehouse-row .warehouse-block-main img {
  margin-top: 30px;
}

.ecom-warehouse-row .warehouse-block-main:nth-of-type(3n) {
  margin-right: 0;
}

.ecom-warehouse-row .warehouse-block-main .warehouse-items-row {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  align-items: center;
  /* min-height: 92px; */
}

.items-center {
  width: 8%;
  flex: 0 0 8%;
  text-align: right;
}

.warehouse-items-row .fa-arrow-right,
.warehouse-items-row .fa-arrow-left {
  font-size: 18px;
  color: var(--green-color);
}

.warehouse-items-row .items-title {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.warehouse-items-row .seebizTitle {
  font-size: 12px;
}

.warehouse-bottom {
  position: absolute;
  bottom: 10px;
  left: 20px;
  right: 20px;
}

.sync-time {
  margin-bottom: 10px;
}

.sync-time p {
  display: flex;
  line-height: 0;
  align-items: baseline;
}

.sync-time p span {
  width: 45%;
  line-height: 1.4;
}

.sync-time .es {
  font-size: 12px;
}

.sync-time .ls {
  font-size: 12px;
}

.btn-loader {
  height: 20px;
  width: 20px;
  margin: 0 auto;
}

/*===========================
   Integration header Css
=============================*/
.ecom-header-main {
  margin-right: 40px;
}

.disable-integration-sec .select {
  position: absolute;
  right: 26px;
  top: 9px;
  z-index: 1;
  width: 72px;
}

.disable-integration-sec button {
  color: #fff;
  z-index: 9999;
  width: 145px;
  padding: 2px 0px 0px 2px;
  border-top-right-radius: 0;
  line-height: 30px;
}

.disable-integration-sec {
  height: 33px;
  min-width: 164px;
  position: relative;
  transition: 0.3s ease;
  z-index: 1;
}

.disable-integration-sec:hover .select select,
.disable-integration-sec:hover {
  background: #fff;
}

.disable-integration-sec:hover .select select {
  border-color: #fff;
}

.disable-integration-sec:hover button,
.disable-integration-sec:hover .select select option {
  color: #000;
}

/*==============================
 Ecom Details Dropdown
===============================*/

span.ecom-details-dropdown {
  padding: 7px 2px;
  cursor: pointer;
  vertical-align: middle;
  line-height: 0;
}

.ecom-details-dropdown i.fas {
  font-size: 12px;
}

ul.ecom-dropdown {
  background: #ffff;
  box-shadow: 0 0 10px #ccc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ecom-dropdown .ecom_detail_list {
  color: #000;
  padding: 7px 10px 5px 10px;
  margin-bottom: 2px;
  cursor: pointer;
}

/* .ecom-dropdown .ecom_detail_list:nth-child(even) {
  padding: 0 10px 5px 10px;
} */

.ecom_detail_list .fas.fa-check {
  font-size: 11px;
  color: var(--green-color);
}

.ecom-details button {
  font-size: 12px;
  color: var(--blue-color);
}

.ecom-details button:hover {
  text-decoration: underline;
}

.ecom-details button:disabled:hover {
  text-decoration: unset;
}

.ecom-dropdown .ecom_detail_list:hover {
  background: #dfe1e5;
}

/*===========================
  Sync Table Css
=============================*/
.ecom-sync-table .table_header_fix th {
  position: initial;
}

.sync_modal_title h4 {
  font-size: 16px;
}

.sync-popup-loading span.ContenLoader_loader__1W5-x {
  margin-top: 37px;
}

.sync-popup-loading .searchable-dropdown__menu {
  word-break: break-word;
}

.sync-loader-margin {
  margin-top: 36px;
}

.ecom-sync-table th:nth-child(5),
.ecom-sync-table td:nth-child(5),
.ecom-sync-table th:nth-child(2),
.ecom-sync-table td:nth-child(2) {
  width: 25%;
}

.sync-history-pagination .pagination-input,
.ecom-sync-table th:nth-child(3),
.ecom-sync-table td:nth-child(3) {
  width: 15%;
}

.sync-history-pagination .pagination-input,
.sync-history-pagination .page-count-header .searchable-dropdown {
  height: 34px !important;
}

.ecom-sync-table th:nth-child(5),
.ecom-sync-table td:nth-child(5),
.ecom-sync-table td:nth-child(5) .list_items_desc .text-right,
.ecom-sync-table td:nth-child(4) .list_items_desc .text-right,
.ecom-sync-table th:nth-child(4),
.ecom-sync-table th:nth-child(3),
.ecom-sync-table td:nth-child(3),
.ecom-sync-table td:nth-child(3) .text-right {
  text-align: left;
}

.sync-history-pagination .searchable-dropdown {
  border: 1px solid #dfe1e5 !important;
}

.ecom-sync-table td:nth-child(5),
.unsync-table td:nth-child(5) .list_items_desc .css-2b097c-container,
.ecom-sync-table.unsync-table td:nth-child(5),
.ecom-sync-table.unsync-table td:nth-child(4),
.ecom-sync-table td:nth-child(4) .text-right .css-2b097c-container,
.ecom-sync-table td:nth-child(5) .text-right .css-2b097c-container,
.ecom-sync-table th:nth-child(4),
.ecom-sync-table td:nth-child(4) {
  padding-right: 10px;
}

.ecom-sync-table th:nth-child(4),
.ecom-sync-table td:nth-child(4) {
  width: 25%;
}

.ecom-sync-table th:nth-child(1),
.ecom-sync-table td:nth-child(1),
.ecom-sync-table th:nth-child(6),
.ecom-sync-table td:nth-child(6) {
  width: 0.4%;
}

.ecom-sync-table.unsync-table td:nth-child(5) .css-yk16xz-control,
.sync-history-pagination .searchable-dropdown,
.sync-history-pagination .pagination-input,
.ecom-sync-table td:nth-child(5) .text-right .css-2b097c-container .css-yk16xz-control,
.ecom-sync-table td:nth-child(4) .css-2b097c-container .css-yk16xz-control {
  border-radius: 4px !important;
}



html body .integration-btns button.btn-default {
  color: #fff;
  z-index: 9999;
  padding: 2px 0 0 2px;
  border-top-right-radius: 0 !important;
  line-height: 30px;
  outline: 0;
}

.disable-integration-sec.integration-btns {
  background-color: #fff;
}

html body .integration-btns button.btn-default:first-child {
  width: 145px;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: #fff;
  transition: .3s;
  background: #306ab4;
  border: 0;
  height: 33px;
  outline: 0;
}

html body .integration-btns button.btn-default:nth-child(2) {
  cursor: pointer;
  vertical-align: middle;
  width: 4px;
  background: #306ab4;
  margin: 0;
  border: 0;
  height: 33px;
  transition: .3s;
  box-shadow: none;
}

html body .integration-btns button.btn-default:nth-child(2):active {
  color: #fff;
}

.disable-integration-sec.integration-btns ul.dropdown-menu {
  width: 170px;
  border: 0;
}

.disable-integration-sec.integration-btns ul.dropdown-menu li {
  color: #000;
  margin-bottom: 0;
  cursor: pointer;
}

.disable-integration-sec.integration-btns ul.dropdown-menu li a {
  padding: 7px 10px 5px;
}

.disable-integration-sec .dropdown.btn-group {
  background: #306ab4;
  color: #fff;
  border-color: #306ab4;
  display: flex;
  align-items: center;
}

html body .integration-btns button.btn-default:nth-child(2) .caret {
  border: 0 !important;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

html body .integration-btns button.btn-default:nth-child(2):focus {
  background-color: #306ab4;
  color: #fff;
}

html body .integration-btns button.btn-default:nth-child(2) .caret:before {
  content: "\f078";
  top: 11px;
  position: absolute;
  left: 5px;
}

html body .disable-integration-sec .dropdown.btn-group:hover button,
.disable-integration-sec .dropdown.btn-group:hover {
  color: #000 !important;
  background: #fff;
}

.disable-integration-sec .dropdown.btn-group:hover {
  box-shadow: 0 0 5px 0 #c6c8cb;
}




/* .ecom-sync-table .css-2b097c-container  .css-yk16xz-control,
.ecom-sync-table .css-2b097c-container .css-1pahdxg-control
{
  min-height: unset !important;
} */
.unsync-table td:nth-child(5) .list_items_desc,
.ecom-sync-table td:nth-child(5) .text-right {
  display: flex;
}

.ecom-sync-table td:nth-child(5) .text-right .css-2b097c-container {
  flex: 0 0 70%;
}

.ecom-sync-table.ecom-sync-table thead {
  /* border-top: 2px solid #306ab4; */
}

.unsync-table td:nth-child(5) .list_items_desc input[type='number'],
.ecom-sync-table td:nth-child(5) .text-right input[type='number'] {
  flex: 0 0 30%;
  width: 100%;
  text-align: center;
}

.sync-history-left h4 {
  font-size: 14px;
  display: inline-block;
}

.pagination-route.sync-history-pagination {
  width: 270px;
  padding: 10px 0;
}

.sync-history-pagination .pagination-input {
  width: 50px;
}

.sync-history-pagination .pagination-input:focus,
.ecom-sync-table td:nth-child(5) .text-right input[type='number']:focus {
  outline: 0;
}

.item-sync-popup .cancel_button {
  border: 1px solid transparent;
}

/*===========================
  unSync Table Css
=============================*/
.unsync-order-popup .pagination-route.sync-history-pagination {
  width: 235px;
  display: flex;
  justify-content: flex-end;

}
.unsync-order-popup  .sync-history-pagination .page-count-header .searchable-dropdown {
    margin-bottom: 0;
}
.unsync-order-popup .ecom-sync-table.unsync-table th:nth-child(2) {
  width: 20%;
}

.unsync-order-popup .unsync-table td:nth-child(5) .list_items_desc input[type='number'],
.unsync-order-popup .ecom-sync-table td:nth-child(5) .text-right input[type='number'] {
  flex: 0 0 10%;
  width: 90px;
  text-align: center;
}

.ecom-sync-table.unsync-table th:nth-child(5) {
  width: 28%;
}

.ecom-sync-table.unsync-table th:nth-child(2) {
  width: 40%;
}

.unsync-table td.list-table--options:empty {
  display: none;
}

.unsync-table td:nth-child(5) .list_items_desc input {
  flex: 0 0 25% !important;
}

.unsync-order-popup .unsync-table td:nth-child(5) .list_items_desc .css-2b097c-container {
  flex: 0 0 65%;
}

.unsync-table td:nth-child(5) .list_items_desc .css-2b097c-container {
  flex: 0 0 75%;
}

.ecom_sync_history,
.sync-history-dropdown .sync-history-left {
  padding: 10px 0 10px 10px;
}

.unsync-order-popup .list-table--wrapper {
  height: unset;
  height: calc(100vh - 240px);
}

/* .ecom-tbl-mob .list-table.linked-row td {
  padding: 1.5rem 1rem;
} */

/*===========================
  unSync Table Css
=============================*/
.sync_history_dropdown {
  padding: 7px 20px 7px 12px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px -2px #9098a9;
  cursor: pointer;
  transition: all 150ms ease;
  position: relative;
  min-width: 170px;
  font-size: 15px;
}
.sync-time .mb-0.ls {
  display: block;
  text-align: center;
}
/*============================
  Sync History Page
=============================*/
.integrations_sync_history thead tr th:nth-of-type(5),
.integrations_sync_history tbody tr td:nth-of-type(5) {
  text-align: center;
}

.ecom-integration .list-table--wrapper {
  min-height: 100vh;
  overflow: unset;
  height: 100%;
}
.integrations_sync_tbl .list-table--wrapper {
  min-height: unset;
  overflow: hidden;
}
.integrations_sync_history tr th:first-child,
.integrations_sync_history tr.table-row td:first-child {
  padding-left: 10px;
}

.ecom_sync_history .close-icon {
  top: 15px !important;
}

.synv-desc {
  display: inline-block;
}

.sync-popup-list .checkbox_wrapper {
  width: 60%;
  flex: 0 0 60%;
}

.sync-popup-list .sync-now-desc {
  width: 40%;
  flex: 0 0 40%;
  padding: 6px 0;
}

.sync-now-desc h6 {
  font-size: 16px;
  font-weight: 400;
}

.sync-now-desc ul li {
  background-repeat: no-repeat;
  padding-left: 16px;
  background-position: left 7px;
  font-size: 15px;
  font-family: sans-serif;
  margin-bottom: 5px;
  background-image: url(https://i.ibb.co/1d59yj4/new-moon-1.png);
  background-size: 9px;

}

/*============================
  Sync Sales order Detail
=============================*/

.sync-item-table th:nth-child(1) {
  width: 60%;
}

.sync-item-table th:nth-child(2) {
  width: 20%;
}

.sync-item-table th:nth-child(4),
.sync-item-table th:nth-child(3) {
  width: 10%;
}

.sync-so-table th:nth-child(1) {
  width: 22%;
}

.sync-so-table th:nth-child(3),
.sync-item-table td:nth-child(4) div,
.sync-so-table td:nth-child(3),
.sync-so-table th:nth-child(2),
.sync-so-table td:nth-child(2),
.sync-item-table th:nth-child(4),
.sync-item-table th:nth-child(3),
.sync-item-table td:nth-child(3) div,
.sync-item-table td:nth-child(4) div {
  text-align: center;
}

html body .sync-so-table th.status,
.sync-so-table th:nth-child(2),
.sync-so-table th:nth-child(3) {
  width: 35%;
}

.sync-so-table td:nth-child(4) span {
  padding: 0.7rem 0.5rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.475rem;
  font-size: 12px;
  color: var(--red-status-text);
  background: var(--red-status);
  display: inline-block;
  word-break: keep-all;
  width: 74px;
}

html body .sync-item-table td:nth-child(1),
html body .sync-so-table td:nth-child(1) {
  padding-left: 9px;
}

.sync-so-table td:nth-child(4) {
  text-align: center;
}

.sync-so-table th.status {
  vertical-align: inherit;
}

/* .ecom_item {
  padding-bottom: 60px;
} */

.map-item-overlay-inner {
  /* clear: both;
  overflow: hidden;
  transition: all 0.2s linear;
  font-size: 15px; */
}

/* .list-table--wrapper.ecom_item_mapping {
  padding-bottom: 60px;
} */

/*.map-item-overlay-inner.map-item-overlay-active {
  bottom: 0;
  transition: 0.6s bottom ease;
  animation: myAnim 1s ease 0s 1 normal forwards;
  box-shadow: 0 0px 6px 2px rgb(168 175 165 / 31%);
  background: #f0f2f5;

}

.ecom-sync-table+.total_count2 {
  border-top: 1px solid #dfe1e5;
  margin-top: 19px;
  border-bottom: 1px solid #dfe1e5;
  margin-left: 2px;
  margin-right: 2px;
}

.list-table--wrapper.ecom_item_mapping {
  padding-bottom: 60px;
}

/* @keyframes myAnim {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes topbottom {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
} */

.map-item-overlay {
  clear: both;
  /* width: 82.3%; */
  overflow: hidden;
  /* margin-left: auto; */
  /* position: fixed; */
  /* margin: 0 auto; */
  /* bottom: 0;
  background-color: #fff;
  box-shadow: 0 0px 6px 2px rgb(168 175 165 / 31%); */
  margin-top: 3px;
}

.map-item-overlay-collapse .map-item-overlay {
  width: calc(82.3% - 110px);
}

/*==============================
  Global listings
===============================*/
.so_main_list td:nth-child(4) .list_items_desc div,
.items_main_list td:nth-child(2) .list_items_desc div {
  display: flex;
  width: 100%;
  align-items: center;
}

.so_main_list td:nth-child(4) .list_items_desc div span:nth-child(1),
.items_main_list td:nth-child(2) .list_items_desc div span:nth-child(1) {
  flex: 0 0 90%;
}

.so_main_list td:nth-child(4) .list_items_desc div span:nth-child(2),
.items_main_list td:nth-child(2) .list_items_desc div span:nth-child(2) {
  flex: 1 0 10%;
  line-height: 0;
}

.so_main_list td:nth-child(4) {
  width: 35%;
}

/*==============================
 Ecom Responsiveness
===============================*/
@media screen and (max-width: 1600px) {
  .ecom-integration .ecom-sidenav .sidenav-heading {
    padding: 15px 10px;
  }

  .ecom-integration .ecom-sidenav .sidenav-heading h2 {
    font-size: 16px;
  }

  .ecom-integration .ecom-sidenav .ecom-sidenav--link {
    font-size: 14px;
  }

  .ecom-integration .ecom-sidenav .sidenav-heading a {
    font-size: 11px;
  }

  .general-table .ecom-table-inner .ecom-table-set .ecom-details {
    font-size: 12px;
  }

  .ecom-tbl-mob.ecom-sync-table th:nth-child(2),
  .ecom-tbl-mob.ecom-sync-table td:nth-child(2) {
    width: 20%;
  }

  .ecom-tbl-mob.ecom-sync-table th:nth-child(3),
  .ecom-tbl-mob.ecom-sync-table td:nth-child(3) {
    width: 10%;
  }

  .ecom-tbl-mob.ecom-sync-table th:nth-child(4),
  .ecom-tbl-mob.ecom-sync-table td:nth-child(4) {
    width: 15%;
  }

  .ecom-tbl-mob.ecom-sync-table th:nth-child(5),
  .ecom-tbl-mob.ecom-sync-table td:nth-child(5) {
    width: 15%;
  }
}

.disconnect-popup-main h3 {
  font-size: 18px;
  font-weight: 400;
}

.disconnect-popup-main ul {
  padding-left: 20px;
}

.disconnect-popup-main ul li {
  list-style: disc;
  margin-bottom: 8px;
  font-size: 12px;
}

.map-btn-pagination {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
  align-items: center;
  overflow: hidden;
}

.map-btn-pagination .btn-style {
  line-height: inherit;
}
html body .map-item-overlay-inner .item-mapping-right {
  margin-right: 0;
}
.map-item-disabled,
.map-item-disabled .btn-green {
  opacity: 0.6;
  cursor: not-allowed;
}

.map-btn-pagination .map-total {
  /* flex: 0 0 60%;
  text-align: left; */
  /* float: left;
  margin-top: 10px; */
}

.map-btn-pagination .item-mapping-right {
  /* flex: 0 0 25%; */
  float: right;
  margin-right: 3rem;
}

/* .map-btn-pagination {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  width: 100%;
} */

@media screen and (max-width: 1280px) {
  /* .ecom-integration .ecom-sidenav {
    display: none;
  } */

  .map-item-overlay {
    width: 100%;
  }

  .integration-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {

  .ecom-tbl-mob.ecom-sync-table th:nth-child(3),
  .ecom-tbl-mob.ecom-sync-table td:nth-child(3) {
    width: 15%;
  }

  .ecom-tbl-mob.ecom-sync-table th:nth-child(2),
  .ecom-tbl-mob.ecom-sync-table td:nth-child(2) {
    width: 18%;
  }
}

@media screen and (max-width: 890px) {
  .unsync-table {
    min-width: 590px;
    overflow: auto;
    display: block;
  }

  .unsync-table::-webkit-scrollbar {
    height: 8px;
  }
}

@media screen and (max-width: 768px) {
  html body .ecom-main-inner {
    flex-direction: column;
  }

  .ecom-sync-table th:nth-child(1),
  .ecom-sync-table td:nth-child(1),
  .ecom-sync-table th:nth-child(6),
  .ecom-sync-table td:nth-child(6) {
    width: 0.8%;
  }

  .ecom-integration .ecom-header-main {
    margin-right: 20px;
  }

  .ecom-main .ecom-right-sec {
    border-top: 1px solid #f0f2f5;
    border-left: none;
  }

  .general-table .ecom-table-set tbody tr td:nth-child(2) {
    width: 30%;
  }

  .ecom-integration .ecom-header-title p {
    font-size: 13px;
  }
}

@media (max-width: 890px) {
  .unsync-table {
    min-width: 590px;
    overflow: auto;
    display: block;
  }

  .ecom-sync-table.unsync-table td:nth-child(5) {
    width: 35%;
  }

  .unsync-order-popup .unsync-table td:nth-child(5) .list_items_desc .css-2b097c-container {
    flex: 0 0 55%;
    width: 100%;
  }
}

/*==============================
Header standard 
===============================*/
.sync-logo .store-name {
  font-size: 14px;
  display: inline-block;
  /* padding-left: 5px; */
  font-weight: bold;
}

.ecom-integration .ecom_sync_history .input-box {
  text-align: right;
  padding: 10px;
  display: block;
  float: right;
  width: 100%;
  /*text-align: right;
  padding: 0 10px 16px 0;*/
}

.sync-history-dropdown .total-order {
  padding: 10px;
  margin-bottom: 0;
}

.ecom-integration .sync-history-pagination .pagination-input
 {
  height: 30px !important;
}
.sync-history-pagination .page-count-header .searchable-dropdown{
  height: 34px !important;
  margin-bottom: 10px;
}
.ecom-integration .page-count-header .searchable-dropdown__control {
  border: 1px solid var(--text-field-border) !important;
  min-height: unset !important;
}

.ecom-integration .sync-history-pagination .searchable-dropdown {
  border: none !important;
}

.ecom-integration .pagination-route.sync-history-pagination {
  padding: 7px 0;
}

.ecom-integration .sync-history-left,
.ecom-integration .ecom_sync_history,
.ecom-integration .sync-history-dropdown .sync-history-left {
  padding: 0 !important;
}

.ecom-integration .sync-history-left .sync-logo,
.ecom-integration .sync-item-single .sync-history-left .ecom-header-logo {
  padding: 10px 10px 0 !important;
}

.ecom-integration .sync-history-pagination+.sync-history-dropdown .close-icon,
.ecom-integration .ecom_sync_history .close-icon {
  top: 8px !important;
}

.ecom-integration .sync-history-dropdown,
.ecom-integration .sync-history-left,
.ecom-integration .ecom_sync_history,
.ecom-integration .sync-history-dropdown .sync-history-left {
  min-height: 42px !important;
  box-shadow: 0 0px 6px 2px rgb(168 175 165 / 31%);
}

.sync-so-table {
  margin-top: 10px;
}

.unsync-order-popup .sync-history-pagination .pagination-input,
.unsync-order-popup .sync-history-pagination .page-count-header .searchable-dropdown {
  height: 35px !important;
}

.ecom-sync-item {
  margin-top: 2px;
}

.btn-arrow-drop {
  position: relative;
  cursor: inherit;
}

.btn-arrow-drop::before,
.btn-arrow-drop::after {
  content: '\0020';
  display: block;
  position: absolute;
  bottom: -9px;
  left: 50%;
  z-index: 2;
  width: 0;
  height: 0;
  overflow: hidden;
  border: solid 10px transparent;
  border-top: 0;
  border-bottom-color: #fff;
  transform: rotate(180deg);
  margin-left: -5px;
}

.btn-arrow-drop::before {
  bottom: -10px;
  z-index: 1;
  border-bottom-color: #f0f2f5;
}

.d-flex.contentCenterDetaisl {
  justify-content: center;
}

@media screen and (max-width: 1366px) {
  .sync-item-table th:nth-child(1) {
    width: 50%;
  }

  .sync-item-table th:nth-child(3),
  .sync-item-table th:nth-child(4) {
    width: 15%;
  }
}

@media screen and (max-width: 1138px) {
  .ecom-warehouse-row {
    justify-content: space-between;
  }

  .ecom-warehouse-row .warehouse-block-main {
    flex: 0 0 49.5%;
    margin-right: 0;
  }

  .sync-item-table th:nth-child(1) {
    width: 50%;
  }

  .sync-item-table th:nth-child(3) {
    width: 20%;
  }

  .ecom-sync-item.sync-item-table thead th:nth-child(4) {
    padding-right: 10px !important;
  }

  .ecom-integration .list-table--wrapper {
    padding: 0 10px;
  }
}

@media screen and (max-width: 1024px) {
  .ecom-integration .card-header {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 10px;
  }
}