.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 0.8rem;
  background-color: #fff;
  border: 1px solid var(--border-dark);
  max-height: 20rem;
  overflow-y: auto;
  z-index: 2;
  border-radius: 4px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 1rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: var(--hover);
}

.auto-complete--suggestion {
  display: flex;
  justify-content: space-between;
}

.auto-complete--suggestion svg {
  pointer-events: none;
  color: var(--icon-primary);
}

.auto-complete--suggestion button:hover svg{
  fill: var(--alert-error);
}