/* .timeline-card {
  position: relative;
  padding: 1.2rem 1.6rem;
}

.timeline-date {
  font-size: 1.2rem;
}
.timeline-card-body {
  display: grid;
  grid-gap: 0.8rem;
  align-items: center;
}
.timeline-card.with-line {
  margin-left: 2.4rem;
}

.timeline-card.with-line:before,
.timeline-card.with-line:after {
  position: absolute;
  left: 0;
  top: 1.6rem;
  transform: translate(-50%, 0);
}
.timeline-card.with-line:before {
  content: '';
  height: 1rem;
  width: 1rem;
  background-color: var(--border-dark);
  border-radius: 50%;
  z-index: 1;
}
.timeline-card.with-line:not(:last-child):after {
  content: '';
  height: 100%;
  width: 1px;
  border: 1px solid var(--border-light);
}

.timeline-card-content {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.8rem;
  grid-template-columns: auto 1fr;
  align-items: start;
}

.timeline-card-body svg {
  fill: var(--icon-primary);
  font-size: 1.4rem;
  margin-top: 3px;
}

.timeline-card-footer {
  color: var(--text-secondary);
  font-size: 1.2rem;
} */

/* .timeline-card-content div {
  margin: var(--block-margin);
} */
.activity_new{padding-left: 25px;position: relative;display: flex;min-height: 80px;}
.activity_new .left{float: left;position: relative;width: 18%;}
.activity_new .border_structure{float: left;width: 10%;position: relative;}
.activity_new .right{float: left;width: 72%;position: relative;margin-bottom: 20px;text-align: justify;}
.activity_new .border_structure .icon{
    /* position: absolute;
    top: 5px;
    left: 26%; */
    height: 35px;
    width: 35px;
    border: 1px solid #CCC;
    border-radius: 17.5px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;
    z-index: 9;
    top: 5px;
    fill: rgb(142, 142, 142);
}
.activity_new .left .date{position: relative;top: 10px;font-weight: 400;text-align: right;}
.activity_new .left small{position: relative;top: 10px;text-align: right;float: left;width: 100%;color: #aaaaaa;}
.activity_new .right .desc{position: relative;top: 10px;word-break: break-word;}
.activity_new .right small{position: relative;top: 10px;color: #aaaaaa;}
.activity_new .right .icon svg{
  height: 16px;
  width: 16px;
}
/* .activity_new .right::after{content: "";background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' %3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%2380F'/%3E%3Cstop offset='1' stop-color='%23f40'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpattern id='b' width='24' height='24' patternUnits='userSpaceOnUse'%3E%3Ccircle fill='%23ffffff' cx='12' cy='12' r='12'/%3E%3C/pattern%3E%3Crect width='100%25' height='100%25' fill='url(%23a)'/%3E%3Crect width='100%25' height='100%25' fill='url(%23b)' fill-opacity='0.1'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;left: -62px;position: absolute;height: 25px;width: 25px;border-radius: 15px;border: 1px solid #000;padding: 10px;} */
.border_structure:before{content: "";left: 49%;position: absolute;top: 0;height: 100%;width: 1px;background-color:#CCC;}
