.account-summary {
  /* display: grid;
    grid-template-columns: 1fr auto;
    justify-content: flex-end;
    margin: 4rem 0 2rem; */
  float: left;
  width: 100%;
  padding: 20px 0 !important;
}
.statement_table{
    float: left;
    width: 100%;
    max-height: calc(100vh - 442px);
    border: 1px solid #DFE1E5;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #c0c0c0 #efecec;
}
.statement_table table{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.statement_thead th{
  height: 43px !important;
  position: -webkit-sticky; 
  position: sticky !important;
  top: -1px;
  z-index: 1;
  background: #E9E9E9;
}

.account-summary--details {
  /* display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 6rem;
    grid-row-gap: 1rem; */
  /* text-align: right; */
  float: left;
  width: 100%;
}

.account-summary--details > div:nth-child(-1n + 1) {
  grid-column: span 2;
  text-align: left;
}

.hidden {
  display: none;
}

.account-summary--background-title {
  background: var(--background);
  padding: 1rem 1.6rem;
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 15px;
}
.mb-1 {
  margin-bottom: 1px;
}
.account-summary hr {
  border-top: 1px solid #adadad !important;
  margin: 0.2rem 0 !important;
}
.w-75{
  width: 75% !important;
}
.padding-summarry {
  padding: 0 2.5rem;
}
.ims-modal-container .padding-summarry {
  padding: 0 2.5rem;
  width: 100% !important;
}
.statement-icons{
  background: #E9E9E9;
  padding: 4px;
  float: right;
  cursor: pointer;
}

.ims-modal--body .statement_table.scrollbar_style {
  margin-bottom: 2rem;
}

a svg{
  height: 1em;
  width: 1em;
}