.switch-label{
  background: #e3e3e3;
  padding: 0.75rem 1.0rem;
  border-radius: 3px;
}
.switch-wrapper{
  cursor: pointer;
}
.switch-active{
  background: #3bb54a;
  color: white;
}