.dashboard_box_width {
  float: left;
  width: 33.33%;
  padding: 0 4px 0 0;
}

.dashboard_box_width_two {
  float: left;
  width: 66.67%;
  padding-right: 5px;
}

.dashboard_box_width_two .data_section {
  height: 489px;
}

.dashboard_box {
  float: left;
  width: 100%;
  height: 260px;
  border: 1px solid #e3e3e3;
  /* margin: 1px; */
  margin-bottom: 5px;
  transition: 0.3s;
}
.dashboard_box:hover {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.5);
}

.data_section {
  height: 226px;
}

.dashboard_box_header {
  background: #e9e9e9;
  height: 34px;
  float: left;
  width: 100%;
}

.dashboard_box .custom-dropdown>.button:hover {
  background-color: transparent !important;
}

.dashboard_box:hover .dashboard_box_header {
  background: linear-gradient(#e9e9e9 0%,
      #fff 29.7%,
      #ececec 52.3%,
      #fff 77.39%,
      #d2d2d2 100%);
}

.double_dash_box {
  /* width: 66.2%; */
  height: 525px;
}

.triple_dash_box {
  /* width: 99.4%; */
}

.dashboard_box_header p:first-child {
  font-weight: 700;
  /* line-height: 2.5rem; */
  font-style: italic;
  padding-left: 7px;
  display: flex;
  float: left;
  height: 100%;
  align-items: center;
  border-left: 10px solid transparent;
  transition: 0.3s;
}

.dashboard_box_header_dropdown .inline {
  position: unset;
}

.dashboard_box:hover .dashboard_box_header_dropdown span:first-child {
  /* border-left: 0px solid transparent !important; */
}

.dashboard_box_header_dropdown span:last-child {
  float: left !important;
  font-style: normal;
  border-left: 0 !important;
}

.dashboard_box_header_dropdown .rdrDayNumber span {
  padding-left: 0 !important;
}

.dashboard_box:hover .dashboard_box_header_dropdown .rdrDayNumber span {
  border-left: 0 !important;
}

.dashboard_box_header p {
  /* line-height: 2.5rem; */
  font-style: normal;
  /* padding-left: 8px; */
  display: flex;
  float: left;
  height: 100%;
  align-items: center;
  /* border-left: 10px solid transparent; */
  transition: 0.3s;
}

.dashboard_box_header p:last-child {
  float: right;
}

.dashboard_box:hover .dashboard_box_header p:first-child {
  border-left: 10px solid green;
}

.dashboard_po_info_progress {
  text-align: center;
  /* margin: auto; */
  float: left;
  width: 100%;
  padding: 0 5rem;
}

.dashboard_box_header_dropdown {
  float: right;
  position: relative;
  display: flex;
}

.dashboard_box_header_dropdown .summary-timeline--date-range.custom-dropdown.button-text.custom_name>button {
  top: 0;
}

.data_section .slick-slider {
  height: 100%;
}

.data_section .slick-slider .slick-list {
  height: 100%;
}

.data_section .slick-slider .slick-list .slick-track {
  height: 100%;
}

.dashboard_box_header .custom-dropdown.button-text .custom-dropdown-container {
  left: -2rem !important;
}

/* .dashboard_box_header_dropdown .date-range-picker { */
/* top: 3rem; */
/* left: -19.5rem; */
/* fixed date picker style in dashboard temporary fix*/
/* } */
.dashboard_box_header_dropdown .date-range-picker {
  left: unset;
}

.dashboard_box_header_dropdown .date-range-picker.date-picker-main {
  /* left: 0; */
  right: 0;
  top: 35px;
}

.data_section>div {
  float: left;
  width: 100%;
  height: 100%;
}

.dashboard_box_header_dropdown .custom-dropdown .button svg:first-child {
  fill: #000;
  margin-left: 10px;
  margin-right: 2px;
  height: 15px;
  width: 15px;
}

.dashboard_box_header_dropdown .custom-dropdown .button :hover svg {
  fill: #306ab4 !important;
}

.dashboard_box_header_dropdown .custom-dropdown .button:hover svg path {
  fill: #306ab4 !important;
}

.custom_name button {
  font-size: 0 !important;
}

.summary-timeline--date-range.custom-dropdown.button-text.custom_name>button {
  position: relative;
  top: -5px;
}

.custom_name .custom-dropdown--options {
  font-size: 12px !important;
}

.dashboard_box_header .custom-dropdown.button-text .custom-dropdown-container {
  left: unset !important;
  right: 0 !important;
  width: 115%;
}

.dashboard_box_header .custom-dropdown.button-text.custom_name .custom-dropdown-container {
  /* width: 230% !important; */
  width: 16rem;
}

.dashboardtitleTootip {
  margin: 5px 0 0 5px;
}

.dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown) .summary-timeline--date-range {
  font-weight: bold;
  color: #33a348;
}

.dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown) .summary-timeline--date-range .button>svg path,
.dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown) .summary-timeline--date-range .button>svg {

  fill: #33a348;

}

html body .dashboard_mobile-box div.dashboard_box.box_6 .custom-dropdown-container>.custom-dropdown--options:hover, .custom-dropdown.button-text .button-active+.dashboard_box.box_6 .custom-dropdown-container .custom-dropdown--options:hover,
.dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown).custom-dropdown-set:hover {
  color: #33a348 !important;
}

.search-bar form input {
  padding: .5rem 3.6rem .5rem 1rem;
}

@media (min-width: 1281px) and (max-width: 1460px) {
  .dashboard_box {
    height: 290px;
  }

  .data_section {
    height: 255px;
  }
}

@media (max-width: 1440px) {
  .date-range-picker.date-picker-main {
    /* left: -22rem; */
  }
}

@media (max-width: 1600px) {
  .dashboard_mobile-box .custom-dropdown.button-text .button {
    padding: 0.8rem 0.26rem;
  }

  /* .dashboard_box.box_6 .custom-dropdown.button-text .button */
  .dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown) .custom-dropdown.button-text .button {
    padding: 0.8rem 0.02rem;
    font-size: 10px;
  }
}

@media (max-width: 1400px) {
  .dashboard_box.box_4.box-cus-main .dashboard_box_header p:nth-child(2) {
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 70px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 7px 0 0 0;
  }

.dashboard_box .custom-dropdown.button-text .button>svg {
    width: 13px;
    height: 13px;
  }

  .dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown) .custom-dropdown.button-text .button {
    font-size: 9px;
  }
}

/* @media (min-width: 1200px) and (max-width: 1440px) {
  .dashboard_box.box_1 .date-range-picker.date-picker-main {
    left: 0 !important;
  }
} */

/*Quantity in dashboard top sellings items quantity drop menu hover effect */


.dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown) .summary-timeline--date-range {
  font-weight: bold;
  color: #33a348;
}

.dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown) .summary-timeline--date-range .button>svg path,
.dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown) .summary-timeline--date-range .button>svg {
  fill: #33a348;
}

html body .dashboard_mobile-box div.dashboard_box.box_6 .custom-dropdown-container>.custom-dropdown--options:hover .custom-dropdown-set {
  color: #33a348 !important;
}

html body .dashboard_mobile-box div.dashboard_box.box_6 .custom-dropdown-container>.custom-dropdown--options:hover, .custom-dropdown.button-text .button-active+.dashboard_box.box_6 .custom-dropdown-container .custom-dropdown--options:hover,
.dashboard_box.box_6 .dashboard_box_header span:not(.dashboard_box_header_dropdown).custom-dropdown-set:hover {
  color: #33a348 !important;
}

.search-bar form input {
  padding: .5rem 3.6rem .5rem 1rem;
}
@media(max-width:1366px){
  .dashboard_box_width .dashboard_box .dashboard_box_header .custom-dropdown.button-text .custom-dropdown-container {width: 100%;min-width: 100px;}
}
@media(max-width:1199px){
  .dashboard_mobile-box .custom-dropdown.button-text .button-active + .custom-dropdown-container .custom-dropdown--options {
    padding: 0 0.5rem 0 .4rem !important;
}
}
@media(max-width:768px){
  .triple_dash_box.box_10 span.dashboard_box_header_dropdown {
    margin-right: 0;
}
}