@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(135deg);
  }
}

.SettingsDropDown {
  position: absolute;
  right: 10rem;
  top: 5rem;
  width: 50rem;
  height: auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.15);
  float: left;
  padding: 10px;
  animation-name: fadeIn;
  animation-duration: 0.3s;
}

.hover-underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.SettingsDropDown .main-heading {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
}

.SettingsDropDown .general_icons {
  width: 16px !important;
  height: 16px !important;
  fill: #000 !important;
}

.SettingsDropDown ul {
  float: left;
  width: 100%;
}

.SettingsDropDown ul li {
  float: left;
  width: 50%;
  cursor: pointer;
  font-size: 16px;
}

.SettingsDropDown ul li a {
  font-size: 16px;
  color: #000;
}

.SettingsDropDown ul li a svg {
  margin-right: 10px;
}

.SettingsDropDown ul li a:hover {
  color: #306ab4;
}

.settings-transform {
  animation-name: spin;
  animation-duration: 0.2s;
  transform: rotate(135deg);
  animation-timing-function: linear;
}

.asyn-paginate-custom {
  width: 100%;
}

.asyn-paginate-custom .asyncPaginateParent .css-b8ldur-Input {
  margin: 0;
}

/* .asyn-paginate-custom .asyncPaginateParent .css-1wa3eu0-placeholder {
  margin-left: 5px;
} */
.field-error {
  position: absolute;
  font-size: 1.1rem;
  color: var(--red-500);
  whitespace: nowrap;
  right: 0.8rem;
  bottom: -1.6rem;
}

.input-error-field .asyncPaginateParent>div {
  border-color: var(--red-500) !important;
  /* box-shadow: none; */
}

.asyncPaginateParent .option--with-image {
  overflow: hidden;
  float: unset;
}

.asyn-paginate-custom .css-1pahdxg-control {
  box-shadow: none;
}

.customer-name-fileds .field-error {
  right: 2.8rem;
}

.css-1hwfws3 {
  padding-left: 20px;
}

/* .css-1uccc91-singleValue {
  padding-left: 15px;
} */

#settings_link {
  display: flex;
  align-items: center;
}