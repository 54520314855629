.CaretDownButton {
  background-color: #306ab4;
  color: #fff;
  position: relative;
  width: 83px;
  text-align: left;
  padding: .2rem 1rem !important;
  border-radius: 2px;
  transition: 0.3s;
}

.CaretDownButton svg {
  fill: #FFF;
  height: 100%;
  width: 20px;
  transform: rotate(0) !important;
  position: absolute;
  right: 0;
  top: 0;
}

.CaretDownButton svg path {
  fill: #FFF;
}

.CaretDownButton:hover svg {
  background-color: #183d6b;
}

.menu_top_inner_content .pointerIn .custom-dropdown-container {
  right: 0 !important;
}

.menu_top_inner_content .sub_tabs_transaction_main tbody tr td:last-child button {
  padding: 0 1rem 0 20px;
}