.invoice-preference label {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}

.invoice-preference--label-text {
  margin: unset !important;
  padding-left: 2rem;
}

.email-preference {
  float: left;
  width: 100%;
  display: none;
}

.email-preference .form-field {
  max-width: 180px;
}

.email-preference .searchable-dropdown__control {
  /* display: block; */
  position: relative;
  height: 40px;
  /* padding: 0.4rem 0.8rem; */
  border-radius: 4px;
  padding: 0.2rem 0.2rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.invoice-terms-pref label.form-label,
.mb-5 {
  margin-bottom: 5px !important;
}
.email-preference svg {
  margin-top: 3px;
}

.__invoice-main--block pointer {
  margin-top: 11px;
}
.__invoice-main--block label {
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
  font-size: 16px;
  margin-bottom: 5px !important;
}
.admin-block span {
  font-size: 11px;
  color: red;
}
.admin-block {
  font-weight: 400 !important;
  font-size: 14px;
}
