.contact-transaction-summary {
  padding: 1.6rem;
}

.contact-transaction-summary--overview-section {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 5rem);
  background: var(--background);
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 1rem;
  word-break: break-all;
}

.contact-transaction-summary--overview-section > div:nth-child(2) {
  text-align: right;
}

.contact-transaction-summary--overview-section.background-transparent {
  background: transparent;
}