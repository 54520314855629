.home-pages {
  height: 100vh;
  overflow: hidden;
}

.home-pages-nav {
  display: grid;
  grid-template-columns: 4rem 1fr 1fr 1fr 1fr 4rem;
  height: 5rem;
  box-shadow: 0 2px 8px 0 #cccccc;
  padding: 0 10%;
}
.home-pages-nav button {
  border: 0;
  outline: 0;
  color: var(--blue-500);
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-pages-nav button div {
  padding-left: 1rem;
}
.home-pages-nav button:first-of-type,
.home-pages-nav button:last-of-type {
  font-size: 3rem;
}

.home-pages-heading {
  font-size: 2.4rem;
  margin-bottom: 2rem;
}

.home-pages-section {
  height: calc(100% - 5rem);
}

.home-pages-nav button svg {
  font-size: 2.5rem;
}
.home-pages-nav--active {
  position: relative;
}
.active {
  cursor: pointer;
}
.grey-background {
  background: #fcfcf9;
}
.home-pages-nav--active:after 	{
  content: '';
  height: 2px;
  width: 100%;
  visibility: visible;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--blue-500);
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

@media only screen and (max-width: 1200px) {
  .home-pages {
    height: auto;
  }

  .home-pages-nav {
    height: 6rem;
    padding: 0 1.5rem;
  }
}

@media only screen and (min-width: 1800px) {
  .home-pages-heading {
    font-size: 240%;
  }
  .home-pages-nav {
    height: 7rem;
  }
  .home-pages-nav button {
    font-size: 120%;
  }
}