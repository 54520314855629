.layout--box {
  background: #fff;
  /* border: 1px solid var(--border-dark); */
  /* box-shadow: var(--shadow-medium); */
  border-radius: 4px;
  padding: 1.6rem 2.4rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;
  max-width: 110rem;
}

.layout--box.no-outline {
  /* no border, no shadow */
  border: none;
  box-shadow: none;
}

.layout--box.no-padding,
.layout--box.no-padding .layout--box--body {
  padding: 0;
}

.btn-width {
  width: auto !important;
  display: flex;
  align-items: center;
}

.layout--box.no-padding-body {
  padding: 0;
}

.layout--box.no-padding-body .layout--box--title {
  padding: 1.6rem 2.4rem;
}

.layout--box--title {
  margin: 0;
  font-weight: 500;
}

.layout--box--body--empty {
  display: flex;
  justify-content: center;
  height: 100%;
  flex: 1;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 20rem;
}

.layout--box--body--empty svg {
  height: 6rem;
  width: 5rem;
}

.layout--box--body {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.layout--box--subtitle {
  color: var(--light-grey-text);
  margin: 2rem 0;
}

.layout-box--button {
  outline: none;
  border: 0;
  color: var(--blue-500);
  background: transparent;
  padding: 0;
  /* text-align: left; */
  margin: auto;
  width: 100%;
  text-decoration: underline;
}

.shipment_modal .layout-box--button {
  width: auto !important;
}

.black-fill {
  margin-right: 3px;
  fill: #000;
  font-size: 2rem;
  height: 3rem !important;
  width: 2rem !important;
}
.black-fill:hover {
  fill: #000 !important;
}

.layout--box--body--empty .buttons_parent {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
}
.layout--box--body--empty .buttons_parent button svg {
  vertical-align: middle;
}
.layout--box--body--empty .buttons_parent button {
  color: #306ab4;
}
.layout--box--body--empty .buttons_parent button:hover {
  text-decoration: underline;
}
.layout--box--body--empty .buttons_parent button:first-child {
  margin-right: 30px;
}
