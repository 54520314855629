@media print {
  .menu-component--menu,
  .details-headers,
  .listing_component,
  .actions-menu--holder,
  .header-responsive-main,
  .header-center,
  .header-responsive-right,
  .ims-navigation {
    display: none !important;
  }
  .sales_order_set .sales_order_info,
  .onprint {
    display: flex;
  }
  img {
    text-indent: -9999px;
  }
}
