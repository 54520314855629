.page-four {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  height: 100%;
  align-items: center;
}

.page-four-title {
  padding-right: 15%;
}

.page-four-img {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 8rem;
}
.page-four-img img {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .page-four {
    display: flex;
    flex-direction: column;
  }

  .page-four-title {
    padding-right: 0;
    align-items: center;
    padding-top: 5rem;
  }

  .page-four-img {
    width: 65vw;
    padding-top: 0;
  }
}