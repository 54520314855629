.terms-and-condition {
  padding: 0.8rem 2.4rem 2.4rem;
  /* padding: 1.2rem 2.4rem 2.4rem; */
  /* border-top: 1px solid var(--border-light); */
  float: left;
  width: 100%;
  /* margin-top: 15px; */
  margin-top: 5px;
}

.terms-and-condition h5 {
  font-size: 1.5rem;
}

.heading {
  font-weight: bold;
}

hr {
  border-top: 1px solid #adadad !important;
  margin: 0.2rem 0 !important;
}

.terms-box {
  background: #fff;
  border: unset;
  height: 10rem;
  max-width: 100%;
  word-break: break-word;
  resize: none;
  scrollbar-width: thin;
}

.notes {
  padding-bottom: 0;
}

/* .terms_condition_main textarea.float-left.w-100.terms-box.term-area-set, .notes textarea.float-left.w-100.terms-box {
  height: auto;
} */

.terms-and-condition.terms_condition_main {
  padding-top: 0;
  margin-top: 0;
}

.notes textarea.float-left.w-100.terms-box,
textarea.float-left.w-100.terms-box.term-area-set {
  padding: 0;
}
