.Package_Details {
  width: 80%;
  margin: auto !important;
}

.package_middle_row {
  width: 100%;
  /* display: flex; */
  float: left;
  padding-top: 50px;
  /* border-top: 1px solid #dfe1e5; */
  /* padding: 10px 0; */
}

.package_address {
  background-color: #f9f9f9;
  padding: 10px 0px !important;
  margin-top: 8rem;
}

.package_address .more_details {
  padding: 0 20px !important;
}

.package_address .more_details span {
  width: 100% !important;
  border-bottom: 1px solid #dfe1e5;
  padding: 5px 0;
  color: #306ab4;
  font-size: 16px;
}

.package_address .more_details .left_sec {
  width: 100% !important;
}

.package_address .more_details .left_sec span {
  border: 0 !important;
  padding: 0 !important;
  color: #000;
  word-break: break-word;
}

.package_address .more_details li:first-child span {
  font-weight: 500 !important;
}

.package_address .details_row {
  border-bottom: 0 !important;
}

.package_ship_btn {
  display: flex;
}

.shipment_no {
  color: #337ab7;
}

.custom_paddding {
  padding: 10px 22px;
}

.Package_Details hr {
  border-top: 1px solid #dfe1e5 !important;
}

.no-cursor {
  cursor: text;
}

.margin_bottom_no {
  margin-bottom: 0 !important;
  float: left;
  width: 100%;
}

.margin_bottom_yes {
  margin-bottom: 20px !important;
  float: left;
  width: 100%;
}

.credit_sales_return_table table tbody tr td:nth-child(1) {
  width: 55%;
}

.credit_sales_return_table table thead tr th:nth-child(2),
.credit_sales_return_table table tbody tr td:nth-child(2) {
  text-align: right;
}

.credit_sales_return_table table thead tr th:nth-child(3),
.credit_sales_return_table table tbody tr td:nth-child(3) {
  text-align: center;
}

/* updated  package info style*/
.w-50 {
  width: 50%;
}

.bottom-border-set {
  border-bottom: 1px solid #dfe1e5 !important;
  height: 28px;
  overflow: hidden;
}

.td-left-set {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  padding-top: 2px;
  text-transform: capitalize;
}

.td-right-set {
  font-size: 14px;
  text-align: right;
  padding-top: 2px;
  color: #000;
}

.table-w {
  width: 100%;
}

.main-table-w {
  max-width: 360px;
  width: 96%;
  overflow: hidden;
  /* margin: 0 auto; */
}

.pckage-module-inner .package_details.pk-1 {
  display: none;
}

@media (max-width: 890px) {
  .create-shipment-popup .action-buttons {
    padding: 0px 0 20px 0;
  }

  .create-shipment-popup hr {
    display: none;
  }
}
@media (min-width: 1281px) and (max-width: 1440px) {
  /* .main-table-wraper {
      width: 94%;
    }
    .main-table-wraper .main-table-w{
      width: 100%;
    } */
}
@media (max-width: 1280px) {
  .package_middle_row .main-table-w {
    width: 90%;
  }

  /* .shipment-large.cus-shipment-popup-set .form-field.inline-field:nth-child(3n) {
    margin-right: 3rem;
  } */
  .shipment-large.cus-shipment-popup-set .text_area_postion {
    display: block;
  }
}
