.slick-slider-wrapper {
  clear: both;
}
.slick-slider {
  margin: 0 auto;

  height: 226px;
}
.slick-slider-wrapper .slick-slide {
  padding: 20px 30px;
  height: 220px;
}
.slider-widget {
  display: flex !important;
  align-items: center;
  /* width: 100%;
  flex: 0 0 100%; */
}
.slider-widget-right {
  flex: 1;
  word-break: break-word;
}
.slider-widget-right h4 {
  margin: 0 !important;
}
.slider-widget-right .top-cus-left {
  float: left;
  width: 40%;
}
.slider-widget-right .top-cus-right {
  float: left;
  width: 60%;
}
.slick-list {
  padding: 0 !important;
}
.slider-widget .image_section {
  width: 110px;
  height: 110px;
  margin-right: 20px;
}

.dashoard_slider_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dashoard_slider {
  /* margin: 10px; */
  display: flex !important;
  flex-direction: row;
  padding: 30px 50px 50px 50px;
}

.dashoard_slider .image_section {
  height: 150px;
}

.dashoard_slider a {
  float: left;
  width: 100%;
}

.dashoard_slider_details {
  /* width: 50%; */
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashoard_slider_sub_details {
  float: left;
  width: 40%;
  position: relative;
}
.slider-widget-right .dashoard_slider_sub_details:after {
  content: ':';
  position: absolute;
  right: 0;
  font-weight: 500;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.topSelling-container {
  position: relative;
  border: 1px solid var(--border-blue-light);
  border-radius: 4px;
  background-color: white;
  box-shadow: var(--shadow-medium);
}

.topSelling-body {
  padding: 2rem 2rem;
}

/*styles for carousel*/
.slick-slider {
  width: 100%;
}

.slick-arrow {
  position: absolute;
  transform: none;
  height: 10%;
  /* width: 4.5rem; */
  border-radius: 4px;
}

.slick-arrow:hover {
}

.slick-prev {
  top: calc(50% - 4rem);
  transform: translateY(-50%);
  left: 5px !important;
}

.slick-next {
  top: calc(50% - 4rem);
  transform: translateY(-50%);
  right: 5px !important;
}

.slick-next:before,
.slick-prev:before {
  color: #306ab4 !important;
  font-weight: 500;
  font-size: 1.6rem;
}

.slick-prev:before {
  font-family: 'Font Awesome 5 Pro' !important;
  content: '\f053' !important;
}

.slick-next:before {
  font-family: 'Font Awesome 5 Pro' !important;
  content: '\f054' !important;
}

.slick-disabled:before {
  opacity: 0.8;
}

.slick-disabled {
  cursor: not-allowed;
}

.slick-arrow {
  z-index: 9;
}

.topSelling-item {
  padding: 0 1rem;
}

.line_clamp {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.topSelling--img {
  position: relative;
  padding-top: 100%;
  border: 1px solid var(--border-blue-light);
  border-radius: 4px;
}

.topSelling--img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
}

.topSelling--desc {
  margin-top: 1rem;
  color: var(--black);
  line-height: 1.2;
}

.topSelling--sku {
  margin: 1rem 0;
}

.topSelling--desc > div:last-child {
  font-weight: 500;
}

.slick-dots {
  /* margin-top: -140px; */
  /* position: relative; */
  bottom: 8px !important;
}

.slick-dots li {
  margin: 0 4px 0 0 !important;
  width: 16px !important;
  height: 16px !important;
}

.bold_text {
  font-weight: bold;
  float: left;
  width: 60%;
  padding-left: 6px;
}

/* .bold_text span {
  margin-right: 6px;
} */

.slick-dots li button {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  opacity: 1;
  left: 0;
  width: 13px !important;
  height: 13px !important;
  content: '';
  text-align: center;
  /* opacity: .25; */
  color: transparent !important;
  background: #8e8e8e;
  background-color: #8e8e8e;
  border-radius: 10px;
  margin-right: 5px;
  border: 2px solid transparent;
  transition: 0.3s;
}

.slick-dots li.slick-active button:before {
  background-color: #fff;
  border: 2px solid green;
  color: transparent !important;
}

.slick-dots li button:hover:before {
  border: 2px solid green;
  background-color: #fff;
}

.slick-next {
  right: 0;
}

.slick-prev {
  left: 0;
}

.slick-next,
.slick-prev {
  top: 50% !important;
}

@media (max-width: 1440px) {
  .dashoard_slider.dashboard-cus-set {
    padding: 15px 15px 24px 35px !important;
    margin: 13px;
  }

  .dashboard-cus-set span.bold_text {
    font-size: 13px;
    display: flex;
    align-items: center;
    padding-right: 4px;
  }

  .dashboard_top_customer.dashboard_customer-main {
    padding: 25px 24px 35px 35px;
    margin: 0 15px;
  }

  .dashboard_top_customer_det.dashboard_top_customer_mob {
    padding-left: 10px;
  }

  .dashboard-cus-set .dashoard_slider_details {
    padding-left: 10px;
  }

  .dashboard_mobile-box .count_progress_circle_main {
    padding-right: 14px;
  }
}

@media (max-width: 1366px) {
  .dashboard-cus-set .dashoard_slider_details {
    padding-left: 25px;
  }

  .dashboard-cus-set .float-left.w-30.image_section {
    padding-left: 14px;
  }
}
