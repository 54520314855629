.unique-warning {
  color: var(--red-500);
  font-size: 1.1rem;
  white-space: nowrap;
}
.contact-form .image-block {
  /*width: 12rem;*/
  /*width: 10rem;
  height: 10rem;
  background-size: cover;
  background-position: center;
  margin-left: 1rem;
  border-radius: 4px;*/
  width: 100%;
  height: 10rem;
  background-size: contain;
  margin-left: 1rem;
  border-radius: 4px;
  background-repeat: no-repeat;
}

.contact-form {
  float: left;
  width: 100%;
}

.inner-contact-form-picture {
  float: left;
  position: relative;
}

.others {
  margin-top: 1% !important;
}

.radio-feilds {
  float: left;
  margin-top: 47px;
  margin-left: 30px;
}

.Heading--forms {
  float: left;
  width: 100%;
  border-bottom: 1px solid var(--border-light);
  margin: 10px 0px;
  padding-bottom: 10px;
  font-size: 1.6rem;
  color: #000;
  font-weight: 700;
}

.float-left {
  float: left;
}

.w-100 {
  width: 100%;
}

.social_link_contact_bottom_text .ellipsis .form-field--bottom-text {
  max-width: 23rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact_email_helper {
  fill: #000;
  stroke: #000;
  width: 15px;
  height: 15px;
}

.contact_email_helper {
  fill: #000;
  stroke: #000;
  width: 15px;
  height: 15px;
}

.contact_email_field {
  position: absolute !important;
  /* right: 108px; */
  top: 10px;
  z-index: 1000;
  left: 54px;
  right: auto;
}

.email_base_field {
  position: relative !important;
  padding: 0 !important;
}

.contact_email_helper {
  fill: #000;
  opacity: 0.4;
}

.contact_email_helper:hover {
  fill: #306ab4;
  stroke: #306ab4;
}

.form-field-set {
  display: inline-block;
}
.contact-form .radio-feilds .form-field {
  align-items: center;
}

.contact-form .radio-feilds .form-field .form-label {
  margin-bottom: 0;
}
.form-fields-container .contact-email .form-field.error .input-feedback {
  bottom: -1.7rem;
}
.contacts-socials .form-field.error.flex-25 .input-feedback {
  word-break: break-word;
  width: 270px;
  white-space: break-spaces;
  position: relative;
  padding-bottom: 8px;
}
@media (max-width: 1440px) {
  .contact-form .radio-feilds {
    margin: 0;
  }
  .inner-contact-form-picture {
    display: block;
    width: 100%;
  }
  .inner-contact-form-picture .contact-add-profile {
    margin-top: 12px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 1138px) {
  .contact-form .action-buttons {
    clear: both;
    position: unset !important;
    /* max-width: 475px; */
    float: unset;
  }
}

/* confirmation block */
.confirmation-block .confirmation-desc {
  margin-bottom: 10px;
}
ul.confirmation-listing {
  width: 100%;
}

ul.confirmation-listing li {
  list-style-type: none;
  /* background-image: url(https://i.ibb.co/MPq1bvq/confirmation-icon.png); */
  background-repeat: no-repeat;
  background-size: 12px;
  padding-left: 29px;
  background-position: left;
  margin-top: 16px;
}
.confirmation-desc {
  flex-direction: column-reverse;
}
.confirmation-inner p {
  width: 64%;
  flex: 0 0 64%;
  margin: 0;
}
.confirmation-left .checkbox--option-label {
  flex: 1;
}
.form-tooltip{
  padding-left: 8px;
  padding-right: 8px;
}
.form-tooltip .contact_email_helper {
  position: absolute;
  top: 9px;
  right: auto;
  left: 63px;
}
.form-tooltip .icon_div{
  position: absolute;
  top: 9px;
  right: auto;
  left: 64px;
}

.form-tooltip .tooltip_tooltip{
  right: 0 !important;
  width: 227px !important;
  left: auto;
  left: 39px !important;
  top: -12px;
  padding: 10px 12px !important;

}
.form-tooltip .tooltip_tooltip p{
  margin: 0;
  font-size: 13px;
}