/* .ims-modal{
  width: 45.6%;
} */
.add-contact-address--radio {
  display: flex;
  margin: 0 0 1rem;
}
.add-contact-address--radio .radio-btn {
  margin-right: 4rem;
}
.add-contact-address--radio .radio-btn:last-child {
  margin-right: 0;
}
.mb_20 {
  margin-bottom: 20px;
}
.contact_person {
  float: left;
  width: 100%;
  border-top: 1px solid #dfe1e5;
  border-bottom: 1px solid #dfe1e5;
  padding: 10px 0 10px 0;
}
.contact_person .contact_person_card .card {
  background-color: #fbfbf9 !important;
  float: left;
  min-height: 304px !important;
  border: 1px solid #edeef0 !important;
}
.contact_person .contact_person_card:nth-child(odd) .card {
  background-color: #f6f6f2 !important;
  float: left;
}
.contact_person_card {
  float: left;
  width: 33.33%;
  padding: 0 5px;
  margin-bottom: 10px;
}
.contact_person_card .card {
  float: left;
  width: 100% !important;
}
.contact_person .card:first-child {
  background-color: transparent;
}
.contact_person .contact_person_card .card .card-header--title {
  font-size: 0;
}
.card_name {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.card_department {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}
.card_designation {
  font-size: 14px;
  font-weight: 400 !important;
  color: #000;
}
.card_email a {
  font-size: 14px;
  font-weight: 400;
  color: #497cbc;
  text-decoration: none;
}
.card_cell a {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  text-decoration: none;
}
.card_phone a {
  font-size: 14px;
  font-weight: 400;
  color: #000;
  text-decoration: none;
}
.name_initail {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-color: #8e8e8e;
  position: relative;
  margin: auto !important;
}
.name_initail span {
  position: absolute;
  left: 0;
  top: 13px;
  color: #fff;
  text-transform: capitalize;
}
