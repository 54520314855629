@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(135deg);
  }
}

.quick-add {
  position: absolute;
  left: 19rem;
  top: 5rem;
  width: 67rem;
  height: auto;
  background-color: #FFF;
  -webkit-box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.15);
  float: left;
  padding: 10px 0px;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.3s;
}

.quick-add-inner {
  float: left;
  width: 33.33%;
  padding: 0 15px;
}

.quick-add-inner .main-heading {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
}

.quick-add-inner ul {
  float: left;
  width: 100%;
}

.quick-add-inner ul li {
  float: left;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

.quick-add-inner ul li a{font-size:16px;color: #000;}
.quick-add-inner ul li a:hover {
  color: #306ab4;
}

.add-transform {
  animation-name: spin;
  animation-duration: 0.2s;
  transform: rotate(135deg);
  animation-timing-function: linear;
}