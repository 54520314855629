.add-sales-container {
  width: 100%;
}

.nav-tabs li.active a,
.nav-tabs li.active a:focus {
  background-color: #f5f5f5;
}

div.items-workable table th.adjust-item--close-btn,
.adjust-item--close-btn {
  padding: 0;
  width: 4rem;
}

.so-list-exp-tabs .panel {
  background-color: transparent;
}

.so-list-exp {
  margin-top: -2rem;
}

.so-list-exp-tabs ul.nav.nav-tabs {
  padding: 0;
}

.so-exp-options {
  position: absolute;
  right: 0;
  top: 0;
}

.so-exp-options>.dropdown>button {
  transform: rotate(90deg);
  background: transparent;
  border: 0;
  font-size: 1.7rem;
}

.so-exp-body {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 4rem;
}

.salereturn_detail_table_icon {
  margin-top: 3px !important;
  height: 23px;
  width: 27px !important;
}

.salereturn_list_table_icon {
  top: 4px !important;
  height: 24px;
  width: 27px !important;
}

.so-list-exp-overview-row {
  display: grid;
  grid-template-columns: 17rem 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;
}

/*<-----customer address*/

.so-customer-address {
  margin: 0.8rem 0;
  display: grid;
  grid-template-columns: repeat(2, 20rem);
  grid-gap: 3rem;
}

.so-customer-address--type {
  display: grid;
  grid-template-columns: 1fr repeat(3, auto);
  align-items: center;
  grid-gap: 1rem;
}

.so-customer-address--icon {
  font-size: 1.7rem;
  box-sizing: content-box;
  cursor: pointer;
  fill: var(--icon-primary);
}

.so-customer-address--icon:hover {
  /*fill: #5e5e5e;*/
  fill: var(--blue-500);
}

.so-customer-address-section {
  word-break: break-all;
}

/*customer address----->*/

/*<-----discount*/

.so-discount-head {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 1rem;
  align-items: center;
  margin: 2rem 0 1rem;
}

.so-discount-head>label.control-label {
  padding: 0;
}

/*discount----->*/

/*<-----terms and conditions radio buttons in so-add & edit*/

.so-tc-radio {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2rem;
  align-items: center;
}

.so-tc-radio .radio-container {
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  grid-gap: 1rem;
}

/*terms and conditions radio buttons in so-add & edit----->*/

/*<-----total tax in so-add & edit*/

.so-total-tax {
  text-align: right;
}

.so-total-tax-container {
  display: inline-grid;
  grid-gap: 1rem;
}

.so-total-tax-body:first-child {
  margin-top: 2rem;
}

.so-total-tax-body:last-child {
  margin-bottom: 2rem;
}

.so-total-tax-body {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

/*total tax in so-add & edit----->*/

.so-selected-items-row {
  display: grid;
  grid-template-columns: minmax(8rem, 2fr) repeat(4, minmax(5rem, 1fr)) 5rem;
}

.so-adjust-item--close-btn {
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.padding-top {
  float: left;
  width: 100%;
  padding: 12px 0 10px 0px;
}

.word-break--break-all {
  word-break: break-word;
}

.sales_reutn_inf0 .details-header {
  width: 100%;
}

/* .fullscreen thead tr th:nth-child(7){
  padding-left: 2% !important;
} */

.fullscreen tbody tr td:nth-child(6) {
  padding-left: 1.5%;
}

.fullscreen tbody tr td:nth-child(8) {
  padding-left: 2.5%;
}

.compressedscreen thead tr th:nth-child(4) {
  padding-left: 6.5% !important;
}

.compressedscreen thead tr th:nth-child(5) {
  padding-left: 4% !important;
}

/* .compressedscreen tbody tr td:nth-child(5){
  padding-left: 5% !important;
}  */

.ims-modal.return-receive-popup .form-table--container {
  margin-right: 0;
}

.dragDropPopupContainer .salesreturn_popup.ims-modal {
  overflow: unset;
}

@media screen and (min-width: 21px) and (max-width: 1600px) {
  .fullscreen thead tr th:nth-child(7) {
    padding-left: 0 !important;
  }
}

/* .sales_order_table_listing table.compressedscreen thead tr th:nth-child(5) {
  padding-left: 0 !important;
  width: auto !important;
} */

.sales-return-main .menu-component--menu.menu_top {
  position: initial;
  left: 0;
  width: 125.3%;
  margin-left: -13% !important;
}

.sales-return-main .menu_top_content.box_shadow_custom {
  position: relative;
  margin-top: -50px;
  margin-bottom: 40px;
  height: 100%;
}

.sales-return-main .sales_order_info {
  padding-top: 0px;
}

.sales-return-main .close_icon:first-child {
  display: none;
}

/* .sales-return-main .overflow_custom_height {
  margin-top: 20px;
} */

.compressedscreen.expanded_view_sale_return thead tr th:nth-child(5),
.compressedscreen.expanded_view_sale_return thead tr th:nth-child(4) {
  padding-left: 0 !important;
}

.compressedscreen.expanded_view_sale_return thead tr th:nth-child(5),
.compressedscreen.expanded_view_sale_return tbody tr td:nth-child(5) {
  display: none;
}

.compressedscreen.expanded_view_sale_return tbody tr td:nth-child(4) {
  text-align: center;
}

.compressedscreen.expanded_view_sale_return thead tr th:nth-child(3),
.compressedscreen.expanded_view_sale_return tbody tr td:nth-child(3) {
  width: 37%;
}

.compressedscreen.expanded_view_sale_return th.list-table--settings {
  width: 25px;
}

@media (max-width: 1600px) {
  .fullscreen tbody tr td:nth-child(8) {
    padding-left: 2%;
  }
}

@media (max-width: 1366px) {
  .list-table.linked-row .list-table--link {
    word-break: break-word;
  }

  .compressedscreen.salesorder_listing .list-table--link {
    word-break: break-word;
    padding: 0;
  }
}

@media (max-width: 1280px) {

  .sales_return_table .transaction-table-set_main th:nth-child(3),
  .sales_return_table .transaction-table-set_main th:nth-child(2),
  .sale_return_listing.list-table th:nth-child(2) {
    width: 10%;
  }

  .sale_return_listing.list-table th:nth-child(4),
  .sale_return_listing.list-table th:nth-child(3) {
    width: 12%;
  }

  .sales_return_table .transaction-table-set_main th:first-child {
    width: 50%;
  }

  .sales_order_info .details-header .details-header--title {
    font-size: 15px;
  }
}

@media (max-width: 1199px) {

  .expanded_view_sale_return th:nth-child(5),
  .expanded_view_sale_return td:nth-child(5) {
    display: none;
  }

  .expanded_view_sale_return thead tr th:nth-child(3) {
    width: 45% !important;
  }

  /* .parent-component #listing_component .table_header_fix .status {
    display: block !important;
  } */
  .expanded_view_sale_return td:nth-child(3) {
    width: 23%;
  }

  /* .expanded_view_sale_return td:nth-child(4) {
    padding-left: 30px;
  } */
  .fullscreen.sale_return_listing th.status a {padding-left: 10px;}
}

@media (max-width: 1099px) {
  .sales_order_info .details-header .details-header--title {
    font-size: 14px;
  }

  .expanded_view_sale_return thead tr th:nth-child(2) {
    width: 20%;
    padding-right: 14px !important;
  }

  .expanded_view_sale_return.list-table.linked-row .list-table--link {
    text-align: left;
  }
}

@media (max-width: 991px) {

  .sale_return_listing.list-table th:nth-child(4),
  .sale_return_listing.list-table th:nth-child(3),
  .sale_return_listing.list-table td:nth-child(4),
  .sale_return_listing.list-table td:nth-child(3) {
    display: none;
  }
}

@media (max-width: 768px) {
  .sale_return_listing.list-table th:nth-child(2) {
    width: 12%;
  }
}

@media (max-width: 890px) {
  .ims-modal.white.return-receive-popup.medium.invoice-popup-set {
    max-width: 90%;
  }

  .recieve_popup {
    overflow-x: auto;
    clear: both;
    margin-right: 0;
  }

  .recieve_popup table {
    min-width: 800px;
  }

  /* .invoice-popup-set table.list-table.form-table.border {
    min-width: 639px;
    overflow-x: auto;
    display: block;
  } */
  .AppplyCredits .record-payment-form .form-field.inline-field {
    flex: 0 0 48%;
    margin-right: 0;
  }

  .AppplyCredits .record-payment-form>div {
    display: flex;
    flex-wrap: wrap;
  }

  .AppplyCredits .record-payment-form .form-field.inline-field:nth-child(even) {
    margin-left: 15px;
    flex: 0 0 48%;
  }

  .AppplyCredits .record-payment-form .form-field.inline-field .searchable-dropdown,
  .AppplyCredits .record-payment-form .form-field.inline-field input,
  .AppplyCredits .record-payment-form .form-field.inline-field.remaining_Credits,
  .AppplyCredits .record-payment-form .form-field.inline-field.payment-record,
  html body .AppplyCredits .record-payment-form .form-field.inline-field.payment-record textarea {
    width: 100% !important;
  }

  .AppplyCredits .record-payment-form .form-field.inline-field.payment-record {
    flex: 0 0 100%;
  }
}