.ims-modal .convert-to-bill-popup thead tr th:nth-child(4),
.ims-modal .convert-to-bill-popup tbody tr td:nth-child(4),
.ims-modal .convert-to-bill-popup thead tr th:nth-child(5),
.ims-modal .convert-to-bill-popup tbody tr td:nth-child(5),
.ims-modal .convert-to-bill-popup thead tr th:nth-child(6),
.ims-modal .convert-to-bill-popup tbody tr td:nth-child(6) {
  text-align: right;
}
@media (max-width: 1280px) {
  .convet-tobill-tble tr th:nth-child(3),
  .convet-tobill-tble tr td:nth-child(3),
  .convet-tobill-tble tr th:nth-child(2),
  .convet-tobill-tble tr td:nth-child(2) {
    display: none;
  }
  .summary_table .w-40 {
    width: 50%;
  }
}
