.contact-info {
  padding: 1.6rem 0;
}

.contact-info .avatar {
  text-align: center;
  margin-bottom: 1rem;
}

.contact-info--details {
  text-align: center;
  line-height: 2.5rem;
  padding: 0 1.6rem;
  word-break: break-all;
}

.contact-info--contact {
  padding: 0 1.6rem;
  word-break: break-all;
}

.contact-info--contact svg {
  margin-right: 1rem;
  fill: var(--icon-primary);
}