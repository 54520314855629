/* .organization-info {
  padding: 2rem 1.6rem;
  display: flex;
  word-break: break-all;
}

.organization-info--image-container {
  position: relative;
  text-align: center;
}

.section-details--info-image-container:hover .organization--logo-update, .section-details--info-image-container:hover .organization--logo-delete {
  opacity: 1;
}

.dropzonearea.organization {
  height: 20rem;
  padding: 6rem 2rem;
}

.organization--logo-update {
  position: absolute;
  right: 5rem;
  top: 1rem;
  font-size: 1.4rem;
  background: rgba(0, 0, 0, .5);
  color: #fff;
  padding: 0 1rem .5rem;
  border-radius: 4px;
  border: 1px solid #222;
  transition: all .15s ease-in;
  opacity: 0;
}

.organization--logo-delete {
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1.4rem;
  background: rgba(0, 0, 0, .5);
  color: #fff;
  padding: 0 1rem .5rem;
  border-radius: 4px;
  border: 0;
  outline: none;
  border: 1px solid #222;
  transition: all .15s ease-in;
  transition-delay: .05s;
  opacity: 0;
} */
.organization-info {
  float: left;
  width: 100%;
  /* padding: 1rem 1.6rem; */
  padding: 1rem 0rem 0rem 1.6rem;
}
.organization-info .organization_info_image_details {
  float: left;
}
.organization-info .section-details--info {
  float: right;
  width: 420px;
  line-height: 2rem;
  padding-left: 0 !important;
}
.top-10 {
  position: relative;
  top: 20px;
}
.ml_20 {
  margin-left: 20px;
}
.organization_info_name {
  color: #000 !important;
}
.w-30 {
  width: 30%;
}
.w-10 {
  width: 10%;
}
.font_700 {
  font-weight: 500;
  color: #000;
}
.organization_info_image_details img {
  object-fit: cover;
}
.mb_10 {
  margin-bottom: 10px;
}
.organization_logo_settings {
  height: 8rem;
  width: 8rem;
  border-radius: 4rem;
  border: 1px solid var(--background);
  margin-bottom: 20px;
  overflow: hidden;
}
.organization_logo_settings img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  padding: 0 15px;
}
@media (max-width: 1260px) {
  .organization-info {
    display: flex;
    justify-content: space-between;
  }
  .organization-info .organization_info_image_details {
    max-width: 45%;
    display: flex;
  }
  .organization-info .section-details--info {
    width: 50%;
  }
}
@media  (max-width:1199px) {
  .organization-info .organization_info_image_details {
    /* max-width: 50%; */
    flex: 0 0 45%;
  }
  .organization-info .section-details--info{
    flex: 1;
  }
}