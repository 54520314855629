.invoice_Details_main {
  width: 80%;
  float: none;
  margin: auto;
}

.invoice_Details_main .custom_padding {
  padding: 27px 10px 10px 10px !important;
}
.invoice-topbar-main {
  margin: 0 10px;
}
.invoice-topbar-inner{
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
  background: #D5F1D9;
}
.invoice-bar-left p {
  margin: 0 0px 0 13px;
  font-size: 16px;
}
.invoice-bar-left p span{
  font-weight: bold;
  font-size: 16px;

}
.invoice-bar-right button.btn-apply {
  background: #000;
  color: #fff;
  text-transform: capitalize;
  padding: 5px 8px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}
.invoice-listing-fullscreen.list-table.linked-row td:nth-child(4) .list-table--link span.list_items_desc div{
  justify-content: space-between;
}
.invoice-listing-fullscreen.list-table.linked-row td:nth-child(4) .list-table--link span.list_items_desc div, 
.invoice-listing-fullscreen.list-table.linked-row td:nth-child(4) .list-table--link span.list_items_desc div span:nth-child(2) span{
    align-items: center;
    display: flex;
}
.apply_credit_notes .summary_table .w-70 {
  width: 65% !important;
}

.apply_credit_notes .summary_table .w-30 {
  width: 35% !important;
}
@media (max-width: 1440px) {

  .invoice_listing th:nth-child(2),
  .invoice_listing th:nth-child(3),
  .invoice_listing th:nth-child(6) {
    width: 9%;
  }

  .invoice_listing th:nth-child(5) {
    width: 10%;
  }
}

@media (max-width: 1199px) {

  .invoice_listing thead th:nth-child(3),
  .invoice_listing tbody td:nth-child(3) {
    display: none;
  }

  .invoice_listing th:nth-child(2) {
    width: 8%;
  }
  html body .invoice-listing-fullscreen span.status {
    margin-right: 0;
}
}

@media (max-width: 991px) {
  html body .invoice_listing thead tr th:nth-child(4) {
    width: 32% !important;
  }

  .invoice_listing td.list-table--options {
    width: 4%;
  }

  .invoice_listing th:nth-child(5) {
    width: 12%;
  }

  .invoice_listing tbody td:nth-child(7) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 890px) {

  .invoice_listing th:nth-child(6),
  .invoice_listing th:nth-child(2) {
    width: 11%;
  }

  html body .invoice_listing thead tr th:nth-child(4) {
    width: 26% !important;
  }

  .invoice_listing th:nth-child(5) {
    width: 14%;
  }
}
.invoice-summary-table .cus_padding{
  padding: 10px 0 0;
  clear: both;
}
.invoice-summary-table .py-7{
  padding: 7px 0;
}