.home-footer {
  height: 12rem;
  background-color: var(--blue-500);
}

.home-footer-container {
  width: 80%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-footer-logo {
  height: 3rem;
  width: auto;
}
.home-footer-logo {
  height: 100%;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .home-footer {
    height: 10rem;
  }

  .home-footer-container {
    width: 100%;
    padding: 0 1.5rem;
  }
}