.bulk-add-items {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  border: 1px solid var(--border-dark);
  border-radius: 4px;
  margin-bottom: .8rem;
  float: left;
  width: 100%;
}

.bulk-add-items>div:nth-child(odd) {
  border-right: 1px solid var(--border-dark);
}

.bulk-add-items--header {
  padding: 1.2rem 1.6rem;
  border-bottom: 1px solid var(--border-dark);
}

.bulk-add-items--search {
  background: var(--background);
}

.bulk-add-items--items {
  height: 45rem;
  overflow-y: auto;
  float: left;
  width: 100%;
}

.bulk-add-items--item-row {
  float: left;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  position: relative;
}

.bulk-add-items--item-row .list-table--item--col {
  display: block !important;
  float: left;
  min-width: 80%;
  max-width: 80%;
  min-width: 80% !important;
}

.bulk-add-items--item-row .list-table--item--col img {
  float: left;
  width: 10% !important;
  height: 3.2rem !important;
  object-fit: contain !important;
  margin-right: 0 !important;
  /* This style is for ALT tag */
  max-width: 10%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.bulk-add-items--item-row .list-table--item--col span {
  float: left;
  width: 90% !important;
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  padding: 0 20px;
}

.bulk-add-items--item-row .list-table--item--col span span {
  padding: 0 !important;
  width: 100% !important;
}

.word-break-all {
  float: left;
  width: 20%;
  text-align: center;
  position: relative;
  font-weight: 400 !important;
  color: #000 !important;
}

.bulk-add-items--item-row .check_icon {
  position: absolute;
  right: 10px;
  top: 10px !important;
}

.right_sec .bulk-add-items--item-row .list-table--item--col {
  display: block !important;
  float: left;
  min-width: 70% !important;
  max-width: 70%;
}

.right_sec .form-field {
  width: 25%;
  float: left;
}

.right_sec button {
  float: left;
  width: 5% !important;
  display: block !important;
  text-align: center;
  position: relative;
  left: 5px;
}

.bulk-add-items--item-row .form-field.error .input-feedback {
  bottom: -1.6rem;
}

.bulk-add-items--item-row:hover {
  background: var(--hover);
}

.header_bulk_item {
  height: 45px;
  background-color: #FFF;
  -webkit-box-shadow: 0px 6px 16px -7px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 6px 16px -7px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 6px 16px -7px rgba(0, 0, 0, 0.35);
}

.header_bulk_item .left_sec {
  float: left;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 2%;
  font-weight: 500;
  font-size: 16px;
}

.header_bulk_item .right_sec {
  float: left;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  font-weight: 500;
  font-size: 16px;
}

.right_sec .bulk-add-items--items {
  height: 50rem !important;
}

.right_sec .header_bulk_item .left_sec {
  float: left;
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 2%;
  font-weight: 500;
  font-size: 16px;
}

.right_sec .header_bulk_item .right_sec {
  float: left;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 0;
  font-weight: 500;
  font-size: 16px;
}

.px_10 {
  padding: 0 10px;
}

.mt_10 {
  margin-top: 10px;
}

.w-80 {
  width: 80% !important;
}

.w-20 {
  width: 20%;
}

.bulk-add-items--header .items {
  height: 4rem;
  background-color: #306ab4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 16px;
}

.text-center {
  text-align: center;
}

.bin {
  fill: #000;
  transition: 0.3s;
}

.close-btn.button:hover svg {
  fill: var(--blue-500) !important;
  stroke: transparent !important;
}

.bulk-add-items--item-row .close-btn.button {
  top: 5px;
}

.test {
  animation: blinker 1s linear infinite;
  font-size: 1.8rem;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.bulk_inv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-item {
  width: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40vh;
  flex-direction: column;
}

/* h1{
  opacity: 0.3;
} */