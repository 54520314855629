.details_row {
  display: flex;
  width: 66%;
  justify-content: space-between;
  line-height: 1 !important;
  /* border-bottom: 1px solid #dfe1e5; */
}

.details_column {
  display: flex;
  flex-direction: column;
}
.text_transform_lower {
  text-transform: capitalize !important;
}

.reference {
  display: block;
  width: 44%;
  word-break: break-all;
  display: flex;
  flex-direction: row-reverse;
  word-break: break-word;
}

.marginfromtop {
  margin-top: 2%;
}

.sales_order_dropdwon .button svg path {
  fill: #fff;
}

.sales_order_dropdwon .button:hover svg path {
  fill: #000 !important;
}

.sales_order_dropdwon .button:hover {
  color: #000 !important;
}
@media (max-width: 991px) {
  .create_package_so.large.invoice-popup-set {
    min-width: 95%;
    max-width: 95%;
  }
}
