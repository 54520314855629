.form {
}

.form-title {
  font-size: 1.6rem;
  color: var(--text-primary);
  font-weight: 500;
}

.form-checkbox {
  display: flex;
  align-items: center;
}

.form-checkbox-label {
  margin-right: 1.6rem;
  position: relative;
  display: -webkit-flex;
  display: flex;
  user-select: none;
}

.form-checkbox-label > input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

.form-checkbox-label > span {
  position: relative;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 2px;
  border: 1px solid var(--border-dark);
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #ffffff 0%, var(--background) 100%);
}

.form-checkbox-label > input[type='checkbox']:checked + span > svg,
.form-checkbox-label > input[type='checkbox']:checked + .check-uncheck > i {
  opacity: 1;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.form-checkbox-label > span > svg,
.form-checkbox-label .check-uncheck i {
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 0;
  left: -0.1rem;
  opacity: 0;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.form-checkbox-label .check-uncheck i {
  opacity: 1;
  font-size: 18px;
  background: #fff;
  left: 0;
  top: auto;
}
.form-checkbox-label .check-uncheck {
  background: #fff;
  border: none;
}
.form-image {
  display: flex;
}

.DDTopElement {
  background-color: #eeeeee;
  /*color: white;*/
  border: 1px solid transparent;
  border-radius: 4px;
  height: 30px;
  /* padding: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  font-size: 14px;
}

.searchable-dropdown:focus-within {
  border-color: #878787 !important;
}

.searchable-dropdown .searchable-dropdown__control {
  border-radius: 4px !important;
}
