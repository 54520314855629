/* .border_bottom{padding: 0 !important;}
.nav_menus {
  float: left;
  width: 100%;
}
.nav_menus {
  zoom: 1;
  margin-left: 20px;
  padding-left: 40px;
  border-bottom: 2px solid #306ab4;
  margin-top: 5px;
}
.nav_menus li {
  list-style: none outside none;
  float: left;
  position: relative; 
  margin-left: -15px;
}
.nav_menus li:first-child {
  margin-left: 0 !important;
}
.nav_menus .active {
  z-index: 3; 当前项在最顶端
}

.nav_menus a {
  float: left;
  text-decoration: none;
  color: #000;
  background: #DFE1E5;
  border-radius: 10px 10px 0 0;
  font-weight: 500;
  height: 34px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav_menus .active a {
  background: #306ab4;
  color: #fff;
}

.navmenu-component--content {
  float: left;
  width: 100%;
}
.close_icon {
  position: absolute;
  right: 1.5rem;
  font-size: 2.5rem;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
top: 6px;
} */
.border_blue{
  border-top: 2px solid #306ab4;
}
.navmenu-component--buttons{
  float: left;
  width: 20%;
  height: calc(100vh - 227px);
  background-color: #F0F2F5;
}
.navmenu-component--buttons li{
  float: left;
  width: 98%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background-color: #FFF;
  transition: all .3s ease-in-out;
  position: relative;
  border-bottom: 1px solid #DFE1E5;
}
.navmenu-component--buttons li a{
  color: #000;
  cursor: pointer;
}
.navmenu-component--buttons li.active{
  background-color: #306AB4;
  border-bottom: 1px solid #306AB4;
}
.navmenu-component--buttons li.active a{
  color: #FFF;
}
.PARENT_ACTIVE svg{
  fill: #FFF;
  border: 1px solid #FFF !important;
}
.navmenu-component--content{
  float: left;
  width: 80%;
}
.navmenu-component--content table{
  margin-top: 0 !important;
}
.parent_tab svg{
  position: absolute;
  right: 10px;
  top: 20%;
  border: 1px solid;
  /* z-index: 999999; */  
  /* commenting for testing reason. getting issue in settings inventory accounts tab */
  cursor: pointer;
}

.hide-add-icon{
  display: none;
}