.data-error {
  text-align: center;
  padding: 2rem 0;
  margin-top: 2rem;
  border: none;
}

.data-error.full {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  justify-items: center;
  text-align: center;
  border: 0;
}

.data-error.dashboard {
  background: #fff;
}

.data-error.shadow {
  box-shadow: var(--shadow-medium);
}

.data-error.free {
  margin-top: 0;
}

.data-error.free img {
  width: 10rem;
  height: 10rem;
}

.data-error.free h1 {
  font-size: 1.4rem;
}

.data-error.free h4 {
  font-size: 1.2rem;
}

.data-error.small img {
  width: 5rem !important;
  height: 8rem !important;
}

.data-error.small h1 {
  font-size: 1.8rem;
}

.data-error.small h4 {
  font-size: 1.4rem;
}

.data-error-style {
  width: 40%;
}