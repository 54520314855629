.access-denied {
  text-align: center;
  padding: 2rem 0;
  border: 1px solid #eee;
  margin-top: 2rem;
}

.access-denied.full {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  justify-items: center;
  text-align: center;
  border: 0;
}

.access-denied.dashboard {
  background: #fff;
}

.access-denied.shadow {
  box-shadow: var(--shadow-medium);
}

.access-denied.free {
  margin-top: 0;
}

.access-denied.free img {
  width: 10rem;
  height: 10rem;
}
.access-denied.free h1 {
  font-size: 1.4rem;
}

.access-denied.free h4 {
  font-size: 1.2rem;
}

.access-denied.small img {
  width: 15rem;
  height: 15rem;
}

.access-denied.small h1 {
  font-size: 1.8rem;
}

.access-denied.small h4 {
  font-size: 1.4rem;
}
.access-denied-style{
  width: 40%;
}