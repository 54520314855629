.new-role-table {
  margin-top: 2rem;
  /* border: 1px solid #eee; */
}

.new-role-table,
.new-role-table th {
  text-align: center;
}
.new-role-table th:first-child,
.new-role-table td:first-child {
  text-align: left;
}

.new-role-table>thead>tr>th {
  padding-left: 4rem;
}

.new-role-table-title--hover:hover {
  color: var(--blue-500-hover);
  cursor: pointer;
}

.new-role-table td.group-header{
  font-size: 1.4rem;
  background: #f8f8f8;
}

.new-role-table td.group-header svg {
  margin-right: .6rem;
  fill: #999;
  width: 2.5rem;
}
.new-role-table td:not(.group-header) {
  padding-left: 4rem;
}

.role-form .form-field {
  grid-template-columns: 9rem 1fr;
}

.role-form .form-field .form-label {
  text-align: left;
}

.role-form .list-header-dropdown--option-label > span {
  margin-left: .4rem;
}

.salesperson-role {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: flex-start;
  grid-gap: .5rem;
}

.salesperson-role .custom-checkbox > span {
  position: relative;
  vertical-align: middle;
}

.salesperson-info {
  font-size: 1.2rem;
  color: var(--dark-grey);
}