.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 19rem);
  grid-gap: 2rem;
}

.float-left {
  float: left;
}

.w-100 {
  width: 100%;
}

.porfile_2 {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}

.porfile_2 .image {
  float: left;
  width: 80px;
  height:80px;
  border-radius: 50%;
}

.porfile_2 .image img {
  height: 100%;
  width: 100%;
  /*border-radius: 50%;*/
  object-fit: cover;
  /*padding: 0 5px;*/
  overflow: hidden;
  min-width: unset;
  min-height: unset;
  max-width: unset;
  background-color: unset;
}

.porfile_2 .desc {
  float: left;
  width: 90%;
  padding-left: 20px;
  /*padding: 15px 0px;*/
}

.porfile_2 .desc .name {
  float: left;
  width: 100%;
  font-weight: bold;
}

.porfile_2 .desc .email {
  float: left;
  width: 100%;
}

.porfile_2 .desc .email span {
  color: #33a348;
}

.contatcs_all_details {
  float: left;
  width: 80%;
}

.contact_type {
  text-transform: capitalize;
  color: #33a348;
}

.due {
  border-bottom: 1px solid #dfe1e5;
  padding-bottom: 15px;
  margin-bottom: 20px;
}

.due .left_sec {
  float: left;
  width: 50%;
  border-right: 1px solid #dfe1e5;
  padding: 10px 0px;
}

.due .inner {
  float: left;
  width: 50%;
}

.due .right_sec {
  float: left;
  width: 50%;
  padding: 10px 0px;
}

.font_custom {
  font-size: 24px;
  color: #33a348 !important;
}

.text-center {
  text-align: center;
}

.due p {
  margin-bottom: 15px;
  color: #000;
}

.alignment {
  position: relative;
  top: -14px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.custom_color {
  color: red !important;
  font-weight: bold;
}

.more_details span {
  color: #000;
  font-size: 18px;
  font-weight: weight;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.more_details .left_sec {
  float: left;
  width: 50%;
}

.more_details .right_sec {
  float: left;
  width: 50%;
}

.more_details ul li {
  border-bottom: 1px solid #dfe1e5;
  padding: 5px 0;
  float: left;
  width: 80%;
  color: #000;
}
.contatcs_all_details .font_custom.alignment {
  word-break: break-word;
}
/* .sales_address .details_row { */
/* display: block !important; */
/* display: -webkit-inline-box !important; */
/* } */

@-moz-document url-prefix() {
  .sales_address .details_row {
    display: -webkit-inline-box !important;
  }
}

.more_details ul li svg {
  float: left;
}

.more_details ul li:last-child {
  border-bottom: 0 !important;
}

.contacts_address {
  float: left;
  width: 100%;
}

.contacts_address span {
  color: #000;
  font-size: 18px;
  font-weight: weight;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.contacts_address .contacts_address_inner {
  float: left;
  width: 33.33%;
}

.contacts_address .contacts_address_inner span {
  color: #306ab4;
  float: left;
  width: 100%;
}
@media (max-width: 1199px) {
  .contatcs_all_details .font_custom.alignment {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 1024px) {
  .sales_order_tabs .porfile_2 .desc {
    padding-left: 10px;
  }

  .due .inner {
    width: 59%;
  }

  .on-m-w40 {
    width: 41% !important;
    padding-top: 4px;
  }
}

@media (max-width: 899px) {
  .contatcs_all_details .right_sec:nth-child(2) {
    padding-left: 4px;
    padding-top: 0;
  }

  .contatcs_all_details .font_custom {
    font-size: 18px;
  }

  .contatcs_all_details {
    width: 100%;
  }

  .sales_order_tabs .porfile_2 .desc {
    padding-left: 28px;
  }

  .contatcs_all_details .inner:nth-child(2) .font_custom {
    position: relative;
    top: -5px;
    margin-bottom: 10px;
  }

  .contatcs_all_details .inner:nth-child(2) p:nth-child(2) {
    position: relative;
    /* top: -4px; */
  }

  .contatcs_all_details .inner:nth-child(2) p:nth-child(3) {
    position: relative;
    top: 0px;
  }

  .contatcs_all_details .inner:nth-child(2) {
    font-size: 13px;
  }

  html body .contacts_address {
    display: flex !important;
    flex-wrap: wrap;
  }

  .contacts_address .card {
    flex: 0 0 31% !important;
    width: 31% !important;
    float: unset;
  }
}

@media (min-width: 1280px) {
  .contacts_address .card:nth-child(5) {
    clear: left;
  }
}

@media (min-width: 712px) and (max-width: 1279px) {
  .contacts_address .card:nth-child(4),
  .contacts_address .card:nth-child(6) {
    clear: left;
  }
}

@media (min-width: 1280px) {
  .contacts_address .card:nth-child(5) {
    clear: left;
  }
}

@media (min-width: 712px) and (max-width: 1279px) {
  .contacts_address .card:nth-child(4),
  .contacts_address .card:nth-child(6) {
    clear: left;
  }
}

@media (max-width: 1280px) {
  .contacts_address .card {
    flex: 0 0 47% !important;
    width: 47% !important;
  }
}
