.bills-main-set {
  display: inline-block;
  position: relative;
  height: 100%;
}
.bills-main-set.bill-details .view-details-btn{
  position: unset;
  float: right;
  padding-right: 26px;
  /* margin-top: -4px; */
}
/* .bills-main-set button.view-details-btn {
  position: absolute;
  right: auto;
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 1.2rem;
  top: auto;
  left: 62%;
  bottom: -13px;
} */
.bill-table-width .error +.bill-prev-purchase{
  float: left;
  width: 100%;
  margin-top: 10px
}
.uiux-bills-container .__dragDrop .__drag-righ {
  min-width:unset;
}
.middle-row.d-inline-set {
  display: inline;
  float: left;
}

.d-inline-set {
  padding-top: 15px;
}

@media (max-width: 1530px) {
  html body .bill-table-width {
    width: 95% !important;
  }
  html body .bill-modal .bill-table-width{
    width: 100% !important;
  }

  .create_bill_enchancer .action-buttons,
  .create_bill_enchancer .float-left.w-100.mt_3.summary_table {
    padding-right: 5% !important;
  }
  .create_bill_enchancer.bill-modal .action-buttons, 
  .create_bill_enchancer.bill-modal .float-left.w-100.mt_3.summary_table{
    padding-right: 0 !important;
  }

  html body .create_bill_enchancer {
    width: 124rem;
  }

  .create_bill_enchancer .create_bills_feild {
    display: flex;
    flex-wrap: wrap;
  }

  .create_bill_enchancer .create_bills_feild > div {
    margin-right: 20px;
    width: 30%;
  }
  .middle-row.d-inline-set .description_box,
  .create_bill_enchancer .middle-row textarea,
  .create_bill_enchancer .create_bills_feild .bills-main-set > div,
  .create_bill_enchancer
    .create_bills_feild
    .bills-main-set
    .searchable-dropdown,
  .create_bill_enchancer .create_bills_feild > div .form-input.large {
    width: 100%;
  }
  
}

@media (max-width: 1440px) {
  .create_bill_enchancer .middle-row {
    padding-top: 14px;
  }

  .create_bill_enchancer .create_bills_feild > div {
    width: 28%;
    flex: 0 0 28%;
  }
  .create_bill_enchancer .po-terms-and-cond .textarea-po-terms,
  .middle-row.d-inline-set .description_box,
  .create_bill_enchancer .middle-row textarea,
  .create_bill_enchancer .create_bills_feild .bills-main-set > div,
  .create_bill_enchancer
    .create_bills_feild
    .bills-main-set
    .searchable-dropdown,
  .create_bill_enchancer .create_bills_feild > div .form-input.large {
    width: 100% !important;
  }

  .bills-main-set button.view-details-btn {
    left: 82%;
  }

  .create_bill_enchancer .create_bills_feild > div {
    margin-bottom: 10px;
  }

  .create_bill_enchancer .middle-row {
    width: 100% !important;
    flex: 0 0 100% !important;
  }

  .create_bill_enchancer .middle-row .form-field.inline-field {
    width: 28%;
  }
  .purchase-invoice-popup .so-tbletabs {
    max-width: 1200px;
    overflow-x: auto;
}
.purchase-invoice-popup .create_bill_enchancer.bill-modal{
  width: 112rem;
}
}

/* @media (max-width: 1366px) {
  html body .create_bill_enchancer {
    width: 116rem;
  }
} */

@media (max-width: 1280px) {
  .create_bill_enchancer .middle-row .form-field.inline-field,
  .create_bill_enchancer .create_bills_feild > div .po-terms-and-cond,
  html body .create_bill_enchancer {
    width: 100%;
  }

  .create_bill_enchancer .create_bills_feild > div {
    width: 30%;
    flex: 0 0 30%;
  }

  .create_bill_enchancer .middle-row.terms-condition-row.d-inline-set {
    width: 100%;
  }

  html body .bill-table-width {
    width: 100% !important;
  }

  .create_bill_enchancer .action-buttons,
  .create_bill_enchancer .float-left.w-100.mt_3.summary_table {
    padding-right: 0 !important;
  }
}
.create_bill_enchancer .number-input {
  border: 1px solid #dfe1e5;
}

.create_bill_enchancer
  .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field
  tr
  td:nth-child(6) {
  width: 12.5% !important;
}
.create_bill_enchancer
  .list-table.form-table.border.horizontal-items-table.bill.bill-table-width.create_package.purchase_field
  td:last-child {
  min-width: 10% !important;
  width: 10% !important;
}

@media (max-width: 1199px) {
  .bills-main-set button.view-details-btn {
    left: 77%;
  }
  .create_bill_enchancer .middle-row {
    padding-top: 0;
  }
  .purchase-invoice-popup .create_bill_enchancer.bill-modal {
    width: 100%;
}
.purchase-invoice-popup .so-tbletabs table {
  min-width: 1200px;
}
}

@media (max-width: 991px) {
  .create_bill_enchancer .create_bills_feild > div:nth-child(even) {
    margin-right: 0 !important;
  }
  .create_bill_enchancer .create_bills_feild .middle-row {
    margin-top: 0;
  }

  .create_bill_enchancer .create_bills_feild > div {
    width: 48% !important;
    flex: 0 0 48% !important;
  }

  .bills-main-set button.view-details-btn {
    left: 83%;
  }

  .create_bill_enchancer.bill-modal .so-tbletabs table {
    width: 1020px !important;
  }
}

@media (max-width: 890px) {
  .bills-main-set button.view-details-btn {
    left: 81%;
  }
}

@media screen and (max-width:768px){
  .uiux-bills-container .tablet-align{
    flex-wrap: wrap;
  }
}
