.radio-btn {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 0.8rem;
}
.radio-btn input[type=radio] {
  position: absolute;
  opacity: 0;
  margin: 0;
}
.radio-btn label {
  cursor: pointer;
  z-index: 10;
  padding-left: 2rem;
  font-weight: 400;
  margin: 0;
}
.radio-circle {
  position: absolute;
  border: 2px solid var(--border-blue);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.radio-btn input[type=radio]:disabled ~ .radio-circle {
  border: 2px solid var(--grey);
}
.radio-btn input[type=radio]:disabled ~ label {
  color: var(--grey);
}
.radio-btn:hover .radio-circle,
.radio-btn:focus-within .radio-circle {
  /*border: 2px solid var(--blue-500);*/
  border: 2px solid var(--grey-500);
}
.radio-circle::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  height: 0.7rem;
  width: 0.7rem;
}
.radio-btn input[type=radio]:checked ~ .radio-circle::before {
  /*background: var(--blue-500);*/
  background: var(--grey-500);
}
.radio-btn input[type=radio]:checked ~ .radio-circle {
  /*border: 2px solid var(--blue-500)*/
  border: 2px solid var(--grey-500)
}