.invenoty_Tabs .navmenu-component--buttons {
  height: calc(100vh - 97px) !important;
}

.inventory_tabs_Table .list-table td {
  padding: 0 0px !important;
}

.inventory_tabs_Table .list-table tbody td {
  padding-left: 6px !important;
}

.inventory_tabs_Table tbody td[data-heading="Status"] {
  line-height: 1.42857143 !important;
}

.inventory_tabs_Table .list-table tbody tr td:first-child, .inventory_tabs_Table .list-table thead tr th:first-child {
  padding-left: 4rem !important;
}

.inventory_tabs_Table .transaction_tab_table tr th:nth-child(4) {
  padding-left: 2.5rem !important;
}

.reset_password input {
  width: 25rem !important;
  padding: 6px 45px 6px 12px !important;
}

.reset_password svg {
  margin-top: 4px !important;
  margin-left: -4% !important;
  height: 23px !important;
  width: 23px !important;
}

.mismatch-passsword--errors.touched {
  position: absolute;
  left: 34%;
  top: 60%;
}

.left_0 {
  left: 0 !important;
  list-style: none !important;
}

.left_0 li {
  list-style: none !important;
}

@media(max-width:890px) {
  .invenoty_Tabs .navmenu-component--buttons {
    width: 23%;
  }
  .invenoty_Tabs .navmenu-component--content {
    width: 77%;
  }
}


.chart-of--account th:nth-child(2),.chart-of--account td:nth-child(2) {
  width: 20%;
}
.chart-of--account th:nth-child(6), .chart-of--account td:nth-child(6),
.chart-of--account th:nth-child(3), .chart-of--account td:nth-child(3),
.chart-of--account th:nth-child(4), .chart-of--account td:nth-child(4) 
{
  width: 8%;
}
.chart-of--account th:nth-child(7), .chart-of--account td:nth-child(7),
.chart-of--account th:nth-child(5), .chart-of--account td:nth-child(5) {
  width: 5%;
}
.chart-of--account th:nth-child(11), .chart-of--account td:nth-child(11),
.chart-of--account th:nth-child(8), .chart-of--account td:nth-child(8) {
  width: 10%;
}

.chart-of--account th:nth-child(9), .chart-of--account td:nth-child(9) {
  width: 7%;
}

.chart-of--account th:nth-child(10), .chart-of--account td:nth-child(10) {
  width: 12%;
}

.list-table.system_settings.chart-of--account th:nth-child(1), .chart-of--account.system_settings.list-table td:nth-child(1),
.chart-of--account th:nth-child(12), .chart-of--account td:nth-child(12) {
  width: 3%;
}
.chart-of--account.system_settings.list-table td:nth-child(2),
.list-table.system_settings.chart-of--account th:nth-child(1), .chart-of--account.system_settings.list-table td:nth-child(1) {
  padding-left: 0 !important;
}


.chart-of--account td:nth-child(10) .text-right .text_col {
  max-width: 100%;
}