.packages_activity_container {
  /*margin: 5px 0px 5px 0px;*/
  float: left;
  width: 100%;
  /* margin-bottom: 10px; */
  max-height: 22rem;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
}

.packages_activity {
  float: left;
  width: 100%;
  /* margin-bottom: 10px; */
  max-height: 300px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
}

.packages_activity .activity_parent {
  padding-left: 8.5%;
  /*padding-top: 10px;*/
}

.menu_top_inner_content .activity_parent:first-child {
  margin-top: 10px;
}

.menu_top_inner_content .activity_parent:last-child {
  margin-bottom: 10px;
}

.package_details .details-header {
  padding: 0 !important;
  margin-bottom: 10px;
  border-bottom: 0 !important;
}

.package_details.section-details--info {
  width: 80% !important;
  padding: 0 !important;
}

.py_5 {
  padding: 0px 0 !important;
}

.package_details hr {
  border-top: 1px solid #dfe1e5 !important;
}

/* .package_middle_row ..package_address{
  height: 98%;
} */
.package_middle_row {
  margin-bottom: 15px;
}

.package_details_table .table-header th {
  line-height: 1.42857143 !important;
  background: #fff !important;
}

.package_details_table thead {
  /* border-left: 1px solid var(--border-dark);
  border-right: 1px solid var(--border-dark); */
  box-shadow: 0 0 10px #ccc;
}

.package_details_table table .list-table--item--col span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  position: relative;
  /* top: 6px; */
  word-break: break-word;
}

.return_recieve_table_border table .list-table--item--col span {
  top: 0 !important;
}

.return_recieve_table_border table .list-table.border tbody {
  border: 0 !important;
}

.package_details_table table tbody tr:nth-child(even) {
  background-color: #fcfcf9;
}

.Package_Details .close_icon {
  display: block;
  top: 6px !important;
  height: auto !important;
}

.sales_representative_title {
  font-size: 1.6rem;
}

.package_details_table table tbody tr td:first-child {
  padding-left: 8px !important;
}

.salesordersummary table tbody tr td:first-child {
  padding-left: 8px !important;
}

.ims-modal.white.large.cus-popup-set {
  max-width: 80rem;
  min-width: 80rem;
}

.shipment-large.cus-shipment-popup-set {
  min-width: 100% !important;
}

.shipment-large.cus-shipment-popup-set .form-field.inline-field:nth-child(3n) {
  margin-right: 0;
}

.sales-return-main.packages_set-main .menu-component--menu.menu_top {
  width: 99.8%;
  margin-left: 0 !important;
}

.packages_set-main .package_middle_row {
  padding-top: 3px;
}

.salesordersummary .total_field {
  width: 99%;
}

.package-list-main th:nth-child(5) {
  width: 50%;
}

@media (max-width: 1440px) {
  .package-list-set th:nth-child(2) {
    width: 9% !important;
  }
}

/* @media (max-width: 1280px) {

  .cus-shipment-popup-set .form-input.large,
  .cus-shipment-popup-set .searchable-dropdown.large {
    width: 100%;
  }

  .cus-shipment-popup-set .form-field {
    width: 48%;
    margin-right: 2%;
  }

  .cus-shipment-popup-set .action-buttons,
  .shipment-large.cus-shipment-popup-set .form-field.inline-field:nth-child(3n) {
    margin-right: 2% !important;
  }
} */

@media (max-width: 1199px) {
  .package_details_table table .list-table--item--col span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 2; */
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 890px) {
  .package-list-set th:nth-child(2) {
    width: 16% !important;
  }

  .package-list-main th:nth-child(2) {
    width: 12%;
  }

  .package-list-main th:nth-child(4),
  .package-list-main th:nth-child(3) {
    width: 15%;
  }

  .package-list-main th:nth-child(5) {
    width: 40%;
  }
}

/*=============Date Cell Width Package===============*/
/* .package-list-compressed thead th:nth-child(2){
  width: 23%;
} */