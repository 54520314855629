.image_modal_popup {
  position: relative;
  float: left;
  width: 100%;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  height: 100%;
}

.controls {
  position: relative;
  bottom: 0;
  /* left: 50%; */
  width: 50%;
  /* transform: translateX(-50%); */
  height: 80px;
  display: flex;
  align-items: flex-end;
  left: 0;
  width: 100%;
  justify-content: center;
}
.controls input {
width: auto !important;
}
.controls input:first-child{
  margin-right: 20px;
}

.slider {
  padding: 22px 0px;
}
.parent_container{
  height: 400px;
  width: 100%;
  position: relative;
}

.custom-cropper .cropper-view-box {
  background-color: white !important;
}

.cropper-crop-box{
  border: 5px solid lightblue;
}

.custom-cropper .cropper-bg {
  background-color: white !important;
}

/* .crop-container .reactEasyCrop_CropArea{
  width: 100% !important;
} */
.zoom_image{
  position: absolute;
  top: -23px;
  right: 6rem;
}
.rotate_image{
    position: absolute;
    top: -23px;
    left: 45px;
}
.justify_content_end{
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 15px;
}
.position_relative{
  position: relative;
}
