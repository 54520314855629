.dashborad_sales_info_invoices {
  text-align: center;
  /* margin-top: 45px; */
}
.dashboard_pkg_shipment .dashborad_sales_info_invoices {
  float: left;
  width: 33.33%;
}
.dashborad_sales_info_invoices h4 {
  margin-bottom: 25px;
  font-size: 16px;
}
.box_1 .recharts-wrapper {
  width: 100% !important;
}
.box_1 .recharts-wrapper svg {
  width: 100% !important;
}
.box_1 .recharts-wrapper svg g {
  transform: translate(-35px, 8px);
}
.box_1 .recharts-wrapper svg g > g {
  transform: translate(0px, 0px);
}
.recharts-wrapper svg g text {
  fill: #000;
  /* font-weight: 700; */
}
.box_1 .recharts-wrapper svg g text {
  fill: #000;
  font-weight: 700;
}
.dashborad_sales_info_invoices {
  height: 226px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.height_100 {
  height: 100%;
}
.mb_10 {
  margin-bottom: 10px;
}
.right_border {
  position: relative;
}
.dashborad_sales_info_invoices svg {
  height: 22px;
  width: 22px;
}
.dashboard_pkg_shipment .dashborad_sales_info_invoices svg {
  height: 27.5px;
  width: 27.5px;
}
.right_border:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 123px;
  background-color: #eeeeee;
  right: 0;
  top: 6rem;
}
.pl_6 {
  padding-left: 6rem;
}

.dashborad_sales_info_invoices a span:last-child:hover {
  color: #306ab4 !important;
  text-decoration: underline;
}

.box_1 .empty-data {
  padding: 10rem 0;
  font-size: 1.8rem;
}
@media (min-width: 1281px) and (max-width: 1460px) {
  .dashborad_sales_info_invoices {
    height: 250px !important;
  }
}
