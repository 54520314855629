.page-view-count {
  /* display: flex;
  align-items: center; */
  float: left;
margin-right: 0px;
}

.page-view-dropdown-container {
  margin: 0 2rem;
  width: 8rem;
}

.page-view-dropdown-body.up .Select-menu-outer {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: -1px;
  margin-top: 0;
  top: auto;
  bottom: 100%;
}