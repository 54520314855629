.ims_modal {
  width: 45.6% !important;
}
.w-18 {
  width: 18%;
}
.package-form {
  float: left;
  width: 100%;
  /* padding: 10px 0px; */
}
.package-width-enhancer .create_package {
  width: 100% !important;
}
.ims-modal.return-receive-popup .ims-modal--body {
  padding: 0;
}
.ims-modal.return-receive-popup .ims-modal--body .so-tbletabs {
  margin-bottom: 10%;
}
.pt_10 {
  padding-top: 10px;
}
.return_recieve_popup .custom_height_return_recieve {
  height: 7rem;
  border-right: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-bottom: 1px solid var(--border-light) !important;
}
.custom_height_return_recieve td {
  border: 0 !important;
}
.right-align.no-padding.with-close--btn.add_return_recieve_modal {
  padding-right: 1.4rem !important;
}
.add_return_recieve_modal .form-field {
  padding-left: 56px !important;
}
.mb_10 {
  margin-bottom: 10px;
}
.return_recieve_table_border tbody tr td:first-child {
  min-width: 40rem !important;
}
.return_recieve_table_border .form-table.list-table th,
.return_recieve_table_border .form-table.list-table td {
  border: 0 !important;
}
.return_recieve_table_border .form-table td {
  height: auto !important;
  padding: 10px 8px !important;
}
.return_recieve_table_border .list-table.border tbody {
  border: 0 !important;
}
.return_recieve_sales_order tbody tr td:last-child .form-field {
  padding-left: 35px;
}
.return_recieve_sales_order tbody tr td:last-child .form-field input {
  width: 100% !important;
}
.return_recieve_sales_order tbody tr td {
  padding: 0 !important;
}
.return_recieve_sales_order tbody tr th:first-child {
  padding-left: 0px !important;
}
.return_recieve_sales_order tbody tr td:first-child {
  padding-left: 10px !important;
}
.return_recieve_sales_order tbody tr td {
  padding-left: 10px !important;
}
.return_recieve_sales_order .list-table--item--col{
  width: 20rem;
}
.sales_return_table .return_recieve_sales_order td:nth-child(3),
.sales_return_table .return_recieve_sales_order td:nth-child(4){
  padding-right: 8px !important;
}
.sales-return-main-set .table.details-table td:last-child {
  /* padding-left: 12.8%; */
  /* padding-left: 11%; */
  width: 130px;
}
div.sales-return-main-set .table.details-table th:last-child {
  text-align: right;
}
.sales-return-main-set .table.details-table th:last-child,
.sales-return-main-set .table.details-table td:last-child {
  text-align: unset;
}
.return_recieve_sales_order .close-btn.button{
  display: flex;
  align-items: center;
  height: 100%;
  margin-top: 0 !important;
}