.add-sales-container {
  width: 100%;
}

.nav-tabs li.active a,
.nav-tabs li.active a:focus {
  background-color: #f5f5f5;
}

div.items-workable table th.adjust-item--close-btn,
.adjust-item--close-btn {
  padding: 0;
  width: 4rem;
}

.so-list-exp-tabs .panel {
  background-color: transparent;
}

.so-list-exp {
  margin-top: -2rem;
}

.so-list-exp-tabs ul.nav.nav-tabs {
  padding: 0;
}

.colorBlue {
  color: var(--blue-500);
}

.so-exp-options {
  position: absolute;
  right: 0;
  top: 0;
}

.so-exp-options>.dropdown>button {
  transform: rotate(90deg);
  background: transparent;
  border: 0;
  font-size: 1.7rem;
}

.so-exp-body {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 4rem;
}

.so-list-exp-overview-row {
  display: grid;
  grid-template-columns: 17rem 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;
}

/*<-----customer address*/

.so-customer-address {
  margin: 0.8rem 0;
  display: grid;
  grid-template-columns: repeat(2, 20rem);
  grid-gap: 3rem;
}

.creditNote_table_icon_details {
  width: 25px !important;
  height: 25px !important;
  margin-top: 4px !important;
}

.creditNote_table_icon {
  width: 26px !important;
  height: 26px !important;
}

.purchaseOrder_table_icon {
  width: 17.3px !important;
  height: 17.3px !important;
}

.purchaseOrder_details_table_icon {
  width: 17.3px !important;
  height: 17.3px !important;
  margin-top: 6px !important;
}

.so-customer-address--type {
  display: grid;
  grid-template-columns: 1fr repeat(3, auto);
  align-items: center;
  grid-gap: 1rem;
}

.so-customer-address--icon {
  font-size: 1.7rem;
  box-sizing: content-box;
  cursor: pointer;
  fill: var(--icon-primary);
}

.so-customer-address--icon:hover {
  /*fill: #5e5e5e;*/
  fill: var(--blue-500);
}

.so-customer-address-section {
  word-break: break-all;
}

/*customer address----->*/

/*<-----discount*/

.so-discount-head {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: 1rem;
  align-items: center;
  margin: 2rem 0 1rem;
}

.so-discount-head>label.control-label {
  padding: 0;
}

/*discount----->*/

/*<-----terms and conditions radio buttons in so-add & edit*/

.so-tc-radio {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 2rem;
  align-items: center;
}

.so-tc-radio .radio-container {
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
  grid-gap: 1rem;
}

/*terms and conditions radio buttons in so-add & edit----->*/

/*<-----total tax in so-add & edit*/

.so-total-tax {
  text-align: right;
}

.so-total-tax-container {
  display: inline-grid;
  grid-gap: 1rem;
}

.so-total-tax-body:first-child {
  margin-top: 2rem;
}

.so-total-tax-body:last-child {
  margin-bottom: 2rem;
}

.so-total-tax-body {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

/*total tax in so-add & edit----->*/

.so-selected-items-row {
  display: grid;
  grid-template-columns: minmax(8rem, 2fr) repeat(4, minmax(5rem, 1fr)) 5rem;
}

.credit_note_details .details-header,
.credit_note_details .cn-summary-info {
  width: 50%;
}

.so-adjust-item--close-btn {
  padding: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.credit_data_row {
  display: flex;
  /* padding-top: 50px; */
}

.credit_note_details {
  width: 80% !important;
  margin: auto !important;
}

.credit_feild {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
}

.mtb {
  margin-top: 3%;
  margin-bottom: 3%;
}

.credit_sales_return_table table thead {
  box-shadow: 0 0 10px #ccc;
}

.credit_sales_return_table table thead tr {
  border-left: 1px solid var(--border-dark);
}

.credit_sales_return_table table thead th {
  background: #fff !important;
}

.credit_sales_return_table table thead th {
  line-height: 1.42857143 !important;
}

.credit_sales_return_table table .list-table--item--col span {
  width: 100%;
}

.credit_sales_return_table table tbody tr:nth-child(even) {
  background: #fcfcf9;
}

.credit_sales_return_table table tbody tr td:nth-child(3) {
  text-transform: capitalize;
}

.credit_note_details .details-header {
  border-bottom: 0 !important;
  align-items: flex-start;
  margin-top: 10px;
}

/* .credit_note_details .details-header--title-info {
  padding-left: 25px;
} */

.expanded_view_creditnote tbody tr td:nth-child(5) {
  text-align: right;
}
.credit_sales_return_table .padddding_right{
  white-space: normal;
}
@media (max-width: 1366px) {
  .credit_note_details .details-header--title-info {
    padding-left: 0px;
  }
}

@media (max-width: 1280px) {

  html body .creditnote_listing thead tr th:nth-child(2),
  html body .creditnote_listing thead tr th:nth-child(3),
  html body .creditnote_listing thead tr th:nth-child(5) {
    width:8% !important;
  }

  html body .creditnote_listing thead tr th:nth-child(4) {
    width: 43% !important;
  }

}

@media (max-width: 1199px) {
  html body .creditnote_listing.creditnote_listing td:nth-child(6) {
    padding-left: 0.7%;
  }
  .Cn-listing-main th.status {
    height: 100% !important;
    vertical-align: middle;
}

.Cn-listing-main th.status a {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}
html body .creditnote_listing thead tr th:nth-child(5){
  width: 9% !important;
}
html body .creditnote_listing thead tr th:nth-child(3){
  width: 10% !important;
}
}

@media (max-width: 1024px) {
  .credit_data_row .details-header {
    flex: 0 0 50%;
  }

  .details-header--info.d-inline {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 992px) {

  .creditnote_listing thead tr th:nth-child(3),
  .creditnote_listing tbody tr td:nth-child(3) {
    display: none;
  }
}

@media(max-width:768px) {
  .creditnote_listing tr td:nth-child(7) .list-table--link {
    padding: 0 9px;
  }
  html body .creditnote_listing thead tr th:nth-child(2), html body .creditnote_listing thead tr th:nth-child(3), html body .creditnote_listing thead tr th:nth-child(5){
    width: 13% !important;
  }
}