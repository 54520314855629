.table {
  width: 100%;
  border-collapse: collapse;
  line-height: 2.5rem;
  margin: 0.8rem 0;
}

.table.details-table {
  float: left;
  margin: 0;
}

.table--row--icon {
  padding-right: 0.5rem !important;
}

.table--row--icon svg {
  width: 1.2rem;
  height: 1.2rem;
}

.table--row--icon svg path {
  fill: var(--icon-primary);
}

.table .table--row.col-span th {
  text-align: center;
  border-right: 1px solid var(--border-dark);
}

.table.td-border > thead > tr.table--row > th {
  border-right: 1px solid var(--border-dark);
}

.table.td-border td {
  border-right: 1px solid var(--border-dark);
}

.table.with-border {
  background-color: white;
  border: 1px solid var(--border-dark);
}

.table > thead > tr.table--row > th,
.table > tbody > tr.table--row > td {
  border: 0;
  /*done to remove bootstrap css*/
  /* height: 4.5rem; */
  /* word-break: normal; */
  /*word-break: break-all;*/
  word-break: break-word;
}

.table > tbody > .table--row {
  border-bottom: 1px solid var(--border-light);
}

.table--row th {
  text-align: left;
}

.form .table--row td {
  position: relative;
  width: 100%;
}

.table--row-image {
  width: 7rem;
  height: 7rem;
}

.table--row-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.table td.link {
  color: var(--text-primary);
}

.table td.link:hover {
  text-decoration: underline;
}

/* --------------------
/* Table Style Classes
/* -------------------- */

.table.with-actions th:last-child,
.table.with-actions td:last-child {
  text-align: center;
}

.table.grey-header thead {
  border-top: 1px solid var(--input);
  background: #f8f8f8;
}

.table.details-table thead {
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  background: var(--background);
}

/* .table.details-table th:first-child,
.table.details-table td:first-child {
  padding-left: 2.4rem;
} */

.table.details-table th:last-child,
.table.details-table td:last-child {
  padding-right: 2.4rem !important;
  text-align: right;
}

.table.details-table .list-table--item--col img {
  width: 3.2rem;
  height: 3.2rem;
}

.table-data--baseline {
  font-size: 1.2rem;
  color: var(--grey);
  white-space: nowrap;
}

.table-data--inline {
  display: flex;
  align-items: center;
}

.table-extra-data {
  position: relative;
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: var(--grey);
  margin-top: 0.5rem;
  padding-right: 0.8rem;
  word-break: break-word;
}
.add_credit_note .table-extra-data.right-align {
  width: 100%;
  padding-right: 0;
}

.table-extra-data.medium {
  width: 17.6rem;
}

.table-data--dropdown {
  min-width: 15rem;
}

.table-data--amount {
  white-space: nowrap;
}

.table > tbody > tr > td.table--row-delete {
  max-width: 3rem;
  padding: 0;
}

.table--row-delete button {
  margin: 0;
}

.table > tfoot.table--bottom-row > tr > td {
  border-top: 1px solid var(--border-dark);
}

/* box table */
.table.box-table > tbody > tr.table--row > td {
  padding: 1rem 1rem 1rem 0;
  vertical-align: top;
}

.table.box-table .custom-dropdown svg {
  transform: rotate(90deg);
}

.table.box-table .second-column {
  font-size: 1.2rem;
  color: #1f5927;
  text-transform: capitalize;
  margin-top: 7px;
}

.table.box-table .third-column {
  font-size: 1.2rem;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 7px;
}

.table .custom-dropdown > button:hover {
  background-color: transparent;
}

.transaction_tab_table tr th:nth-child(4) {
  padding-right: 8px !important;
}

.transaction_tab_table tr th:last-child {
  padding-right: 20px !important;
}

.transaction_tab_table tr th {
  padding: 8px !important;
  line-height: 1.42857143 !important;
}

@media screen and (max-width: 1099px) {
  .transaction-table-set_main {
    /*     min-width: 536px;
        display: block;  */
    overflow-x: auto;
    white-space: nowrap;
  }

  .table-extra-data {
    font-size: 0.8em;
  }
}

@media only screen and (max-width: 600px) {
  .table--row,
  .table--row td {
    display: block;
  }

  .table-header {
    position: absolute;
    bottom: 100vh;
  }

  .table--row {
    margin-bottom: 1rem;
    border: 1px solid #eee;
  }

  .table--row td:before {
    content: attr(data-heading);
    position: absolute;
    left: 0;
    width: 50%;
    font-weight: 500;
  }

  .table--row td {
    position: relative;
    padding-left: 50%;
  }
}

.table-header {
  font-weight: 500 !important;
  font-size: 16px;
}

.sub_tabs_table .table-header {
  background: #e9e9e9;
  line-height: 6px !important;
}

.sub_tabs_table .table-header th {
  line-height: 1.42857143 !important;
}

.table-header th {
  background: #e9e9e9;
  line-height: 6px !important;
}

tr.table--row.unsyncItems {
  background: #fafafa !important;
  /* color: white; */
}

tr.table--row.unsyncItems .small-td-info {
  /* color: white !important; */
}

.unsyncItems .tooltip_tooltip {
  position: absolute;
  left: -54px;
  top: 30px;
  font-size: 12px;
}

tr.table--row.unsyncItems .tooltip_tooltip:before {
  transform: rotate(1deg);
  top: -20px;
  left: 54px;
}

html body .section-details--footer-amount > div {
  grid-template-columns: 6.5fr 1fr;
}

.unsync_salesOrder .sales_order_info {
  padding-top: 25px;
  padding-bottom: 15px;
}

/* .sub_tabs_transaction_main thead th:last-child{float: right;padding-right: 24px;} */
/* .sub_tabs_transaction_main tbody td:last-child{float: right;} */
html body .payment-report-main td.payment-report-main-td {
  padding-right: 0px !important;
  /* width: 50%; */
}

/* html body .payment-report-main tfoot td:nth-child(2) {
  width: 46%;
}

html body .payment-report-main td.payment-report-main {
  width: 35%;
}

html body .payment-report-main tfoot td:nth-child(3) {
  width: 15%;
}

html body .payment-report-main tfoot td:nth-child(4) {
  width: 15% !important;
} */
