.preference {
  display: grid;
  grid-template-columns: 24rem auto;
  grid-column-gap: 2rem;
}

.preference .card-header {
  padding: 1.5rem;
  height: 5rem;
}

.preference .card {
  height: auto;
}

.preference .card-body {
  color: var(--black);
  padding: 1rem 2rem;
  font-size: unset;
}

.preference .card:hover {
  box-shadow: none;
}

.preference .pref--hr {
  margin: 2rem 0;
}

.preference .form-field {
  grid-template-columns: auto;
  align-items: unset;
}

.preference .textarea {
  min-width: 42rem;
  min-height: 12rem
}

.preference .form-label {
  text-align: left;
}