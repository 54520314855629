.contact-employee-card .card .card-body {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-employee-card .custom-dropdown {
  position: absolute;
  top: 0;
  right: 0;
  height: 4rem;
}