/* .tab-component--tab {
  height: 100%;
}

.tab-component--buttons {
  height: 6rem;
  border-bottom: 1px solid var(--background);
  padding: 0 .5rem;
}

.tab-component--button {
  height: 100%;
  border: 0;
  outline: none;
  text-decoration: none;
  display: inline-block;
  background: #fff;
  margin: 0 .6rem;
  border-bottom: 2px solid transparent;
  padding: 0;
}

.tab-component--button.current {
  border-bottom: 2px solid var(--blue-500);
  transition: all var(--transition);
}

.tab-component--content {
  position: relative;
  height: calc(100% - 6rem);
  padding: 1.2rem 0;
  overflow: auto;
  border-bottom: 1px solid var(--border-blue-light);
} */

.border_bottom {
  padding: 0 !important;
}

.nav_tabs {
  float: left;
  width: 100%;
}

.nav_tabs {
  zoom: 1;
  /* margin-left: 20px; */
  padding-left: 40px;
  border-bottom: 2px solid #306ab4;
  margin-top: 5px;
}

.nav_tabs li {
  list-style: none outside none;
  float: left;
  position: relative;
  /*这个很重要*/
  /* border: 1px solid #000; */
  /* border-bottom: 0 !important; */
  margin-left: -15px;
}

.nav_tabs li:first-child {
  margin-left: 0 !important;
}

.nav_tabs .active {
  z-index: 3;
  /*当前项在最顶端*/
}

.nav_tabs li:before, .nav_tabs li:after, .nav_tabs a:before, .nav_tabs a:after {
  content: "";
  position: absolute;
  bottom: 0;
}

.nav_tabs li:before, .nav_tabs li:after {
  background: #DFE1E5;
  width: 10px;
  height: 10px;
}

.nav_tabs li:before {
  left: -10px;
}

.nav_tabs li:after {
  right: -10px;
}

.nav_tabs a {
  float: left;
  text-decoration: none;
  color: #000;
  background: #DFE1E5;
  border-radius: 10px 10px 0 0;
  font-weight: 500;
  height: 34px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.double_dash_box .nav_tabs {
  padding-left: 30px;
  margin-bottom: 0;
}

.double_dash_box .nav_tabs a {
  width: 180px;
}

.nav_tabs .active a {
  background: #306ab4;
  color: #fff;
}

.nav_tabs a:before, .nav_tabs a:after {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: #DFE1E5;
  z-index: 2;
}

.nav_tabs .active a:before, .nav_tabs .active a:after {
  background: #DFE1E5;
}

.nav_tabs a:before {
  left: -20px;
}

.nav_tabs a:after {
  right: -20px;
}

.nav_tabs .active:before, .nav_tabs .active:after {
  z-index: 1;
  background: #306ab4;
}

.nav_tabs li:first-child a:before, .nav_tabs li:last-child a:after {
  background-color: #fff;
}

.tab-component--content {
  float: left;
  width: 100%;
}

.close_icon {
  position: absolute;
  right: 1.5rem;
  font-size: 2.5rem;
  /* display: flex;
    height: 100%;
    align-items: center;
    justify-content: center; */
  top: 6px;
}

.close_icon svg {
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
}

.close_icon svg:hover {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  box-shadow: 0px 0px 5px 0px #C6C8CB;
}

.dashboardTableFill {
  fill: #8E8E8E;
  width: 15px;
  height: 15px;
}

.dashboard_info_text {
  color: #8E8E8E;
  font-size: 12px;
}

.dash_info_div {
  position: absolute;
  right: 1.5rem;
  display: flex;
  margin-top: 1%;
  justify-content: space-between;
  /* width: 13.5%; */
  margin-bottom: 1%;
}

.dash_info_div svg {
  margin-right: 5px;
}

@media screen and (min-width:1400px) and (max-width:1600px) {
  .nav_tabs a {
    width: 160px !important;
    font-size: 13px;
  }
}

@media screen and (min-width:1200px) and (max-width:1400px) {
  .nav_tabs a {
    width: 155px !important;
    font-size: 12px;
  }
}

@media(max-width:991px) {
  .dash_info-mob, .dash_info-mob span {
    font-size: 12px;
  }
}