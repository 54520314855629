.tab-component--tab {
  float: left;
  width: 100%;
}

.tab-component-sub-tab {
  float: left;
  width: 75%;
  padding: 0 20px;
  padding: 0 2px 0 20px;
  margin-bottom: 20px;
}

.items_details .section-details--info {
  width: 64%;
}

/* .items_details .float-left.w-70 {
  width: 80% !important;
} */

.ims-modal-container .tab-component-sub-tab {
  float: left;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 0px !important;
  height: calc(100vh - 299px);
  overflow: auto;
}

.ims-modal-container .details .tab-component-sub-tab {
  height: auto;
}

.tab-component--buttons {
  /* border-bottom: 2px solid var(--blue-500); */
  padding: 0 0.5rem;
  float: left;
  width: 100%;
  position: relative;
}

.tab-component--button {
  padding: 5px 20px;
  height: 100%;
  border: 0;
  outline: none;
  text-decoration: none;
  display: inline-block;
  background: #fff;
  margin: 0 0.6rem;
  position: relative;
  border-bottom: 2px solid transparent;
  font-weight: 400;
}

.tab-component--button.current {
  color: var(--blue-500);
  transition: all var(--transition);
  z-index: 10;
  position: relative;
  /* border-bottom: 2px solid #3bb54a; */
}

.tab-component--button.current:after {
  width: 100%;
  content: '';
  top: 101%;
  height: 5px;
  background-color: #33a348;
  position: absolute;
  left: 0;
}

.tab-component--content {
  position: relative;
  /* height: calc(100% - 6rem); */
  /* padding: 1.2rem 0; */
  /* overflow: auto; */
  /* border-bottom: 1px solid var(--border-blue-light); */
  float: left;
  width: 100%;
}

.border_bottom_sub_tab {
  float: left;
  width: 100%;
  border-bottom: 1px solid #707070;
  margin-bottom: 10px;
}

.border-0 {
  border: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

@media (min-width: 1260px) {
  .tab-component-sub-tab {
    width: 100%;
  }
}

@media (max-width: 1800px) {
  .items-tabs-responsive button.tab-component--button {
    padding: 3px 8px !important;
    margin: 0.4rem;
    font-size: 13px;
  }

  .items-tabs-responsive button.tab-component--button:first-child {
    margin-left: 0;
    float: left;
  }
}

@media (max-width: 1440px) {
  .items-tabs-responsive button.tab-component--button {
    padding: 3px 9px !important;
  }

  .items-tabs-responsive button.tab-component--button:nth-child(2n) {
    margin-left: 0;
  }
}

@media (max-width: 1366px) {

  .items-tabs-responsive button.tab-component--button {
    padding: 3px 6px !important;
  }

  .ui-itemgroup-icon .float-left.w-80,
  .items_details .float-left.w-80 {
    width: 90% !important;
  }
}

@media (max-width: 1280px) {
  .transaction_table.min_height .tabs-table th:first-child {
    min-width: 90px;
  }

  .transaction_table.min_height .tabs-table th:nth-child(2) {
    min-width: 100px;
  }
}