.list-table {
  width: 100%;
}

/* form fields with list table fixes */

.list-table .form-field {
  display: inline-flex;
  padding: 0;
  width: 100%;
}
.uiux_all_items tr td:last-child .table_icon:hover,
.uiux_all_items tr td:last-child .list-table--link path {
  stroke: #000;
  fill: #000 !important;
}
.list-table .form-field.error .input-feedback {
  bottom: -1.6rem;
}

.form-table .form-field.error .input-feedback {
  right: 0.8rem;
}

.item-level-discount .form-field.error .input-feedback {
  bottom: -2.2rem !important;
}

.create_sales_order .form-field.error .input-feedback {
  bottom: -1.7rem;
  border: none;
}

.create_sales_order .form-field.error .input-feedback:hover {
  border: 0 !important;
}

.create_sales_order .form-field.error .input-feedback {
  right: 0rem;
}

.create_invoice .form-field.error .input-feedback {
  bottom: -1.7rem;
  border: none;
}

.create_invoice .form-field.error .input-feedback:hover {
  border: 0 !important;
}

.create_invoice .form-field.error .input-feedback {
  right: 0rem;
}

.list-table.border tbody {
  border-left: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
}

.list-table.border thead {
  border-left: 1px solid var(--border-dark);
  border-right: 1px solid var(--border-dark);
}

.list-table.linked-row td {
  padding: 0;
}

.list-table.linked-row .list-table--link {
  display: block;
  padding: 0.8rem 0rem 0.8rem 0rem;
}

.right-tabs-section .list-table--wrapper {
  position: relative;
  min-height: 5rem;
  float: left;
  width: 100%;
  height: calc(100vh - 213px);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
  /* width: 100%;
  float: left;
  height: calc(100vh - 23.6rem);
  overflow: auto;
  scrollbar-width: thin;
scrollbar-color: #C7DCEB #dfedf7;  */
}

.SarchResults .list-table--wrapper {
  position: relative;
  min-height: 5rem;
  float: left;
  width: 100%;
  max-height: calc(100vh - 98px);
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
  /* width: 100%;
  float: left;
  height: calc(100vh - 23.6rem);
  overflow: auto;
  scrollbar-width: thin;
scrollbar-color: #C7DCEB #dfedf7;  */
}

.list-table--wrapper {
  position: relative;
  min-height: 5rem;
  float: left;
  width: 100%;
  height: calc(100vh - 98px);
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
  /* width: 100%;
  float: left;
  height: calc(100vh - 23.6rem);
  overflow: auto;
  scrollbar-width: thin;
scrollbar-color: #C7DCEB #dfedf7;  */
}

.list-table thead {
  /* background: var(--background); */
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  /* -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.15); */
}

.list-table--item--col {
  /* display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.8rem;
  word-break: break-word;
   width: -moz-max-content;
  min-width: 20rem;*/
  max-width: 20rem;
}

.list-table--item--col img {
  width: 4.2rem;
  height: 4.2rem;
  /* object-fit: scale-down; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.list-table--user--col {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* grid-template-columns: auto 1fr;
  grid-column-gap: 0.8rem; */
  /* word-break: break-word; */
  /* width: -moz-max-content; */
  /* min-width: 20rem; */
  /* max-width: 100%; */
}

.list-table--user--col img {
  width: 3.2rem;
  height: 3.2rem;
  object-fit: contain;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-table td {
  padding: 1.5rem 1rem;
  word-break: break-word;
}

.list-table th:first-child {
  padding-left: 0.6rem;
}

.list-table .list-table--checkbox {
  width: 30px;
}

.list-table th:last-child,
.list-table td:last-child {
  padding-right: 0rem;
  white-space: initial;
}

.list-table--checkbox {
  /* width: 5rem; */
  padding-right: 0.6rem;
}

.list-table--checkbox-lable {
  margin: 0;
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  user-select: none;
  justify-content: center;
}

.list-table--checkbox-lable > input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

.list-table--checkbox-lable > span {
  position: relative;
  width: 17px;
  height: 17px;
  border-radius: 2px;
  border: 1px solid #000;
  left: 0;
  right: 0;
  /* background: linear-gradient(180deg, #FFFFFF 0%, var(--background) 100%); */
}

.list-table--checkbox-lable > input[type='checkbox']:checked + span > svg {
  opacity: 1;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.list-table--checkbox-lable > span > svg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: -0.1rem;
  opacity: 0;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.list-table tbody tr {
  border-bottom: 1px solid var(--border-light);
}

.table-row:hover {
  background: #dcdee0 !important;
}

.form-table tbody tr:hover {
  background: transparent;
}

.list-table tbody tr.selected {
  background: #dcdee0 !important;
}

.list-table--settings {
  /* width: 5rem; */
  text-align: end;
}

.list-table--options {
  text-align: right;
}

/* .list-table--settings .custom-dropdown>.button{padding: 0 !important;} */

.custom-dropdown > .button {
  padding: 0.8rem 0rem;
}

.list-table--options .custom-dropdown .button:hover,
.list-table--settings .custom-dropdown .button:hover {
  background-color: transparent;
}

.list-table--settings .list-table--icon {
  text-align: right;
}

.list-table--link {
  color: unset;
}

.list-table--sort-header {
  color: unset;
}

/* .list-table .custom-dropdown svg {
  transform: rotate(90deg);
  transform: rotate(90deg);
  width: 25px;
  height: 25px;
  fill: #a3a3a3;
} */

.list-table .list-table--settings .custom-dropdown svg {
  transform: none;
}

.list-table--unit {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin-top: -0.4rem;
}

.form-table .list-table--amount {
  min-width: 9rem;
  max-width: 12.5rem;
}

.with-close--btn {
  position: relative;
}

.list-table--amount .close-btn.button,
.with-close--btn .close-btn.button {
  position: absolute;
  top: 0;
  left: 100%;
  color: var(--red-500);
}

.form-table td {
  height: 8rem;
}

.form-table.list-table th,
.form-table.list-table td {
  border: 1px solid var(--border-dark);
}

.form-table.list-table th {
  padding: 0.8rem;
}

.form-table.list-table td {
  padding: 0.4rem 0.8rem;
  vertical-align: top;
}

/* .character{
  background-color: #E3E3E3;
  margin-bottom: 6px;
  width: 25px;
  cursor: pointer;
  text-align: center;
} */
.mt-1 {
  margin-top: 1%;
}

.tabs-table {
  font-weight: 500 !important;
  font-size: 16px;
}

.transaction_table {
  max-height: calc(100vh - 259px);
  overflow: auto;
  float: left;
  width: 100%;
  border: 1px solid #dfe1e5;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
}

/* .transaction_table  table thead tr th:last-child{
  right: 26px;
} */
.transaction_table th {
  font-weight: 500;
}

.transaction_table th {
  height: 43px !important;
  position: -webkit-sticky;
  position: sticky !important;
  top: -1px;
  z-index: 1;
  background: #e9e9e9;
}

/* .transaction_table tbody tr td:last-child{
  float: right;
} */

.transaction_table thead tr th:last-child {
  text-align-last: right;
  padding-right: 4rem !important;
}

.transaction_table th {
  padding-left: 2rem !important;
}

/* added the above to align the item adjustment table fields */
.transaction_table td {
  padding-left: 2rem !important;
}

.notes_hover_adjustment .table_icon {
  width: 17px;
  height: 17px !important;
  height: auto;
  cursor: pointer;
}

.table_icon:hover {
  stroke: #33a348 !important;
  fill: #33a348 !important;
  cursor: pointer;
}

.notes_hover_adjustment {
  position: relative;
}

.notes_hover_adjustment_data {
  display: none;
  position: absolute;
  left: -175px;
  background-color: #fff;
  width: 210px;
  top: 100%;
  transition: 0.3s;
  padding: 10px;
  z-index: 9;
  text-align: justify;
  word-break: break-word;
  border: 1px solid #dcdee0;
}

.notes_hover_adjustment_data h2 {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 0;
}

.notes_hover_adjustment:hover .notes_hover_adjustment_data {
  display: block;
}

.min_height {
  min-height: 300px;
}

.list-table thead tr th.date {
  min-width: 18%;
}

.list-table thead tr th.type {
  min-width: 15%;
}

/*.list-table thead tr th:last-child{
  min-width: 70px;
}*/
.transaction-table-reason {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 40rem;
  display: block;
}

.billed_popup .receive-unbilled-table tbody tr td:nth-child(3) div div {
  text-align: left !important;
}

.create_package tbody tr td:last-child div:first-child {
  border: 1px solid #e0e2e5;
}

.create_package .create_package_input .form-field {
  width: 90% !important;
  min-width: 90% !important;
}

.ims-modal-container .create_package .create_package_input .form-field {
  min-width: 90% !important;
}

@media (max-width: 1850px) {
  html body .uiux_all_items .list-table thead th:first-child,
  html body .uiux_all_items .list-table tbody td:first-child {
    min-width: 100px;
  }

  html body .uiux_all_items .list-table thead th:nth-child(2),
  html body .uiux_all_items .list-table tbody td:nth-child(2) {
    min-width: 100px;
  }
}

@media (max-width: 1280px) {
  .invoice-listing-tabs tr th:nth-child(3),
  .invoice-listing-tabs tr td:nth-child(3),
  .invoice-listing-tabs tr th:nth-child(5),
  .invoice-listing-tabs tr td:nth-child(5) {
    display: none;
  }

  /* .invoice_Details_main {
    width: 94% !important;
  } */
}

@media (max-width: 991px) {
  .invoice-listing-tabs tr th:nth-last-child(2),
  .invoice-listing-tabs tr td:nth-last-child(2) {
    display: none;
  }
}
.uiux_all_items tr td:last-child .list-table--link {
  text-align: right;
  padding-right: 30px;
}