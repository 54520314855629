.loader {
  /*display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  height: 100vh;*/
  position: relative;
  /*float: right;*/
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;
}

.so-form--items-loader {
  position: relative;
  right: auto;
  left: 100%;
  bottom: 0;
}
.so-form--items-loader .loader {
  margin: 0;
}

.loader.small {
  position: relative;
  margin: 0;
  right: auto;
  left: 100%;
  bottom: 0.8rem;
}

.loader.small .ball-clip-rotate > div {
  /*height: 24px;
  width: 24px;*/
}

.ball-clip-rotate {
  display: flex;
}

.ball-clip-rotate > div {
  border-radius: 50%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /*border: 3px solid var(--blue-500);
  border-bottom-color: transparent;*/
  height: 64px;
  width: 64px;
  border: 4px solid #999;
  background: transparent;
  display: inline-block;
  will-change: transform;
  /*-webkit-animation: rotate 0.75s 0s linear infinite;
  animation: rotate 0.75s 0s linear infinite; */
  animation:lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  -webkit-animation:lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1); }
  50% {
    -webkit-transform: rotate(180deg) scale(0.7);
            transform: rotate(180deg) scale(0.7); }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1); } }