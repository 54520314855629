.page-two {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
}

.page-two-title {
  margin-left: 15%;
}

.page-two-img {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 8rem;
}
.page-two-img img {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .page-two {
    display: flex;
    flex-direction: column;
  }

  .page-two-title {
    margin-left: 0;
    align-items: center;
    padding-top: 5rem;
  }

  .page-two-img {
    width: 65vw;
    padding-top: 0;
  }
}