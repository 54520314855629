.searchable-dropdown {
  border-radius: 0 !important;
  /* z-index: 99; */
}
.pagination-route input {
  border-radius: 0 !important;
}
/* .css-yk16xz-control{
  border-radius: 0 !important;
} */

.searchable-dropdown.xs {
  width: 7.2rem;
}
.searchable-dropdown.small {
  width: 10.4rem;
}
.searchable-dropdown.medium {
  width: 17.6rem;
}
.searchable-dropdown.large {
  width: 23.2rem;
}

.form-table .searchable-dropdown {
  width: 100%;
}
.form-table .searchable-dropdown.xs {
  min-width: 7.2rem;
  max-width: 7.2rem;
}
.form-table .searchable-dropdown.small {
  min-width: 10.4rem;
  max-width: 16rem;
}
.form-table .searchable-dropdown.medium {
  min-width: 17.6rem;
  max-width: 100%;
}

.searchable-dropdown .searchable-dropdown__control {
  border-color: var(--text-field-border);
  min-height: 3.2rem;
  box-shadow: none;
}
.form-table .searchable-dropdown .searchable-dropdown__control {
  border-color: transparent;
  border-radius: 0;
}

.searchable-dropdown .searchable-dropdown__control:hover,
.searchable-dropdown .searchable-dropdown__control:focus-within {
  border-color: var(--blue-500);
}

.searchable-dropdown .searchable-dropdown__value-container {
  padding: 0 0rem;
}
.form-table .searchable-dropdown .searchable-dropdown__value-container {
  padding: 0 0.6rem;
}

.searchable-dropdown .searchable-dropdown__indicator {
  padding: 4px 2px 4px 2px;
}

.searchable-dropdown__value-container.searchable-dropdown__single-value {
  top: 0;
}

.searchable-dropdown__multi-value__label,
.searchable-dropdown__multi-value__remove {
  background-color: var(--selected);
}
.searchable-dropdown__multi-value__remove svg {
  color: var(--red-500);
}
.textarea-field textarea {
  width: 476px !important;
  height: 12rem !important;
}
