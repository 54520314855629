.sales_return_details {
  width: 80% !important;
  margin: auto !important;
}

.sale_return_section .menu-component--menu.menu_top {
  left: 0px;
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
  /* position: relative !important; */
}
.sale_return_section .upper_row .section-details--info.mtb{
  white-space: initial;
}
.overflow_custom_height .sales_return_details {
  width: 80% !important;
}

.upper_row {
  display: flex;
  justify-content: space-between;
}

.fields_data {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  color: #000;
}

.sales_return_details .fields_data {
  width: 100%;
}

.mtb {
  margin-top: 3%;
  margin-bottom: 3%;
}

.mtb hr {
  border-top: 1px solid #dfe1e5 !important;
}

.mtb .semi-bold {
  /* font-weight: 600 !important; */
}

.title {
  width: 50%;
}

/*.sales_return_table table thead tr th:nth-child(3) , .sales_return_table table tbody tr td:nth-child(3){
  padding-left: 5.5%;
}*/

.sales_return_table table.return_recieve_sales_order thead tr th:nth-child(3), .sales_return_table table.return_recieve_sales_order tbody tr td:nth-child(3) {
  padding-left: 8px;
}

.sales_return_table table thead {
  box-shadow: 0 0 10px #CCC;
}

.sales_return_table table thead tr {
  border-left: 1px solid var(--border-dark);
}

.sales_return_table table thead th {
  background: #FFF !important;
}

.sales_return_table table thead th {
  line-height: 1.42857143 !important;
}

.sales_return_table table .list-table--item--col span {
  width: 100%;
  /* overflow: hidden;
  text-overflow: ellipsis; */
  /* white-space: nowrap; */
}

/* .sales_return_table table.return_recieve_sales_order .list-table--item--col span {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.sales_return_table table tbody tr:nth-child(even) {
  background: #FCFCF9;
}
@media (max-width: 1280px) {
  .overflow_custom_height .sales_return_details {
    width: 92% !important;
  }
}
/*
.sale_return_section.sales-return-main.sales-return-main-set {
  max-width: 729px;
}*/

@media screen and (max-width:1138px){
  .sales-return-main-set .details-header{
    width: 100% !important;
}
.sales-return-main-set .section-details--info{
        width:50% !important;
}
.sales-return-main-set .details-header .details-header--title-info .details-header--info{
    display:inline-block !important;
}
}