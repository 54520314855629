/* .ims-modal{
  width: 56.3% !important;
} */
.address-form .form-field {
  grid-template-columns: 12rem 1fr;
}
.form-fields-container.shipment-form-main .form-field.error .input-feedback {
  bottom: -0.6rem;
}
.shipment-large {
  min-width: 104rem !important;
}

.shipment-large textarea {
  width: 48.5rem !important;
}

.modal_invoice_title_align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

.modal_package_title_align {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 36.5%;
}

.total_field {
  display: flex;
  width: 98%;
  justify-content: flex-end;
  margin-top: 1%;
  margin-bottom: 1%;
}

.bottom_title {
  margin-right: 9.5%;


}

.customTop {
  margin-top: 5px;
}
@media(max-width:1138px) {
  .create-so-invoice .modal_invoice_title_align {
    width: 100%;
    justify-content: unset;
  }
}
@media(max-width:890px){
  .form-fields-container.shipment-form-main .form-field.error .input-feedback{
    bottom: -0.1rem;
  }
}
@media(max-width:768px){
  .shipment-form-main .form-field.flex-33:nth-child(5):where(.error) .input-feedback {
    bottom: -9px;
}
}