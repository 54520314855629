.section-header--pagination {
  /* display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  min-height: 6.1rem; */
  float: left;
  width: 100%;
  padding: 5px 8px;
  position: relative;
  z-index: 2;
  background-color: #dfe1e5;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.mr_3 {
  margin-right: 10px;
}

.section-header--title svg {
  margin-right: 1rem;
  vertical-align: middle;
  width: 30px;
  height: 30px;
}

.section-header--title svg path {
  fill: var(--black);
}

.section-header--title svg circle {
  stroke: var(--black);
}

.actions-menu--holder {
  min-width: 35rem;
  justify-content: flex-end;
}

.table_header_icon {
  width: 17px !important;
  height: 17px !important;
}

.showDetailsComponent {
  /* font-size: 100px; */
  display: flex;
  flex-direction: row;
  /* float: left;
  width: 100%; */
}

.detail_component {
  margin: 1%;
  width: -webkit-fill-available;
  /* float: left;
  width: 65%;
  padding: 1.5rem 1.5rem 0 1.5rem; */
}

.parent-component {
  float: left;
  width: 100%;
  display: block !important;
  height: 100%;
  overflow: hidden;
}

.parent-component #listing_component {
  margin: 0 !important;
  float: left;
  width: 30%;
}

.parent-component #listing_component .layout--grid {
  width: 100%;
}

.parent-component #listing_component .alert.full-width.green + .layout--grid {
  height: calc(100vh - 190px);
  overflow: hidden;
}

.parent-component
  #listing_component
  .alert.full-width.green
  + div
  .list-table--wrapper {
  max-height: calc(100vh - 231px);
  overflow: auto;
}

.SarchResults .parent-component #listing_component .list-table--wrapper {
  float: left;
  width: 100%;
  overflow: auto;
  box-shadow: var(--shadow-medium);
  max-height: calc(100vh - 98px);
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
}

.parent-component #listing_component .list-table--wrapper {
  float: left;
  width: 100%;
  overflow: auto;
  box-shadow: var(--shadow-medium);
  height: calc(100vh - 98px);
  scrollbar-width: thin;
  scrollbar-color: #c0c0c0 #efecec;
  z-index: 1;
}

.fullscreenyes
  #listing_component
  .alert.full-width.green
  + div
  .list-table--wrapper {
  max-height: calc(100vh - 175px);
}

.parent-component
  #listing_component
  .alert.full-width.green
  + div
  .alphabatical_listing
  td
  a {
  height: 2.7%;
}

.tab-component--content > .list-table--wrapper {
  height: 320px !important;
}

.tab-component--content > .list-table--wrapper.custom_height_dashboard {
  height: 410px !important;
}

.dashboard_box
  .tab-component--content
  > .list-table--wrapper.custom_height_dashboard {
  height: 448px !important;
}

.parent-component .detail_component {
  margin: 0 !important;
  float: left;
  width: 70%;
  /* padding: 1rem 1rem 0 1rem; */
}

.parent-component .detail_component .layout {
  float: left;
  width: 100%;
}

.parent-component .detail_component .layout .top-header {
  float: left;
  width: 100%;
}

.parent-component .detail_component .layout #listing_component {
  float: left;
  width: 100%;
  padding-top: 1rem;
}

#listing_component .layout--grid-container {
  float: left;
  width: 100%;
  display: block !important;
}

.parent-component .detail_component .layout #listing_component .layout--grid {
  float: left;
  width: 65%;
}

.parent-component
  .detail_component
  .layout
  #listing_component
  .layout--grid.transparent {
  float: left;
  width: 35%;
  padding-left: 1rem;
}

#onboarding-screen {
  /* commented below display property to resolve empty package table style related issue */
  display: flex;
  flex-direction: row;
  width: 100%;
}

#onboarding-screen .layout--grid {
  width: 100%;
}

#onboarding-screen .on-boarding {
  width: 100%;
}

/* SIQ-1226 */

#listing_component > div {
  /* margin: 1%; */
  width: 100%;
}

.details-headers {
  height: 4.6rem;
  background-color: #e9e9e9;
}

.header-wrapper-icon {
  float: left;
  position: relative;
  top: 8px !important;
  height: 16px;
}

.header-wrapper-icon path {
  float: left;
  fill: black;
}

.hw-name {
  position: relative;
  top: 3px;
  font-size: 20px;
  margin: 0 1rem 0 0.5rem;
}

.name_in_hw {
  position: absolute;
  left: 2.5rem;
  left: 6rem;
  /* top: 1.3rem; */
  /* max-width: 70%; */
  font-weight: 600;
  font-size: medium;
  /* line-break: anywhere; */
  margin-bottom: 0;
  margin-top: 0;
  word-break: break-word;
  max-width: 50%;
  padding-right: 20px;
  /* word-break: break-all; */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 46px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.icon_in_hw {
  width: 25px !important;
  position: absolute !important;
  left: 3rem;
  margin-bottom: 0;
  margin-top: 6px;
  /* margin-top: 8px;  commneting this for testing purpose, this was effecting the details page headericon*/
}

.salereturn_general_module_icon {
  width: 24px !important;
  height: 24px !important;
  top: 5px !important;
}

.credit_general_module_icon {
  width: 25px !important;
  height: 25px !important;
}

.contact_general_module_icon {
  width: 25px !important;
  height: 25px !important;
}

.contact_top {
  top: 4px !important;
}

.status-placement-hw {
  position: absolute;
  left: 168px;
}

.contact_info_header_icon {
  fill: #000;
  stroke: #000;
  width: 15px;
  height: 15px;
  position: relative;
}

.contact_info_header_icon {
  fill: #000;
  opacity: 0.4;
}

.header-contact-info {
  position: absolute;
  right: 5px;
  top: 15px;
}

.header-contact-info:hover .totalcount4 {
  display: block;
}

.totalcount4 {
  position: absolute;
  right: 0;
  bottom: -35px;
  display: none;
  width: 140px;
  color: #fff;
  background-color: #000;
  padding: 5px 0;
  text-align: center;
  transition: 0.3s;
  z-index: 9999999;
  font-size: 12px;
}

.contact_top ~ .header_right_Sec button#Click_Add_New_Item_Adjustment_Button {
  margin-right: 25px;
}

@media screen and (max-width: 1587px) {
  .alert.full-width.green .alert--title {
    font-size: 13px;
  }

  .alert.full-width.green u {
    font-size: 13px;
  }

  .alert.full-width.green span {
    font-size: 13px;
  }
}

/* @media screen and (max-width:1454px) {
  .parent-component #listing_component .alert.full-width.green + div .list-table--wrapper {
    height: calc(100vh - 271px);
  }
}
@media screen and (max-width:1446px) {
  .parent-component #listing_component .alert.full-width.green + .layout--grid {
    height: calc(100vh - 218px);
    overflow: hidden;
}
.parent-component #listing_component .alert.full-width.green + div .list-table--wrapper {
  height: calc(100vh - 295px);
}
} */

@media screen and (min-width: 1921px) {
  .parent-component #listing_component .alert.full-width.green + .layout--grid {
    height: calc(100vh - 162px);
    overflow: hidden;
  }

  .parent-component
    #listing_component
    .alert.full-width.green
    + div
    .list-table--wrapper {
    height: calc(100vh - 206px);
  }
}

@media (max-width: 1800px) {
  .detais-component-main {
    width: 45%;
  }

  .contact-detail-trans
    .tab-component--buttons.border_bottom_sub_tab.item-responsive-tab {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .contact-detail-trans
    .tab-component--buttons.border_bottom_sub_tab.item-responsive-tab::-webkit-scrollbar {
    height: 8px;
    margin-top: 3px;
  }
}

@media (max-width: 1600px) {
  .detais-component-main .float-left.w-70 {
    width: 80% !important;
  }

  .section-header--dropdown .custom-dropdown {
    max-width: 95px;
  }

  .name_in_hw {
    max-width: 46%;
  }
}

@media screen and (max-width: 1536px) {
  .parent-component
    #listing_component
    .alert.full-width.green
    + div
    .alphabatical_listing
    td
    a {
    height: 2.5%;
  }
  .section-header--dropdown .custom-dropdown {
    max-width: 90px;
  }
}

@media (max-width: 1440px) {
  .detais-component-main .float-left.w-70 {
    width: 90% !important;
  }

  .name_in_hw {
    max-width: 48%;
  }
}

@media (max-width: 1450px) {
  .name_in_hw {
    max-width: 40%;
  }
}

@media screen and (max-width: 1400px) {
  .alphabatical_listing td a {
    height: 3.2%;
  }

  .detais-component-main .float-left.w-70 {
    width: 90% !important;
  }
}

@media screen and (max-width: 1366px) {
  /* .name_in_hw {
    max-width: 40%;
  } */

  .actions-menu--holder .action-menu .options-dropdown {
    left: 248px;
  }

  .actions-menu--holder .action-menu {
    margin-right: 8px;
  }
}

@media (max-width: 1440px) {
  .tab-component--buttons.border_bottom_sub_tab.item-responsive-tab {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .tab-component--buttons.border_bottom_sub_tab.item-responsive-tab::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #c0c0c0;
    box-shadow: inset 0 0 6px #c0c0c0;
    background-color: #efecec;
  }

  .tab-component--buttons.border_bottom_sub_tab.item-responsive-tab::-webkit-scrollbar {
    height: 9px;
  }

  .tab-component--buttons.border_bottom_sub_tab.item-responsive-tab::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px #dbdbdb;
    box-shadow: inset 0 0 6px #dbdbdb;
    box-shadow: inset 0 0 6px #c0c0c0;
    background-color: #c0c0c0;
  }
}

@media (max-width: 1280px) {
  p.name_in_hw {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 220px;
    display: initial;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .items_sub_tabs.items-tabs-responsive {
    float: left;
    width: 100%;
  }

  .items-tabs-responsive .tab-component--tab {
    white-space: nowrap;
  }

  .items-tabs-responsive .tab-component--content.border-0.p-0 {
    overflow-x: auto;
    white-space: break-spaces;
    padding-bottom: 40px !important;
  }

  .tab-component--buttons.border_bottom_sub_tab.item-responsive-tab::-webkit-scrollbar {
    height: 8px;
    margin-top: 3px;
  }

  .items-tabs-responsive .activity_new .right {
    white-space: normal;
  }

  .items-tabs-responsive
    button.tab-component--button:first-child
    span.tabs_label_set,
  .items-tabs-responsive
    button.tab-component--button:nth-child(2)
    span.tabs_label_set {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 57px;
  }

  .items-tabs-responsive
    button.tab-component--button:first-child
    span.tabs_label_set {
    width: 57px;
  }

  .items-tabs-responsive
    button.tab-component--button:nth-child(2)
    span.tabs_label_set {
    width: 80px;
  }

  .btn-add-varient {
    max-width: 130px;
    padding: 0.6rem 1.4rem 0.6rem 1rem;
  }
}

@media (max-width: 1199px) {
  .detais-component-main .float-left.w-70 {
    width: 100% !important;
  }

  .btn-add-varient {
    max-width: 130px;
    padding: 0.6rem 1.4rem 0.6rem 1rem;
  }

  p.name_in_hw {
    width: 130px;
  }

  p.name_in_hw.item-adjustment {
    width: 100%;
  }

  /* .mob_menu_inner .status-placement-hw {
    position: relative;
  } */
}

@media (max-width: 1024px) {
  p.name_in_hw {
    font-size: 15px;
  }
}

/* Mobile menu  */
/* .mobile-menu-main.actions-menu--holder {
  width: 100%;
  min-width: 100% !important;
}

.mobile-menu-main>.checked-menu--toolbar,
.mobile-menu-main .mob_menu_inner {
  flex: 0 0 50%;
  width: 100%;
}

.mobile-menu-main>.checked-menu--toolbar,
.mobile-menu-main .checked-menu--toolbar .mobile-menus-dynamic {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.mob_menu_inner {
  display: flex;
  align-items: center;
} */

.actions-menu--holder {
  width: 100%;
  min-width: 100% !important;
}

.mobile-menus-dynamic {
  width: 100%;
  display: flex;
  z-index: 20;
}
