.bills_table_header_icon {
  width: 20px !important;
  height: 20px !important;
}

.bills_details_header_icon {
  width: 22px !important;
  height: 20px !important;
  margin-top: 6px;
}

.bill_payment_modal_title_align {
  display: flex;
  align-items: center;
}

.bill_payment_modal_box_align {
  width: 55% !important;
}

.bill_payment_modal_box_align .section-details--info {
  width: 90% !important;
}

.payment_info_bills {
  display: flex;
  justify-content: space-between;
}

.payment_details_heading_title {
  width: 40% !important;
}

.payment_details_fields_value {
  width: 60% !important;
}

.bills_noteS_popup {
  margin-bottom: 2%;
  margin-top: 0% !important;
}

.table_bottom {
  margin-bottom: 2% !important;
}

.bill_vendor_details {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%;
  float: left;
  /* margin-top: 1%; */
  margin-bottom: 2%;
  /* flex-direction: row; */
  margin-top: 2%;
}

.paid_data {}

.paid_data svg {
  float: left;
  margin-right: 10px;
}

.paid_data a {
  float: left;
  position: relative;
  top: -3px;
  font-size: 1.2rem;
}

.paid_data .detail {
  float: left;
  position: relative;
  top: -3px;
  font-size: 1.2rem;
}

.sales-return-main.bill_set_main .menu-component--menu.menu_top {
  width: 99.8%;
  margin-left: 0 !important;
}

.bill_set_main .overflow_custom_height {
  padding-top: 1%;
}

.expanded_view_bill tbody tr td:nth-child(5) {
  padding: 0 5px;
}

@media (max-width: 1366px) {
  /* html body table.expanded_view_bill thead tr th:nth-child(2) {
    width: 20% !important;
  } */

  /* html body .expanded_view_bill thead tr th:nth-child(3) {
    width: 30% !important;
  } */
  html body .status {
    min-width: 75px;
    font-size: 1rem !important;
  }

  /* html body .expanded_view_bill thead tr th:nth-child(4) {
    width: 12% !important;
  } */

  /* html body .expanded_view_bill thead tr th:nth-child(5) {
    width: 18%;
  } */
}

/* @media (max-width: 1280px) {
  html body table.expanded_view_bill thead tr th:nth-child(2) {
    width: 25% !important;
  }
} */

@media (max-width: 1024px) {

  .sales-return-main.bill_set_main .payments_menu th:nth-child(2),
  .sales-return-main.bill_set_main .payments_menu td:nth-child(2),
  .sales-return-main.bill_set_main .payments_menu th:nth-child(3),
  .sales-return-main.bill_set_main .payments_menu td:nth-child(3) {
    width: 20%;
  }

  .sales-return-main.bill_set_main .payments_menu th:nth-child(4),
  .sales-return-main.bill_set_main .payments_menu td:nth-child(4) {
    width: 18%;
  }
}

@media (max-width: 991px) {

  .list-table.linked-row.bill_listing.bill_listing thead th:nth-child(3),
  .list-table.linked-row.bill_listing.bill_listing tbody tr td:nth-child(3) {
    display: none;
  }

  .list-table.linked-row.bill_listing.bill_listing thead th:nth-child(2),
  .list-table.linked-row.bill_listing.bill_listing tbody tr td:nth-child(2) {
    width: 10%;
  }

  .list-table.linked-row.bill_listing.bill_listing thead th:nth-child(4),
  .list-table.linked-row.bill_listing.bill_listing tbody tr td:nth-child(4) {
    width: 16%;
  }
}

@media (max-width: 890px) {

  .list-table.linked-row.bill_listing.bill_listing thead th:nth-child(4),
  .list-table.linked-row.bill_listing.bill_listing tbody tr td:nth-child(4) {
    display: none;
  }

  .list-table.linked-row.bill_listing.bill_listing thead th:nth-child(2),
  .list-table.linked-row.bill_listing.bill_listing tbody tr td:nth-child(2) {
    width: 13%;
  }

  html body .bill_listing thead tr th:nth-child(5) {
    width: 43% !important;
  }

  .add_new_payment.bill-inner-popup span.payment_modal_title {
    width: 36%;
  }
}