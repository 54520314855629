.quantity_row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px 0px 40px;
}

.quantity_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upper_text {
  font-size: 16px;
  font-weight: 400;
}

.lower_text {
  color: #33a348;
  font-weight: bold;
  font-size: 18px;
}

.background_custom {
  background-color: #f8f8f8;
}

.stock_row hr {
  border-top: 1px solid #dfe1e5 !important;
}

.height_custom {
  height: 155px;
}

.stock_information {
  position: relative;
  top: 24px;
}

.stock_information div {
  margin-bottom: 10px;
}

.items_sub_tabs .tab-component--tab .tab-component-sub-tab {
  width: 100% !important;
}

.items_sub_tabs .tab-component--tab .tab-component-sub-tab .tab-component--button {
  padding: 5px 10px;
}

/*===========================
Custom toggles   
=============================*/

.item-group-desc {
  /* min-width: 350px;
  max-width: 500px; */
  padding: 20px 20px 15px;
  margin-bottom: 20px;
  border: 1px solid #f2f2f2;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%);
  border-radius: 6px;
  display: none;
}

.item-group-desc.group-desc-show {
  display: block;
  position: relative;
}

.items-group-main button {
  font-size: 15px;
  color: #2485e8;
  text-decoration: underline;
  /* font-style: italic; */
  text-transform: capitalize;
  margin-bottom: 10px;
  margin-right: 10px;
}

button.text-italic-active {
  font-style: italic;
}

.items-group-main {
  padding-left: 28px;
}

.group-row {
  align-items: center;
}

.group-right-set {
  flex: 1;
}

.item-table-width th {
  flex: 0 0 50%;
}

.item_toggle {
  position: absolute;
  right: 20px;
  top: 20px;
}

.item_toggle button:hover {
  color: #000;
}

.group-counts {
  display: flex;
  align-items: center;
}

.group-counts p {
  /* width: 50%; */
  font-size: 14px;
  margin-right: 10px;
}

.group-counts a:hover {
  color: var(--blue-color);
  text-decoration: underline;
}

.item-group-img {
  margin-right: 15px;
  line-height: 0;
}

.group-right-set h4 {
  font-size: 18px;
  margin-bottom: 2px;
  font-weight: 500;
  margin-top: 0;
}

.item-table-width td {
  word-break: break-word;
}

.mr-2 {
  margin-right: 20px;
}

.group-counts span {
  font-weight: 500;
}

.item_toggle i.fas.fa-cog {
  cursor: pointer;
}

i.fas.fa-chevron-down.fa-rotate {
  transform: rotate(-180deg);
  transition: .2s ease;
}

.item_toggle i.fas.fa-chevron-down {
  transition: .5s ease;
}

.items-group-main {
  clear: both;
}

.items-group-main .items-group-inner {
  flex: 0 0 49.5%;
}

.items-group-main {
  flex-wrap: wrap;
}

.item_toggle .fas {
  color: #000000ba;
  cursor: pointer;
}

@media (max-width: 1440px) {
  .quantity-mob-row span.upper_text {
    font-size: 14px;
  }

  .quantity-mob-row span.lower_text {
    font-size: 16px;
  }
}

/* @media (max-width: 1280px) {
  .float-left.w-100.d-flex {
    display: flex;
    justify-content: space-between;
  }
} */

@media(max-width:1024px) {
  .items-group-main .items-group-inner {
    flex: 0 0 100%;
  }
}