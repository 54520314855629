.create_invoice thead {
  line-height: 1.42857143;
  background: transparent !important;
}

.create_invoice.form-table.list-table th,
.create_invoice.form-table.list-table td {
  border: 0 !important;
}
.uiux-invoice-form-main .tablet-align >.form-field:first-child .input-feedback {bottom: -0.01rem;}

.create_invoice.form-table.list-table td span input {
  border: 1px solid #e0e2e5;
}

.create_invoice tbody tr td {
  height: auto !important;
  padding: 10px 5px !important;
}

.create_invoice tbody tr td:last-child {
  margin-top: 10px;
}

.create_invoice .form-input.small {
  border-radius: 4px !important;
  border: 1px solid #e0e2e5;
}

.create_invoice .list-table--item--col span {
  max-width: 100%;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  word-break: break-word;
}

.create_invoice .searchable-dropdown {
  border-radius: 4px !important;
}

.create_invoice .searchable-dropdown .searchable-dropdown__control {
  border-radius: 4px !important;
}

.create_invoice.form-table .list-table--amount .close-btn.button {
  top: 5px;
}

.horizontal-items-table .summary_table .form-field {
  min-width: auto !important;
}

.textarea-invoice textarea {
  width: 48.8rem !important;
}

.adj-shipping-invoice-field input {
  width: 14.5rem !important;
}

.create_invoice {
  width: 98.3% !important;
}
.error.invoice-add-form .input-feedback {
  bottom: -0.5rem;
}
.error.invoice-form-details .input-feedback {
  bottom: 1.5rem;
}
.create_invoice .searchable-dropdown__input input {
  color: transparent;
  text-shadow: 0 0 0 black;
}

.pr-2 {
  padding-right: 1.8rem !important;
}

.mr-1 {
  margin-right: 1rem;
}

.payment-record textarea {
  width: 48.5rem !important;
  margin-bottom: 25px;
}

.payment-record .input-feedback {
  bottom: 1.5rem !important;
}

.invoice_term_btn {
  display: flex;
  flex-direction: row-reverse;
}

.invoice_modal_BulkItems_title_align {
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  width: 15%;
}

.invoice_modal_BulkItems_title_align span svg {
  height: 16px;
  width: 12px;
}

.issued_SO_PO {
  height: 60vh;
  overflow: auto;
}

.confirm_SO_modal {
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  width: 60%;
}

.confirm_SO_modal span svg {
  height: 20px;
  width: 16px;
}

.Issue_PO_modal {
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  width: 60%;
}

.Issue_PO_modal span svg {
  height: 16px;
  width: 14px;
}

.invoice-main-set {
  display: inline-block;
  position: relative;
  height: 100%;
}

.invoice-main-set button.view-details-btn {
  position: absolute;
  right: auto;
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 1.2rem;
  top: auto;
  left: 62%;
  bottom: -13px;
}

.invoice-term-bottom {
  padding-top: 10px;
}
.uiux-invoice-form-main .list-table .__dragDrop .form-field:first-child {
  top:-20px
}
.uiux-invoice-form-main .list-table .__dragDrop {
  width: 100%;
}

.uiux-invoice-form-main .list-table  .__dragDrop .__drag-righ {
  max-width: unset;
}
span.icon_div.invoice-tooltip-main {
  font-size: 13px;
}
.tooltip_tooltip.invoice-tooltip {
  top: 3px;
  left: -2px;
  text-align: center;
  width: 135px;
  padding: 9px;

}
.invoice-tooltip-main.icon_div:hover .tooltip_tooltip::before {
  top: 0;
  transform: rotate(1deg);
  top: -19px;
  left: 59px;
}
.tooltip_tooltip.invoice-tooltip p {
  margin: 0;
  font-size: 12px;
}

.form>.invoice-main-set~.form-field.inline-field:nth-child(8),
.form>.invoice-main-set~.form-field.inline-field:nth-child(9) {
  margin-top: 21px;
}

.invoice-main-set.invoices-popup-set button.view-details-btn {
  bottom: -13px;
}

.invoice-main-set button.view-details-btn {
  bottom: -88px;
}

.order-no-set {
  display: inline-block;
}

@media (max-width: 1280px) {
  .create-invoice-sec .form-field.inline-field {
    width: 48%;
    margin-right: 2%;
    margin-top: 0 !important;
  }

  .create-invoice-sec .form-field.inline-field .form-input.large,
  .create-invoice-sec .form-field.inline-field .searchable-dropdown.large {
    width: 100% !important;
  }

  .create-invoice-sec .invoice-main-set {
    display: initial;
  }

  .create-invoice-sec .textarea-so-terms,
  .create-invoice-sec .form-field.inline-field.textarea-so-terms textarea {
    width: 100% !important;
  }

  .create-invoice-sec .textarea-so-terms,
  .create-invoice-sec .textarea-invoice {
    display: block !important;
  }

  .create-invoice-sec .inline-terms-and-conditions {
    padding-left: 0;
  }

  .create-invoice-sec .invoice-main-set {
    position: relative;
  }

  .invoice-popup-set.create_invoice_popup.so-invoice-popup button.view-details-btn {
    left: auto;
    right: 22px;
  }

  .order-no-set .form-field.inline-field {
    width: 100% !important;
  }

  .order-no-set,
  .order-no-set+.form-field.inline-field {
    margin-top: 6px !important;
  }

  .create-invoice-sec .invoice-main-set .form-field.inline-field {
    width: 96%;
  }
  .order-no-set {
    width: 48%;
    margin-right: 19px;
  }

  .order-no-set+.form-field.inline-field {
    margin-top: 21px !important;
  }

  .invoice-main-set.invoices-popup-set .form-field.inline-field {
    padding-top: 3px !important;
  }

  html body .uiux_invoice_edit_form.create_invoice.w-100 {
    min-width: unset !important;
    max-width: 100%;
  }
}

@media (max-width: 1138px) {
  .action-buttons.UIUX_invoice_actions {
    padding-right: 0;
  }

  .invoice_form_main>.order-no-set {
    margin-top: 0 !important;
  }

  .invoice_form_main>.invoice-main-set {
    margin-top: 6px;
  }
  .invoice_form_main .order-no-set .form-field.inline-field,
  .invoice_form_main>.order-no-set .form-field {
    padding-top: 0;
  }
  .ims-modal.white.large.create-invoice-popupset.so-invoice-popup .form-field.inline-field.invoice-term-set .searchable-dropdown {
    width: 100% !important;
    max-width: 100%;
  }

  .uiux_invoice_table_summary.discountAdded thead tr th:nth-child(2),
  .uiux_invoice_table_summary.discountAdded tbody tr td:nth-child(2) {
    width: 6% !important;

  }

  .uiux_invoice_table_summary.discountAdded thead tr th:nth-child(5),
  .uiux_invoice_table_summary.discountAdded tbody tr td:nth-child(5) {
    width: 4% !important;
  }
  .uiux_invoice_table_summary.discountRemoved thead tr th:nth-child(3),
  .uiux_invoice_table_summary.discountRemoved tbody tr td:nth-child(3) ,
  .uiux_invoice_table_summary.discountAdded thead tr th:nth-child(3),
  .uiux_invoice_table_summary.discountAdded tbody tr td:nth-child(3) {
    width: 11% !important;
  }
  .uiux_invoice_table_summary.discountAdded thead tr th:nth-child(6),
  .uiux_invoice_table_summary.discountAdded tbody tr td:nth-child(6),
  .uiux_invoice_table_summary.discountAdded thead tr th:nth-child(4),
  .uiux_invoice_table_summary.discountAdded tbody tr td:nth-child(4) {
    width: 10% !important;
  }

  .uiux_invoice_table_summary.discountRemoved thead tr th:nth-child(2),
  .uiux_invoice_table_summary.discountRemoved tbody tr td:nth-child(2) {
    width: 7% !important;

  }
  .uiux_invoice_table_summary.discountRemoved thead tr th:nth-child(4),
  .uiux_invoice_table_summary.discountRemoved tbody tr td:nth-child(4) {
    width: 8% !important;
  }

  .uiux_invoice_table_summary.discountRemoved thead tr th:nth-child(5),
  .uiux_invoice_table_summary.discountRemoved tbody tr td:nth-child(5) {
    width: 12% !important;
  }
  html body .uiux_invoice_edit_form.create_invoice.w-100.no-discount-table {
    min-width: 1054px !important;
}
}
@media(max-width:1099px){
  html body .uiux_invoice_edit_form.create_invoice.w-100.no-discount-table {
    min-width: 1022px !important;
}
}

@media (max-width: 1024px) {
  .invoice-main-set {
    display: block;
    margin-bottom: 15px;
  }

  .invoice_form_main .form-field.inline-field.invoice-term-set,
  .order-no-set,
  .invoice-popup-main .form-field.inline-field {
    width: 48% !important;
  }

  html body .uiux_invoice_edit_form th:nth-child(4) {
    padding-left: 11px;
  }

  .invoice_form_main .invoice-main-set.invoices-popup-set,
  .invoice_form_main .order-no-set .form-field.inline-field,
  .invoices-popup-set .form-field.inline-field,
  .invoice-main-set .form-field.inline-field,
  .invoice-popup-main .form-field.inline-field .searchable-dropdown,
  .invoice-main-set .searchable-dropdown.large,
  .order-no-set .invoice-popup-main .searchable-dropdown.large,
  .invoice-popup-main .form-field.inline-field input {
    width: 100% !important;
  }

  html body .invoice-popup-main .form-field.inline-field.textarea-so-terms {
    width: 96% !important;
  }

  .order-no-set,
  .order-no-set .form-field.inline-field,
  .invoice-popup-main .searchable-dropdown.large {
    margin-right: 0 !important;
  }
  .invoice-popup-main .form-field.inline-field:nth-child(odd),
  .invoice-popup-main .form-field.inline-field:nth-child(even) {
    width: 48%;
  }

  .invoice-popup-main .form-field.inline-field:nth-child(odd) {
    margin-right: 4px !important;
  }

  .invoice-popup-main .form-table--container {
    margin-right: 0.4rem;
  }
  .uiux-invoice-form .form-field.inline-field:nth-child(4),
  html body .create-invoice-popupset .invoice-main-set+.form-field.inline-field,
  .invoice-term-set,
  .order-no-set,
  .order-no-set+.form-field.inline-field {
    margin-top: 0 !important;
  }

  .invoice-main-set.invoices-popup-set button.view-details-btn {
    right: 0;
    left: auto;
  }

  .uiux-invoice-form .form-field.inline-field:nth-child(4),
  html body .create-invoice-popupset .invoice-main-set+.form-field.inline-field {
    padding-top: 0;
  }
  html body .uiux_invoice_edit_form.create_invoice.w-100.no-discount-table {
    min-width: 947px !important;
}
}

@media (max-width: 991px) {
  html body .uiux_invoice_edit_form th:first-child {
    width: 33.8% !important;
  }

  html body .uiux_invoice_edit_form th:nth-child(2) {
    width: 19.2% !important;
  }
  html body .uiux-invoice-form-main th:nth-child(1) {
    width: 26% !important;
}
}

@media (max-width: 890px) {
  .order-no-set {
    margin-right: 13px;
  }
}

@media (max-width: 768px) {

  html body .so-invoice-popup form>div:not(.invoice-table-set, .invoices-popup-set, .middle-row):nth-child(odd),
  .create-invoice-sec .form-field.inline-field:nth-child(4) {
    margin-left: 0 !important;
  }

  html body .layout--body div.uiux-invoice-form .form-field.inline-field.invoice-term-set,
  html body .invoice-popup-main .form-field.inline-field.textarea-so-terms,
  .invoice-popup-main .form-field.inline-field.textarea-so-terms textarea,
  .invoice-popup-main .middle-row textarea,
  .order-no-set,
  .invoice-popup-main .form-field.inline-field,
  .invoice-popup-main .form-field.inline-field,
  .so-invoice-popup .form-field.inline-field.invoice-term-set,
  .create-invoice-sec .form-field.inline-field .form-input.large,
  .create-invoice-sec .form-field.inline-field .searchable-dropdown.large {
    width: 100% !important;
  }

  .create_invoice tbody tr td.no-padding:nth-child(1),
  .create_invoice thead tr th:nth-child(1) {
    width: 26% !important;
  }

  .create_invoice tbody tr td.no-padding:nth-child(5) {
    /* width: 17% !important; */
    font-size: 12px !important;
  }

  .create_invoice tbody tr td.no-padding:nth-child(5) div {
    font-size: 12px;
  }

  .create_invoice thead tr th:nth-child(2) {
    padding-left: 14px !important;
  }

  .invoice-main-set.invoices-popup-set button.view-details-btn {
    right: 0 !important;
    left: auto;
  }

  .form>.invoice-main-set~.form-field.inline-field:nth-child(8),
  .form>.invoice-main-set~.form-field.inline-field:nth-child(9) {
    margin-top: 0;
  }

  .UIUX-invoice-popup .invoice-table-set .no-discount-table th:nth-child(1) {
    width: 33% !important;
  }

  html body .uiux_invoice_edit_form.create_invoice th:nth-child(2) {
    width: 20% !important;
  }

  /* html body .uiux_invoice_edit_form.create_invoice.discount-added th:nth-child(2) {
    width: 18.6% !important;
  } */

  html body .so-invoice-popup .middle-row .inline-terms-and-conditions {
    padding: 0;
  }

  html body .no-discount-table.horizontal-items-table th:first-child {
    width: 33% !important;
  }

  .uiux-invoice-form .tablet-align {
    flex-wrap: wrap;
  }
  .uiux-invoice-form-main .uiux-invoice-form .invoice-tbl .error .input-feedback{
    bottom: -7px !important;
  }
  .error.invoice-form-details .input-feedback{
    bottom: -0.8rem;
  }
}

.invoice-form-items-action {
  display: flex;
}
.invoice-term-set {
  margin-top: 11px;
}