div.update-organization-component .timezone-picker {
  display: block !important;
}

div.update-organization-component .timezone-picker-textfield input {
  border-color: #ccc;
  border-radius: 4px;
}

.update-organization-component {
  width: 75%;
  margin: 5rem auto;
}
.left__{
  position: absolute;
  left: 0px;
  padding: 0 var(--padding-horizontal)
}