.row_lable {
  margin: 0px;
  margin-top: 16px;
  /*margin-bottom: 5px;*/
  /*padding-bottom: 10px;*/
}

.topci_laebl {
  min-width: 160px;
  color: #888;
}

div.item-view {
  padding-bottom: 25px;
}

.field-row {
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  width: 66%;
}

.heading_font {
  font-size: 1.4rem !important;
}

.bottom_line {
  border-top: 2px solid #F4F5F7 !important;
}

.adjustment_summary_update .field-row {
  float: left;
  width: 100%;
  line-height: 34px;
}

.adjustment_summary_update .close_icon {
  height: auto;
  top: 10px;
}

.adjustment_summary_update .section-details--info {
  float: left;
  width: 64%;
  padding: 10px 20px;
  line-height: 30px;
}

.adjustment_details {
  float: left;
  width: 100%;
}

.adjustment_details .section-details--info {
  width: 75% !important;
}

.adjustment_summary_update .capitalize {
  word-break: break-word;
}

/* .adjustment_summary_update .section-details--info{float: left;width: 100%;padding: 10px 20px;} */

.adjustment_summary_update .section-details--info hr {
  float: left;
  width: 100%;
}

.adjustment_summary_update .section-details--info .field-row div:first-child {
  float: left;
  width: 30%;
}

.adjustment_summary_update .section-details--info .field-row div:last-child {
  float: left;
  width: 70%;
}

div.item-info img.item-image {
  display: inline-block;
  height: 2em;
  border-radius: 3px;
  margin-right: 5px;
  max-width: 40px;
  max-height: 35px;
}

.item-adjust--table {
  margin: 3rem 0;
}

.items-adjust-exp-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
}

.icon_top {
  top: 6px !important;
}

.items-adjust-exp-overview-row {
  display: grid;
  grid-template-columns: 17rem 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;
}

.items-adjust-exp-overview-group {
  font-size: 1.2rem;
  color: #696969;
}

.items-adjust-exp-table-summary--row {
  display: grid;
  grid-template-columns: 5rem minmax(15rem, 4fr) minmax(9rem, auto);
  grid-gap: 1rem;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
}

.items-adjust-exp-table-summary--row-image {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.items-adjust-exp-table-summary--row-image img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
}

.itemstab {
  width: 78%;
  /* padding: 10px 20px; */
  overflow: auto;
  /* max-height: calc(100vh - 259px); */
  padding: 0 !important;
  max-height: 50%;
}

.linked-row th.right-align {
  padding-right: 3%;
  text-align: right !important;
}

.listtable tbody tr td:nth-child(4) {
  max-width: 35rem;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.listtable tbody tr td:nth-child(4) .list-table--link {
  word-break: unset;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 92%;
}

.listtable tbody tr td:nth-child(2) div {
  max-width: 27rem;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.listtable.listable_item_adjustment tbody tr td:nth-child(2) {
  width: 25%;
}

.listtable.listable_item_adjustment tbody tr td:nth-child(2) div {
  max-width: 100%;
  padding: 0.8rem 0.6rem 0.8rem 0;
  word-break: break-word;
}

.listtable.listable_item_adjustment tbody tr td:nth-child(3) {
  width: 15%;
  padding-right: 10px !important;
}

.listtable.listable_item_adjustment tbody tr td:nth-child(4) {
  width: 30%;
  padding: 0 10px 0 0;
}

@media(max-width:1199px) {
  .create-adjustment .form-field.inline-field {
    width: 49%;
    margin-right: 9px;
  }

  .create-adjustment .form-field.inline-field .form-input.large, .create-adjustment .form-field.inline-field .searchable-dropdown {
    width: 100% !important;
  }

  .images-uploading .form-field {

    width: auto !important;
  }

  html body .adjustment_summary_update .section-details--info,
  .item_adjustment_table {
    width: 90% !important;
  }
}

@media(max-width:1024px) {
  html body .itemstab {
    width: 100% !important;
    padding-right: 18px !important;
  }

  .adjustment_summary_update {
    padding-left: 20px;
  }

  html body .adjustment_summary_update .section-details--info, .item_adjustment_table {
    width: 100%;
  }
}

@media(max-width:992px) {
  .create-adjustment .form-field.inline-field {
    width: 100%;
  }
}

@media(max-width:768px) {
  .item-adjustment-main .dropdwon-width-bill {
    display: flex;
    flex-wrap: wrap;
  }

  .item-adjustment-main .dropdwon-width-bill .form-field input {
    width: 100%;
  }

  .item-adjustment-main .dropdwon-width-bill .searchable-dropdown.large {
    width: 100% !important;
  }

  .dropdwon-width-bill .inline-field {
    width: 225px !important;
  }

  .item_adjustment_table {
    width: 100% !important;
  }
}