.existing_items{
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
}
.select-product{
  flex:0 0 45%;
  margin-right: 10px;
}
.select-brand{
 display: flex;
 flex: 0 0 53.5%;
}
.select-brand .css-2b097c-container{
  flex: 0 0 70%;
  margin-right: 10px;
}
.select-brand input[type="number"]{
  width: 100%;
  max-width: 135px;
  padding-right: 10px;
}
.confirm-item{
  display: flex;
  margin:15px 0 0;

}
.confirm-item .css-2b097c-container{
  flex: 0 0 62.5%;
}
.confirm-item input[type="number"]{
  max-width: 150px;
  padding-right: 10px;
  margin: 0 10px;
  width: 100%;
}
