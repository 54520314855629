.reports--body {
  float: left;
  width: 100%;
}

.reports--organization-info h4.report-organization {
  font-size: 15px;
}

.inventory_reports_section .info_section > .float-left {
  width: 70%;
}

.reports--organization-info {
  /* padding: 0 var(--padding-horizontal);
  padding-top: var(--padding-vertical); */
  width: 100%;
}

.reports--organization-title {
  display: flex;
  align-items: center;
  word-break: break-all;
}

.reports_image_circle {
  height: 8rem;
  width: 8rem;
  border-radius: 4rem;
  border: 1px solid #dfe1e5;
  padding: 0 10px;
  margin-right: 10px;
  background-color: #dfe1e5;
  overflow: hidden;
}

.reports--organization-title img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.reports--organization-info h5 {
  word-break: break-all;
}

.red {
  color: red;
}

.reports--title {
  margin: 3rem 0;
}

/* .reports--filters-container {
  display: grid;
  grid-template-columns: 1fr auto;
}

.reports--filter {
  position: relative;
  display: grid;
  grid-template-columns: minmax(100px, max-content) repeat(auto-fit, minmax(100px, max-content));
  grid-gap: 0.8rem;
  padding: var(--padding);
  align-items: center;
} */
.reports--filter {
  display: flex;
  align-items: center;
  height: 78px;
}

.reports--filter .date-picker--body {
  right: 0 !important;
}

.reports--filter .date-range-picker {
  right: 0 !important;
  left: unset !important;
}

.reports--filter.single-date {
  grid-template-columns: auto auto auto auto;
}

.reports--filter .rdrDateRangePickerWrapper .rdrDateDisplay {
  background-color: unset;
}

.reports--filter .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  width: 150px;
}

.filter--dropdown {
  width: 100%;
}

.filter--dropdown .custom-dropdown.button-text .button {
  font-size: 1.4rem;
  margin: 0;
  min-width: 10rem;
}

.filter--dropdown .custom-dropdown.button-text .custom-dropdown-container {
  left: 0;
}

.filter--items-picker {
  display: grid;
  grid-template-columns: auto 1fr;
}

.report-filter--btn {
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  margin-left: 0.5rem;
  line-height: normal;
}

.report-filter--btn svg {
  margin-right: 0.8rem;
  height: 1.6rem;
  width: 1.6rem;
}

.report-filter--btn svg path,
.report-filter--btn svg circle {
  fill: var(--icon-primary);
}

.report--table {
  margin: 0;
}

.report--table .table-header {
  background-color: var(--background);
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
}

.report--table th:first-child,
.report--table tr:not(.spanned) td:first-child {
  padding-left: var(--padding-horizontal);
}

.report--table th:last-child,
.report--table td:last-child {
  padding-right: var(--padding-horizontal);
}

.report--table thead tr:first-child {
  border-bottom: 1px solid var(--border-dark);
}

.report--filter-modal .column {
  justify-content: flex-start;
}

.report--table td {
  word-break: break-word !important;
}

.custom_report {
  font-size: 13px !important;
  height: 3.2rem !important;
  width: 276px !important;
}

/* .payment-report-main tfoot tr {
  width: 100%;
} */

/* .hassan {
  float: left;
  width: 100%;
}
.hassan tr {
  float: left;
  width: 100%;
}
.hassan tr th:first-child  {
  float: left;
}
.hassan tr th:first-child  {
  float: right;
}

.sss .table-header{
  float: left;
  width: 100%;
}
.sss .table--row{
  float: left;
  width: 100%;
}
.sss .table--row th:first-child{
  float: left;
}
.sss .table--row th:last-child{
  float: right !important;
}
.sss .table--row td:first-child{
  float: left;
}
.sss .table--row td:last-child{
  float: right;
} */
.inventory_reports_section {
  height: calc(100vh - 50px);
  overflow: hidden;
}

.inventory_reports_section .info_section {
  padding: 20px 2%;
}

.inventory_listing_Table_inner thead {
  border-top: 2px solid #306ab4 !important;
  border-bottom: 0 !important;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
}

.warehouse_report_table
  .reports--body
  .inventory_listing_Table_inner
  thead.hassan {
  border-top: 0px solid #306ab4 !important;
}

.inventory_listing_Table_inner thead {
  float: left;
  width: 100%;
  padding-left: 10px;
  background-color: #fff !important;
}

.inventory_listing_Table_inner tbody {
  float: left;
  width: 100%;
  padding-left: 10px;
  background-color: transparent !important;
}

.inventory_listing_Table_inner tbody tr td {
  background-color: transparent !important;
}

.inventory_listing_Table_inner tbody tr:nth-child(even) {
  background-color: #fcfcf9 !important;
}

.inventory_listing_Table_inner tbody tr td:first-child {
  padding-left: 8px !important;
}

.inventory_listing_Table_inner thead {
  position: sticky;
  top: 0;
  z-index: 9;
}

.inventory_listing_Table_inner thead tr,
.inventory_listing_Table_inner tbody tr {
  float: left;
  width: 100%;
  background-color: #fff !important;
}

.inventory_listing_Table_inner tr th:nth-child(4) {
  padding-right: 0 !important;
}

.inventory_listing_Table_inner thead tr th {
  float: left;
  width: 12.5%;
  background-color: #fff !important;
  line-height: 1.42857143 !important;
}

.inventory_listing_Table_inner tbody tr td {
  float: left;
  width: 12.5%;
  /* background-color: #FFF !important; */
}

.fifo_Cost thead tr th {
  /* float: left;
  width: 12.5%; */
  background-color: #fff !important;
  line-height: 1.42857143 !important;
}

.fifo_Cost tbody tr td {
  /* float: left;
  width: 12.5%; */
  /* background-color: #FFF !important;
  line-height: 1.42857143 !important;*/
}

.fifo_Cost thead tr:first-child th:first-child {
  background-color: #dfe1e5 !important;
  font-weight: bold;
  width: 63% !important;
}

.fifo_Cost thead tr:first-child th:last-child {
  background-color: #e9e9e9 !important;
  font-weight: bold;
  width: 37% !important;
}

.fifo_Cost thead tr:first-child {
  border-bottom: 0 !important;
  position: relative;
}

.fifo_Cost thead tr:first-child:after {
  background-color: #306ab4;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  float: left;
  width: 100%;
}

.fifo_Cost thead tr:last-child {
  border-top: 2px solid #306ab4 !important;
  border-bottom: 0 !important;
}

.fifo_Cost tbody tr {
  border-bottom: 1px solid var(--border-light) !important;
}

.fifo_Cost thead tr th.add-border,
.fifo_Cost tbody tr td.add-border {
  border-right: 1px solid var(--border-light) !important;
}

.fifo_Cost tbody tr td.add-border {
  line-height: inherit;
}

.fifo_Cost thead tr th,
.fifo_Cost tbody tr td {
  border: 0 !important;
}

.fifo_Cost thead {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  z-index: 9;
  background-color: transparent !important;
  border-top: none !important;
  border-bottom: none !important;
}

.fifo_Cost tbody tr:nth-child(even) {
  background-color: #fcfcf9 !important;
}

.warehouse_report_table table tbody tr {
  border-top: 1px solid var(--border-light) !important;
}

.warehouse_report_table table tbody tr.spanned {
  border-top: 1px solid transparent !important;
  /* background-color: transparent !important; */
}

.warehouse_report_table table tbody tr.spanned td {
  border-top: 1px solid var(--border-light) !important;
}

.warehouse_report_table table thead tr th,
.warehouse_report_table table tbody tr td {
  border: 0 !important;
}
.inventory_reports_section .top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inventory_reports_section .top-header .center,
.inventory_reports_section .top-header span.layout--title {
  width: 100%;
  flex: 0 0 50%;
}

.inventory_reports_section .top-header .center {
  display: flex;
  justify-content: flex-end;
}

.reports--body {
  /* height: calc(100vh - 227px);
  overflow: auto; */
}

/* .report--table.inventory_listing_Table_inner th:first-child, .report--table.inventory_listing_Table_inner tr:not(.spanned) td:first-child{
  padding-left:2% !important;
} */
.inventory_listing_Table_inner tfoot {
  float: left;
  width: 100%;
  border-top: 1px solid var(--border-dark);
  /* padding: 8px !important; */
  /* margin-bottom: 20px; */
}

.inventory_listing_Table_inner tfoot tr {
  float: right;
}

.inventory_listing_Table_inner tfoot tr td {
  /* float:left;
  width:100%; */
  border-top: 0 !important;
}

.warehouse_report_table .reports--body {
  height: calc(100vh - 222px);
  /* overflow: auto; */
  border-top: 2px solid #306ab4 !important;
}

.warehouse_report_table .reports--body > table {
  min-width: 1024px;
}

.inventory_valution_summary .inventory_listing_Table_inner thead tr th,
.inventory_valution_summary .inventory_listing_Table_inner tbody tr td {
  float: left;
  width: 25%;
  /* background-color: #fff !important; */
}

/* .inventory_valution_summary .inventory_listing_Table_inner thead tr th:nth-child(2) , .inventory_valution_summary .inventory_listing_Table_inner tbody tr td:nth-child(2){
  text-align: center;
}*/
.inventory_valution_summary
  .inventory_listing_Table_inner
  tbody
  tr
  td:nth-child(3) {
  padding-left: 5%;
}

.inventory_valution_summary
  .inventory_listing_Table_inner
  thead
  tr
  th:nth-child(4),
.inventory_valution_summary
  .inventory_listing_Table_inner
  tbody
  tr
  td:nth-child(4) {
  text-align: right;
}

.inventory_valution_summary
  .inventory_listing_Table_inner
  thead
  tr
  th:nth-child(4) {
  /* text-align: right; */
  padding-right: 25px !important;
}

.salesorder_report table thead tr th,
.salesorder_report table tbody tr td {
  width: 16.66% !important;
}

.packing_report_table table thead tr th,
.packing_report_table table tbody tr td {
  width: 20% !important;
}

.salesreturn_report table thead tr th,
.salesreturn_report table tbody tr td {
  width: 20% !important;
}

.purchase_order_report table thead tr th,
.purchase_order_report table tbody tr td {
  width: 16.66% !important;
}

.recieve_report__ table thead tr th,
.recieve_report__ table tbody tr td {
  width: 16.66% !important;
}

.recieve_report__ table thead tr th:nth-child(6),
.recieve_report__ table tbody tr td:nth-child(6) {
  text-align: right;
}

.bill__report thead tr th,
.bill__report table tbody tr td {
  width: 14.28% !important;
}

.payemntmadereport thead tr th,
.payemntmadereport table tbody tr td {
  width: 11.66%;
}

.payemntmadereport thead tr th:last-child,
.payemntmadereport table tbody tr td:last-child {
  width: 30%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 500px;
}

.inventory_reports_section .top-header {
  position: relative;
}

.inventory_reports_section .top-header span h3 {
  font-weight: 700 !important;
}

.inventory_reports_section .top-header span h3 span {
  font-weight: 700 !important;
  color: #000000 !important;
}

.reports_close {
  top: 50px !important;
  right: 4rem !important;
}

.reports_close svg {
  fill: #000;
  height: 20px;
  width: 20px;
}

.padding-bottom-J {
  padding-bottom: 25px;
}

.inventory_details_reports table thead tr th,
.inventory_details_reports table tbody tr td {
  width: 11.42857142857143%;
  float: left;
}

.inventory_details_reports table thead tr th:first-child,
.inventory_details_reports table tbody tr td:first-child {
  width: 20%;
}

.inventory_valution_summary table tbody tr td img {
  margin-right: 10px;
}

.warehouse_report_table table thead,
.warehouse_report_table table tbody {
  float: revert;
}

.warehouse_report_table table thead tr,
.warehouse_report_table table tbody tr {
  width: 100%;
  float: revert;
}

.warehouse_report_table
  table.inventory_listing_Table_inner
  thead
  tr
  th:first-child {
  padding-left: 2% !important;
}

.warehouse_report_table
  table.inventory_listing_Table_inner
  tbody
  tr
  td:first-child {
  padding-left: 2% !important;
}

.warehouse_report_table
  table.inventory_listing_Table_inner
  tbody
  tr.spanned
  td:first-child {
  padding-left: 8px !important;
}

.warehouse_report_table table thead {
  /*border-top:5px solid #306ab4 !important;
  position: sticky;*/
  top: 0;
  z-index: 9;
}

.warehouse_report_table table thead tr th,
.warehouse_report_table table tbody tr td {
  width: 11.42857142857143%;
  float: revert !important;
}

.warehouse_report_table table thead tr th:first-child,
.warehouse_report_table table tbody tr td:first-child {
  width: 20%;
}

.warehouse_report_table table tbody tr td img {
  margin-right: 20px;
}

.warehouse_report_table table tbody tr.spanned td:first-child {
  margin-left: 35%;
}

.warehouse_report_table table tbody tr td.right-align {
  text-align: right;
}

/* .inventory_details_reports table tbody tr td .list-table--item--col span{
  width: 200px !important;
} */
/* .warehouse_report_table table tbody tr td .list-table--item--col span{
  width: 200px !important;
}
.inventory_valution_summary table tbody tr td .list-table--item--col span{
  width: 200px !important;
} */
.inventory_reports_section .ascending:after {
  content: '' !important;
}

.inventory_reports_section .ascending svg {
  height: 10px !important;
  width: 10px !important;
  position: relative !important;
  top: -2px !important;
  left: 10px !important;
  fill: #33a348;
}

.inventory_reports_section .ascending {
  background-color: #f9f9fa !important;
  color: #306ab4;
}

.inventory_reports_section .descending:after {
  content: '' !important;
}

.inventory_reports_section .descending svg {
  height: 10px !important;
  width: 10px !important;
  position: relative !important;
  /* top: -2px !important; */
  left: 10px !important;
  fill: #33a348;
}

.inventory_reports_section .descending {
  background-color: #f9f9fa !important;
  color: #306ab4;
}

.customer-report-main th:nth-child(2),
.customer-report-main td:nth-child(2) {
  text-align: center;
}

.salesorder_report table thead tr th:nth-child(5),
.salesorder_report table tbody tr td:nth-child(5) {
  text-align: center;
}

.sales-by-item th:nth-child(3),
.sales-by-item td:nth-child(3),
.customer-report-main th:nth-child(3),
.customer-report-main td:nth-child(3),
.salesorder_report table thead tr th:last-child,
.salesorder_report table tbody tr td:last-child {
  text-align: right;
}

.inventory_reports_section tfoot tr td:first-child {
  padding-right: 80px !important;
}

.invoice_report_table table thead tr th:last-child,
.invoice_report_table table tbody tr td:last-child {
  text-align: right;
}

.invoice_report_table table thead tr th:nth-child(7),
.invoice_report_table table tbody tr td:nth-child(7) {
  text-align: right;
}

.payment_recived_report table thead tr th:last-child,
.payment_recived_report table tbody tr td:last-child {
  text-align: right;
}

.packing_report_table table thead tr th:last-child,
.packing_report_table table tbody tr td:last-child {
  text-align: right;
}

.salesreturn_report table thead tr th:last-child,
.salesreturn_report table tbody tr td:last-child {
  text-align: right;
}

.purchase_order_report table thead tr th:last-child,
.purchase_order_report table tbody tr td:last-child {
  text-align: right;
}

.recieve_report__ table thead tr th:last-child,
.recieve_report__ table tbody tr td:last-child {
  text-align: right;
}

.bill__report table thead tr th:last-child,
.bill__report table tbody tr td:last-child {
  text-align: right;
}

.payemntmadereport table thead tr th:last-child,
.payemntmadereport table tbody tr td:last-child {
  text-align: right;
}

.invoice_report_table tfoot tr {
  float: left;
  width: 100%;
}

.invoice_report_table thead tr th:nth-child(6) {
  padding-left: 3rem !important;
}

.invoice_report_table tfoot tr td:first-child {
  width: 82%;
  float: left;
  padding-right: 5.5% !important;
  text-align: right;
  padding-left: 0 !important;
}

.invoice_report_table tfoot tr td:nth-child(2) {
  width: 8%;
  float: left;
  text-align: left !important;
  /* padding-left: 1%; */
}

.invoice_report_table tfoot tr td:last-child {
  width: 9.5%;
  float: right;
  text-align: right !important;
  /* padding-left: 1%; */
}

/* Payment receive report */
.payment_recived_report tfoot.table--bottom-row tr {
  width: 100%;
  float: left;
}

.payment_recived_report tfoot.table--bottom-row td:first-child {
  width: 78%;
  float: left;
  padding-right: 5.5% !important;
  text-align: right;
  padding-left: 0 !important;
}

.payment_recived_report tfoot.table--bottom-row td:nth-child(2) {
  width: 8%;
  float: left;
  /* text-align: left !important; */
}

.payment_recived_report tfoot.table--bottom-row td:nth-child(3) {
  width: 9.5%;
  float: right;
}
@media (max-width: 1700px) {
  .inventory_reports_section
    .top-header
    .center
    .searchable-dropdown
    .searchable-dropdown__value-container {
    font-size: 12px;
    padding: 0 0.6rem;
    display: flex;
    align-items: center;
    height: 100%;
  }
  .inventory_reports_section
    .top-header
    .center
    .searchable-dropdown
    .searchable-dropdown__indicators {
    width: 15px;
    margin-right: 4px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inventory_reports_section
    .searchable-dropdown__indicator.searchable-dropdown__dropdown-indicator.css-tlfecz-indicatorContainer {
    width: 18px;
  }
}
@media screen and (max-width: 1139px) {
  .invoice_report_table tfoot tr td:first-child {
    width: 76%;
  }

  .invoice_report_table tfoot tr td:nth-child(2) {
    width: 12%;
    text-align: right !important;
  }

  .invoice_report_table tfoot tr td:last-child {
    width: 12%;
  }
}

.bill__report tfoot tr {
  float: left;
  width: 100%;
}

.bill__report tfoot tr td:first-child {
  width: 82%;
  float: left;
  padding-right: 11% !important;
}

.bill__report tfoot tr td:nth-child(2) {
  width: 8%;
  float: left;
  text-align: left !important;
  padding-left: 1%;
  margin-left: 5px;
}

.bill__report tfoot tr td:last-child {
  width: 8%;
  float: right;
  text-align: right !important;
}

.bill__report table thead tr th:nth-child(6),
.bill__report table tbody tr td:nth-child(6) {
  text-align: right;
}

.inventory_valution_summary .reports--body {
  height: auto;
}

.sales-by-customer th:first-child,
.sales-by-customer td:first-child {
  width: 48% !important;
}

.sales-by-customer th:nth-child(2),
.sales-by-customer td:nth-child(2) {
  width: 12% !important;
}

.sales-by-customer th:nth-child(3),
.sales-by-customer td:nth-child(3) {
  width: 10% !important;
}

.sales-by-customer th:nth-child(4),
.sales-by-customer td:nth-child(4) {
  width: 20% !important;
  text-align: right;
}

.sales-by-customer.committed-stock tfoot tr td:nth-child(2) {
  width: 14% !important;
  float: left;
}

.sales-by-customer tfoot tr {
  width: 100%;
  float: right;
}

.sales-by-customer tfoot tr td:first-child {
  width: 76% !important;
  float: left;
  padding-right: 0% !important;
}

.sales-by-customer tfoot tr td:nth-child(2) {
  width: 9.2% !important;
  float: left;
}

.sales-by-customer tfoot tr td:nth-child(3) {
  width: 14% !important;
  float: left;
  padding-right: 1rem !important;
}

.sales-by-customer th:nth-child(4),
.sales-by-customer td:nth-child(4),
.sales-by-customer th:nth-child(5),
.sales-by-customer td:nth-child(5) {
  width: 15% !important;
}

.order-fulfillment-by-item th:first-child,
.order-fulfillment-by-item td:first-child {
  width: 20% !important;
}

.order-fulfillment-by-item th:nth-child(2),
.order-fulfillment-by-item td:nth-child(2),
.order-fulfillment-by-item th:nth-child(3),
.order-fulfillment-by-item td:nth-child(3),
.order-fulfillment-by-item th:nth-child(4),
.order-fulfillment-by-item td:nth-child(4),
.order-fulfillment-by-item th:nth-child(5),
.order-fulfillment-by-item td:nth-child(5),
.order-fulfillment-by-item th:nth-child(6),
.order-fulfillment-by-item td:nth-child(6),
.order-fulfillment-by-item th:nth-child(7),
.order-fulfillment-by-item td:nth-child(7),
.order-fulfillment-by-item th:nth-child(8),
.order-fulfillment-by-item td:nth-child(8),
.order-fulfillment-by-item th:nth-child(9),
.order-fulfillment-by-item td:nth-child(9),
.order-fulfillment-by-item th:nth-child(10),
.order-fulfillment-by-item td:nth-child(10) {
  width: 8.5% !important;
  text-align: right !important;
}

.committed-stock th:first-child,
.committed-stock td:first-child {
  width: 20% !important;
}

.committed-stock td:first-child {
  margin-left: 1% !important;
}

.committed-stock th:nth-child(2),
.committed-stock td:nth-child(2) {
  width: 50% !important;
}

.committed-stock td:nth-child(2) {
  margin-left: -1% !important;
}

.committed-stock th:nth-child(3),
.committed-stock td:nth-child(3) {
  width: 20% !important;
  text-align: right;
}

.product-sales th:first-child,
.product-sales td:first-child {
  width: 36% !important;
}

.product-sales th:nth-child(2),
.product-sales td:nth-child(2),
.product-sales th:nth-child(3),
.product-sales td:nth-child(3),
.product-sales th:nth-child(4),
.product-sales td:nth-child(4),
.product-sales th:nth-child(5),
.product-sales td:nth-child(5),
.product-sales th:nth-child(6),
.product-sales td:nth-child(6),
.product-sales th:nth-child(7),
.product-sales td:nth-child(7) {
  width: 12% !important;
}

.product-sales th:nth-child(3),
.product-sales td:nth-child(3),
.product-sales th:nth-child(4),
.product-sales td:nth-child(4),
.product-sales th:nth-child(5),
.product-sales td:nth-child(5),
.product-sales th:nth-child(6),
.product-sales td:nth-child(6),
.product-sales th:nth-child(7),
.product-sales td:nth-child(7) {
  text-align: right;
}

.product-sales tfoot tr {
  float: none !important;
  display: flex;
}

.product-sales tfoot tr td:first-child {
  width: 60.2% !important;
  padding-right: 0% !important;
}

.product-sales tfoot tr td:nth-child(2),
.product-sales tfoot tr td:nth-child(3),
.product-sales tfoot tr td:nth-child(4) {
  width: 12% !important;
}

.active-purchase-orders th:first-child,
.active-purchase-orders td:first-child {
  width: 55% !important;
}

.active-purchase-orders th:nth-child(2),
.active-purchase-orders td:nth-child(2),
.active-purchase-orders th:nth-child(3),
.active-purchase-orders td:nth-child(3),
.active-purchase-orders th:nth-child(4),
.active-purchase-orders td:nth-child(4) {
  width: 14% !important;
}

.active-purchase-orders th:nth-child(3),
.active-purchase-orders td:nth-child(3),
.active-purchase-orders th:nth-child(4),
.active-purchase-orders td:nth-child(4) {
  text-align: right;
}

.active-purchase-orders tfoot tr {
  float: none !important;
  display: flex;
}

.active-purchase-orders tfoot tr td:first-child {
  width: 69.2% !important;
  padding-right: 0% !important;
}

.active-purchase-orders tfoot tr td:nth-child(2),
.active-purchase-orders tfoot tr td:nth-child(3) {
  width: 14% !important;
}

.report-organization {
  font-weight: 700;
  margin-bottom: 5px;
  word-break: break-word;
}

.organization-label {
  font-weight: 300;
}

.uiux_organization-main {
  position: relative;
}

.uiux_organization-main span.organization-label {
  position: relative;
  right: 0;
  top: 0;
}

.searchable-dropdown-height .searchable-dropdown {
  height: auto;
}

.inventory_details_reports table thead tr th {
  text-align: right;
}

.inventory_details_reports table thead tr th:first-child,
.inventory_details_reports table thead tr th:nth-child(2) {
  text-align: left;
}

.inventory_details_reports table tbody tr td {
  text-align: right;
}

.inventory_details_reports table tbody tr td:first-child,
.inventory_details_reports table tbody tr td:nth-child(2) {
  text-align: left;
}

.inventory_reports_section
  .pagination-container
  .page-number-container
  .left_arrow {
  margin-right: 10px;
}

.fifo_Cost thead tr th:first-child,
.fifo_Cost tbody tr td:first-child {
  width: 7% !important;
}
.fifo_Cost thead tr th:nth-child(2),
.fifo_Cost tbody tr td:nth-child(2) {
  width: 8% !important;
}
.fifo_Cost thead tr th:nth-child(3),
.fifo_Cost tbody tr td:nth-child(3) {
  width: 8% !important;
}
.fifo_Cost thead tr th:nth-child(4),
.fifo_Cost tbody tr td:nth-child(4) {
  width: 9% !important;
}
.fifo_Cost thead tr th:nth-child(5),
.fifo_Cost tbody tr td:nth-child(5) {
  width: 14% !important;
  text-align: left;
  padding-left: 2%;
}

.fifo_Cost thead tr th,
.fifo_Cost tbody tr td {
  width: 9% !important;
}

.UIUX_report_table_inner tfoot.table--bottom-row.bold td:last-child {
  padding-right: 2.4rem;
}

.nine-col thead tr th,
.nine-col tbody tr td {
  width: 11.1%;
}

/* Sales by person  */
.salesreturn_report .sales-by-sales-by-sales-person thead tr th,
.salesreturn_report .sales-by-sales-by-sales-person tbody tr td {
  width: 11% !important;
}

.salesreturn_report .sales-by-sales-by-sales-person tfoot tr td,
.salesreturn_report
  .sales-by-sales-by-sales-person
  thead
  tr
  th:not(:first-child),
.salesreturn_report
  .sales-by-sales-by-sales-person
  tbody
  tr
  td:not(:first-child) {
  text-align: right !important;
}

.salesreturn_report .sales-by-sales-by-sales-person thead th:nth-child(1),
.salesreturn_report .sales-by-sales-by-sales-person tbody td:nth-child(1) {
  width: 16% !important;
}

.salesreturn_report .sales-by-sales-by-sales-person thead th:nth-child(2),
.salesreturn_report .sales-by-sales-by-sales-person tbody td:nth-child(2),
.salesreturn_report .sales-by-sales-by-sales-person thead th:nth-child(3),
.salesreturn_report .sales-by-sales-by-sales-person tbody td:nth-child(3),
.salesreturn_report .sales-by-sales-by-sales-person thead th:nth-child(5),
.salesreturn_report .sales-by-sales-by-sales-person tbody td:nth-child(5),
.salesreturn_report .sales-by-sales-by-sales-person thead th:nth-child(6),
.salesreturn_report .sales-by-sales-by-sales-person tbody td:nth-child(6) {
  width: 10% !important;
}

/* .salesreturn_report .sales-by-sales-by-sales-person tfoot td:first-child {
  width: 311px;
  padding-left: 3px !important;
  padding-right: 0 !important;
} */
/* .slide-in + .app-content.scrollbar_style .salesreturn_report .sales-by-sales-by-sales-person tfoot td:first-child {
  width: 328px;
}


.slide-in + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(2){
  width: 150px;
}
.slide-in + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(3){
  width: 179px;
}
.slide-in + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(4){
  width: 190px;
}
.slide-in + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(5){
  width: 174px;
  padding-right: 4px;
}
.slide-in + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(6){
  width: 178px;
}
.slide-in + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(7){
  width: 195px;
}
.slide-in + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(8){
  width: 187px;
  padding-right:0px;
} */
/* .salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(9) {padding-right: 2.3rem !important;}
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(2){
  width: 184px;
}
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(3){
  width: 182px;
}
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(4){
  width: 208px;
}
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(5){
  width: 185px;
}
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(6){
  width: 186px;
}
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(7){
  width: 204px;
}
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(8){
  width: 204px;
} */
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(9) {
  /* width: 204px; */
  padding-right: 1.9rem;
}

.salesreturn_report .sales-by-sales-by-sales-person tfoot td:first-child {
  width: 17%;
  padding-left: 3px !important;
  padding-right: 0 !important;
}

.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(6),
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(5),
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(2) {
  width: 10%;
}

.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(2) {
  padding-right: 16px;
}

.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(3) {
  width: 184px;
  padding-right: 0;
}

.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(5),
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(6) {
  padding-right: 2px;
}

.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(9),
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(8),
.salesreturn_report
  .sales-by-sales-by-sales-person
  tfoot
  td:nth-child(9)
  .salesreturn_report
  .sales-by-sales-by-sales-person
  tfoot
  td:nth-child(8),
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(7),
.salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(4) {
  width: 11%;
}

.salesreturn_report .sales-by-sales-by-sales-person tfoot tr {
  width: 100%;
}

/* .salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(9) {width: 199px;} */
/* .sales-by-sales-by-sales-person th:nth-child(1),
.sales-by-sales-by-sales-person td:nth-child(1) {
  width: 11% !important;
}


.sales-by-sales-by-sales-person th:nth-child(2),
.sales-by-sales-by-sales-person td:nth-child(2), 
.sales-by-sales-by-sales-person th:nth-child(3),
.sales-by-sales-by-sales-person td:nth-child(3),
.sales-by-sales-by-sales-person th:nth-child(5),
.sales-by-sales-by-sales-person td:nth-child(5),
.sales-by-sales-by-sales-person th:nth-child(6),
.sales-by-sales-by-sales-person td:nth-child(6)
{
  width: 7% !important;
}
.sales-by-sales-by-sales-person th:nth-child(7),
.sales-by-sales-by-sales-person td:nth-child(7){
  width: 10% !important;
}
.sales-by-sales-by-sales-person th:nth-child(8),
.sales-by-sales-by-sales-person td:nth-child(8){
  width: 10% !important;
}
.sales-by-sales-by-sales-person th:nth-child(9),
.sales-by-sales-by-sales-person td:nth-child(9){
  width: 12%;
}
.sales-by-sales-by-sales-person th:nth-child(4) {
  width: 11% !important;
} */

/* Refund report css */
.refund_report .inventory_listing_Table_inner thead th:nth-child(1),
.refund_report .inventory_listing_Table_inner tbody td:nth-child(1) {
  width: 6%;
}

.refund_report .inventory_listing_Table_inner th:nth-child(2),
.refund_report .inventory_listing_Table_inner tbody td:nth-child(2) {
  width: 9%;
}

.refund_report .inventory_listing_Table_inner th:nth-child(3),
.refund_report .inventory_listing_Table_inner td:nth-child(3) {
  width: 40%;
}

.refund_report .inventory_listing_Table_inner th:nth-child(4),
.refund_report .inventory_listing_Table_inner td:nth-child(4) {
  width: 15%;
}

.refund_report .inventory_listing_Table_inner th:nth-child(6),
.refund_report .inventory_listing_Table_inner td:nth-child(6) {
  width: 20%;
}

.refund_report .inventory_listing_Table_inner th:nth-child(5),
.refund_report .inventory_listing_Table_inner td:nth-child(5) {
  width: 10%;
}

.uiux_bills_report .bill__report tfoot tr td:first-child {
  width: 77.5%;
  padding-right: 0% !important;
}

.uiux_bills_report tfoot tr td:last-child,
.uiux_bills_report tfoot tr td:nth-child(2) {
  padding-right: 2.4rem;
  width: 9%;
  text-align: right !important;
}

.payemntmadereport thead tr th:nth-child(1),
.payemntmadereport table tbody tr td:nth-child(1),
.payemntmadereport thead tr th:nth-child(2),
.payemntmadereport table tbody tr td:nth-child(2) {
  width: 6%;
}

.payemntmadereport thead tr th:nth-child(3),
.payemntmadereport table tbody tr td:nth-child(3) {
  width: 14%;
}

.payemntmadereport thead tr th:nth-child(6),
.payemntmadereport table tbody tr td:nth-child(6),
.payemntmadereport thead tr th:nth-child(4),
.payemntmadereport table tbody tr td:nth-child(4) {
  width: 27%;
}

.payemntmadereport thead tr th:nth-child(7),
.payemntmadereport table tbody tr td:nth-child(7),
.payemntmadereport thead tr th:nth-child(5),
.payemntmadereport table tbody tr td:nth-child(5) {
  width: 10%;
}

/* Invoice report */
.payment-receive-report th:nth-child(8),
.payment-receive-report td:nth-child(8),
.invoice_report_table .invoice-report-main tfoot tr td:nth-child(2) {
  text-align: right !important;
}

.invoice_report_table .invoice-report-main tfoot tr td:nth-child(1) {
  width: 80%;
}

.global-report-set tfoot tr td:nth-child(1) {
  width: 80% !important;
}

.global-report-set tfoot tr td:nth-child(2) {
  text-align: right !important;
  width: 9% !important;
}

.payment_recived_report table.refund-report-main tfoot td:first-child {
  width: 90% !important;
  padding-right: 0 !important;
}

.refund-report-main tfoot td:nth-child(2) {
  width: 10% !important;
}
.inventory_valution_summary {
}
@media (max-width: 1720px) {
  .salesreturn_report .sales-by-sales-by-sales-person thead tr th:nth-child(4),
  .salesreturn_report .sales-by-sales-by-sales-person tbody tr td:nth-child(4) {
    width: 15% !important;
  }

  .salesreturn_report .sales-by-sales-by-sales-person thead th:nth-child(1),
  .salesreturn_report .sales-by-sales-by-sales-person tbody td:nth-child(1) {
    width: 12% !important;
  }

  .salesreturn_report .sales-by-sales-by-sales-person tfoot td:first-child {
    width: 15%;
  }

  .salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(2) {
    padding-right: 46px;
  }

  .salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(3) {
    padding-right: 68px;
  }

  .payemntmadereport thead tr th:nth-child(6),
  .payemntmadereport table tbody tr td:nth-child(6),
  .payemntmadereport thead tr th:nth-child(4),
  .payemntmadereport table tbody tr td:nth-child(4) {
    width: 25%;
  }

  .payemntmadereport thead tr th:nth-child(5),
  .payemntmadereport table tbody tr td:nth-child(5) {
    width: 14%;
  }
}

@media (max-width: 1600px) {
  .refund_report .inventory_listing_Table_inner th:nth-child(3),
  .refund_report .inventory_listing_Table_inner td:nth-child(3) {
    width: 35%;
  }

  .refund_report .inventory_listing_Table_inner thead th:nth-child(1),
  .refund_report .inventory_listing_Table_inner tbody td:nth-child(1) {
    width: 8%;
  }

  .refund_report .inventory_listing_Table_inner th:nth-child(2),
  .refund_report .inventory_listing_Table_inner tbody td:nth-child(2) {
    width: 11%;
  }

  .payemntmadereport thead tr th:nth-child(1),
  .payemntmadereport table tbody tr td:nth-child(1),
  .payemntmadereport thead tr th:nth-child(2),
  .payemntmadereport table tbody tr td:nth-child(2) {
    width: 8%;
  }

  .payemntmadereport thead tr th:nth-child(6),
  .payemntmadereport table tbody tr td:nth-child(6) {
    width: 20%;
  }

  .payemntmadereport thead tr th:nth-child(4),
  .payemntmadereport table tbody tr td:nth-child(4) {
    width: 23%;
  }

  .payemntmadereport thead tr th:nth-child(7),
  .payemntmadereport table tbody tr td:nth-child(7) {
    width: 11%;
  }

  .payemntmadereport thead tr th:nth-child(3),
  .payemntmadereport table tbody tr td:nth-child(3) {
    width: 16%;
  }
  .fifo_Cost thead tr th:first-child,
.fifo_Cost tbody tr td:first-child {
  width: 7% !important;
}
.fifo_Cost thead tr th:nth-child(2),
.fifo_Cost tbody tr td:nth-child(2) {
  width: 8% !important;
}
.fifo_Cost thead tr th:nth-child(3),
.fifo_Cost tbody tr td:nth-child(3) {
  width: 10% !important;
}
.fifo_Cost thead tr th:nth-child(4),
.fifo_Cost tbody tr td:nth-child(4) {
  width: 9% !important;
}
.fifo_Cost thead tr th:nth-child(5), .fifo_Cost tbody tr td:nth-child(5){
  width: 12% !important; 
}
}

/* @media screen and (min-width: 1530px) and (max-width: 1580px){
  .slide-out + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(2){
    padding-right: 1px;
  }
} */
@media (max-width: 1580px) {
  .salesreturn_report .sales-by-sales-by-sales-person tfoot td:first-child {
    width: 13.4%;
  }

  .salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(3) {
    padding-right: 4%;
  }

  .reports--body.so-tbletabs.sales-person-report-main {
    overflow: auto;
    display: block;
    max-width: 1566px;
  }

  .reports--body.so-tbletabs.sales-person-report-main table {
    min-width: 1420px;
  }

  .salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(2) {
    padding-right: 20px;
  }

  /* .slide-out + .app-content.scrollbar_style .sales-by-sales-by-sales-person tfoot td:nth-child(2){
    padding-right: 1px;
  } */
  .slide-out
    + .app-content.scrollbar_style
    .sales-by-sales-by-sales-person
    tfoot
    td:nth-child(3) {
    padding-right: 2.1%;
  }

  .slide-out
    + .app-content.scrollbar_style
    .sales-by-sales-by-sales-person
    tfoot
    td:nth-child(4) {
    width: 13%;
  }
  .order-fulfillment-report .order-fulfillment-by-item {
    min-width: 1600px;
  }
  .order-fulfillment-report {
    overflow: auto;
  }
}

@media (max-width: 1530px) {
  .salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(3) {
    width: 187px;
  }
}
@media (max-width: 1440px) {
  .fifo_Cost thead tr th:nth-child(3),
  .fifo_Cost tbody tr td:nth-child(3) {
    width: 10% !important;
  }

  .fifo_Cost {
    overflow: auto;
    min-width: 1300px;
  }
  .fifo-report-main {
    overflow: auto;
  }
}
@media (max-width: 1366px) {
  .payemntmadereport thead tr th:nth-child(6),
  .payemntmadereport table tbody tr td:nth-child(6),
  .payemntmadereport thead tr th:nth-child(4),
  .payemntmadereport table tbody tr td:nth-child(4) {
    width: 22%;
  }

  .payemntmadereport thead tr th:nth-child(3),
  .payemntmadereport table tbody tr td:nth-child(3),
  .payemntmadereport thead tr th:nth-child(7),
  .payemntmadereport table tbody tr td:nth-child(7) {
    width: 13%;
  }

  .payemntmadereport thead tr th:nth-child(5),
  .payemntmadereport table tbody tr td:nth-child(5) {
    width: 14%;
  }
}

@media (max-width: 1138px) {
  .uiux_bills_report .bill__report tfoot tr td:first-child {
    width: 73.9%;
  }

  .uiux_bills_report tfoot tr td:last-child,
  .uiux_bills_report tfoot tr td:nth-child(2) {
    padding-right: 2rem;
    width: 11%;
  }

  html body div.bill__report thead tr th:nth-child(5),
  html body .bill__report table tbody tr td:nth-child(5),
  html body div.bill__report thead tr th:nth-child(3),
  html body .bill__report table tbody tr td:nth-child(3),
  html body div.bill__report thead tr th:nth-child(1),
  html body .bill__report table tbody tr td:nth-child(1),
  html body div.bill__report thead tr th:nth-child(2),
  html body .bill__report table tbody tr td:nth-child(2) {
    width: 8% !important;
  }

  html body div.bill__report thead tr th:nth-child(4),
  html body .bill__report table tbody tr td:nth-child(4) {
    width: 36% !important;
  }

  html body div.bill__report thead tr th:nth-child(6),
  html body .bill__report table tbody tr td:nth-child(6),
  html body div.bill__report thead tr th:nth-child(7),
  html body .bill__report table tbody tr td:nth-child(7) {
    width: 16% !important;
  }

  html body div.bill__report thead tr th:nth-child(5) {
    text-align: center;
  }

  .inventory_reports_section span.layout--title.ellipsis {
    flex: 0 0 40%;
  }

  .inventory_reports_section div.center {
    /* flex: 0 0 70%; */
    text-align: right;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .inventory_reports_section span.layout--title.ellipsis h3 {
    display: -webkit-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 249px;
    display: initial;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-size: 1.7rem;
  }

  .salesreturn_report .sales-by-sales-by-sales-person tfoot td:nth-child(2) {
    padding-right: 19px;
  }
  .inventory_reports_section
    .searchable-dropdown__menu-list
    .searchable-dropdown__option {
    text-align: left;
  }
}
@media (max-width: 1139px) {
  .invoice_report_table .invoice-report-main tfoot tr td:nth-child(1) {
    width: 76%;
  }
  .invoice_report_table table thead tr th:nth-child(7),
  .invoice_report_table table tbody tr td:nth-child(7) {
    padding-right: 1.5rem !important;
  }
  .invoice_report_table tfoot tr td:nth-child(2) {
    padding-right: 2.3rem !important;
  }
}
@media (max-width: 1099px) {
  html body div.bill__report thead tr th:nth-child(5),
  html body .bill__report table tbody tr td:nth-child(5),
  html body div.bill__report thead tr th:nth-child(3),
  html body .bill__report table tbody tr td:nth-child(3),
  html body div.bill__report thead tr th:nth-child(1),
  html body .bill__report table tbody tr td:nth-child(1),
  html body div.bill__report thead tr th:nth-child(2),
  html body .bill__report table tbody tr td:nth-child(2) {
    width: 9% !important;
  }

  html body div.bill__report thead tr th:nth-child(4),
  html body .bill__report table tbody tr td:nth-child(4) {
    width: 31.4% !important;
  }

  .listing_section.refund_report .UIUX_report_table_inner thead tr th {
    /*float: left;*/
    width: 16.6%;
    background-color: #fff !important;
    line-height: 1.42857143 !important;
  }

  .listing_section.refund_report .UIUX_report_table_inner tbody tr td {
    /*float: left;*/
    width: 16.6%;
    /* background-color: #FFF !important; */
  }

  .listing_section.refund_report
    .UIUX_report_table_inner
    thead
    tr
    th:last-child,
  .listing_section.refund_report
    .UIUX_report_table_inner
    tbody
    tr
    td:last-child {
    text-align: right;
  }
}

@media (max-width: 1024px) {
  .inventory_reports_section .info_section > .float-right {
    width: 40%;
  }

  .inventory_reports_section .info_section > .float-left {
    width: 60%;
  }

  .payemntmadereport thead tr th:nth-child(3),
  .payemntmadereport table tbody tr td:nth-child(3) {
    width: 12%;
  }

  .payemntmadereport thead tr th:nth-child(1),
  .payemntmadereport table tbody tr td:nth-child(1),
  .payemntmadereport thead tr th:nth-child(2),
  .payemntmadereport table tbody tr td:nth-child(2) {
    width: 9%;
  }

  .payemntmadereport thead tr th:nth-child(6),
  .payemntmadereport table tbody tr td:nth-child(6),
  .payemntmadereport thead tr th:nth-child(4),
  .payemntmadereport table tbody tr td:nth-child(4) {
    width: 21%;
  }
}

@media (max-width: 991px) {
  span.uiux-reports-title {
    display: none;
  }

  html body div.bill__report thead tr th:nth-child(4),
  html body .bill__report table tbody tr td:nth-child(4) {
    width: 25.4% !important;
  }
  .so-tbletabs.reports-main-container tbody tr:last-child {
    padding-bottom: 15px;
  }
}

@media (max-width: 890px) {
  .reports--filter .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    width: 110px;
  }

  .reports--filter .rdrCalendarWrapper {
    flex: 0 0 100%;
    width: 100%;
  }

  .date-range-picker.date-picker-main {
    max-width: 600px;
    margin: 0 auto;
    right: 6rem !important;
    top: 4rem;
  }

  .inventory_reports_section span.layout--title.ellipsis {
    flex: 0 0 30%;
  }
  .inventory_reports_section div.center {
    flex: 0 0 70% !important;
  }
  .inventory_reports_section .reports-main-container span.status {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .inventory_reports_section .top-header span h3 {
    font-size: 1.6rem;
  }
  .inventory_reports_section .top-header {
    padding: 0 1.4rem;
  }

  .inventory_reports_section .button--primary {
    padding: 0.5rem 1.2rem 0.5rem 1.2rem;
    font-size: 12px;
  }

  .reports--organization-info h4.report-organization {
    font-size: 13px;
    line-height: 18px;
  }

  .reports--organization-info .reports_image_circle {
    min-width: 80px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    overflow: hidden;
  }

  .reports--filters-container + .float-right {
    font-size: 13px;
  }

  .inventory_reports_section .info_section > .float-left,
  .inventory_reports_section .info_section > .float-right {
    width: 50%;
  }

  .listing_section.bill__report .so-tbletabs tbody tr:last-child {
    padding-bottom: 20px;
  }

  html body div.bill__report thead tr th:nth-child(4),
  html body .bill__report table tbody tr td:nth-child(4) {
    width: 31.5% !important;
  }
  .inventory_reports_section .pagination-container.pagination-route {
    width: 43%;
  }

  .inventory_reports_section
    .pagination-container.pagination-route
    .page-view-count {
    width: 33%;
  }

  /* Calander responsiveness */
  /* .reports--filters-container .rdrDateRangePickerWrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
  }

  .reports--filters-container .rdrStaticRanges {
    flex-direction: row;
    width: 100%;
    max-width: 664px;
  }

  .reports--filter .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    overflow-x: auto;
    display: block;
    width: 100%;
  }
  .reports--filter .rdrDefinedRangesWrapper::-webkit-scrollbar {
    height: 10px;
  }
  .reports--filter .date-range-picker.date-picker-main {
    min-height: 500px;
    z-index: inherit;
  }

  .reports--filter .date-range-picker .button--border {
    bottom: 1rem;
  } */
  /* .inventory_reports_section span.layout--title.ellipsis {
    flex: 0 0 34%;
  } */
}

@media (max-width: 712px) {
  html body .inventory_reports_section span.layout--title.ellipsis {
    flex: 0 0 28%;
  }

  .inventory_reports_section div.center {
    flex: 0 0 72% !important;
  }

  .payemntmadereport thead tr th:nth-child(5),
  .payemntmadereport table tbody tr td:nth-child(5) {
    width: 15%;
  }
  .date-range-picker.date-picket-show.date-picker-main {
    max-width: 680px;
    right: 0rem !important;
  }

  .date-range-picker.date-picket-show.date-picker-main
    .rdrCalendarWrapper.rdrDateRangeWrapper {
    width: 80% !important;
    flex: 0 0 80%;
  }
  .date-range-picker.date-picket-show.date-picker-main
    .rdrCalendarWrapper.rdrDateRangeWrapper
    .rdrMonth {
    width: 25em;
  }
}
