.warehouse-list {
  margin: 3rem 0;
}

.current-warehouse {
  margin-left: 0 !important;
}

.system_settings.warehouse.list-table {
  float: left;
}

.system_settings.warehouse.list-table thead,
.system_settings.warehouse.list-table tbody {
  float: left;
  width: 100%;
}

.system_settings.warehouse.list-table tbody tr {
  margin-top: 0 !important;
}

.system_settings.warehouse.list-table thead tr,
.system_settings.warehouse.list-table tbody tr {
  float: left;
  width: 100%;
}

.system_settings.warehouse.list-table thead tr th {
  padding: 10px 10px 10px 10px !important;
}

.system_settings.warehouse.list-table thead tr th:first-child {
  width: 20% !important;
}

.system_settings.warehouse.list-table thead tr th:nth-child(2) {
  width: 20% !important;
}

.system_settings.warehouse.list-table thead tr th {
  width: 12% !important;
  float: left;
  /* display: flex;
  align-items: center; */
  line-height: 23px;
}

/* .system_settings.warehouse.list-table thead tr th:nth-child(5) , .system_settings.warehouse.list-table tbody tr td:nth-child(5){
  text-align: center;
}  */
.system_settings.warehouse.list-table tbody tr td:nth-child(7) {
  display: none;
}

.system_settings.warehouse.list-table tbody tr td:last-child {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.system_settings.warehouse.list-table tbody tr td:first-child {
  width: 20% !important;
}

.system_settings.warehouse.list-table tbody tr td:nth-child(2) {
  width: 20% !important;
}

.system_settings.warehouse.list-table tbody tr td {
  width: 12% !important;
  float: left;
  /* display: flex;
  align-items: center; */
}

.system_settings.warehouse.list-table tbody tr td {
  line-height: 1.5 !important;
  word-break: break-word;
  /* max-width: 210px; */
  padding: 10px 10px 10px 10px !important;
}

/* .system_settings.warehouse.list-table tbody tr td span {
  padding: 0px 10px !important;
} */

.system_settings.warehouse.list-table tbody tr td:first-child {
  padding-left: 28px !important;
  width: 30%;
}

/* .system_settings.warehouse.list-table tbody tr td span , .system_settings.warehouse.list-table tbody tr td{
  width: 17% !important;
} */
/* .system_settings.warehouse.list-table thead tr th:nth-child(5){
  text-align: center;
} */
/* .system_settings.warehouse.list-table tbody tr td:nth-child(5) span , .system_settings.warehouse.list-table tbody tr td:nth-child(5){
  width: 17% !important;
  text-align: center;
} */

.current-warehouse--current {
  /*background: var(--border-dark);*/
  color: var(--black);
  font-size: 1rem;
  margin-left: 5rem;
  border-radius: 0.2rem;
  padding: 0.2rem;
  vertical-align: bottom;
}
.current-warehouse--current svg {
  fill: #3d3d3d;
}
.system_settings.warehouse.list-table thead tr th:first-child {
  /* padding-left: 1rem !important; */
}

.system_settings.warehouse.list-table tbody tr td:last-child {
  padding-right: 1.2rem !important;
}

.warehouse_add_new {
  min-width: 109rem !important;
}

.warehouse_add_new svg {
  height: 17px;
  width: 17px;
}

.warehouse_add_new .form-field.inline-field:nth-child(5) {
  margin-bottom: 20px !important;
}

.warehouse_add_new .action-buttons {
  margin: 0 !important;
  padding: 1.5rem 2.2rem !important;
  /* border-top:1px solid var(--border-light); */
  float: left !important;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

@media (max-width: 1800px) {
  .system_settings.warehouse.list-table tbody tr td span:nth-child(1) {
    float: left;
    width: 80% !important;
  }

  .system_settings.warehouse.list-table tbody tr td span:nth-child(2) {
    width: 19% !important;
  }
}
