.purchaseorder-form .multiple-field-with-label {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  width: 80%;
}
.po-fields-container{
  align-items: flex-start;
}
.purchaseorder-form .multiple-field-with-label .form-field:first-child {
  width: 100%;
}

.purchaseorder-form .searchable-dropdown {
  border-radius: 4px !important;
}

.purchaseorder-form .searchable-dropdown:focus-within {
  border-color: #878787 !important;
}

.purchaseorder-form .searchable-dropdown .searchable-dropdown__control:focus-within {
  border: none !important;
}

.purchaseorder-form .multiple-field-with-label .form-field:not(:first-child) {
  grid-template-columns: 20rem 1fr;
  width: 100%;
}

.purchaseorder-form .create_package tbody tr td:last-child {
  margin-top: 10px;
}

.purchaseorder-form .create_package .list-table--amount .close-btn.button {
  top: 5px;
}

.purchaseorder-form tbody .table--row {
  height: auto;
}

.width-100 {
  width: 100% !important;
}

.po-terms-and-cond div:nth-child(2) textarea {
  margin-top: 0px;
  width: 23rem !important;
}

.form-field.inline-field.textarea-po-terms textarea {
  width: 22.5rem !important;
  width: 490px !important;
}

.terms-po label {
  font-size: 15px;
}

.terms-po .radio-container div:first-child {
  margin-left: 20px !important;
}

.purchase-popup-set .list-table.new_reveive_set .form-field {
  padding: 0;
  position: relative;
  bottom: 4px;
}

.list-table.new_reveive_set .form-field.error .input-feedback {
  bottom: -3.6rem;
}

.new_reveive_set tr {
  padding-top: 8px;
  padding-bottom: 4px;
  border-bottom: 0 !important;
}

.new_reveive_set thead tr {
  padding: 0;
}

.purchase-order-set.purchase-order-vendor {
  display: inline-block;
}

.create_package tbody tr td:nth-child(5) .searchable-dropdown__option+div {
  padding: 6px;
}

.create_sales_order tbody tr td:nth-child(6) .searchable-dropdown__option>div,
.create_package tbody tr td:nth-child(5) .searchable-dropdown__option>div {
  background-color: #eeeeee;
  border: 1px solid transparent;
  border-radius: 4px;
  height: 25px;
  line-height: 24px;
  font-size: 12px !important;
  text-align: center;
}

.po-terms-and-cond .form-field {
  padding: 7px 0 0 0;
  display: flex;
  align-items: center;
}

html body .po-terms-and-cond .form-field label {
  margin-bottom: 0px !important;
}

.po-terms-and-cond .form-field .radio-container .radio-btn {
  display: flex;
  align-items: center;
}
span.previous_sold-po {
  float: left;
  width: 100%;
}
span.previous_sold-po .table-extra-data div {margin-top: 14px;}

@media (max-width: 1280px) {
  .purchaseorder-form .summary_table .w-40 {
    width: 50% !important;
  }

  html body .form.purchaseorder-form .middle-row {
    padding-top: 14px;
  }

  /* .create_bill_enchancer .create_package tr th:nth-child(3),.create_bill_enchancer .create_package tr td:nth-child(3),
  .create_bill_enchancer .create_package tr th:nth-child(2),.create_bill_enchancer .create_package tr td:nth-child(2) {
    display: none;
  } */
}

@media (max-width: 1199px) {
  .purchase-order-set~.action-buttons {
    padding-right: 4%;
  }
}

@media (max-width: 1024px) {

  .purchaseorder-form .form-section--body .form-field.inline-field,
  .create_bill_enchancer .form-field.inline-field {
    width: 48%;
    margin-right: 2%;
  }

  .bills-main-set .form-field.inline-field,
  .purchase-order-set.purchase-order-vendor .form-field.inline-field,
  .invoice-main-set.invoices-popup-set .form-field.inline-field {
    width: 100% !important;
    padding-right: 0 !important;
  }

  .purchase-order-set.purchase-order-vendor,
  .purchaseorder-form .form-section--body .form-field.inline-field:nth-child(2) {
    margin-bottom: 10px;
  }

  .purchase-order-date.d-inline .form-field.inline-field,
  .create_bill_enchancer,
  .purchaseorder-form .searchable-dropdown.large,
  .purchaseorder-form .form-section--body .form-field.inline-field .form-input,
  .po-terms-and-cond .form-field.inline-field,
  .create_bill_enchancer .searchable-dropdown.large,
  .create_bill_enchancer .form-field.inline-field .form-input,
  .po-terms-and-cond .form-field.inline-field {
    width: 100% !important;
  }

  .purchaseorder-form .middle-row,
  .create_bill_enchancer .middle-row {
    display: initial;
  }

  .purchase-order-date.d-inline {
    width: 50%;
    padding-right: 19px;
  }

  .purchase-order-date.d-inline,
  .purchase-order-date.d-inline+.form-field.inline-field {
    margin-top: 13px;
  }

  .create_bill_enchancer .form-field.inline-field.description_box+.inline-terms-and-conditions,
  .create_bill_enchancer .middle-row .form-field.inline-field.description_box {
    width: 100%;
  }
}

.purchase-order-set {
  /* display: inline-block; */
  position: relative;
  height: 100%;
}

.purchase-order-set button.view-details-btn {
  position: absolute;
  right: auto;
  color: var(--blue-500);
  white-space: nowrap;
  font-size: 1.2rem;
  top: 100%;
  left: 62%;
  bottom: auto;
}

.form.purchaseorder-form .middle-row {
  padding-top: 8px;
}

.dropship-main {
  width: 25%;
  padding-top: 8px;
}

.dropship-main .lower_so_address {
  padding-left: 0;
}

/* .textarea-show-set+.dropship-main {
  left: 0;
} */

.dropship-main p {
  color: var(--text-primary);
  margin-bottom: 0.8rem;
  text-transform: capitalize;
  font-weight: 600;
}

.dropship-main+.form-field.inline-field {
  padding-top: 2px;
}

/* .po-term-set {
  margin-top: 11px;
} */
.mr-0 {
  margin-right: 0;
}

.po-term-set .form-field.error .input-feedback {
  position: absolute;
  top: 80px;
  bottom: auto;
}
.dropship-reference.form-field.error .input-feedback {
  top: 81px;
}
.create-po-package .__dragDrop .__drag-righ {
  max-width: 390px;
}
.create-po-package input,
.create-po-package .form-field {
    border-radius: 4px;
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .searchable-dropdown.large {
    width: 22.5rem;
  }
}

@media (min-width: 1025px) {
  .purchaseorder-form .middle-row .inline-terms-and-conditions {
    margin-left: -1rem;
    padding-left: 0;
  }
}

@media(max-width:1024px) {
  html body .create-po-package.horizontal-items-table td:first-child {
    width: 30% !important;
  }

  .form-table.border.horizontal-items-table.create_package.create-po-package .close-btn {
    left: unset;
    right: -35px;
  }
  .po-fields-container {padding-bottom: 14px;}
}

@media (max-width: 890px) {
  .purchaseorder-form .middle-row .inline-terms-and-conditions {
    padding-left: 0;
  }

  .purchase-order-set.purchase-order-vendor,
  .middle-row.po-term-set .form-field.inline-field,
  .purchase-order-set.purchase-order-vendor~.form-field.inline-field {
    width: 100% !important;
  }

  .purchase-order-set.purchase-order-vendor+.form-field.inline-field {
    margin-top: 7px;
  }

  .purchase-order-set.purchase-order-vendor~.form-table--container {
    margin-right: 0;
  }
}

