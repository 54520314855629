.card_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 54px;
  row-gap: 10px;
}

.temp {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'flex-start';
  align-items: 'flex-start';
  margin-top: '66px';
  position: relative;
  min-height: 242px;
  min-width: 142px;
  /* border: 1px solid lightgray !important; */
  border-radius: 4px;
  overflow: hidden;
}
.img_container {
  position: relative;
}
.img_container img {
  width: 170px;
  height: 200px;
}
.img_container .default_badge {
  position: absolute;
  top: 6px;
  right: 4px;
}
.img_container .default_badge .status--draft {
  border-radius: 30px;
}
.temp .text_area {
  display: none;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.temp:hover .text_area {
  display: block;
}
.temp label {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

h1 {
  margin-bottom: 22px;
  font-size: 20px;
  text-transform: capitalize;
  &:empty {
    display: none;
  }
}
.styled--store {
  gap: 20px;
  margin-bottom: 25px;
  padding: 0 15px;
  .temp {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    padding: 8px;
    position: relative;
    .selected {
      position: absolute;
      border: none;
      z-index: 4;
      background-color: #1170ff;
      border-radius: 45px !important;
      font-size: 10px;
      text-transform: capitalize;
      color: #fff;
      top: 15px;
      left: 0px;
      height: 24px;
      line-height: 10px;
      filter: drop-shadow(0px 1px 0px #1170ff), (0px 10px 0px #1170ff);
      transform: rotate(321deg);
    }
    label {
      font-size: 12px;
    }
    &:hover .text_area {
      display: block;
      .button--primary {
        min-height: 40px;
        font-size: 12px;
        height: 100%;
      }
    }
  }
}
.time-span {
  font-size: 12px;
  color: #8d8888;
  margin-left: 4px;
}
.action-buttons {
  margin-right: 1rem !important;
}

.field-pdf--dropdown {
  width: calc(25em - 10px) !important;
}
