.purchaseorder-form .form-field .form-label {
  margin-top: 0;
}

table.vendor-table tr {
  height: 25px;
}

table.vendor-table tr td:first-child {
  color: #aaa;
  width: 90px;
  font-weight: 500;
}

div.topci_laebl {
  font-weight: 500;
}
.global-status-listing {
  height: 100%;
}
table.po-item-table {
  background-color: #f7f7f7 !important;
  margin-top: 10px;
}

table.po-item-table tr {
  border-bottom: 1px solid #eee;
}

table.po-item-table tr td:first-child {
  color: #888;
  width: 90px;
  font-weight: 500;
  vertical-align: top;
}

table.po-item-account-table {
  background-color: #f7f7f7 !important;
  /*border-top: 2px solid #eee;*/
}

table.po-item-account-table tr {
  /*height: 30px;*/
  background-color: #f7f7f7 !important;
}

table.po-item-account-table tr td:first-child {
  color: #aaa;
  width: 120px;
  font-weight: 500;
}

.po_top {
  top: 6px !important;
}

.select-all-wrapper {
  display: inline-block !important;
  margin-right: 5px;
  padding-right: 10px;
}

.selectall-h4 {
  display: inline-block !important;
}

.order_details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.po_details {
  display: flex;
}

.confirm-delete-header {
  background-color: #f4d4d4;
  border-bottom: 1px solid #eab298;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.list-item-act {
  display: inline-block !important;
}

.nav-tabs > li > a {
  border-radius: 0px;
}

li.filter-label a.dropdown-toggle {
  font-size: 16px;
  text-decoration: none;
  color: #555;
}

.pb5 {
  padding-bottom: 5px;
}

/*<-----checkbox*/
.po-table--checkbox-label {
  margin: 0;
  position: relative;
  display: flex;
  width: 100%;
  user-select: none;
}

.po-table--checkbox-label > span {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px;
  border: 1px solid #589dd8;
  left: 0;
  right: 0;
}

.po-table--checkbox-label > input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

.po-table--checkbox-label > span > svg {
  color: var(--seebiz-light-blue);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transform: scale(1.3);
  opacity: 0;
  transition: all 0.1s ease-in-out;
}

.po-table--checkbox-label > input[type='checkbox']:checked + span > svg {
  opacity: 1;
  transition: all 0.1s ease-in-out;
}

/*checkbox----->*/

/*<-----dropdown*/
.po-list-table--row-dropdown {
  margin: 0;
}

.po-list-table--row-dropdown .custom-dropdown {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.po-list-table--row-dropdown .custom-dropdown > button {
  transform: rotate(90deg);
  margin: 0;
  width: 3rem;
}

.po-list-table--row-dropdown .custom-dropdown > button > svg {
  font-size: 2rem;
}

/*dropdown----->*/

.po-exp-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;
}

.po-list-exp-overview-row {
  display: grid;
  grid-template-columns: 17rem 1fr;
  grid-gap: 2rem;
  margin: 1rem 0;
}

.po-exp-table-summary--row {
  display: grid;
  grid-template-columns: 5rem minmax(15rem, 4fr) minmax(13rem, 2fr) repeat(
      auto-fit,
      minmax(9rem, auto)
    );
  grid-gap: 1rem;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
}

.po-exp-table-summary--row-image {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.po-exp-table-summary--row-image img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
}

.po-divider {
  margin: 3rem 0 1.5rem;
  border-top: 1px solid #cccccc;
}

.po-list-exp .panel {
  background-color: transparent;
}

.cancel_items_popup thead {
  /* border-left: 1px solid var(--border-dark);
  border-right: 1px solid var(--border-dark); */
  box-shadow: 0 0 10px #ccc;
  line-height: 1.42857143 !important;
  background: #fff !important;
}
.cancel_items_popup.form-table.list-table td{
  padding: 1rem 0.8rem 1.6rem;
} 



.cancel_items_popup.form-table.list-table th,
.cancel_items_popup.form-table.list-table td {
  border: 0 !important;
}

.cancel_items_popup.form-table.list-table td {
  height: auto;
}

.cancel_items_popup tbody tr td:first-child {
  width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: block;
  /* top: 11px; */
}
/* .cancel_items_popup td:last-child input {
  text-align: right;
} */
.cancel_items_popup td:last-child,
.cancel_items_popup tbody tr td:nth-child(2),
.cancel_items_popup tbody tr td:nth-child(3),
.cancel_items_popup tbody tr td:nth-child(4),
.cancel_items_popup tbody tr td:nth-child(5) {
  vertical-align: middle;
}
.cancel_items_popup td:last-child .close-btn.button.button_right {
  height: 100%;
}


.purchase_order_table_listing .compressedscreen thead tr th:nth-child(5) {
  /*padding-left: 2rem !important;*/
  /* width: auto !important; */
}

.purchase_order_table_listing table thead tr th:nth-child(6),
.purchase_order_table_listing table thead tr th:nth-child(7) {
  /* text-align: center !important; */
  text-align: center;
}

.purchase_order_table_listing table thead tr th:nth-child(6) {
  padding-left: 1% !important;
}

.purchase-order-main {
  display: flex;
  width: 100%;
  /* align-items: center; */
}

.purchase-order-main .section-details--info.package_details {
  width: 40% !important;
  padding-top: 15px  !important;
  /* margin-top: -24px; */
}
/* :where(.purchase_order_details .purchase-order-main.details_header_left .details-header, .purchase-order-main > .section-details--info) {
  padding-top: 20px !important;
} */

/* .purchase-order-main + .package_address.details_header_right {
  margin-top: 0 !important;
} */

.purchase-order-main ~ .package_address.details_header_right {
  margin-top: 0 !important;
}

.purchase_order_table_listing {
  float: left;
  width: 100%;
}

.purchase_order_table_listing .lopi th.status {
  text-align: left !important;
  padding-left: 2.5rem !important;
}

.bills_menu tr td:last-child .custom-dropdown .button {
  padding-left: 0 !important;
}

.bills_menu tr td:last-child .custom-dropdown svg {
  fill: #3d3d3d;
}
.pr-label label {margin-bottom: 0;}
@media (max-width: 1700px) {
  .purchase_order_table_listing thead tr th:nth-child(8) {
    width: 9%;
  }

  .purchase_order_details .details-header {
    width: 95% !important;
  }
}

@media (max-width: 1280px) {
  .purchase_order_table_listing tr th:nth-child(6),
  .purchase_order_table_listing tr td:nth-child(6),
  .purchase_order_table_listing tr th:nth-child(7),
  .purchase_order_table_listing tr td:nth-child(7) {
    display: none;
  }

  /* .invoice_Details_main {
    width: 94% !important;
  } */

  .bills_menu .pointerIn .button {
    padding-left: 0 !important;
  }

  .bills_menu .pointerIn:last-child {
    left: -17px !important;
  }
  html body .cancel_items_popup.list-table.form-table{
    width: 100%;
  }
  html body .cancel_items_popup .adjust_width{
    border: 0 !important;
  }
}
@media (max-width:1199px){
  .purchase-order-main .section-details--info.package_details,
  .purchase_order_details .details-header{
    width: 50% !important;
  }
  .purchase-order-main .section-details--info.package_details .sale-order-ref {
    line-height: 20px;padding: 0 5px;width: 65%;
    text-align: right;
    white-space: pre-line;
  }

.purchase-order-main .section-details--info.package_details .section-details--info-title {width: 45%;}

html body .purchase_order_details .details-header {padding: 2.4rem 3px!important;}
table.list-table.form-table.border.cancel_items_popup{
  width: 100%;
}
.cancel_items_popup td:last-child .adjust_width {
  border: 0 !important;
}
.purchase_order_table_listing .lopi th.status{
  padding-left: 1rem !important;
}
}
@media (max-width: 991px) {
  .purchase_order_table_listing tr th:nth-child(3),
  .purchase_order_table_listing tr td:nth-child(3) {
    display: none;
  }

  .purchase_order_table_listing tr th:nth-child(2),
  .purchase_order_table_listing tr td:nth-child(2) {
    width: 12%;
  }
}

.cancel_items_popup tr td .number-input {
  border: 1px solid #dfe1e5;
}

/* .BillsFormPopup .__dragDrop_iconParent {
  display: none;
} */

@media screen and (max-width:1138px) {
  .purchase-order-main .section-details--info.package_details{
    width: 50% !important;
  }
  .purchase_order_details .details-header{
    width: 50% !important;

  }
  
  
}
@media(max-width:768px){
  .purchase_order_table_listing .lopi th.status {
    padding-left: 1.5rem !important;
}
}