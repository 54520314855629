.date-range-picker {
  position: absolute;
  top: 6rem;
  border: 1px solid var(--border-light);
  border-radius: 4px;
  z-index: 3000;
  left: 0rem;
  background: #fff;
  box-shadow: var(--grey-box-shadow);
}

.date-picker--container {
  position: relative;
}

.date-picker--body {
  position: absolute;
  top: calc(100% + 0.8rem);
  border: 1px solid var(--border-light);
  border-radius: 4px;
  z-index: 3000;
  background: #fff;
  box-shadow: var(--grey-box-shadow);
}

.date-picker--button {
  margin: 0;
  border: 1px solid var(--border-dark);
  border-radius: 16px;
  background: transparent;
  margin-left: 1rem;
  outline: none;
  padding: .5rem 1rem;
}

.date-picker--button svg {
  margin-left: .5rem;
}

.date-picker--button:hover {
  background: var(--button-hover);
}

.date-range-picker .button--border {
  position: absolute;
  left: 1.4rem;
  bottom: 2rem;
  font-size: 1.4rem;
  padding: .2rem 1rem .3rem 0;
  height: 3rem;
}

.date-range-picker .button--border svg {
  font-size: 2rem;
  margin: 0 0 0 .5rem;
}

.date-range-picker .rdrDateRangePickerWrapper .rdrDateDisplay {
  background-color: unset;
}

.date-range-picker .rdrDefinedRangesWrapper {
  width: unset;
}

.date-range-picker .rdrStaticRange {
  min-width: 12rem;
}
.rdrStaticRangeSelected{
  border-left: 3px solid #33a348;
  background: #DFE1E5 ;
  color: black !important;
}

.date-picker-inmodal{
  position: fixed !important;
  top: 18rem !important;
  left: 4.8rem !important;
}
/* #date-range-picker+ .date-select-set.date-picker-main {
  left: 0;
  top: 3.8rem;
} */
@media(max-width:1280px){
  .date-range-picker.date-picker-main {
    left: -25.5rem;
}
}