.form-section--header {
  display: flex;
  position: relative;
  /* Because new UI & not implemented anywhere else */
  /* justify-content: space-between; */
  align-items: center;
  padding-bottom: 0.8rem;
}

.form-section--title {
  font-size: 1.6rem;
  color: #000;
  font-weight: 700;
}

.font-medium .form-section--title {
  font-weight: 500 !important;
}

.form-section--right{
  margin-left: 20%;
  font-size: 16px;
}
@media (max-width: 1080px){
 .margin-top-lg .form-section--body .flex-row > div:first-child{
    flex: 1;
  }
  .item-form  .form-section--body .inline{
    display: block;
  }
  .item-form  .margin-top-lg .form-section--body .searchable-dropdown.large,.item-form .margin-top-lg .form-section--body .form-input.large {
    width: 100%;
  }
}