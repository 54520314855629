.my-account {
  position: relative;
}

.myaccountplan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.package-plan {
  margin: 0;
  float: left;
  line-height: 5rem;
  margin-right: 1rem;
}

.flot-left {
  float: left;
  margin-top: 10px;
}

.my-account--form .genral_tab_content {
  padding: 0 60px !important;
  height: calc(100vh - 110px) !important;
}

.top_adjust {
  position: relative;
  top: 40px;
  margin-left: 20px;
}

.ml-5 {
  margin-left: 5% !important;
}

.create_new_account_inventory {
  width: 103rem !important;
}

.remove-header .ims-modal--title {
  border-bottom: 0 !important;
  padding: 0;
}

.remove-header .ims-modal--title span {
  font-size: 0;
}

.remove-header .ims-modal--title svg {
  height: 0;
  width: 0;
}

.remove-header .ims-modal--body {
  padding: 0 0 1.6rem 0;
}

.remove-header .close-button {
  position: relative;
  top: 15px;
}

.my-account .input-feedback {
  bottom: -12px !important;
}

@media(max-width:1024px) {
  .uiux-my-account form.form-horizontal.column {
    display: flex;
    flex-wrap: wrap;
  }

  html body .uiux-my-account form.form-horizontal.column .form-input-wrapper input {
    width: 100% !important;
  }

  html body .uiux-my-account ul.mismatch-passsword--errors.passsword--errors.touched {
    padding-top: 25px;

    position: relative;
    left: unset;
    top: unset;
  }

  .uiux-my-account label.semi-bold.control-label {
    padding: 1.5rem 0 1rem;
  }
}