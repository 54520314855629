.password-request--footer {
  margin-top: 4rem;
}

.password-request-signin {
  text-align: center;
}

.password-request-signin a:focus,
.password-request-signin a:hover {
  color: var(--blue-500-hover);
  text-decoration: none;
}

button.resend-text {
  font-weight: 600;
  text-decoration: underline;
  font-style: italic;
  color: var(--blue-500);
}

button.resend-text:hover {
  color: #000;
}