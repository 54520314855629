.settings .layout--body {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  padding: 20px;
}

.settings .left_Sec {
  position: relative;
  background-color: #F0F2F5;
}

.icon_top {
  position: absolute;
  top: 15px;
  right: 20px;
}

.icon_top svg {
  height: 20px;
  width: 20px;
}

.h-100 {
  height: 100%;
}

.image_orgnaization {
  float: left;
  width: 100%;
  margin-top: 5%;
}

.image_orgnaization .m_auto {
  width: 100px;
  margin: auto;
  height: 100px;
  margin-bottom: 10px;
  background-color: #FFF;
  border: 1px solid #f5feff;
  border-radius: 50%;
  overflow: hidden;
}

.image_orgnaization .m_auto img {
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  object-fit: contain;
}

.name_organization {
  font-weight: 500;
  margin-bottom: 5px;
}

/* .about_organization{
  font-size: 16px;
} */

.organzation_action_buttons {
  /* display: inline-flex;
  justify-content: center; */
  position: absolute;
  bottom: 10px;
  left: 0;
  text-align: center;
}

.organzation_action_buttons a {
  /* font-size: 16px; */
  color: #000;
  border-right: 1px solid;
  padding: 0 10px;
  float: left;
  width: 33.33%;
  text-align: center;
}

.organzation_action_buttons a:last-child {
  border-right: 0;
}

.settings .right_sec .box_data {
  float: left;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #F0F2F5;
}

.settings .right_sec .box_data:last-child {
  border-bottom: 0;
}

.settings .right_sec .box_data .left_box_data {
  float: left;
  width: 30%;
  font-weight: 300;
}

.settings .right_sec .box_data .mid_box_data {
  float: left;
  width: 10%;
  text-align: center;
}

.settings .right_sec .box_data .right_box_data {
  float: left;
  width: 60%;
  font-weight: 500;
}

.padding_right_custom {
  padding-right: 60px;
}

.settings .left_Sec:before {
  content: "";
  position: absolute;
  right: -9px;
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 14px solid #F0F2F5;
  border-radius: 4px;
  top: 42%;
}

.seetings_card_parent {
  margin: 20px 0px 20px 40px;
}

.seetings_card_parent .colum_3 {
  float: left;
  width: 33.33%;
  padding: 0 10px;
  margin-bottom: 20px;
}

.settings .settings-card {
  float: left;
  width: 100%;
}

.settings-card--sub-title {
  color: #000 !important;
}

.seetings_card_parent .settings-card svg {
  /* height: 30px !important;
  width: 30px !important; */
  height: 31px !important;
  width: 31px !important;
}

@media(max-width:1280px) {
  .seetings_card_parent .colum_3 {
    width: 50%;
  }

  html body .float-left.w-70.seetings_card_parent {
    width: 100% !important;
    margin-left: 0;
  }
}

@media(max-width:890px) {
  .image_orgnaization {
    margin-top: 24%;
  }
}