.new-account-form {
  width: 70%;
}

.new-account-form .form-field {
  grid-template-columns: 18rem 1fr;
  width: 100%;
}

.widthClass {
  /* lower Width instruction from Rohail QA */
  width: 29.8%;
  margin-top: 100px;
}

.smallWidthClass {
  width: 43.0%;
  margin-top: 100px;
}

.account-form .form-field.inline-field{
  margin-right: 1.5rem !important;
}

.account-form .form-field.inline-field:last-child{
  margin-right: 0.0rem !important;
}

.fields_align{
  display: flex;
  width: 40% !important;
  /* justify-content: space-between; */
  align-items: left;
  flex-direction: column;
}
.fields_align label{
  margin-left: 10px !important;
}

.inventory_modal_title{
  align-items: center;
  display: flex;
  width: 90%;
  word-break: break-word;
}