.mobile-menu ul li,
li.mobileList a,
.mobile-menu ul li button {
  color: #242424;
  display: flex;
  align-items: center;
}

.mobile-menu ul li {
  margin-bottom: 3px;
  border-bottom: 0;
}

.mobile-menu ul li button,
.mobile-menu ul li a {
  width: 100%;
  color: #242424;
}

.mobile-menus-dynamic .mobile-menu ul {
  left: -125px;
  width: 160px;
  padding: 6px 0;
  top: 40px;
  z-index: 20;
  background: #fff;
  position: absolute;
}
.mobile-workig .checked-menu--toolbar {
  z-index: 10;
}
.buttonMobileIcon {
  box-shadow: 0px 0px 3px 0px rgb(0 0 0 / 25%);
  padding: 0.2rem 0.2rem !important;
  background-color: #e9e9e9 !important;
  padding: 4px !important;
}

html body .mobileListing .checked-menu--button--icon-only:hover svg,
html body .mobileListing a:hover svg,
button.buttonMobileIcon svg path {
  fill: #000 !important;
}

.mobile-menu ul li:last-child {
  border-bottom: 0;
}

.mobile-menus-dynamic li.mobileList button:hover {
  background-color: transparent;
  border: 0;
  box-shadow: none;
}

.buttonMobileIcon i.fas.fa-bars {
  font-size: 19px;
  padding: 3px;
  border-radius: 5px;
}

.mobile-menu ul li button,
.mobile-menu ul li a {
  padding: 0px 0.5rem 2px 0.5rem !important;
}

.mobile-menu ul li:empty {
  display: none;
}

.mobile-menu ul li button:hover,
.mobile-menu ul li a:hover {
  background: transparent;
  box-shadow: none;
  color: #242424;
}

.mobile-menu ul li:hover {
  background-color: #dfe1e5;
}

.mobile-menu {
  position: relative;
  /* top: 45px; */
  z-index: 1;
  left: auto;
  /* right: 35%; */
}

ul.mobileListing {
  border-radius: 5px;
  box-shadow: 0 0 10px #ccc;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  padding: 10px 0;
}

@media (max-width: 768px) {
  .mobile-menus-dynamic .mobile-menu ul {
    left: -120px;
  }

  .mobile-menu ul li {
    padding: 2px 0.1em 2px 0.3rem !important;
  }

  .mobile-menu ul li span {
    margin-left: 2px;
  }
}
