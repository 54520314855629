.form-table .item-group-items .form-input,
.horizontal-main-set .form-table .item-group-items .form-input {
  border-color: #dfe1e5 !important;
}

div.item-sec-wrp div.item-sec {
  display: inline-block;
  border-right: 2px solid #ddd;
  color: #888;
  font-size: 12px;
  padding: 0px 5px;
}

div.item-sec-wrp div.item-sec:first-child {
  padding-left: 0px;
}

div.item-sec-wrp div.item-sec:last-child {
  border-right: 0px;
}

div.item-view {
  margin-top: 10px;
}

div.item-images-grid {
  max-width: 150px;
  text-align: center;
  margin-top: 5px;
}

div.item-images-grid div.image-item {
  width: 30px;
  height: 40px;
  margin: 0px 3px;
  border-radius: 3px;
  cursor: pointer;
}

div.item-view-wrapper {
  background: #f8f8f8;
  padding: 5px 10px 10px 10px;
  border: 1px solid #ccc;
  border-top: 0;
}

.item-view-wrapper .container {
  width: 100%;
}

.section-detail--info-main .capitalize.float-left {
  word-break: break-word;
}

h4.item-name {
  margin-top: 0;
  font-size: 24px;
}

.item-content {
  padding-left: 15px;
}

.item-content b {
  color: #777;
}

.select-all-wrapper {
  display: inline-block;
  margin-right: 5px;
  padding-right: 10px;
  /*border-right: 2px solid #ddd;*/
}

.selectall-h4 {
  display: inline-block;
}

.confirm-delete-header {
  background-color: #f4d4d4;
  border-bottom: 1px solid #eab298;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.list-item-act {
  display: inline-block !important;
}

.list-table--link-error {
  color: red !important;
}

.checkall_fix_split_btn {
  display: none;
}

.check_all_fix_rc {
  display: inline-block;
  padding: 6px 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
  background-color: #fff;
}

.item-list-exp-body {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-gap: 1.5rem;
}

.item-list--table .list-table--row-name div:first-child {
  color: var(--blue-500);
}

.item-list-exp--img-container {
  padding-right: 1.5rem;
  border-right: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-list-exp-first-section {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-gap: 2rem;
}

.searchable-dropdown__menu {
  z-index: 9999;
}

/* TODO: will be moved */

.list-head {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.list-head .sort-dropdown .custom-dropdown.button-text .button {
  font-size: 1.4rem;
}

.general_module_icon {
  width: 20px !important;
  height: 20px !important;
}

.collapsed-item-groups-table .list-table--item--col {
  max-width: 100%;
}

.mr_10.items_right_set {
  margin-right: 21px !important;
  border-radius: 5px !important;
}

.searchable-dropdown__menu {
  z-index: 9999 !important;
}

@media (max-width: 1199px) {
  .details-table.group_items_table tr td:first-child {
    width: 50%;
  }

  .details-table.group_items_table {
    min-width: 800px;
    overflow-x: auto;
    display: block;
  }

  .collapsed-item-groups-table .list-table--link span div {
    padding-right: 15px;
  }

  /* .parent-component #listing_component .table-row td:nth-child(4) {
    display: block !important;
  } */
  .description-set .form-field.inline-field.medium_large.mr_10,
  .edit-group-item .item-form .form-section--body>div {
    width: 100%;
  }

  .edit-group-item .form-section {
    padding-bottom: 35px;
  }

  .edit-group-item .group_main_set_top {
    flex-direction: column;
  }

  .edit-group-item .inline .form-field.overForm.inline-field.medium_large,
  .edit-group-item .group_main_set_top .form-field.inline-field.medium_large.mr_5 {
    margin-right: 0;
  }

  .edit-group-item .layout--box {
    width: 100%;
    max-width: 100%;
    padding: 0 20px;
  }

  .account_addnew.new_click_set {
    left: auto !important;
  }

  /* .group-set-main {
    overflow-x: auto;
    max-width: 1045px;
  } */

  /* .group-set-main>table {
    max-width: 1119px;
    overflow: auto;
    margin-top: 10px;
  } */

  td.item-name-set {
    width: 15%;
  }

  td.inventory-status {
    width: 15% !important;
  }

  .group-table-set th:first-child {
    width: 18%;
  }

  .group-table-set th:nth-child(2) {
    width: 19%;
  }

  /* .group-table-set th:nth-child(3) {
    width: 32%;

  } */
}

@media (max-width: 1024px) {
  /* .group-set-main>table {
    max-width: 936px;
  } */

  .group-table-set th {
    font-size: 12px;
    padding: 6px 0 !important;
  }

  .group-table-set th:first-child {
    padding-left: 6px !important;
  }

  .edit-group-item .images-uploading .form-field {
    width: 15%;
  }

  .group-table-set th:nth-child(2) {
    width: 19.5%;
  }

  .group-table-set th:nth-child(3) {
    width: 32%;
  }

  .opening-stock-popup .stock_distribution_table {
    min-width: 1100px;
  }

  .opening-stock-popup .reports--body {
    overflow-x: auto;
  }
}