button.button--full-width.btn-select-set {
  max-width: 90px !important;
}

.float-left.h-100.choose_organiartion_card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto !important;
  float: none;
}

.choose_organization {
  padding: 0 15px;
}

@media(max-width:1199px) {
  .system_settings.warehouse.list-table.sytem_setting_main thead tr th:first-child {
    padding-left: 2rem !important;
    width: 20% !important;
  }

  .system_settings.warehouse.list-table.sytem_setting_main thead tr th:nth-child(2), .system_settings.warehouse.list-table.sytem_setting_main tbody tr td:nth-child(2) {
    width: 15% !important;
  }

  .system_settings.warehouse.list-table.sytem_setting_main thead tr th:nth-child(3), .system_settings.warehouse.list-table.sytem_setting_main tbody tr td:nth-child(3) {
    width: 19% !important;
  }

  .system_settings.warehouse.list-table.sytem_setting_main thead tr th:nth-child(7), .system_settings.warehouse.list-table.sytem_setting_main tbody tr td:nth-child(7) {
    width: 12% !important;
  }

  .system_settings.warehouse.list-table.sytem_setting_main thead tr th:nth-child(6), .system_settings.warehouse.list-table.sytem_setting_main tbody tr td:nth-child(6) {
    width: 10% !important;
  }
}

@media(max-width:768px) {
  .choose_organization .padding_right_custom {
    padding-right: 0;
  }

  .choose_organization .left_Sec,
  .choose_organization .right_sec {
    padding: 5px !important;
  }

  .choose_organization .right_sec .box_data .mid_box_data {
    width: 5%;
  }

}