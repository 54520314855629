/* .ecom-main {
  border: 1px solid #bbb;
  float: left;
  width: 100%;
} */

/* html body .ecom-main-inner {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  height: 230px;
  margin-bottom: 0;
} */

/* .ecom-main .ecom-left-sec {
  flex: 0 0 30%;
  margin: 0 !important;
  border-right: 1px solid #bbb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */

/* .ecom-main .ecom-right-sec {
  flex: 0 0 70%;
} */

/* .btn-ecom {
  min-width: 120px;
  border: 1px solid #bbb;
  padding: 7px 20px;
  color: #000;
  font-weight: 300;
} */

/* .btn-cancel,
.btn-connect {
  color: #fff;
  font-size: 16px;
  min-width: 100px;
  border-radius: 5px;
  padding: 7px 20px;
} */

/* .btn-cancel {
  background-color: #dfe1e5;
  color: #8e8e8e;
} */

/* .btn-connect {
  background-color: #33a348;
}

.ecom-left-desc .ecom-integrate {
  font-weight: bold;
  margin-top: 10px;
}

.ecom-left-desc {
  margin-top: 25px;
}

.btn-ecom:hover {
  color: var(--blue-500);
  text-decoration: underline;
}

.ecom-right-desc {
  padding: 15px 15px 0 15px;
}

.ecom-right-desc h4 {
  font-weight: bold;
  margin-bottom: 0;
}

.ecom-action-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-ecom-active,
html body .btn-ecomstore {
  min-width: 130px;
  border: 1px solid #bbb;
  padding: 7px 20px;
  text-align: center;
  color: #fff;
  font-weight: 300;
  position: relative;
} */

/* html body .btn-ecomstore:first-child {
  border: 1px solid #306ab4;
}

html body .btn-ecomstore:nth-child(2) {
  background: #33a348;
  border: 1px solid #33a348;
}

.btn-ecom-active {
  color: #000;
}

html body .btn-ecomstore {
  transition: 0.3s ease;
}

a.btn-ecom-active {
  color: #000;
}

html body .btn-ecomstore {
  background: #306ab4;
}

p.ecom-desc-set {
  padding-top: 13px;
}

.ecom-right-desc small {
  font-weight: 100;
  color: #565656;
}

.btn-ecomstore:hover {
  color: #fff;
}

.ecom-right-desc p {
  font-size: 14px;
  line-height: 26px;
  font-weight: 300;
}

.btn-ecomstore.btn-stores {
  background: #3bb54a;
} */

/* table styles */

/* table.ecom-table-set {
  width: 100%;
}

.ecom-table-inner {
  max-height: 15rem;
  overflow: auto;
}

table.ecom-table-set thead {
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  line-height: 16px;
  height: 35px;
}

.ecom-table-set th:first-child {
  width: 30%;
  padding-left: 2rem;
}

.ecom-table-set th:nth-child(2) {
  width: 30%;
}

.ecom-table-set th:nth-child(4),
.ecom-table-set th:nth-child(3) {
  width: 20%;
}

.ecom-table-sec {
  margin-bottom: 0 !important;
}

table.ecom-table-set tbody tr td {
  padding: 0.8rem 0rem 0.8rem 0rem;
}

table.ecom-table-set tbody tr {
  border-bottom: 1px solid var(--border-light);
}

table.ecom-table-set tbody tr:nth-child(even) {
  background-color: #fcfcf9 !important;
}

.pl-3 {
  padding-left: 2rem;
}

.ecom-left-desc {
  text-align: center;
}

.__warehouse_desc {
  width: 70%;
  margin: 0 auto !important;
  margin-top: 9px !important;
  font-size: 13px;
}

.ecom-left-desc .ecom-integrate + p {
  margin-bottom: 35px;
}

.sync-store {
  font-size: 17px;
  font-weight: 100;
}

.sync-store input[type='text'] {
  height: 40px;
  width: 46%;
  border: 1px solid #878787;
  color: #808080;
  font-size: 16px;
  padding-left: 13px;
}

.sync-store input[type='text'].error {
  border: 1px solid red;
}

.sync-store > span {
  margin-right: 10px;
  color: #000;
}

span.sync-store-inner {
  margin-left: 10px;
}

.action-btns {
  border-top: 1px solid #dfe1e5;
  padding-top: 18px;
} */

/* .sync-note {
  font-weight: 500;
  padding: 13px 0 10px 0;
}

.modal-inner {
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding-bottom: 25px;
}

.modal-inner .sync-store {
  flex: 0 0 55%;
}

.sync-note {
  flex: 0 0 45%;
}

.ecom-table-sec {
  float: left;
  width: 100%;
} */

/* Details css */

/* .d-flex {
  display: flex;
}

.ecom-warehouse-main {
  float: left;
  width: 100%;
}

.warehouse-block {
  background: #eff5fc;
  padding: 30px 20px 10px 20px;
}

.warehouse-block .warehouse-desc h3 {
  font-size: 20px;
  color: #306ab4;
  margin-bottom: 20px;
}

.remove_pagination
  .pagination-container.pagination-route.sync-history-pagination {
  display: none;
} */

/* .warehouse-bottom .btn-sync{

} */

/* html body .warehouse-desc .btn.btn-enable {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  align-items: center;
}

.warehouse-bottom .btn-sync,
html body .warehouse-desc .btn.btn-enable {
  color: #fff;
  font-size: 16px;
  min-width: 100px;
  border-radius: 5px !important;
  padding: 7px 20px;
  background-color: #33a348;
  margin-top: 20px;
}

.ecom-warehouse-bottom {
  float: left;
  width: 100%;
}

.ecom-warehouse-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.ecom-warehouse-row .warehouse-block-main {
  border: 1px solid #eeeeee;
  flex: 0 0 32.9%;
  min-height: 227px;
  margin-right: 7px;
  padding: 10px 0 0 15px;
  position: relative;
}

.ecom-warehouse-row .warehouse-block-main:last-child {
  margin-right: 0;
}

.warehouse-items-row.d-flex {
  align-items: center;
}

.warehouse-items-row .items-center,
.warehouse-items-row .items-left {
  flex: 0 0 30%;
}

.warehouse-items-row .items-right {
  flex: 0 0 40%;
}

.warehouse-items-row .items-center {
  text-align: center;
}

.btn.btn-sync.btn-disabled {
  background-color: #dfe1e5;
  color: #000;
}

svg.warehouse-icon {
  position: absolute;
  right: 15px;
}

span.ecom-details button {
  color: var(--blue-500);
  cursor: pointer;
} */

/* Custom dropdown styles */

/* .warehouse-desc select {
  outline: 0;
  border: 0;
  box-shadow: none;
  flex: 1 1;
  padding: 0 1em;
  color: #000;
  background-image: none;
  cursor: pointer;
  border: 1px solid #777;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: horizontal;
}

select::-ms-expand {
  display: none;
} */

/* Custom Select wrapper */
/* 
.warehouse-desc .select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: 0.25em;
  margin: 0 auto;
} */

/* Arrow */

/* .warehouse-desc .select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: 0.25s all ease;
  pointer-events: none;
  border: 1px solid #777;
  height: 3em;
  background-color: #fff;
} */

/* button.btn-connect.btnloader {
  display: flex;
  float: right;
  align-items: center;
}

.action-btns .loader-set {
  top: 0;
}

.btnloader .loader-set {
  -webkit-animation: spinSmallLoader 1s linear infinite;
  animation: spinSmallLoader 1s linear infinite;
  border: 3px solid #ddd;
  border-top: 3px solid #589dd8;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  position: relative;
  top: 5px;
  left: -8px;
} */

/* @-webkit-keyframes spinSmallLoader {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinSmallLoader {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */

/* Sync hisotry table css */

/* div.item-sec-wrp div.item-sec {
  display: inline-block;
  border-right: 2px solid #ddd;
  color: #888;
  font-size: 12px;
  padding: 0px 5px;
}

div.item-sec-wrp div.item-sec:first-child {
  padding-left: 0px;
}

div.item-sec-wrp div.item-sec:last-child {
  border-right: 0px;
}

div.item-view {
  margin-top: 10px;
}

div.item-images-grid {
  max-width: 150px;
  text-align: center;
  margin-top: 5px;
}

div.item-images-grid div.image-item {
  width: 30px;
  height: 40px;
  margin: 0px 3px;
  border-radius: 3px;
  cursor: pointer;
}

div.item-view-wrapper {
  background: #f8f8f8;
  padding: 5px 10px 10px 10px;
  border: 1px solid #ccc;
  border-top: 0;
}

.item-view-wrapper .container {
  width: 100%;
}

h4.item-name {
  margin-top: 0;
  font-size: 24px;
}

.item-content {
  padding-left: 15px;
}

.item-content b {
  color: #777;
} */

/* .select-all-wrapper {
  display: inline-block;
  margin-right: 5px;
  padding-right: 10px;
}

.selectall-h4 {
  display: inline-block;
}

.confirm-delete-header {
  background-color: #f4d4d4;
  border-bottom: 1px solid #eab298;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.list-item-act {
  display: inline-block !important;
}

.list-table--link-error {
  color: red !important;
}

.checkall_fix_split_btn {
  display: none;
}

.check_all_fix_rc {
  display: inline-block;
  padding: 6px 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
  background-color: #fff;
}

.item-list-exp-body {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-gap: 1.5rem;
}

.item-list--table .list-table--row-name div:first-child {
  color: var(--blue-500);
}

.item-list-exp--img-container {
  padding-right: 1.5rem;
  border-right: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-list-exp-first-section {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-gap: 2rem;
} */

/* TODO: will be moved */

/* .list-head {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.list-head .sort-dropdown .custom-dropdown.button-text .button {
  font-size: 1.4rem;
}

.general_module_icon {
  width: 20px !important;
  height: 20px !important;
} */

/* sync table */

/* .ecom-sync-table tr.table-row td .list-table--link {
  padding: 0;
  margin: 0;
}

.ecom-sync-table span.list-table--item--col div,
.ecom-sync-table tr.table-row td .list-table--link .small-td-info,
.ecom-sync-table tr.table-row td .list-table--link .text-right {
  margin: 0;
}

.ecom-sync-table span.list-table--item--col div span {
  display: block;
  width: 100%;
} */

/* custom dropdown */

/* .sync-history-dropdown select { */
/* Reset Select */
/* appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none; */
/* Personalize */
/* flex: 1;
  padding: 0 1em;
  color: #000;
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
  font-weight: 200;
} */

/* Remove IE arrow */

/* .sync-history-dropdown select::-ms-expand {
  display: none;
} */

/* Custom Select wrapper */

/* .sync-history-dropdown .select {
  position: relative;
  display: flex;
  width: 16em;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
  border: 1px solid #dfe1e5;
  color: #000;
  margin-left: auto;
  margin-right: 45px;
} */

/* Arrow */

/* .sync-history-dropdown .select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #fff;
  transition: 0.25s all ease;
  pointer-events: none;
  border-left: 1px solid #dfe1e5;
}

.sync-history-dropdown {
  float: right;
  width: 100%;
}

button.btn.btn-resync {
  background: #306ab4;
  margin-right: 17px;
  color: #fff;
  min-width: 74px;
}

.sync-history-dropdown {
  display: flex;
  align-items: center;
}

.close-icon.close_icon.close-icon-pos {
  top: 28px !important;
  width: 16px;
}

.close-icon.close_icon.close-icon-pos svg {
  font-size: 2.2rem;
}

span.invalid-url {
  display: block;
  padding-left: 68px;
  margin-top: 6px;
  font-size: 1.4rem;
  color: var(--red-500);
}

.ecom-sync-table th.sorted-background {
  width: 23%;
}

.sync-desc p {
  font-size: 12px;
  color: #8e8e8e;
  font-weight: 300;
  margin-top: 0;
  margin-left: 10px;
}

.sync-desc h4 {
  font-size: 14px;
  color: #1d1d1d;
  font-weight: bold;
  margin-bottom: 5px;
}

.list-table.ecom-sync-table tbody tr {
  height: 50px;
} */

/* popup stylings */

/* .disconnect-popup-main h3 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 20px;
}

.disconnect-popup-main ul {
  padding-top: 5px;
  display: block;
  padding-left: 20px;
}

.disconnect-popup-main ul li {
  list-style: disc;
  margin-bottom: 20px;
}

.sync-interval-desc {
  display: flex;
  align-items: center;
}

.sync-interval-desc p {
  margin-bottom: 0;
  font-size: 16px;
  flex: 0 0 26%;
}

.sync-interval-desc select {
  width: 225px;
  height: 40px;
  position: relative;
}

.sync-interval-desc select:focus {
  outline: 0;
}

.interval-desc b {
  text-transform: uppercase;
}

.interval-desc {
  padding-top: 15px;
}

.error-message {
  background-color: red;
}

.addUnFocusLayer {
  opacity: 0.3;
  pointer-events: none;
}

tr.table-row.unsyncOrders {
  background: #ef5d3a5e !important;
}
.mappBtn {
  margin: 12px 0px 0px 50px !important;
  display: grid !important;
}
.seebizTitle {
  opacity: 0.6;
}

.items-center.stock_img {
  transform: rotate(180deg);
} */
/*===========================
   1-28-2022
=============================*/

/* .ecom-integration .card-header {
  padding: 1.5rem;
  height: 5rem;
} */

/* .ecom-integration .card {
  height: auto;
}

.ecom-integration .card-body {
  color: var(--black);
  padding: 1rem 2rem;
  font-size: unset;
}

.ecom-integration .card:hover {
  box-shadow: none;
}

.ecom-integration .pref--hr {
  margin: 2rem 0;
} */

/* .ecom-integration .form-field {
  grid-template-columns: auto;
  align-items: unset;
}

.ecom-integration .textarea {
  min-width: 42rem;
  min-height: 12rem;
}

.ecom-integration .form-label {
  text-align: left;
} */

/* .genral_tab_content {
  padding: 20px 0;
  height: calc(100vh - 192px);
  position: relative;
  overflow: auto;
  overflow-x: hidden;
} */

/* .genral_tab_content .action-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
} */

/* .genral_tab_content input {
  float: left;
  top: 3px;
  margin-right: 10px !important;
  left: 0;
} */

/* .genral_tab_content .radio-btn label {
  padding-left: 30px;
  margin-bottom: 10px !important;
}

.genral_tab_content .radio-btn {
  display: block !important;
}

.genral_tab_content .radio-circle {
  top: 1px;
}
 */


/* .general_tabs_set textarea#terms {
  font-weight: 400;
}

.general_tabs_set label.form-label {
  color: #000;
  font-size: 18px;
  font-weight: 400;
} */


/* .btn-cancel,
.btn-connect {
  color: #fff;
  font-size: 16px;
  min-width: 100px;
  border-radius: 5px;
  padding: 7px 20px;
} */

/* .btn-cancel {
  background-color: #dfe1e5;
  color: #8e8e8e;
}

.btn-connect {
  background-color: #33a348;
} */



/* .btn-ecom:hover {
  color: var(--blue-500);
  text-decoration: underline;
} */


/* TODO: will be moved */

/* .list-head {
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.list-head .sort-dropdown .custom-dropdown.button-text .button {
  font-size: 1.4rem;
}

.general_module_icon {
  width: 20px !important;
  height: 20px !important;
}

/* sync table */

/* .ecom-sync-table tr.table-row td .list-table--link {
  padding: 0;
  margin: 0;
}

.ecom-sync-table span.list-table--item--col div,
.ecom-sync-table tr.table-row td .list-table--link .small-td-info,
.ecom-sync-table tr.table-row td .list-table--link .text-right {
  margin: 0;
}

.ecom-sync-table span.list-table--item--col div span {
  display: block;
  width: 100%;
} */

/* custom dropdown */

/*.sync-history-dropdown select {*/
  /* Reset Select */
  /* appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none; */
  /* Personalize */
  /* flex: 1;
  padding: 0 1em;
  color: #000;
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
  font-weight: 200;
} */

  /* Remove IE arrow */

  /* .sync-history-dropdown select::-ms-expand {
    display: none;
  } */

  /* Custom Select wrapper */

  /* .sync-history-dropdown .select {
    position: relative;
    display: flex;
    width: 16em;
    height: 3em;
    border-radius: 0.25em;
    overflow: hidden;
    border: 1px solid #dfe1e5;
    color: #000;
    margin-left: auto;
    margin-right: 45px;
  } */

  /* Arrow */

  /* .sync-history-dropdown .select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    background-color: #fff;
    transition: 0.25s all ease;
    pointer-events: none;
    border-left: 1px solid #dfe1e5;
  } */

  /* .sync-history-dropdown {
    float: right;
    width: 100%;
  }

  button.btn.btn-resync {
    background: #306ab4;
    margin-right: 17px;
    color: #fff;
    min-width: 74px;
  } */

  /* .sync-history-dropdown {
    display: flex;
    align-items: center;
  }

  .close-icon.close_icon.close-icon-pos {
    top: 28px !important;
    width: 16px;
  }

  .close-icon.close_icon.close-icon-pos svg {
    font-size: 2.2rem;
  } */

  /* span.invalid-url {
    display: block;
    margin-top: 6px;
    color: var(--red-500);
    font-size: 14px;
  }

  .ecom-sync-table th.sorted-background {
    width: 23%;
  }

  .sync-desc p {
    font-size: 12px;
    color: #8e8e8e;
    font-weight: 300;
    margin-top: 0;
    margin-left: 10px;
  } */

  /* .sync-desc h4 {
    font-size: 14px;
    color: #1d1d1d;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .list-table.ecom-sync-table tbody tr {
    height: 50px;
  } */

  /* popup stylings */

  /* .disconnect-popup-main h3 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .disconnect-popup-main ul {
    padding-top: 5px;
    display: block;
    padding-left: 20px;
  }

  .disconnect-popup-main ul li {
    list-style: disc;
    margin-bottom: 20px;
  }

  .sync-interval-desc {
    display: flex;
    align-items: center;
  }

  .sync-interval-desc p {
    margin-bottom: 0;
    font-size: 16px;
    flex: 0 0 26%;
  }

  .sync-interval-desc select {
    width: 225px;
    height: 40px;
    position: relative;
  }

  .sync-interval-desc select:focus {
    outline: 0;
  }

  .interval-desc b {
    text-transform: uppercase;
  }

  .interval-desc {
    padding-top: 15px;
  }

  .error-message {
    background-color: red;
  }

  .addUnFocusLayer {
    opacity: 0.3;
    pointer-events: none;
  }

  tr.table-row.unsyncOrders {
    background: #ef5d3a5e !important;
  }

  .mappBtn {
    margin: 12px 0px 0px 50px !important;
    display: grid !important;
  }

  .seebizTitle {
    opacity: 0.6;
  }

  .items-center.stock_img {
    transform: rotate(180deg);
  } */




  /* table.ecom-table-set thead {
    border-top: 1px solid var(--border-dark);
    border-bottom: 1px solid var(--border-dark);
    line-height: 16px;
    height: 35px;
  } */



  /* .ecom-left-desc {
    text-align: center;
  } */

  /* .__warehouse_desc {
    width: 70%;
    margin: 0 auto !important;
    margin-top: 9px !important;
    font-size: 13px;
  } */

  /* .ecom-left-desc .ecom-integrate+p { */
  /* margin-bottom: 35px; */
  /*  */

  /* .sync-store {
    font-size: 17px;
    font-weight: 100;
  } */

  /* .sync-store input[type='text'] {
    height: 40px;
    width: 46%;
    border: 1px solid #878787;
    color: #808080;
    font-size: 16px;
    padding-left: 13px;
  } */

  /* .sync-store input[type='text'].error {
    border: 1px solid red;
  }
  
  .sync-store>span {
    margin-right: 10px;
    color: #000;
  } */



  /* .modal-inner {
    display: flex;
    width: 100%;
    align-items: flex-start;
    padding: 25px 0;
  }
  
  .modal-inner .sync-store {
    flex: 0 0 55%;
  } */

  /* .sync-note {
    flex: 0 0 45%;
  } */



  /* Details css */

  /*  */

  /* .warehouse-bottom .btn-sync{
  
  } */

  /* html body .warehouse-desc .btn.btn-enable {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
  } */

  /* .warehouse-bottom .btn-sync,
  html body .warehouse-desc .btn.btn-enable {
    color: #fff;
    font-size: 16px;
    min-width: 100px;
    border-radius: 5px !important;
    padding: 7px 20px;
    background-color: #33a348;
    margin-top: 20px;
  } */

  /* .ecom-warehouse-bottom {
    /* float: left; */
  /* width: 100%; */
  /*
}

*/
/* .ecom-warehouse-row .warehouse-block-main {
    border: 1px solid #eeeeee;
    flex: 0 0 32.9%;
    min-height: 227px;
    margin-right: 7px;
    padding: 10px 0 0 15px;
    position: relative;
  }
  
  .ecom-warehouse-row .warehouse-block-main:last-child {
    margin-right: 0;
  }
  
  .warehouse-items-row.d-flex {
    align-items: center;
  }
  
  .warehouse-items-row .items-center,
  .warehouse-items-row .items-left {
    flex: 0 0 30%;
  }
  
  .warehouse-items-row .items-right {
    flex: 0 0 40%;
  }
  
  .warehouse-items-row .items-center {
    text-align: center;
  }
  
  .btn.btn-sync.btn-disabled {
    background-color: #dfe1e5;
    color: #000;
  } */

/* svg.warehouse-icon {
    position: absolute;
    right: 15px;
  }

  span.ecom-details button {
    color: var(--blue-500);
    cursor: pointer;
  }

  /* Custom dropdown styles */

/* .warehouse-desc select {
    outline: 0;
    border: 0;
    box-shadow: none;
    flex: 1 1;
    padding: 0 1em;
    color: #000;
    background-image: none;
    cursor: pointer;
    border: 1px solid #777;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: horizontal;
  } */

select::-ms-expand {
  display: none;
}

/* Custom Select wrapper */

/* .warehouse-desc .select {
    position: relative;
    display: flex;
    width: 20em;
    height: 3em;
    border-radius: 0.25em;
    /* overflow: hidden; */
/* margin: 0 auto; */



/* Arrow */


/* button.btn-connect.btnloader {
  display: flex;
  float: right;
  align-items: center;
}

.action-btns .loader-set {
  top: 0;
}

.btnloader .loader-set {
  -webkit-animation: spinSmallLoader 1s linear infinite;
  animation: spinSmallLoader 1s linear infinite;
  border: 3px solid #ddd;
  border-top: 3px solid #589dd8;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  position: relative;
  top: 5px;
  left: -8px;
} */

/* @-webkit-keyframes spinSmallLoader {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */

/* @keyframes spinSmallLoader {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */

/* Sync hisotry table css */

/* div.item-sec-wrp div.item-sec {
  display: inline-block;
  border-right: 2px solid #ddd;
  color: #888;
  font-size: 12px;
  padding: 0px 5px;
}

div.item-sec-wrp div.item-sec:first-child {
  padding-left: 0px;
}

div.item-sec-wrp div.item-sec:last-child {
  border-right: 0px;
}

div.item-view {
  margin-top: 10px;
}

div.item-images-grid {
  max-width: 150px;
  text-align: center;
  margin-top: 5px;
} */

/* div.item-images-grid div.image-item {
  width: 30px;
  height: 40px;
  margin: 0px 3px;
  border-radius: 3px;
  cursor: pointer;
}

div.item-view-wrapper {
  background: #f8f8f8;
  padding: 5px 10px 10px 10px;
  border: 1px solid #ccc;
  border-top: 0;
}

.item-view-wrapper .container {
  width: 100%;
}

h4.item-name {
  margin-top: 0;
  font-size: 24px;
}

.item-content {
  padding-left: 15px;
}

.item-content b {
  color: #777;
} */

/* .select-all-wrapper {
  display: inline-block;
  margin-right: 5px;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}

.selectall-h4 {
  display: inline-block;
}

.confirm-delete-header {
  background-color: #f4d4d4;
  border-bottom: 1px solid #eab298;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
} */

/* .list-item-act {
  display: inline-block !important;
}

.list-table--link-error {
  color: red !important;
}

.checkall_fix_split_btn {
  display: none;
}

.check_all_fix_rc {
  display: inline-block;
  padding: 6px 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
  background-color: #fff;
}

.item-list-exp-body {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-gap: 1.5rem;
} */

/* .item-list--table .list-table--row-name div:first-child {
  color: var(--blue-500);
}

.item-list-exp--img-container {
  padding-right: 1.5rem;
  border-right: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */


/* .item-list-exp-first-section {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-gap: 2rem;
  } */