.package-item-info.credit_sales_return_table div.border-top {
  border-top: 1px solid var(--border-light);
  padding-top: 10px;
}

span.address_sec {
  height: 100px;
  overflow-y: auto;
}

.cn-summary-info .credit_feild {
  width: 100%;
}
.invoice-desc-main .invoice_to{
  width: 50%;
  background-color: #f9f9f9;
  padding-left:25px;
}
@media  screen and (max-width:1366px) {
  .invoice-desc-main .invoice_to{
    width: 100%;
  }
}
@media(max-width:1600px) {
  .expanded_view_creditnote th.list-table--settings {
    width: 3%;
  }
}